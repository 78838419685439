import CommonService from './common.service';

function returnQueryFormat(params) {
  // returns the query in URL ready format
  // params: conatins
  var queryString = '?';
  const keys = Object.keys(params);
  for (const key of keys) {
    if (key === 'from' || key === 'to') {
      var encodedKey = encodeURIComponent(params[key]);
      queryString = queryString + key + '=' + encodedKey + '&';
    } else {
      queryString = queryString + key + '=' + params[key] + '&';
    }
  }

  let finalQueryString = queryString.slice(0, -1);

  return finalQueryString;
}
function getVsMetrics(params) {
  const api = CommonService.getApiUrl("vsmetrics");
  return CommonService.httpGet(api, params);
}
function getTeamApplications(params) {
  const api = CommonService.getApiUrl("userData/getAllTeamApplications");
  return CommonService.httpGet(api, params);
}
function getFormattedProgramData(userData) {
  const data = []
  userData.Programs.map(
    (programDataItem) => {
      var dataObject = {};
      dataObject["program_name"] = programDataItem.program_name;
      dataObject["description"] = programDataItem.description;
      dataObject["program_code"] = programDataItem.program_name.slice(0, 3).toLowerCase()
      data.push(dataObject);
    }
  )
  return { result: data }
}


const UsageDashboardService = {
  getVsMetrics, getFormattedProgramData, getTeamApplications
};
export default UsageDashboardService;
