import React, { useEffect, useState } from 'react';
import { Pasmat } from '@COE-Telstra/pasmat-ui_coe'
import pasmatService from '../../services/pasmat.service';
import { useHistory } from "react-router-dom";
import errorResponses from '../ErrorHandling/errorResponses';
import Swal from 'sweetalert2';
import './viewPasmatSurvey.css'

const ViewPasmatSurvey = (props) => {

  let history = useHistory();

  const [pasmatQuestions, setPasmatQuestions] = useState([])

  const pasmatAnswers = (props.location.state.pasmatAnswers);

  const setData = async () => {
    try {
      let questions = await pasmatService.getQuestions();
      if (questions.data.pages.length > 0) {
        let questionsList = [];
        questions.data.pages.forEach(element => {
          questionsList.push(...element.questions);
        });
        setPasmatQuestions([{
          "phase": '',
          "questions": questionsList
        }]);

      }
    }
    catch (error) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        },
      });
    }
  }

  useEffect(() => {
    setData();
  }, [])

  useEffect(() => {
    let inputElement = document.getElementsByTagName("input");
    for (let i = 0; i < inputElement.length; i++) {
      inputElement[i].setAttribute('disabled', true);
    };

    let completedBtnElement = document.getElementsByClassName("blurButtonsComplete");
    if (completedBtnElement && completedBtnElement.length) {
      for (let i = 0; i < completedBtnElement.length; i++) {
        completedBtnElement[i].setAttribute('style', "display : none");
      };
    }

    let headerElement = document.getElementsByTagName("header");
    if (headerElement && headerElement.length) {
      for (let i = 0; i < headerElement.length; i++) {
        headerElement[i].setAttribute('style', "display : none");
      };
    }
  }, [pasmatQuestions])

  return pasmatQuestions.length ? <Pasmat
    pasmatQuestions={pasmatQuestions}
    pasmatAnswers={pasmatAnswers}
    hideHeading={false}
    subHeading="Collaborative framework to capture qualitative and quantitative product adoption metrics across software engineering teams."
  /> : [];
};

export default ViewPasmatSurvey;