import MaterialTable from 'material-table'
import React, { useState, useEffect } from 'react'
import { Form, Input, Label, Row, Col, Button, Container, Card } from 'reactstrap'
import Swal from 'sweetalert2';

import { palette } from "../../../../src/assets/colors"
import { getAllValueChainsForProgram, addOrUpdateValueChain, getAllPrograms } from "../../../services/support.service";
import {

    Collapse

} from "@material-ui/core";

const columns = [
    // Field names are taken exactly  from database.
    { title: "Valuechain  Name", field: "valueChain_name", width: "10%" },
    { tile: "icon", field: "icon", width: "10%" },
    { title: "color", field: "color", width: "10%" },
    { title: "program_id", field: "program_id", width: "10%" },
    { title: "Valuechain Description", field: "description", width: "10%" },
    { title: "Valuechain Code", field: "valuechain_code", width: "80%" },
];
// this means initial data
const dataConst = [
    // These values are using the same field names as database
    {
        program_id: 1,
        valueChain_name: 'B2B Digitisation',
        description: 'b2b Digitisation',
        valuechain_code: 'b2b',
        color: "green",
        icon: "profitable"
    },
    {
        program_id: 1,
        valueChain_name: 'B2C Digitisation',
        description: 'b2c Digitisation',
        valuechain_code: 'b2c',
        color: "blue",
        icon: "profitable"
    },

]

export default function ValuechainEdit() {
    const [selectedProgram, setSelectedProgram] = useState({ program_name: 'test', program_id: 1, program_code: "code" });
    const [allPrograms, setAllPrograms] = useState([{

        program_id: 1,
        program_name: 'B2B Digitisation',
        description: 'b2b Digitisation',
        program_code: 'b2b'

    },
    {
        program_id: 2,
        program_name: 'B2C Digitisation',
        description: 'b2c Digitisation',
        program_code: 'b2c'
    }
    ])
    const [showForm, setShowForm] = useState(false);
    // defines the current selected index of the materialTable
    const [revision, setRevision] = useState(false)
    // data is used to display the material table at the top
    const [valueChains, setValueChains] = useState(dataConst);
    // determines if the program is newly added or not. If Program is newly added, the program_code will be editable else not.
    const [isNewValuechain, setIsNewValuechain] = useState(false);

    // programData is used for handling form data
    const [selectedValuechainData, setSelectedValuechainData] = useState({
        valueChain_name: '', description: 'set Description',
        valuechain_code: '', color: 'green', icon: 'crowd-support', program_id: 1
    })

    const actions = [

        {
            icon: "add_circle",
            iconProps: { style: { fontSize: "35px", color: palette.primaryshade2 } },
            tooltip: "Add endpoint",
            isFreeAction: true,

            onClick: () => {
                setSelectedValuechainData({
                    valueChain_name: '',
                    description: '',
                    valuechain_code: '',
                    icon: 'crowd-support',
                    color: 'green',
                    program_id: 1
                })
                setValueChains([...valueChains, {
                    valueChain_name: 'Default Name',
                    description: 'Default Description',
                    valuechain_code: 'Default value chain code',
                    icon: 'crowd-support',
                    color: 'green'
                }])
                setIsNewValuechain(true);
            },
        },
    ];

    const saveButton = async () => {
        try {

            await addOrUpdateValueChain({
                valueChain_name: selectedValuechainData.valueChain_name, description: selectedValuechainData.description,
                valuechain_code: selectedValuechainData.valuechain_code, color: selectedValuechainData.color,
                icon: selectedValuechainData.icon, program_id: selectedValuechainData.program_id
            });
            Swal.fire("ValueChain Added")
        }
        catch (error) {
            Swal.fire("Failed to update")
        }

    }

    const onProgramDataChange = (e) => {
        let objectValue = selectedValuechainData;
        objectValue[e.target.name] = e.target.value;
        setSelectedValuechainData(objectValue);
        setRevision(!revision)
    }
    const getData = async () => {
        try {
            const response = await getAllPrograms();
            setAllPrograms(response.data.data)
            // setSelectedProgram(response.data.data[0]['program_name'])
            setSelectedProgram(response.data.data[0])
            let valueChainResponse = await getAllValueChainsForProgram({ program_id: response.data.data[0]['program_id'] })
            setValueChains(valueChainResponse.data.data)
        }
        catch (error) {
            Swal.fire(`Something went wrong. Error ${error}`);
        }

    }

    const getValueChainsForProgramFunc = async (program_id) => {
        try {
            const response = await getAllValueChainsForProgram({ program_id: program_id })
            setValueChains(response.data.data)
        }
        catch (error) {
            Swal.fire("something went wrong")
        }
    }
    useEffect(() => {
        getData()
    }, []);
    return (

        <div>
            <Row>
                <h1>ValueChains </h1>
            </Row>
            <div >

                <Container className="mt-3 p-2 pl-0 pr-0">
                    <Row>
                        <Col xs="4">
                            <Input
                                type="select"
                                bsSize="lg"
                                name="selectedProgramName1"
                                value={selectedProgram['program_id']}
                                onChange={(e) => {
                                    const progName = allPrograms.filter((programItm) => programItm.program_id === parseInt(e.target.value));
                                    setSelectedProgram(progName[0])

                                    getValueChainsForProgramFunc(e.target.value)

                                }
                                }
                            >
                                {
                                    allPrograms.map((programItem, index) => {
                                        return <option key={index}
                                            value={programItem.program_id}
                                        >{programItem.program_name}</option>
                                    })
                                }

                            </Input>
                        </Col></Row>
                    <br /><br />
                    <Form mt-3 p-2>



                        <MaterialTable
                            style={{ width: "100%" }}
                            columns={columns}
                            data={valueChains}
                            actions={actions}
                            onRowClick={(evt, selectedRow) => {

                                setShowForm(true);
                                setSelectedValuechainData({
                                    valueChain_name: selectedRow.valueChain_name, description: selectedRow.description,
                                    valuechain_code: selectedRow.valuechain_code, color: selectedRow.color,
                                    icon: selectedRow.icon, program_id: selectedRow.program_id
                                })

                                // only row fetched from database will have program_id and the newly added program won't have it.
                                if (selectedRow.hasOwnProperty('valueChain_id')) {
                                    setIsNewValuechain(false)
                                }
                                else {
                                    setIsNewValuechain(true)
                                }



                            }}
                            options={{


                                headerStyle: {
                                    fontWeight: "bold",
                                    backgroundColor: palette.blueseq[3],
                                },
                            }}
                            title="Programs"

                        />



                    </Form>
                </Container>
            </div>
            <br /><br />
            <Collapse in={showForm}>
                <Form className="float-left w-100 p-2">

                    {/* {(showForm === false) ? (<></>) : ( */}
                    <Card
                        className="p-3"
                        style={{ backgroundColor: palette.blueseq[0] }}
                    >
                        <Row ><Col xs="3">
                            <Label for="program_name" className="mt-3 float-left">Program name</Label>
                        </Col>
                            <Col xs="4" ><Input type="select" name="program_id" id="program_id"
                                onChange={(e) => { onProgramDataChange(e) }}
                                disabled={(isNewValuechain) ? false : true}
                                value={selectedValuechainData.program_id} >
                                {allPrograms.map((programItem, index) => {
                                    return <option key={index}
                                        value={programItem.program_id}
                                    >{programItem.program_name}</option>
                                }


                                )}
                            </Input>
                            </Col>

                        </Row>


                        <Row className="mt-3"><Col xs="3">
                            <Label for="valueChain_name" className="mt-3 float-left">Value chain  name</Label>
                        </Col>
                            <Col xs="4" ><Input type="text" name="valueChain_name" id="valueChain_name" placeholder="Enter program name"
                                onChange={(e) => { onProgramDataChange(e) }} value={selectedValuechainData.valueChain_name} />
                            </Col>

                        </Row>
                        <Row className="mt-3">
                            <Col xs="3">
                                <Label for="description" className="mt-3 float-left">Value chain description</Label>
                            </Col>
                            <Col xs="4"><Input type="text" name="description" id="description" placeholder="Enter description"
                                onChange={(e) => { onProgramDataChange(e) }} value={selectedValuechainData.description} /></Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="3">
                                <Label for="valuechain_code" className="mt-3 float-left">Value chain  code</Label>
                            </Col>
                            <Col xs="4">
                                <Input type="text" name="valuechain_code" id="valuechain_code" placeholder="Enter value chain code"
                                    onChange={(e) => { onProgramDataChange(e) }} value={selectedValuechainData.valuechain_code} disabled={(isNewValuechain) ? false : true} />
                            </Col>
                        </Row>
                        <br />
                        <Row className="mt-3">

                            <Col xs="5">
                                <Button

                                    color="success"
                                    className="mt-3 float-left btn-lg"
                                    onClick={saveButton}>
                                    Save
                               </Button>
                            </Col>
                        </Row>

                    </Card>
                    {/* } */}


                </Form>
            </Collapse>

        </div>
    )
}
