import './LatestActivity.css';
import React , { useEffect, useState }from "react";
import { Row, Col , Spinner} from "reactstrap";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getDashboardMetrics } from "../../services/support.service";
import errorResponses from "../ErrorHandling/errorResponses";
import DashboardStatCard from '../Support/Dashboard/Cards/DashboardStatCard';


const LatestActivity = () => {

    let history = useHistory();
    
    const [dashboardMetrics, setDashboardMetrics] = useState(undefined);

    const generateGradient = (index) => {
		return index === 0 || index === 3 ? 'custom-gradient-2' : 'custom-gradient-3';
	};

  const getMetrics = async () => {
		try {
			let response = await getDashboardMetrics();
			if (response.data !== undefined) {
				setDashboardMetrics(response.data.data);
			}
		}
		catch (error) {
			history.push({
				pathname: 'errorPage',
				state: {
					errorMessage: errorResponses.GENERIC_ERROR,
				},
			});
		}
	};

  const renderDashboardStatCards = () => {
		let metricKeys = Object.keys(dashboardMetrics);

		return metricKeys.map((metric, index) => {
			return (
				<Col xs={3} key={index}>
					<DashboardStatCard gradient={generateGradient(index)} metrics={dashboardMetrics[metric]} />
				</Col>
			);
		});
	};

  useEffect(() => {
			getMetrics();
	},[]);
 
    return (
        <div className="white mt-3">
			<h3>
                Latest Activity <FontAwesomeIcon className="ml-2" icon={faUsers} size="1x" />
			</h3>
			<hr />
			<Row>
				{dashboardMetrics !== undefined && renderDashboardStatCards()}
				{dashboardMetrics === undefined &&
              <Col className="text-center"><Spinner className="p-5" color="primary" /></Col>
						}
					</Row>
		</div>
    )
}

export default LatestActivity;
