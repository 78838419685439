import React, { useEffect, useState } from "react";
import {
  TabPane,
  Jumbotron,
  Spinner,
  Button,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
import { Divider, Typography, InputLabel } from "@material-ui/core";
import workspaceSettingsService from "../../services/workspaceSettings.service";


const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

export default function General({ sVHost, activeTab }) {
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const makeMessagesDisappearAfterSometime = () => {
    delay(20000).then(() => {
      setSuccessMessage("");
      setApiErrorMessage("");
    });
  };

  const [scriptEngineStatus, setScriptEngineStatus] = useState({ isEnabled: false, selectedExpiryDays: 1 });

  const [teamAuthPass, setTeamAuthPass] = useState({ ttl: '', authPass: '' })

  const manageScriptEngineChanges = (e) => {
    switch (e.target.name) {
      case "expiryDays":
        setScriptEngineStatus({ ...scriptEngineStatus, selectedExpiryDays: e.target.value });
        break;
      case "enable":
        workspaceSettingsService.manageGeneralSettings(sVHost, {
          scriptEngine: {
            isEnabled: true,
            expiryDays: scriptEngineStatus.selectedExpiryDays
          }
        })
          .then(() => {
            updateGeneralSettingsData();
            setSuccessMessage("Script engine enabled");
            makeMessagesDisappearAfterSometime();
          })
          .catch((error) => {
            setApiErrorMessage(`Unable to enable script engine. ${error}`);
            makeMessagesDisappearAfterSometime();
          });
        break;
      case "disable":
        workspaceSettingsService.manageGeneralSettings(sVHost, {
          scriptEngine: {
            isEnabled: false,
          }
        })
          .then(() => {
            updateGeneralSettingsData();
            setSuccessMessage("Script engine disabled");
            makeMessagesDisappearAfterSometime();
            setScriptEngineStatus({ ...scriptEngineStatus, isEnabled: false, selectedExpiryDays: 3 });
          })
          .catch((error) => {
            setApiErrorMessage(`Unable to disable script engine. ${error}`);
            makeMessagesDisappearAfterSometime();
          });
        break;
      default:
        break;
    }
  };

  const updateGeneralSettingsData = () => {
    setLoading(true);
    workspaceSettingsService.getGeneralSettings(sVHost)
      .then((response) => {
        setScriptEngineStatus({ ...response.data.scriptEngine, selectedExpiryDays: (response.data.scriptEngine.expiryDays ? response.data.scriptEngine.expiryDays : 1), expiryHuman: response.data.scriptEngine.expiryHuman });
        setTeamAuthPass({ ...response.data.teamAuthPass });
        setLoading(false);
      })
      .catch((error) => {
        setApiErrorMessage(`Unable to fetch information. ${error}`);
        makeMessagesDisappearAfterSometime();
        setLoading(false);
      });
  };

  useEffect(() => {
    if (activeTab === "0") {
      updateGeneralSettingsData();
    }
  }, [activeTab]);

  return (
    <div style={{ maxHeight: 500, overflow: 'auto' }}>
      <TabPane tabId="0" className="p-3">
        {loading ? (
          <Jumbotron className="text-center">
            <Spinner className="p-5" color="primary"></Spinner>
          </Jumbotron>
        ) : (
          <>
            <Row className="mb-1">
              <Col>
                <Typography
                  variant="body2"
                  className="text-success"
                  hidden={!successMessage}
                >
                  <span role="img" aria-label="check mark emoji">
                    ✔️
                  </span>{" "}
                  {successMessage}
                </Typography>
                <Typography
                  variant="body2"
                  color="error"
                  hidden={!apiErrorMessage}
                >
                  <span role="img" aria-label="warning emoji">
                    &#9888;&#65039;
                  </span>{" "}
                  {apiErrorMessage}
                </Typography>
              </Col>
            </Row>
            {
              (successMessage || apiErrorMessage) && <Divider className="mb-1 mt-0"></Divider>
            }
            {/* Team auth pass */}
            <Typography variant="h6">Team Authentication Password </Typography>
            <div className="m-0">
              <Row className="mt-1">
                <Col>
                  <Typography variant="subtitle1">
                    This password can be used to securely access debug logs directly by passing sv-debug-print header.
                  </Typography>
                  <Typography variant="subtitle2"><strong>TeamAuthPass: </strong>{teamAuthPass.authPass} (Valid for {teamAuthPass.ttl} sec)</Typography>
                </Col>
              </Row>
            </div>
            <Divider className="m-2"></Divider>
            <Typography variant="h6">Script engine</Typography>
            <div className="m-0">
              <Row className="mt-1">
                <Col>
                {/*
                  <Typography variant="subtitle1">
                    Manage script engine for the workspace. Script engine allows you to execute code in response to a virtual service request.
                  </Typography>
                */}
                  <Typography variant="subtitle1">
                    Script engine is permanently enabled to ensure users can use the 'Execute Javascript' action without having to enable script engine.
                  </Typography>
                </Col>
              </Row>
            </div>
            {/* TODO Following code is hidden because script engine is enabled permanently and no longer needs user to enable from UI. Remove script engine setting as a whole after 30 days. Written on 18MAY2022 */}
            {false && <Typography variant="subtitle2"><strong>Current status: </strong>Script engine is currently{` ${scriptEngineStatus.isEnabled ? "enabled" : "disabled"}${scriptEngineStatus.isEnabled ? `. It will be automatically disabled ${scriptEngineStatus.expiryHuman}` : ``}`}</Typography>}
            {false && 
              <Row className="mt-1">
                <Col xs="5" className="pl-0 ml-3 d-flex align-items-center border">
                  <Col xs="auto" className="p-0">
                    <InputLabel className="m-0 pl-2 pr-2 text-dark font-weight-bold">
                      {scriptEngineStatus.selectedExpiryDays} days
                    </InputLabel>
                  </Col>
                  <Col className="p-0">
                    <CustomInput
                      name="expiryDays"
                      id={1}
                      bsSize="lg"
                      type="range"
                      step={1}
                      min={1}
                      max={5}
                      value={scriptEngineStatus.selectedExpiryDays}
                      onChange={(e) => manageScriptEngineChanges(e)}
                    />
                  </Col>
                  <Col xs="auto" className="p-0">
                    <Button
                      color="primary"
                      size="sm"
                      name="enable"
                      onClick={(e) => manageScriptEngineChanges(e)}
                      className="m-2"
                    >{scriptEngineStatus.isEnabled ? "Update expiry" : "Enable"}</Button>
                  </Col>
                </Col>
                <Col xs="2" className="d-flex align-items-center">
                  {scriptEngineStatus.isEnabled &&
                    <Button
                      color="danger"
                      size="sm"
                      name="disable"
                      onClick={(e) => manageScriptEngineChanges(e)}
                      className="ml-2"
                    >Disable</Button>
                  }
                </Col>
              </Row>
            }
            <div className="m-0">
              <Row className="mt-3">
                <Col>
                  <Typography variant="body2" className="text-warning">
                    <span role="img" aria-label="warning-emoji">&#9888; </span><strong>Warning: </strong> This feature should be used only as a last resort when a virtual service could not
                    be implemented by using native features such as variable set, if conditions, loops, cache and other features.
                    This feature should not be used for convenience purposes as it executes code on AWS - Lambda and is more expensive than the native features.
                  </Typography>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Typography variant="body2" className="text-danger">
                    <span role="img" aria-label="cross-mark-emoji">❌ </span><strong>Disclaimer: </strong> You are responsible for the code executed here. Ensure it is clean and doesn't contain anything malacious.
                    The code is executed in a <strong>NodeJS sandbox</strong> and with a timeout to prevent issues however, it is your responsiblity to ensure your code is not malacious.
                    Always test the code before adding it here to make sure that it is doing what it is and only what it is supposed to do.
                  </Typography>
                </Col>
              </Row>
            </div>
          </>
        )}
      </TabPane>
    </ div>
  );
}
