import React from 'react';
import { Row, Col } from 'reactstrap'
import IframeResizer from 'iframe-resizer-react';
import './PortalHealthMetrics.css';
import CommonService from '../../../services/common.service';

const NewPortalHealthMetricsProd = () => {

    return (
        <>
            <div className="white mt-3">

                {/* ECS CPU Utilisation */}
                <h2 style={{ marginLeft: "1vw" }}>ECS</h2>

                <Row className="mb-n1">
                    <Col className="mb-4" xs={12}>
                        <div className="chart">
                            <IframeResizer
                                // log
                                // src={iFrameSrc}
                                //src="https://chart-embed.service.newrelic.com/herald/97ffad8b-b469-42bf-b482-9a1cccd26f06?height=400px&timepicker=true"
                                src={CommonService.getEcsCpuUrl(CommonService.getCurrentEnv())}
                                style={{ width: '1px', minWidth: '100%', height: '425px' }}
                            />
                        </div>
                    </Col></Row>

                {/* Ecs Memory Utilisation */}
                <Row className="mb-n1"><Col className="mb-4" xs={12}>
                    <div className="chart">
                        <IframeResizer
                            //src="https://chart-embed.service.newrelic.com/herald/cb1646a6-9517-4a7e-87cb-457679b8880b?height=400px&timepicker=true"
                            src={CommonService.getEcsMemoryUrl(CommonService.getCurrentEnv())}
                            style={{ width: '1px', minWidth: '100%', height: '425px' }}
                        />
                    </div>
                </Col>

                </Row>

            </div>
            <div className="white mt-3">

                {/*Number of  Requests by ALB */}
                <h2 style={{ marginLeft: "1vw" }}>Load Balancers</h2>

                <Row className="mb-n1"><Col className="mb-4" xs={12}>
                    <div className="chart">
                        <IframeResizer
                            //src="https://chart-embed.service.newrelic.com/herald/150c55d8-c47b-435b-a622-7c244ff1f368?height=400px&timepicker=true"
                            src={CommonService.getAlbNoOfReq1Url(CommonService.getCurrentEnv())}
                            style={{ width: '1px', minWidth: '100%', height: '425px' }}
                        />
                    </div>
                </Col>


                </Row>
                <Row className="mb-n1">
                    <Col className="mb-4" xs={6}>
                        <div className="chart">
                            <IframeResizer
                                //src="https://chart-embed.service.newrelic.com/herald/348f9604-b4de-4bf7-b7ef-55bb2184e9e3?height=400px&timepicker=true"
                                src={CommonService.getAlbNoOfReq2Url(CommonService.getCurrentEnv())}
                                style={{ width: '1px', minWidth: '100%', height: '425px' }}
                            />
                        </div>
                    </Col>
                    {/* </Row> */}
                    {/* Average Backend response time
                <Row className="mb-n1"><Col className="mb-4" xs={12}>
                <div className="chart">
                <IframeResizer
                style = {{topMargin:"2vh"}}
                    // log
                    // src={iFrameSrc}
                    src ="https://chart-embed.service.newrelic.com/herald/418464bb-17cf-4b36-b867-2fd885cbbfe8?height=400px&timepicker=true"
                    style={{ width: '1px', minWidth: '100%', height: '425px'}}
                />
                </div>
                </Col>
            </Row> */}

                    {/* Backend Response Time ALB */}
                    {/* <Row className="mb-n1"> */}
                    <Col className="mb-4" xs={6}>
                        <div className="chart">
                            <IframeResizer
                                src="https://chart-embed.service.newrelic.com/herald/ca974f73-944c-498f-a28d-ebff26c228f6?height=400px&timepicker=true"
                                style={{ width: '1px', minWidth: '100%', height: '425px' }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            {/* Lambda Invocations */}
            <div className="white mt-3">
                <h2 style={{ marginLeft: "1vw" }}>Lambda Functions</h2>
                <Row className="mb-n1">
                    <Col className="mb-4" xs={6}>
                        <div className="chart">
                            <IframeResizer
                                //src="https://chart-embed.service.newrelic.com/herald/e4c27eb5-077a-4a0c-bc98-009a7e2583e4?height=400px&timepicker=true"
                                src={CommonService.getLambdaInvocationUrl(CommonService.getCurrentEnv())}
                                style={{ width: '1px', minWidth: '100%', height: '425px' }}
                            />
                        </div>
                    </Col>
                    {/* </Row> */}
                    {/* Lambda Errors */}
                    {/* <Row className="mb-n1">*/}
                    <Col className="mb-4" xs={6}>
                        <div className="chart">
                            <IframeResizer
                                //src="https://chart-embed.service.newrelic.com/herald/e85a75e8-1810-4f36-81e5-6c55f82e61bc?height=400px&timepicker=true"
                                src={CommonService.getLambdaErrorUrl(CommonService.getCurrentEnv())}
                                style={{ width: '1px', minWidth: '100%', height: '425px' }}
                            />
                        </div>
                    </Col>
                </Row>


            </div>
            <div className="white mt-3">
                <h2 style={{ marginLeft: "1vw" }}>Other Graphs</h2>
                {/* HTTP Request Errors */}
                <Row className="mb-n1"><Col className="mb-4" xs={12}>
                    <div className="chart">
                        <IframeResizer
                            //src="https://chart-embed.service.newrelic.com/herald/195d5860-f95b-4fcf-89b4-64a11f1181cc?height=400px&timepicker=true"
                            src={CommonService.getHttpReqErrorUrl(CommonService.getCurrentEnv())}
                            style={{ width: '1px', minWidth: '100%', height: '425px' }}
                        />
                    </div>
                </Col>
                </Row>
            </div>
        </>
    )
}

export default NewPortalHealthMetricsProd;