import CommonService from "./common.service";
import {  getUserDetails } from './authentication';

  function getAllTeams(role,searchText='') {
    // role can be admin, GENERAL or FTL
    let user = getUserDetails();
    var params = { user_id: user.id , role:role , searchText};

    const api = CommonService.getApiUrl("getAllTeams");
    return CommonService.httpGet(api, params);
  }

  function getMetrics(params) {
    const api = CommonService.getApiUrl("vsmetrics/usage");
    return CommonService.httpGet(api, params);
  }
  const transactionMetricsService = {
    getAllTeams,
    getMetrics
  };
  export default transactionMetricsService;