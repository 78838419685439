import React from "react";
import DashboardCard from "../../DashboardNew/Cards/DashboardCard";

export const LibraryCard = (props) => (
  <DashboardCard
    title={"Library"}
    subtitle="Browse virtual services library."
    icon=""
    color="green"
    to="library-search"
  />
);

export default LibraryCard;
