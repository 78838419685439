import axios from 'axios';
import config from "./config";
import { getToken } from './authentication';
import { getUserDetails, handleError } from './authentication';

// Purpose : Common get post method for all calls

function getCurrentEnv() {
  return config.env;
}

function getApiUrl(route) {
  const apiUrl = config.api.uri;
  if (route) {
    return apiUrl + route;
  } else return apiUrl;
}

function getEnvApiUrl(route, env) {
  const apiUrl = config.api[env];
  if (route) {
    return apiUrl + route;
  } else return apiUrl;
}

function httpPost(url, data, headers, queryParams) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${getToken()}`, 'loggedinid': `${getUserDetails().id}` }

    const configuration = {
      method: 'post',
      url: url,
      data: data
    };
    if (headers) {
      configuration.headers = headers;
    }
    if (queryParams) {
      configuration.params = queryParams;
    }
    axios(configuration)
      .then((response) => {
        return resolve(response)
      })
      .catch(error => {
        handleError(error);
        return reject(error);
      });
  });

}

function httpGet(url, params, headers, responseType) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${getToken()}`, 'loggedinid': `${getUserDetails().id}` }

    const configuration = {
      method: 'get',
      url: url
    };
    if (params) {
      configuration.params = params;
    }
    if (headers) {
      configuration.headers = headers;
    }
    if (responseType) {
      configuration.responseType = responseType;
    }
    axios(configuration)
      .then((response) => {
        return resolve(response)
      })
      .catch(error => {
        handleError(error);
        return reject(error);
      });
  });

}
function httpGetPdf(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${getToken()}`, 'loggedinid': `${getUserDetails().id}` }

    const configuration = {
      method: 'get',
      url: url,
      // Accept: 'application/pdf',
    };
    if (params) {
      configuration.params = params;
    }
    axios(configuration)
      .then((response) => {
        return resolve(response)
      })
      .catch(error => {
        handleError(error);
        return reject(error);
      });
  });

}

function httpDelete(url, params, headers) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${getToken()}` }
    const configuration = {
      method: 'delete',
      url: url,
      data: params
    };
    if (headers) {
      configuration.headers = headers;
    }
    axios(configuration)
      .then((response) => {
        return resolve(response);
      })
      .catch(error => {
        handleError(error);
        return reject(error);
      });
  });
}

const transformS3ResponseToBLOB = (s3Response) => {
  const contentType = s3Response.ContentType;
  const byteCharacters = atob(s3Response.base64Body);
  const byteNumbers = [...byteCharacters].map((item, index) => byteCharacters.charCodeAt(index));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: contentType });
  const file = new File([blob], s3Response.Filename, { type: contentType })
  return file
};

function getEcsCpuUrl(env) {
  return config.ecs_cpu[env];
};

function getEcsMemoryUrl(env) {
  return config.ecs_memory[env];
};

function getAlbNoOfReq1Url(env) {
  return config.alb_no_of_req_1[env];
};

function getAlbNoOfReq2Url(env) {
  return config.alb_no_of_req_2[env];
};

function getAlbBackendResTimeUrl(env) {
  return config.alb_backend_res_time[env];
};

function getHttpReqErrorUrl(env) {
  return config.http_req_error[env];
};

function getLambdaInvocationUrl(env) {
  return config.lambda_invocation[env];
};

function getLambdaErrorUrl(env) {
  return config.lambda_error[env];
};

const CommonService = {
  getApiUrl,
  getEnvApiUrl,
  httpGet,
  httpPost,
  httpDelete,
  httpGetPdf,
  transformS3ResponseToBLOB,
  getCurrentEnv,
  getEcsCpuUrl,
  getEcsMemoryUrl,
  getAlbNoOfReq1Url,
  getAlbNoOfReq2Url,
  getAlbBackendResTimeUrl,
  getHttpReqErrorUrl,
  getLambdaInvocationUrl,
  getLambdaErrorUrl
};

export default CommonService;
