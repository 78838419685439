// Source of truth https://git.tools.telstra.com/projects/CAV/repos/sv-engine/browse/user-doc/simulation.schema.json
export const simulationSchema = {
  "additionalProperties": false,
  "definitions": {
    "delay": {
      "properties": {
        "delay": {
          "type": "integer"
        },
        "httpMethod": {
          "type": "string"
        },
        "urlPattern": {
          "type": "string"
        }
      },
      "type": "object"
    },
    "delay-log-normal": {
      "properties": {
        "httpMethod": {
          "type": "string"
        },
        "max": {
          "type": "integer"
        },
        "mean": {
          "type": "integer"
        },
        "median": {
          "type": "integer"
        },
        "min": {
          "type": "integer"
        },
        "urlPattern": {
          "type": "string"
        }
      },
      "type": "object"
    },
    "field-matchers": {
      "properties": {
        "matcher": {
          "type": "string"
        },
        "value": {}
      },
      "required": [
        "matcher"
      ],
      "type": "object"
    },
    "headers": {
      "additionalProperties": {
        "items": {
          "type": "string"
        },
        "type": "array"
      },
      "type": "object"
    },
    "params": {
      "additionalProperties": {
        "type": "string"
      },
      "type": "object"
    },
    "meta": {
      "properties": {
        "hoverflyVersion": {
          "type": "string"
        },
        "schemaVersion": {
          "type": "string"
        },
        "timeExported": {
          "type": "string"
        }
      },
      "required": [
        "schemaVersion"
      ],
      "type": "object"
    },
    "request": {
      "properties": {
        "body": {
          "items": {
            "$ref": "#/definitions/field-matchers"
          },
          "type": "array"
        },
        "destination": {
          "items": {
            "$ref": "#/definitions/field-matchers"
          },
          "type": "array"
        },
        "headers": {
          "$ref": "#/definitions/request-headers"
        },
        "path": {
          "items": {
            "$ref": "#/definitions/field-matchers"
          },
          "minItems": 1,
          "type": "array"
        },
        "method": {
          "items": {
            "$ref": "#/definitions/field-matchers"
          },
          "minItems": 1,
          "type": "array"
        },
        "query": {
          "$ref": "#/definitions/request-queries"
        },
        "requiresState": {
          "patternProperties": {
            ".{1,}": {
              "type": "string"
            }
          },
          "type": "object"
        },
        "scheme": {
          "items": {
            "$ref": "#/definitions/field-matchers"
          },
          "type": "array"
        }
      },
      "required": [
        "path",
        "method",
        "headers"
      ],
      "type": "object"
    },
    "request-headers": {
      "additionalProperties": {
        "items": {
          "$ref": "#/definitions/field-matchers"
        },
        "type": "array"
      },
      "type": "object"
    },
    "request-queries": {
      "additionalProperties": {
        "items": {
          "$ref": "#/definitions/field-matchers"
        },
        "type": "array"
      },
      "type": "object"
    },
    "request-response-pair": {
      "properties": {
        "request": {
          "$ref": "#/definitions/request"
        },
        "response": {
          "$ref": "#/definitions/response"
        },
        "label": {
          "type": "string"
        },
        "endpointId": {
          "type": "string"
        },
        "defaultCJVScenario": {
          "type": "string"
        }
      },
      "required": [
        "request",
        "response"
      ],
      "type": "object"
    },
    "response": {
      "properties": {
        "body": {
          "type": "string"
        },
        "bodyFile": {
          "type": "string"
        },
        "encodedBody": {
          "type": "boolean"
        },
        "fixedDelay": {},
        "headers": {
          "$ref": "#/definitions/headers"
        },
        "actions": {
          "items": {
            "$ref": "#/definitions/action"
          },
          "type": "array"
        },
        "logNormalDelay": {
          "properties": {
            "max": {
              "type": "integer"
            },
            "mean": {
              "type": "integer"
            },
            "median": {
              "type": "integer"
            },
            "min": {
              "type": "integer"
            }
          }
        },
        "removesState": {
          "type": "array"
        },
        "status": {
          "type": "integer"
        },
        "templated": {
          "type": "boolean"
        },
        "transitionsState": {
          "patternProperties": {
            ".{1,}": {
              "type": "string"
            }
          },
          "type": "object"
        }
      },
      "type": "object"
    },
    "action": {
      "type": "object",
      "properties": {
        "actionId": {
          "type": "string"
        },
        "label": {
          "type": "string"
        },
        "actionType": {
          "type": "string"
        },
        "condition": {
          "type": "string"
        },
        "forEach": {
          "type": "string"
        },
        "delaySeconds": {},
        "templated": {
          "type": "boolean"
        },
        "synchronous": {
          "type": "boolean"
        },
        "actionData": {
          "anyOf": [
            {
              "$ref": "#/definitions/httpForwardAction"
            },
            {
              "$ref": "#/definitions/propertiesAction"
            }
          ]
        }
      },
      "required": [
        "actionId",
        "actionType",
        "actionData"
      ]
    },
    "httpForwardAction": {
      "type": "object",
      "properties": {
        "httpUrl": {
          "type": "string"
        },
        "httpMethod": {
          "type": "string"
        },
        "massl": {
          "type": "boolean"
        },
        "masslAlias": {
          "type": "string"
        },
        "masslPassword": {
          "type": "string"
        },
        "headers": {
          "$ref": "#/definitions/headers"
        },
        "params": {
          "$ref": "#/definitions/params"
        },
        "body": {
          "type": "string"
        }
      },
      "required": [
        "httpUrl",
        "httpMethod"
      ]
    },
    "propertiesAction": {
      "type": "object",
      "properties": {
        "body": {
          "type": "string"
        }
      },
      "required": [
        "body"
      ]
    }
  },
  "description": "SV-Stubs simulation schema",
  "properties": {
    "data": {
      "properties": {
        "globalActions": {
          "properties": {
            "delays": {
              "items": {
                "$ref": "#/definitions/delay"
              },
              "type": "array"
            },
            "delaysLogNormal": {
              "items": {
                "$ref": "#/definitions/delay-log-normal"
              },
              "type": "array"
            }
          },
          "type": "object"
        },
        "pairs": {
          "items": {
            "$ref": "#/definitions/request-response-pair"
          },
          "type": "array"
        }
      },
      "type": "object"
    },
    "meta": {
      "$ref": "#/definitions/meta"
    }
  },
  "required": [
    "data",
    "meta"
  ],
  "type": "object"
}
