import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../../DashboardNew/DashboardNew.css';
import PortalInsightsCard from '../PortalInsights/PortalInsightsCard';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SupportFolderStructure from '../../FolderStructure/SupportFolderStructure';
import AccessManagementCard from '../AccessManagement/AccessManagementCard';
import UsageDashboardCard from '../UsageDashboard/UsageDashboardCard';
import UsageReportCard from '../../UsageReport/UsageReportCard';
import LibraryCard from '../Library/LibraryCard';
import TransactionMetricsCard from '../../TransactionMetrics/TransactionMetricsCard';
import PasmatCard from '../../PASMAT/PasmatCard';
import LatestActivity from '../../LatestActivity/LatestActivity';
export const SupportDashboard = props => {

	//Purpose : This component display the latest activity on the dashboard of the support user
	// this is the container for other pods like utility and stats tiles

	return (
		<Container>
			<>
				<LatestActivity/>
				<div className="white mt-3">
					<SupportFolderStructure></SupportFolderStructure>
				</div>

				<div className="white mt-3">
					<Row>
						<Col xs="8">
							<h3>
                				SV Utilities<FontAwesomeIcon className="ml-2" icon={faTools} size="1x" />
							</h3>
						</Col>
					</Row>

					<hr />
					<Row className="mt-3">
						<Col xs={3}>
							<LibraryCard></LibraryCard>
						</Col>
						<Col xs={3}>
							<UsageReportCard />
						</Col>
						<Col xs={3}>
							<TransactionMetricsCard />
						</Col>
						<Col>
							<PasmatCard />
						</Col>


						{/* <Col xs={3}>
                <OnboardCard />
              </Col> */}



					</Row>
					<Row className="mt-3">
						<Col xs={3}>
							<AccessManagementCard />

						</Col>
						<Col xs={3}>
							<PortalInsightsCard />
						</Col>
						<Col xs={3}>
							<UsageDashboardCard />
						</Col>


					</Row>

				</div>
			</>

		</Container>
	);
};

export default SupportDashboard;
