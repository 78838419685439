import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";

const WorkspaceAccessRequests = ({requestList}) =>{

    const columns= [
        { title: 'Team Name', field: 'team.team_name', editable: 'never' },
        { title: 'Status', field: 'status' , lookup : {
           'APPROVED' : 'APPROVED',
           'REJECTED' : 'REJECTED',
           'PENDING' : 'PENDING',
           0 : 'APPROVED',
           1 : 'PENDING',
           2 : 'PENDING',

        }},
        { title: 'Updated By', field: 'updated_user.user_name' },
        // { title: 'Created On', field: 'createdAt' , type: 'datetime'},
        // { title: 'Updated On', field: 'updatedAt' , type: 'datetime',defaultSort: 'desc'},

      ];

    const [accessRequestList , setAccessRequestList] = useState([]);

      useEffect(()=>{
        setAccessRequestList(requestList);
      },[requestList]);

    return (
                <MaterialTable
                  style={{ boxShadow: 'none', width: '100%', display: 'block' }}
                  columns={columns}
                  data={accessRequestList}
                  title={''}
                  options={{
                    headerStyle: {
                      fontWeight: 'bold',
                    },
                    actionsColumnIndex: -1,
                  }}
                />
    )
}

export default WorkspaceAccessRequests;