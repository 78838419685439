import MaterialTable from 'material-table'
import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Label, Row, Col, Button, Container, Card } from 'reactstrap'
import { palette } from "../../../../src/assets/colors"
import { getAllPrograms, getAllApplicationsAdmin, getAllValueChainsForProgram, addOrUpdateApplication } from "../../../services/support.service";
import {

    Collapse

} from "@material-ui/core";
import Swal from 'sweetalert2';

const columns = [
    { title: "Application name", field: "application_name", width: "10%" },

    { title: "Application code", field: "application_code", width: "10%" },
    { title: "Program name", field: "program_name", width: "10%" },
    { title: "Value chain name", field: "valueChain_name", width: "10%" },
    // { title: "Last updated", field: "updated_datetime", width: "10%" },
];

// this means initial data
const dataConst = [
    {
        application_id: 1,
        application_name: 'My Telstra',
        description: 'My Telstra App Description',
        application_code: 'mytelstra'
    },
    {
        application_id: 2,
        application_name: 'My Telstra 2',
        description: 'My Telstra App Description',
        application_code: 'mytelstra2'
    },

]

export default function ApplicationEdit() {
    const applicationListTableRef = useRef(null);
    const appData = useRef(null);
    const [selectedProgram, setSelectedProgram] = useState({ program_name: 'test', program_id: 1, program_code: "code" });
    const [selectedValuechain, setSelectedValuechain] = useState({ valueChain_name: 'test', valueChain_id: 1, valuechain_code: "code" })
    const [showForm, setShowForm] = useState(false);
    // defines the current selected index of the materialTable
    const [revision, setRevision] = useState(false)
    // data is used to display the material table at the top
    const [applicationList, setApplicationList] = useState(dataConst);
    // a Program is new if it is not present in database
    const [isNewApplication, setIsNewApplication] = useState(false);
    const [valueChains, setValueChains] = useState([{
        valueChain_id: 7,
        valueChain_name: 'P2O',
        valuechain_code: 'p2o'
    }]);

    // programData is used for handling form data
    const [selectedApplicationData, setSelectedApplicationData] = useState({
        application_name: '', description: 'set Description',
        application_code: ''
    })

    const [allPrograms, setAllPrograms] = useState([{

        program_id: 1,
        program_name: 'B2B Digitisation',
        description: 'b2b Digitisation',
        program_code: 'b2b'

    },
    {
        program_id: 2,
        program_name: 'B2C Digitisation',
        description: 'b2c Digitisation',
        program_code: 'b2c'
    }
    ])
    const scrollToRef = (ref) => {
        window.scrollTo({
            behavior: "smooth",
            top: ref.current.offsetTop,
        });
    };
    const actions = [

        {
            icon: "add_circle",
            iconProps: { style: { fontSize: "35px", color: palette.primaryshade2 } },
            tooltip: "Add endpoint",
            isFreeAction: true,

            onClick: () => {
                // Remove hardcoded values
                setSelectedProgram({ program_name: 'B2B Dig', program_id: 1 });
                setSelectedValuechain({ valueChain_id: 1 })
                // used for form under the Material table in UI
                setSelectedApplicationData({
                    application_name: '',
                    description: '',
                    application_code: '',
                    //Remove this hardcoded value later
                    program_id: 1,
                    valueChain_id: 1,
                    icon: 'internet'



                })
                // used for Displaying Application's in Material Table
                setApplicationList([...applicationList, {
                    application_name: 'Default Name',
                    description: 'Default Description',
                    application_code: 'Default program code'
                }])
                setIsNewApplication(true);
                setShowForm(true)
            },
        },
    ];


    const onApplicationDataChange = (e) => {
        let objectValue = selectedApplicationData;
        if (e.target.name === 'valueChain_id') {
            objectValue[e.target.name] = parseInt(e.target.value);

        }
        else {
            objectValue[e.target.name] = e.target.value;
        }
        setSelectedApplicationData(objectValue);


        setRevision(!revision)

    }
    const getData = async () => {
        try {
            const response = await getAllApplicationsAdmin();

            setApplicationList(response.data.data)
            setSelectedApplicationData(response.data.data[0])
            // The following Program and valuechains are used when editing a single endpoint.
            const responseProgram = await getAllPrograms();
            setAllPrograms(responseProgram.data.data)

            let valueChainResponse = await getAllValueChainsForProgram({ program_id: response.data.data[0]['program_id'] })
            setValueChains(valueChainResponse.data.data)
        }
        catch (error) {
            Swal.fire(`Something went wrong. Error ${error}`)

        }

    }
    const saveButton = async () => {
        try {

            await addOrUpdateApplication({
                application_name: selectedApplicationData.application_name, description: selectedApplicationData.description,
                application_code: selectedApplicationData.application_code, valueChain_id: selectedApplicationData.valueChain_id,
                icon: selectedApplicationData.icon
            });
            // by calling following function, it is made sure that the data is fetched from the database.
            getData();
            Swal.fire("Successfully updated Applications")

        }
        catch (error) {
            Swal.fire(`Something went wrong. Error ${error}`)
        }

    }

    const getValueChainsForProgramFunc = async (program_id) => {
        try {
            const response = await getAllValueChainsForProgram({ program_id: parseInt(program_id) })
            setValueChains(response.data.data)
            // make sure to set a default value for Valuechain as well in selectedApplication.
            let objectValue = selectedApplicationData;
            if (response.data.data.length !== 0) {
                objectValue['valueChain_id'] = response.data.data[0].valueChain_id;
                setSelectedApplicationData(objectValue);
            }


        }
        catch (error) {
            Swal.fire("something went wrong")
        }
    }

    useEffect(() => {
        getData()
    }, []);
    return (
        <div>
            <Row>
                <h1>Applications</h1>
            </Row>
            {/* <Row>
                {(showBanner) ? (
                    <h3 style={{ "color": "red" }}> Please save current Program before Proceeding</h3>) : (<></>)}

            </Row> */}
            <div ref={applicationListTableRef}>
                <Container className="mt-3 p-2 pl-0 pr-0">

                    <Form mt-3 p-2>



                        <MaterialTable
                            style={{ width: "100%" }}
                            columns={columns}
                            data={applicationList}
                            actions={actions}
                            onRowClick={(evt, selectedRow) => {

                                setShowForm(true);

                                setSelectedApplicationData({
                                    application_name: selectedRow.application_name, description: selectedRow.description,
                                    application_code: selectedRow.application_code, program_id: parseInt(selectedRow.program_id),
                                    program_name: selectedRow.program_name, updated_datetime: selectedRow.updated_datetime,
                                    icon: selectedRow.icon, valueChain_id: parseInt(selectedRow.valueChain_id),
                                    valueChain_name: selectedRow.valueChain_name
                                })

                                setSelectedProgram({ program_id: selectedRow.program_id, program_name: selectedRow.program_name })
                                setRevision(!revision)
                                // only row fetched from database will have program_id and the newly added program won't have it.
                                if (selectedRow.hasOwnProperty('application_id')) {
                                    setIsNewApplication(false)
                                }
                                else {
                                    setIsNewApplication(true)
                                }
                                scrollToRef(appData)


                            }}
                            options={{


                                headerStyle: {
                                    fontWeight: "bold",
                                    backgroundColor: palette.blueseq[3],
                                },
                                filtering: true,
                                paging: true
                            }}
                            title="Applications"


                        />



                    </Form>
                </Container>
            </div>
            <br /><br />
            <Collapse in={showForm}>

                <Form className="float-left w-100 p-2" ref={appData}>

                    {/* {(showForm === false) ? (<></>) : ( */}
                    <Card
                        className="p-3"
                        style={{ backgroundColor: palette.blueseq[0] }}
                    >
                        <Row>
                            <Col xs="3">
                                <Label for="program_name" className="mt-3 float-left">Program  Name</Label>
                            </Col>
                            <Col xs="4">
                                <Input
                                    type="select"
                                    name="program_id"
                                    disabled={(isNewApplication) ? false : true}
                                    value={selectedProgram['program_id']}
                                    onChange={(e) => {
                                        const progName = allPrograms.filter((programItm) => programItm.program_id === parseInt(e.target.value));
                                        setSelectedProgram(progName[0])
                                        onApplicationDataChange(e)

                                        getValueChainsForProgramFunc(e.target.value)




                                    }
                                    }
                                >
                                    {
                                        allPrograms.map((programItem, index) => {
                                            return <option key={index}
                                                value={parseInt(programItem.program_id)}
                                            >{programItem.program_name}</option>
                                        })
                                    }

                                </Input>
                            </Col></Row>



                        <Row className="mt-3"> <Col xs="3">
                            <Label for="program_name" className="mt-3 float-left">ValueChain Name</Label>
                        </Col>
                            <Col xs="4">
                                <Input
                                    type="select"
                                    disabled={(valueChains.length === 0) ? true : (isNewApplication) ? false : true}

                                    name="valueChain_id"
                                    value={selectedValuechain['valueChain_id']}
                                    onChange={(e) => {
                                        const vcItem = valueChains.filter((valuechainItem) => parseInt(valuechainItem.valueChain_id) === parseInt(e.target.value));
                                        setSelectedValuechain(vcItem[0])
                                        onApplicationDataChange(e)


                                    }
                                    }
                                >
                                    {
                                        valueChains.map((valuechainItem, index) => {
                                            return <option key={index}
                                                value={parseInt(valuechainItem.valueChain_id)}
                                            >{valuechainItem.valueChain_name} ({valuechainItem.valuechain_code})</option>
                                        })
                                    }

                                </Input>
                            </Col>
                        </Row>




                        <Row className="mt-3"><Col xs="3">
                            <Label for="application_name" className="mt-3 float-left">Application name</Label>
                        </Col>
                            <Col xs="4" ><Input type="text" name="application_name" id="application_name" placeholder="Enter application name"
                                onChange={(e) => { onApplicationDataChange(e) }} value={selectedApplicationData.application_name} />
                            </Col>

                        </Row>
                        <Row className="mt-3">
                            <Col xs="3">
                                <Label for="description" className="mt-3 float-left">Application description</Label>
                            </Col>
                            <Col xs="4"><Input type="text" name="description" id="description" placeholder="Enter description"
                                onChange={(e) => { onApplicationDataChange(e) }} value={selectedApplicationData.description} /></Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="3">
                                <Label for="application_code" className="mt-3 float-left">Application code</Label>
                            </Col>
                            <Col xs="4">
                                <Input type="text" name="application_code" id="application_code" placeholder="Enter application code"
                                    onChange={(e) => { onApplicationDataChange(e) }} value={selectedApplicationData.application_code} disabled={(isNewApplication) ? false : true} />
                            </Col>
                        </Row>
                        <br />
                        <Row className="mt-3">

                            <Col xs="5">
                                <Button

                                    color="success"
                                    disabled={(valueChains.length === 0 ? true : false) ||
                                        (selectedApplicationData.application_code === '' ? true : false) ||
                                        (selectedApplicationData.application_name === '' ? true : false)}
                                    className="mt-3 float-left btn-lg"
                                    onClick={saveButton}>
                                    Save
                               </Button>
                            </Col>
                        </Row>

                    </Card>
                    {/* } */}


                </Form>
            </Collapse>

        </div>
    )
}
