import React, { useEffect, useState } from 'react'

import { Container, Row, Col, Jumbotron, Card, Button, Spinner } from "reactstrap"
import { TelstraIcon } from "../common/icons/GradientIcons";
import accessManageService from '../../services/accessManagement.service';
import transactionMetricsService from '../../services/transactionMetrics.service';
import TransactionTimeSelect from './TransactionTimeSelect';
import { useHistory } from "react-router-dom";
import errorResponses from "../../components/ErrorHandling/errorResponses"
import moment from "moment-timezone";
import MaterialTable from 'material-table';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import Select from 'react-select';

import { palette } from "../../assets/colors";
import './UsageBilling.css';
import DashboardStatCard from '../Support/Dashboard/Cards/DashboardStatCard';

export default function TransactionMetricsComp() {
    let history = useHistory();
    const Plot = createPlotlyComponent(Plotly);

    const [showLoader, setShowLoader] = useState(true)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const groupby = 'stub_name';
    // this contains both total and erroneous values
    const [reqCountGraph, setReqCountGraph] = useState('')
    const [resTimeGraph, setResTimeGraph] = useState('')

    const [reqResSizeGraph, setReqResSizeGraph] = useState('');
    const [allTeams, setAllTeams] = useState([])
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [vsMetricsData, setVsMetricsData] = useState(null)
    const columns = [
        { title: 'Stub name', field: 'stub_name' },
        { title: 'Number of transactions', field: 'cum_req_count', type: 'numeric' },
        { title: 'Failed transactions', field: 'cum_err_count', type: 'numeric' },
        // totalPassed = rowData.cum_req_count - rowData.cum_err_count)
        { title: 'Average response time(sec)', field: 'cum_res_time', type: 'numeric', render: rowData => (rowData.cum_res_time && ((rowData.cum_req_count - rowData.cum_err_count) !== 0)) ? ((rowData.cum_res_time / (rowData.cum_req_count - rowData.cum_err_count)) / 1000).toFixed(4) : 0 },
        { title: 'Average request size(kb)', field: 'cum_req_size', type: 'numeric', render: rowData => (rowData.cum_req_size && ((rowData.cum_req_count - rowData.cum_err_count) !== 0)) ? ((rowData.cum_req_size / (rowData.cum_req_count - rowData.cum_err_count)) / 1000).toFixed(4) : 0 },
        { title: 'Average response size(kb)', field: 'cum_res_size', type: 'numeric', render: rowData => (rowData.cum_res_size && ((rowData.cum_req_count - rowData.cum_err_count) !== 0)) ? ((rowData.cum_res_size / (rowData.cum_req_count - rowData.cum_err_count)) / 1000).toFixed(4) : 0 },


    ]

    const initialDateSetter = () => {
        try {
            const dateNow = moment().tz("Australia/Sydney")
            let tempstartDate = new Date(dateNow.year(), dateNow.month(), dateNow.date(), dateNow.hour() - (7 * 24), dateNow.minute(), dateNow.second())
            let startDateFilter = new Date(tempstartDate.setDate(tempstartDate.getDate()));
            startDateFilter = moment(startDateFilter).format("YYYY-MM-DDTHH:mm:ss");
            setStartDate(startDateFilter)

            let tempDate = new Date(dateNow.year(), dateNow.month(), dateNow.date(), dateNow.hour(), dateNow.minute(), dateNow.second())
            let endDateFilter = new Date(tempDate.setDate(tempDate.getDate()));
            endDateFilter = moment(endDateFilter).format("YYYY-MM-DDTHH:mm:ss");
            setEndDate(endDateFilter)
            return [startDateFilter, endDateFilter]

        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: errorResponses.GENERIC_ERROR
                }
            });
        }
    }
    const generateGraphDataReqCount = (vsMetricsDataInput) => {
        try {
            let graphData = []
            let reqCountGraphObj = { 'cum_req_count': 'Number of Transactions', 'cum_err_count': 'Erroneous Transactions' }
            for (let fieldName of Object.keys(reqCountGraphObj)) {
                let x = []
                let y = []
                let graphObject = {}

                // iterating through all arrays of data/ used for rows
                for (let item of vsMetricsDataInput) {
                    x.push(item.stub_name)
                    y.push(item[fieldName])
                    graphObject['x'] = x
                    graphObject['y'] = y
                    graphObject['type'] = 'bar'
                    graphObject['name'] = reqCountGraphObj[fieldName]
                }
                graphData.push(graphObject)

            }
            setReqCountGraph(graphData)
            return graphData
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: errorResponses.GENERIC_ERROR
                }
            });
        }
    }
    const generateGraphDataResTime = (vsMetricsDataInput) => {
        try {
            let graphData = []
            let reqCountGraphObj = { 'cum_res_time': 'Response Time' }
            for (let fieldName of Object.keys(reqCountGraphObj)) {
                let x = []
                let y = []
                let graphObject = {}

                // iterating through all arrays of data/ used for rows
                for (let item of vsMetricsDataInput) {
                    x.push(item.stub_name)
                    y.push((item[fieldName] && ((item['cum_req_count'] - item['cum_err_count']) !== 0)) ? ((item[fieldName] / (item['cum_req_count'] - item['cum_err_count'])) / 1000).toFixed(4) : 0)
                    // y.push(item[fieldName] / 1000)
                    graphObject['x'] = x
                    graphObject['y'] = y
                    graphObject['type'] = 'bar'
                    graphObject['name'] = reqCountGraphObj[fieldName]
                }
                graphData.push(graphObject)

            }

            setResTimeGraph(graphData)
            return graphData
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: errorResponses.GENERIC_ERROR
                }
            });
        }
    }
    const generateGraphDataReqResSize = (vsMetricsDataInput) => {
        try {
            let graphData = []
            let reqCountGraphObj = { 'cum_req_size': 'Request Size', 'cum_res_size': 'Response Size' }
            for (let fieldName of Object.keys(reqCountGraphObj)) {
                let x = []
                let y = []
                let graphObject = {}

                // iterating through all arrays of data/ used for rows
                for (let item of vsMetricsDataInput) {
                    x.push(item.stub_name)
                    y.push((item[fieldName] && ((item['cum_req_count'] - item['cum_err_count']) !== 0)) ? ((item[fieldName] / (item['cum_req_count'] - item['cum_err_count'])) / 1000).toFixed(4) : 0)

                    // y.push(item[fieldName] / 1000)
                    graphObject['x'] = x
                    graphObject['y'] = y
                    graphObject['type'] = 'bar'
                    graphObject['name'] = reqCountGraphObj[fieldName]
                }
                graphData.push(graphObject)

            }
            setReqResSizeGraph(graphData)
            return graphData
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: errorResponses.GENERIC_ERROR
                }
            });
        }
    }

    const generateGradientCardValues = () => {
        try {
            let failed = 0;
            let passed = 0;
            let totalReq = 0;
            let totalResTime = 0
            let avg_res_time = 0;
            let avg_req_size = 0
            let avg_res_size = 0

            let totalReqSize = 0
            let totalResSize = 0;
            // this length doesn't include unmatched
            let lengthSuccessful = 0
            let divider = 0
            for (let item of vsMetricsData) {
                totalReq = parseInt(totalReq) + parseInt(item.cum_req_count);
                failed = parseInt(failed) + parseInt(item.cum_err_count);
                if (item.stub_name !== 'NON-MATCHED') {
                    lengthSuccessful = lengthSuccessful + 1;
                    divider = divider + parseInt(totalReq - failed)
                    totalResTime = totalResTime + parseInt(item.cum_res_time);
                    totalReqSize = totalReqSize + parseInt(item.cum_req_size);
                    totalResSize = totalResSize + parseInt(item.cum_res_size);
                }
            }
            avg_res_time = totalResTime / divider;
            avg_req_size = totalReqSize / divider;
            avg_res_size = totalResSize / divider

            passed = totalReq - failed;
            let finalResponse = {}

            let fields = [{ 'Total transactions': totalReq, 'Passed transactions': passed, 'Failed transactions': failed },
            { 'Average response time (sec)': (typeof avg_res_time !== 'undefined') ? (avg_res_time / 1000).toFixed(4) : 0 },
            { 'Average request size (kb)': (avg_req_size / 1000).toFixed(4), 'Average response size (kb)': (avg_res_size / 1000).toFixed(4) }
            ]
            for (let fieldItems of fields) {
                let response = []

                for (let item of Object.keys(fieldItems)) {
                    let responseObj = {}
                    // item here will contain key
                    responseObj['count'] = fieldItems[item];
                    responseObj['stat'] = item;
                    responseObj['time'] = ''
                    response.push(responseObj);
                }
                finalResponse[Object.keys(fieldItems)[0]] = response
            }
            return finalResponse;
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: errorResponses.GENERIC_ERROR
                }
            });
        }
    }
    const getData = async () => {
        try {
            let adminRoleResponse = (await accessManageService.isFtlOrAdmin()).data.data.role;
            initialDateSetter();
            getTeamsList(adminRoleResponse);
            setShowLoader(false,)
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: errorResponses.GENERIC_ERROR
                }
            });
        }
    }
    const onDateChange = (type, value) => {
        if (type === 'start') {
            setStartDate(value)
        }
        else {
            setEndDate(value)
        }
    }
    const generateGradient = (index) => {
        return index === 0 || index === 3 ? 'custom-gradient-2' : 'custom-gradient-3'
    }
    const Graphs = () => {
        return (
            <>
                {(reqCountGraph !== '') ?
                    <Row style={{ marginTop: "5vh" }}>
                        <Col style={{ marginLeft: "10vw" }}>
                            <Plot
                                data={reqCountGraph}
                                layout={{
                                    width: 700, height: 500, title: 'Transaction Count',
                                    // xaxis: {
                                    //     title: 'Stub names',
                                    //     size: 20

                                    // },
                                    yaxis: {
                                        title: 'Number of transactions',

                                    }

                                }}
                            />
                        </Col>
                    </Row> : ""}

                {(resTimeGraph !== '') ?
                    <Row style={{ marginTop: "5vh" }}>
                        <Col style={{ marginLeft: "10vw" }}>

                            <Plot
                                data={resTimeGraph}
                                layout={{
                                    width: 700, height: 500, title: 'Response Time',
                                    // xaxis: {
                                    //     title: 'Stub names',

                                    // },
                                    yaxis: {
                                        title: 'Response Time(sec)',

                                    }

                                }}
                            />
                        </Col>
                    </Row> : ""}
                {(reqResSizeGraph !== '') ?
                    <Row style={{ marginTop: "5vh" }}>
                        <Col style={{ marginLeft: "10vw" }}>

                            <Plot
                                data={reqResSizeGraph}
                                layout={{
                                    width: 700, height: 500, title: 'Response Size',

                                    yaxis: {
                                        title: 'Response Size(kb)',

                                    }
                                }}
                            />
                        </Col>
                    </Row> : ""}
            </>

        )
    }
    const loadResult = async () => {
        try {
            setShowLoader(true)
            let response = await transactionMetricsService.getMetrics({
                from: startDate, to: endDate,
                team_code: selectedTeam.team_code, groupby: groupby
            })
            if (response.data && response.data.length !== 0) {
                setVsMetricsData(response.data)
                generateGraphDataReqCount(response.data)
                generateGraphDataResTime(response.data)
                generateGraphDataReqResSize(response.data)
            }
            else {
                setVsMetricsData(response.data)
            }
            setShowLoader(false)
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: errorResponses.GENERIC_ERROR
                }
            });
        }
    }
    const renderNoDataState = () => {
        if (vsMetricsData && selectedTeam) {
            return <Container>
                <div className='whiteTable mt-3'>
                    <h2>No Data found</h2></div>
            </Container>
        }
        else return <></>;
    }
    const getTeamsList = (role, value = "") => {
        let teamsList = []
        transactionMetricsService.getAllTeams(role, value).then(allTeamsResponse => {
            teamsList = allTeamsResponse.data.teams.map(item => { return { ...item, label: item.team_name, value: item.team_id } });
            setAllTeams(teamsList);
        }).catch(err => {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: errorResponses.GENERIC_ERROR
                }
            });
        })
    }

    const renderMetricsData = () => {
        if (vsMetricsData?.length > 0 && typeof vsMetricsData !== 'undefined') {
            return <>
                <Row className="mt-5 p-2 pl-0 pr-0">
                    <Col xs="4">
                        <Container>

                            <DashboardStatCard gradient={generateGradient(0)} metrics={generateGradientCardValues()['Total transactions']} />
                        </Container>
                    </Col>

                    <Col xs="4">
                        <Container>
                            <DashboardStatCard gradient={generateGradient(0)} metrics={generateGradientCardValues()['Average response time (sec)']} />


                        </Container>
                    </Col>
                    <Col xs="4">

                        <Container>
                            <DashboardStatCard gradient={generateGradient(0)} metrics={generateGradientCardValues()['Average request size (kb)']} />


                        </Container>
                    </Col>
                </Row>
                <Row className="mt-3 p-2 pl-0 pr-0">
                    <Col>
                        <Container >

                            <MaterialTable
                                title=''
                                columns={columns}
                                data={vsMetricsData}
                                options={{

                                    headerStyle: {
                                        fontWeight: "bold",
                                        backgroundColor: palette.blueseq[3],
                                    }
                                }}
                                style={{ marginTop: '3vh' }}
                            />
                        </Container>
                    </Col>
                </Row>
                <Container>
                    <div className='whiteTable mt-3'>

                        <div className='white mt-3'>
                            <Row>
                                <Col>
                                    <Graphs />
                                </Col>
                            </Row>
                        </div>

                    </div>

                </Container>
            </>;
        }
        else {
            return renderNoDataState();
        }
    }

    const showMetricsData = () => {
        if ((!showLoader)) {
            return renderMetricsData();
        } else {
            return <div className='whiteTable mt-3'>
                <Container>
                    <Jumbotron className="text-center"><Spinner className="p-5" color="primary" ></Spinner></Jumbotron>
                </Container>
            </div>
        }
    }
    useEffect(() => {
        getData();
    }, [])
    return (
        <div>
            <>
                <Container>
                    <div className='whiteTable mt-3'>
                        <Row>
                            <Col xs={11}><h2>
                                Transaction Metrics</h2></Col>
                            <Col xs={1}><TelstraIcon primary icon="check-usage" color="blue" /></Col>

                        </Row>
                        <hr></hr>

                        <Card className="" style={{
                            border: 'none',
                            background: 'transparent'
                        }} >

                            <Row className="mt-3  pr-0" style={{ paddingLeft: '30px' }}>
                                <Col xs="auto" className="m-0 h6 d-flex align-items-center">
                                    Team
                                </Col>
                                <Col className="m-0 mt-2">
                                    <Select
                                        options={allTeams}
                                        width='200px'
                                        onChange={(value) => {
                                            setSelectedTeam(value);
                                            setVsMetricsData(null);
                                        }}
                                        value={selectedTeam}
                                        placeholder='Select Team'
                                    />
                                </Col>
                                <Col xs="auto">
                                    {(startDate !== '') ? <TransactionTimeSelect
                                        onDateChange={onDateChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                    /> : <></>}</Col>


                                <Col x="auto" className="m-0 d-flex align-items-center">
                                    <Button
                                        color="info"
                                        disabled={!selectedTeam}
                                        onClick={
                                            () => {
                                                loadResult();
                                            }
                                        }
                                    >
                                        Load Results
                                    </Button>
                                </Col>

                            </Row>
                        </Card>
                        {showMetricsData()}
                    </div></Container>

            </>
        </div>
    )
}
