import React, { useEffect } from "react"

const LoginSuccess = () => {

  // const readCookieData = () => {
  //   var cookie = document.cookie;
  // }

  useEffect(() => {
    var cookie = document.cookie;
  }, []);

  return <div>loading....</div>
};

export default LoginSuccess;