const PROGRAM_RETRIEVE_ERROR = "Error while Retrieving User's Program/Stream Data";
const STREAM_RETRIEVE_ERROR = "Error while Retrieving User's Streams Data"
const DASHBOARD_APPLICATIONS_RETRIEVE_ERROR = "Error while Retrieving Applications"
const DASHBOARD_USER_TEAMS_ERROR = "Error while Retrieving Teams"
const DASHBOARD_ADMIN_STATS_ERROR = "Error while fecthing Admin Stats"

//AccessManagement
const ACCESSMANAGEMENT_DATA_RETRIEVE_ERROR = "Error while retrieving Data in AccessManagment"
const ACCESSMANAGEMENT_SEARCH_USER_ERROR = "Error while Searching for the user"
const SIMULATION_DEPLOYMENT_RETRIEVE_ERROR = "Error while retrieving Deployment Status"

const LIBRARY_CHECKOUT_ERROR = "Something went wrong while checking out stubs"
const LIBRARY_ENDPOINTLIST_ERROR = "Error while retrieving endpoint's data"
const LIBRARY_ENDPOINT_ERROR = "Error while retrieving endpoints"
const LIBRARY_VS_RETRIEVE_ERROR = "Error while retrieving virtual services"


const PORTAL_INSIGHTS_GENERIC_USER_METRICS_ERROR = "Error while retrieving user metrics"
const PORTAL_INSIGHTS_GENERIC_STREAM_METRICS_ERROR = "Error while retrieving streams metrics"
const PORTAL_INSIGHTS_GENERIC_STUB_METRICS_ERROR = "Error while retrieving Stubs metrics"
const USAGE_METRICS_ERROR = "Error while retrieving usage metrics"
const REGISTER_PROGRAM_ERROR = "Error while retrieving Programs"
const REGISTER_TEAMS_ERROR = "Error while retrieving Teams"
const REGISTER_HANGAR_ERROR = "Unable to fetch your information. Ensure you are connected to Telstra network and have provided a valid dNumber."

const GENERIC_ERROR = 'Error while retrieving content. Please retry after a while. If error persists'
const GENERIC_FOOTER_ERROR = `<p>Something went wrong. Please retry after a while. If error persists
, please contact SV Support at

<a href="mailto:SECOETesting-ServiceVirtualisation@team.telstra.com"><span> SECOETesting-ServiceVirtualisation@team.telstra.com</span></a>
</p>`


const errorResponses = {
  PROGRAM_RETRIEVE_ERROR, STREAM_RETRIEVE_ERROR,
  DASHBOARD_APPLICATIONS_RETRIEVE_ERROR,
  DASHBOARD_USER_TEAMS_ERROR,
  DASHBOARD_ADMIN_STATS_ERROR,
  ACCESSMANAGEMENT_DATA_RETRIEVE_ERROR,
  ACCESSMANAGEMENT_SEARCH_USER_ERROR,
  SIMULATION_DEPLOYMENT_RETRIEVE_ERROR,
  LIBRARY_CHECKOUT_ERROR,
  LIBRARY_ENDPOINTLIST_ERROR,
  LIBRARY_ENDPOINT_ERROR,
  LIBRARY_VS_RETRIEVE_ERROR,
  PORTAL_INSIGHTS_GENERIC_USER_METRICS_ERROR,
  PORTAL_INSIGHTS_GENERIC_STREAM_METRICS_ERROR,
  PORTAL_INSIGHTS_GENERIC_STUB_METRICS_ERROR,
  USAGE_METRICS_ERROR,
  REGISTER_PROGRAM_ERROR,
  REGISTER_TEAMS_ERROR,
  REGISTER_HANGAR_ERROR,
  GENERIC_ERROR,
  GENERIC_FOOTER_ERROR

};
export default errorResponses;
