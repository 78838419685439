import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col , Input} from "reactstrap";
import "./tiles/DataTilesFrame.css";
import { TilePose } from "../utils/pose";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { GlobalContext } from "../../context/GlobalState";
import EditIcon from '@material-ui/icons/Edit';

import {
  // getAppData,
  // getFolderStructureId,
  getFormattedApplication,
  getUserTeamsApplications
} from "../../services/folderStructure.service";
import { useHistory } from "react-router-dom";
import { TelstraIcon } from "../common/icons/GradientIcons";

import { DataFilterTileNew } from "./tiles/DataFilterTileNew";
import ApplicationEdit from "../Support/AdminSupport/ApplicationEdit"
import TeamEdit from "../Support/AdminSupport/TeamEdit"

import errorResponses from '../ErrorHandling/errorResponses';
const MySwal = withReactContent(Swal);

export const ApplicationsStructure = (props) => {
  // To show all Applications and action after selecting the valuechain

  let history = useHistory();
  const [valueChain] = useState(props.location.state.valueChain);
  const { isAdmin } = useContext(GlobalContext);

  const [teamSelection, setTeamSelection] = useState({
    team_id: '',
    team_name: '',
    team_code: '',
    team_Application_id: '',
  });
  const [application, setApplication] = useState({
    application_id: '',
    application_name: '',
    application_code: '',
    // team_Application_id:""
  });

  const [revision, setRevision] = useState(0);
  const [app_data, setAppData] = useState([]);
  const [action_data, setActionData] = useState([]);
  const [team_data, setTeamData] = useState([]);

  const getProgramId = (progName) => {
    // gets ProgramId for a given name

    return props.location.state.userData.Programs.filter(
      (programItem) => programItem.program_name === progName
    );
  };
  const chosenProgramId = getProgramId(
    props.location.state.program_chosen.program_name
  );

  const setDataForAction = async (team_name) => {
    var folder_s3_path =
      props.location.state.program_chosen.program_name +
      '/' +
      props.location.state.valueChain.valueChain_name +
      '/' +
      application.application_name +
      '/' +
      teamSelection.team_name;
    folder_s3_path = folder_s3_path.replace(/ /g, '_');
    let application_code = application.application_code;

    if (teamSelection.team_name != '') {
      scrollToBottom();
      setActionData([
        {
          Route: 'createstub',
          // folderStructure_id: response.data[0].folderStructure_id,
          folderStructureS3: folder_s3_path,
          application_code: application_code,
          application_name: application.application_name,
          application_id: application.application_id,
          team_Application_id: teamSelection.team_Application_id,
          team_id: teamSelection.team_id,
          team_name: teamSelection.team_name,
          team_code: teamSelection.team_code,
          program_name: props.location.state.program_chosen.program_name,
          valueChain_id: props.location.state.valueChain.valueChain_id,
          valueChain_name: props.location.state.valueChain.valueChain_name,
          valueChain_code: props.location.state.valueChain.valueChain_code,
          color: 'green',
          label: 'Create Virtual Service',
          icon: 'business-building',
          isSelected: false,
        },
        // {
        //   Route: "virtualservice",
        //   team_Application_id: teamSelection.team_Application_id,
        //   color: "green",
        //   label: "Create Virtual Service - Beta",
        //   icon: "business-building",
        //   isSelected: false,
        //   hidden: !config.api.uri.includes("/backend/api/dev/"),
        // },
        {
          Route: 'managestub',
          // folderStructure_id: response.data[0].folderStructure_id,
          folderStructureS3: folder_s3_path,
          application_code: application_code,
          application_id: application.application_id,
          application_name: application.application_name,
          team_Application_id: teamSelection.team_Application_id,
          team_id: teamSelection.team_id,
          team_name: teamSelection.team_name,
          team_code: teamSelection.team_code,
          program_id: chosenProgramId[0].id,
          program_code: chosenProgramId[0].program_code,
          program_name: props.location.state.program_chosen.program_name,
          valueChain_id: props.location.state.valueChain.valueChain_id,
          valueChain_name: props.location.state.valueChain.valueChain_name,
          valueChain_code: props.location.state.valueChain.valueChain_code,

          // program_id:data.program_id,
          random: 1121,
          color: 'green',
          label: 'Manage Virtual Services',
          icon: 'file-sharing',
          isSelected: false,
        },
      ]);
    }
  };
  const handleApplicationClick = async (data) => {
    try {
      setApplication({
        application_id: data.application_id,
        application_name: data.application_name,
        application_code: data.application_code,
      });
      setTeamSelection({
        team_id: '',
        team_name: '',
        team_code: '',
        team_Application_id: '',
      });
      // Teams for a given application and a given user
      let userTeamsApplications = await getUserTeamsApplications(
        data.application_id
      );
      setTeamData(userTeamsApplications.data.result);
      if (userTeamsApplications.data.result.length == 1) {
        // if there is only one Team name under that application, set Action automatically
        setDataForAction(userTeamsApplications.data.result[0].team_name);
        setTeamSelection({
          team_id: userTeamsApplications.data.result[0].team_id,
          team_name: userTeamsApplications.data.result[0].team_name,
          team_code: userTeamsApplications.data.result[0].team_code,
          team_Application_id:
            userTeamsApplications.data.result[0].team_Application_id,
        });
      } else {
        setActionData([]);
      }
    } catch (error) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR,
        },
      });
    }
  };

  const handleActionClick = (data) => {
    history.push({
      pathname: data.Route,
      state: {
        // folderStructure_id: data.folderStructure_id,
        team_name: data.team_name,
        folderStructureS3: data.folderStructureS3,
        application_code: data.application_code,
        application_id: data.application_id,
        application_name: data.application_name,
        team_Application_id: data.team_Application_id,
        team_code: data.team_code,
        program_id: data.program_id,
        program_name: data.program_name,
        program_code: data.program_code,
        valueChain_name: data.valueChain_name,
        valueChain_id: data.valueChain_id,
        valueChain_code: data.valueChain_code,
        team_id: data.team_id,
      },
    });
  };

  const setSelectionFn = (obj) => {
    app_data.forEach((item) => {
      if (item.c === obj.application_name) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    });
    setAppData(app_data);
    setRevision(revision + 1);

    handleApplicationClick(obj);
  };

  const EditTeamData = () => {
    if(isAdmin){
      return(<EditIcon onClick={() => editButton("teams")} fontSize="large" color="secondary" style={{ cursor: "pointer" }} />)
    }else{
      return (<></>);
    }
  };

  const EditAppData = () => {
    if(isAdmin){
      return(<> <EditIcon onClick={() => editButton("applications")} fontSize="large" color="secondary" style={{ cursor: "pointer" }} /></>)
    }else{
      return (<></>);
    }
  };

  const Teams = (teams_data) => {
    return (
      <Row style={{ marginLeft: '10pw' }}>
        <Col xs='5'>
          <Input
            type='select'
            bsSize='sm'
            onChange={(e) => {
              {
                teams_data.data.map((dataItem) => {
                  if (dataItem.team_name == e.target.value) {
                    setTeamSelection({
                      team_id: dataItem.team_id,
                      team_name: dataItem.team_name,
                      team_code: dataItem.team_code,
                      team_Application_id: dataItem.team_Application_id,
                    });
                  }
                });
              }
              // setTeamSelection(e.target.value)
              setDataForAction(e.target.value);
            }}
            name='teamSelection'
            value={teamSelection.team_name}
          >
            <option
              isselected={team_data.length != 1 ? 'true' : 'false'}
              disabled={
                team_data.length == 1 || teamSelection.team_name != ''
                  ? true
                  : false
              }
            >
              Select a Team
            </option>
            {teams_data.data.map((dataItem) => {
              return (
                <option style={{ marginTop: '122px' }} key={dataItem.team_id}>
                  {dataItem.team_name}
                </option>
              );
            })}
          </Input>
        </Col>
      </Row>
    );
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      var scrollEl = document.getElementById('view-content');
      if (scrollEl != null)
        window.scrollTo({
          top: scrollEl.scrollHeight + 85,
          behavior: 'smooth',
        });
    }, 100);
  };
  const Application = (applicationProps) => {
    const { data } = applicationProps;
    return (
      <Row style={{ marginTop: '30px', paddingLeft: '10px' }}>
        {data.map((tile, index) => (
          <Col xs={2} key={index} className='mt-3'>
            <div
              id='view-content'
              className={`tiles-grid flex base-${tile.color}`}
            >

              <TilePose pose={tile.isSelected ? 'selected' : 'init'}>
                <DataFilterTileNew
                  {...tile}
                  obj={tile}
                  isSelected={
                    application.application_id === tile.application_id
                  } // If this is undefined handle as usual
                  selectType={() => {
                    setSelectionFn(tile);
                  }}
                ></DataFilterTileNew>
              </TilePose>
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  const Action = (actionProps) => {
    const { data } = actionProps;
    return (
      <Row style={{ marginTop: '30px', paddingLeft: '5px' }}>
        {data.map((tile, index) => (
          <Col xs={2} key={index}>
            <div className={`tiles-grid flex base-${tile.color}`}>
              <TilePose pose={tile.isSelected ? 'selected' : 'init'}>
                <DataFilterTileNew
                  {...tile}
                  obj={tile}
                  minimize={tile.minimize}
                  isSelected={tile.isSelected} // If this is undefined handle as usual
                  selectType={() => {
                    handleActionClick(tile);
                  }}
                ></DataFilterTileNew>
              </TilePose>
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  const renderApplication = async () => {
    // let response = await getAppData({
    //   program_id: program.program_id,
    //   team_id: program.team_id,
    //   valueChain_id: valueChain.valueChain_id,
    // });
    // get Applications for a particular valueChain_id and a particular program
    try {
      let response = getFormattedApplication(
        props.location.state.userData,
        valueChain.valueChain_id,
        props.location.state.program_chosen.program_name
      );
      if (response.code === 200) {
        let d = response.data;
        let a = [];
        d.forEach((element) => {
          a.push({
            application_id: element.application_id,
            application_name: element.application_name,
            application_code: element.application_code,
            team_Application_id: element.team_Application_id,
            color: 'magenta',
            label: element.application_name,
            icon: element.app_icon,
            isSelected: false,
          });
        });
        setAppData(a);
      }
    } catch (error) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR,
        },
      });
    }
  };
  const swalCompToLoad = (caller) => {
    switch (caller) {
      case 'applications':
        return <ApplicationEdit></ApplicationEdit>


      case 'teams':
        return <TeamEdit></TeamEdit>
      default:

    }
  }
  const editButton = (callerName) => {
    MySwal.fire({
      html: swalCompToLoad(callerName)
      ,
      confirmButtonText: 'Close',
      width: 1200,
      padding: '2em',
    })
  }
  useEffect(() => {
    renderApplication();
    if (team_data.length > 0) {
      // everytime team name changes, i.e., team selection changes from the drop down.
      setDataForAction('Random');
    }
  }, [teamSelection.team_name]);
  const applicationName = application.application_name !== '' ? ' - ' + application.application_name : '';
  const teamName = teamSelection.team_name != '' ? `-  ${teamSelection.team_name}`: '';
  return (
    <Container>
      <>

        <div className='white mt-3'>
          {app_data.length > 0 ? (
            <div>
              <Row>
                <Col xs={11}>
                  <h1>
                    Virtual Service Creation (
                    {valueChain.valueChain_name}
                    )
                  </h1>
                </Col>
                <Col>
                  {valueChain.valueChain_name !== '' && (
                    <TelstraIcon
                      primary
                      icon='network-elem-managers'
                      color='blue'
                    />
                  )}
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col xs={10}>
                  <h2>
                    Application
                    {applicationName}
                  </h2>
                </Col>

                <Col xs={1}>
                  <EditAppData></EditAppData>
                </Col>
                <Col xs="1">
                  {application.application_name !== '' && (
                    <TelstraIcon primary icon='tick' color='green' />
                  )}
                </Col>
              </Row>

              <Application data={app_data} />
            </div>
          ) : (
              ''
            )}
          {team_data.length > 0 ? (
            <div>
              <br />
              <br />
              <Row>
                <Col xs="11">
                  <h2>
                    Team {teamName}
                  </h2>
                </Col>

                <Col xs="1">
                  <EditTeamData></EditTeamData>
                </Col>
              </Row>
              <Teams data={team_data} />
            </div>
          ) : (
              ''
            )}
          {action_data.length > 0 ? (
            <div>
              <br></br>
              <br></br>
              <h2>Actions</h2>
              <Action data={action_data} />
            </div>
          ) : (
              ''
            )}
        </div>
      </>
    </Container>
  );
};

export default ApplicationsStructure;
