import axios from "axios";
import {
  CREATE_REQUEST,
  CHANGEPASS,
  PROGRAM_LIST,
  TEAM_LIST_ByProgramId
} from "./endpoints";

import { getToken } from "./authentication";
import CommonService from "./common.service";


// Purpose : User operation like registeration etc


export const addUserDnumber = async (email, dnumber) => {
  const api = CommonService.getApiUrl("userData/addUserDnumber");
  CommonService.httpPost(api, { email, dnumber })
    .then(response => {
      localStorage.setItem('dnumber', dnumber);
      return response;
    }).catch(error => {
      console.log('ERROR:', error)
      return error;
    });
}


export const register = async (user) => {
  let response;
  try {
    response = await axios.post(CREATE_REQUEST, user);
  } catch (error) {
    response = error.response;
  }
  return response.data;
};

export const changepass = async (user) => {
  let response;
  axios.defaults.headers.common = { Authorization: `Bearer ${getToken()}` };
  try {
    response = await axios.post(CHANGEPASS, user);
  } catch (error) {
    response = error.response;
  }
  return response.data;
};

const handleErrorResponse = (error) => {
  let apiResponse;
  const { response } = error;
  if (response) {
    apiResponse = error;
  }
  return apiResponse;
}

export const getProgramList = async () => {

  return await axios
    .get(PROGRAM_LIST)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return handleErrorResponse(e);
    });
};

export const getTeamList = async (program_id) => {

  return await axios
    .post(TEAM_LIST_ByProgramId, { program_id: program_id })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return handleErrorResponse(e);
    });

};


export const getUserAPITokens = async (user_id) => {
  const api = CommonService.getApiUrl("userTokens");
  return CommonService.httpGet(api, { user: user_id });
};

export const generateUserAPIToken = async (tokenDescription) => {
  const api = CommonService.getApiUrl("userTokens");
  return CommonService.httpPost(api, { description: tokenDescription });
};

export const deleteUserAPIToken = async (tokenId) => {
  const api = CommonService.getApiUrl(`userTokens/${tokenId}`);
  return CommonService.httpDelete(api);
};

export const getTokenExpiry = async () => {
  const api = CommonService.getApiUrl("getTokenExpiry");
  return CommonService.httpGet(api);
}

export const getTeams = async () => {
  const api = CommonService.getApiUrl("userTeams");
  return CommonService.httpGet(api);
}
export const submitUserFeedback = async (feedback) => {
  const api = CommonService.getApiUrl("rating");
  return CommonService.httpPost(api, feedback);
}