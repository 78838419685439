import React from 'react'
import { Row, Col, Input } from "reactstrap";
import { AddToolTip } from "../../common/custom/AddToolTip";
import { Verbs } from "../../../services/verbiage";
import { InputLabel, Typography } from "@material-ui/core";
import {
  MAX_BODY_SIZE,
} from "../../utils/stubValidators";

export default function Properties({
  actionData,
  index,
  handleFEPPropertiesResponseActionsUpdate
}) {
  return (
    <div>
      <Row className="mt-2">
        <Col>
          <Typography variant="body1">
            In this action, you can use any templates supported by SV to retrieve and assign complex variables.
            Example usage: create variables from request components, extract variables from cache and create new variables from them and so on. <br />
            ${`{{ #set: fullName }}{{ Request.QueryParam.firstName }} {{ Request.QueryParam.lastName }}{{/ set}}`}
          </Typography>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.properties.body}>
            <InputLabel className="p-0">Body</InputLabel>
          </AddToolTip>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Input
            type="textarea"
            bsSize="sm"
            maxLength={MAX_BODY_SIZE}
            rows="8"
            name="body"
            value={(actionData.body) ? actionData.body : ""}
            onChange={(e) => handleFEPPropertiesResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>
    </div>
  )
}
