import React, { useEffect, useState } from 'react';
import { TilePose } from "../../utils/pose";
import { DataFilterTileNew } from "../../FolderStructure/tiles/DataFilterTileNew";
import { TelstraIcon } from "../../common/icons/GradientIcons";



import { Container, Row, Col, Label, Input } from 'reactstrap';
import UsageDashboardService from '../../../services/UsageDashboard.service';
import MaterialTable from 'material-table';
import Graphs from './Graphs';
import Swal from 'sweetalert2';
import moment from "moment";
import { useHistory } from "react-router-dom";


import {
  getDetailedDataUser,

} from '../../../services/folderStructure.service';
import errorResponses from "../../ErrorHandling/errorResponses";

export default function UsageDashboard() {
  let history = useHistory();

  const options_map_default = [
    {
      description: '',
      program_name: 'Select a Program',
      program_code: 0,
      valuechains: [
        {
          vc_code: 0,
          valueChain_name: 'Select a Valuechain',
          vc_id: '0',
          applications: [
            {
              application_name: 'Select Application',
              application_code: 0,
              application_id: '0',
              app_icon: '',
              teams: [
                {
                  team_id: 0,
                  team_code: 0,
                  team_name: 'Select Team',
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  const [queryParameter, setQueryParameter] = useState('');

  // sets efault value of value chain in the dropdown
  // default value is "Select a Team"
  // contains all the program's data
  const [, setProgramData] = useState([
    { program_name: '', program_code: '', program_description: '' },
  ]);
  const [graphicalData, setGraphicalData] = useState([]);
  const [numberOfDays, setNumberOfDays] = useState(7)
  const [selectedProgramData] = useState(
    options_map_default[0]
  );
  // contains the master data having all relations of teams and applications
  const [allTeamApplications, setAllTeamApplications] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(0);
  const [allApplications, setAllApplications] = useState();

  const [selectedApplication, setSelectedApplication] = useState(0);

  const [, setShowDates] = useState(false);
  const [selectedValueChain] = useState(
    options_map_default[0].valuechains[0]
  );

  // FROM DATE
  const [, setFromDate] = useState('2020-07-20T00:30');

  // To Date
  const [, setToDate] = useState('2020-07-20T10:30');

  const [, setUserData] = useState({
    Programs: options_map_default,
    code: 200,
  });
  const [columns] = useState([
    {
      title: 'Date',
      field: 'grouped_time',
      type: 'datetime',
      // render: rowData =>
    },
    { title: 'Incoming requests', field: 'cum_req_count', type: 'numeric' },
    { title: 'Average request size(bytes)', field: 'avg_req_size', type: 'numeric', render: rowData => rowData.avg_req_size.toFixed(3) },
    { title: 'Average response size(bytes)', field: 'avg_res_size', type: 'numeric', render: rowData => rowData.avg_res_size.toFixed(3) },
    { title: 'Average response time(ms)', field: 'avg_res_time', type: 'numeric', render: rowData => rowData.avg_res_time.toFixed(3) },
    { title: 'Errored Response', field: 'cum_err_count', type: 'numeric' },
    {
      title: 'Number of Deployed endpoints',
      field: 'avg_deployed_endPoints_count',
      type: 'numeric',
    },
    {
      title: 'Number of Deployments',
      field: 'cum_deployments_count',
      type: 'numeric',
    },
    {
      title: 'Total Number of EndPoints',
      field: 'total_endpoints',
      type: 'numeric',
    },
    {
      title: 'New Endpoints',
      field: 'cum_new_endpoints_count',
      type: 'numeric',
    },


    {
      title: 'Modified Endpoints',
      field: 'cum_modified_endpoints_count',
      type: 'numeric',
    },
  ]);
  const [data, setData] = useState('');
  // Outer loop will be data,
  //  inner loop will loop through form sample keys and add key values relevant to it
  // loop through keys of this form, i.e. graph1, graph2
  // data1.map(value => value.grouptime

  // )
  const getResults = async (days, team_code, application_code) => {
    try {
      let [startDate, endDate] = dateSetter(days);
      let query_params = {};
      if (selectedTeam != '0' || team_code != 0) {
        query_params.team_code = team_code;
        if (team_code == 0) {
          delete query_params["team_code"];

        }
      }
      if (selectedApplication != '0' || application_code != 0) {

        query_params.application_code = application_code
        if (application_code == 0) {
          delete query_params["application_code"];

        }
      }
      if (selectedValueChain.vc_code != '0') {
        query_params.valuechain_code = selectedValueChain.vc_code;
      }
      if (selectedProgramData.program_code != '0') {
        query_params.program_code =
          selectedProgramData.program_code;
      }
      query_params.groupby = 'hour';
      query_params.from = startDate;
      query_params.to = endDate;
      setQueryParameter(query_params);

      let response = await UsageDashboardService.getVsMetrics(
        query_params
      );
      if (response.data.length > 0) {
        setData(response.data);

        var resposneVsMetrics = response.data;
        var graphData = {};
        const keys = Object.keys(response.data[0]);

        keys.forEach((keyItem) => {
          graphData[keyItem] = { x: [], y: [] };
        })

        resposneVsMetrics.forEach((resposneVsMetricsItem) => {
          //  x variable contains dates
          //  xVariable =

          keys.forEach((keyItem) => {
            graphData[keyItem]['x'].push(
              resposneVsMetricsItem['grouped_time']
            );

            graphData[keyItem]['y'].push(
              resposneVsMetricsItem[keyItem]
            );
            graphData[keyItem]['type'] = 'bar';
          });
        });

        const keysGraphData = Object.keys(graphData);
        keysGraphData.forEach((keyGraphItem) => {
          var arr = [];
          arr.push(graphData[keyGraphItem]);
          graphData[keyGraphItem] = arr;
        });

        setGraphicalData(graphData);
      } else {
        Swal.fire(
          'No Data',
          'No Data for the provided Parameters'
        ).then(() => setData());
      }
    }
    catch (error) {
      history.push({
        pathname: "errorPage",
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        }
      });
    }
  }
  const getData = async () => {
    try {
      dateSetter(numberOfDays)
      let teamApplicationsData = await UsageDashboardService.getTeamApplications();
      setAllTeamApplications(teamApplicationsData.data.result)

      // gives all the programs and Applications, that user has access to.
      let userDetailedData = await getDetailedDataUser();

      setUserData(userDetailedData.data);
      let formattedProgramData = UsageDashboardService.getFormattedProgramData(
        userDetailedData.data
      );
      setProgramData(formattedProgramData.result);
      if (queryParameter != '') {
        let response = await UsageDashboardService.getVsMetrics(queryParameter);
        setData(response.data);
      }
    }
    catch (error) {
      history.push({
        pathname: "errorPage",
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        }
      });
    }
  };
  const dateSetter = (days) => {
    try {
      let tempDate = new Date();
      var startDateFilter;
      var endDateFilter;
      endDateFilter = new Date(tempDate.setDate(tempDate.getDate()));
      endDateFilter = moment.utc(endDateFilter).format("YYYY-MM-DDTHH:mm:ss");

      startDateFilter = new Date(tempDate.setDate(tempDate.getDate() - days));

      startDateFilter = moment.utc(startDateFilter).format("YYYY-MM-DDTHH:mm:ss");


      setFromDate(startDateFilter);
      setToDate(endDateFilter);
      return [startDateFilter, endDateFilter]
    }
    catch (error) {
      history.push({
        pathname: "errorPage",
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        }
      });
    }
  }
  const setSelectionFn = (obj) => {
    try {
      setSelectedApplication(obj.application_code)
      getResults(numberOfDays, selectedTeam, obj.application_code)

      allApplications.forEach((item) => {
        if (item.application_code === obj.application_code) {
          item.isSelected = true;
        } else {
          item.isSelected = false;
        }
      })
    }
    catch (error) {
      history.push({
        pathname: "errorPage",
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        }
      });
    }
  }

  const Application = (props) => {
    // props contains data of all applictions
    const { data: dataObject } = props;
    return (
      <Row style={{ marginTop: "30px", paddingLeft: "10px" }}>
        {dataObject.map((tile, index) => (
          <Col xs={2} key={index} className="mt-3">
            <div id="view-content" className={`tiles-grid flex base-magenta`}>
              <TilePose pose={tile.isSelected ? "selected" : "init"}>
                <DataFilterTileNew
                  {...tile}
                  obj={tile}
                  //  isSelected={tile.isSelected} // If this is undefined handle as usual
                  selectType={(tileData) => {
                    setSelectionFn(tileData);
                  }}
                ></DataFilterTileNew>
              </TilePose>
            </div>
          </Col>
        ))}
      </Row>
    );
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Container>
        <>
          <div className='whiteTable mt-3'>
            <Row >
              <Col xs={11}><h1>Usage Metrics</h1></Col>
              <Col xs={1}><TelstraIcon primary icon="check-usage" color="blue" /></Col>
            </Row>
            <hr></hr>
            <Row style={{ marginTop: "2vh", marginLeft: "2vw" }}>
              <Col xs="1.5">
                <Label>Select a Team</Label>
              </Col>
              <Col xs="3">
                <Input
                  type="select"
                  bsSize="sm"
                  onChange={(e) => {
                    setSelectedTeam(allTeamApplications[e.target.value][0].team_code);
                    setAllApplications(allTeamApplications[e.target.value]);
                    setSelectedApplication(0);

                    getResults(numberOfDays, allTeamApplications[e.target.value][0].team_code, 0)
                    setShowDates(true)
                  }}
                >
                  <option isselected={selectedTeam == 0 ? "true" : "false"}
                    disabled={selectedTeam != 0 ? true : false}
                    value={0}>
                    Select a Team
                  </option>
                  {Object.keys(allTeamApplications).map((allTeamApplicationsDataItem, index) => {
                    return <option key={index} value={allTeamApplicationsDataItem}>{allTeamApplicationsDataItem}</option>
                  })}
                </Input>
              </Col>
            </Row>


            {/* <Row
              style={{
                marginTop: '4vh',
                marginLeft: '2vh',
                marginBottom: '3vh',
              }}
            >

            </Row> */}
            {(data != '') ? (<div>
              {/* <Row style={{ marginLeft: "2vw" }}> <Col xs = "1.5">
                Application</Col> <Col xs="5">- {(selectedApplication==0)?<div>All</div>:selectedApplication}
              </Col></Row> */}
              <Row>
                <Col>
                  <Application data={allApplications} />
                </Col>

              </Row>
              {/* <Row style = {{ marginTop: "2vh", marginLeft: "2vw" }}>
              <Col xs="1.5"> Filter Apps</Col>
              <Col xs="2">
                <Input
                type="select"
                bsSize="sm">

                  {
                    allApplications.map(
                      (applicationItem, index)=>{
                      return <option key={index}>{applicationItem.application_name}</option>
                      }
                    )
                  }
                </Input>
              </Col>


            </Row> */}
            </div>) : (<></>)}
          </div>
        </>
      </Container>
      {data != '' ? (

        <Container>
          <><div className='whiteTable mt-3'>
            <Row >
              <Col style={{ display: "inline" }}>
                <li className='float-right' style={{ listStyleType: "none " }}>
                  <ul className='hoverClass1' style={{ display: "inline", paddingRight: "10px", color: (numberOfDays == 1 ? "blue" : "black") }}
                    onClick={() => { dateSetter(1); setNumberOfDays(1); getResults(1, selectedTeam, selectedApplication) }}>
                    1d
                  </ul>
                  <ul className='hoverClass1' style={{ display: "inline", paddingRight: "10px", color: (numberOfDays == 7 ? "blue" : "black") }}
                    onClick={() => { dateSetter(7); setNumberOfDays(7); getResults(7, selectedTeam, selectedApplication) }}>1w</ul>
                  <ul className='hoverClass1' style={{ display: "inline", paddingRight: "10px", color: (numberOfDays == 30 ? "blue" : "black") }}
                    onClick={() => { dateSetter(30); setNumberOfDays(30); getResults(30, selectedTeam, selectedApplication) }}>1M</ul>
                  <ul className='hoverClass1' style={{ display: "inline", paddingRight: "10px", color: (numberOfDays == 182 ? "blue" : "black") }}
                    onClick={() => { dateSetter(182); setNumberOfDays(182); getResults(182, selectedTeam, selectedApplication) }}>6M</ul>
                  <ul className='hoverClass1' style={{ display: "inline", color: (numberOfDays == 365 ? "blue" : "black") }}
                    onClick={() => { dateSetter(365); setNumberOfDays(365); getResults(365, selectedTeam, selectedApplication) }}>1Y</ul>
                </li>
              </Col>


            </Row>
            <Row>
              <Col>
                <MaterialTable
                  title=''
                  columns={columns}
                  data={data}
                  options={{
                    headerStyle: {
                      fontWeight: 'bold',
                    },
                    actionsColumnIndex: -1,
                  }}
                  style={{ marginTop: '3vh' }}
                />
              </Col>

            </Row>
            <></>
          </div></>

        </Container>
      ) : (<></>)}


      <Container><><div>
        {data != '' ? (
          <div>
            {' '}
            {graphicalData.length !== 0 && <div className='white mt-3'>
              <Graphs value={graphicalData} />
            </div>}
          </div>
        ) : (
          <></>
        )}
        <Row>
          <Col></Col>
        </Row>
      </div>
      </>
      </Container>
    </div>
  );
}
