import React from 'react';
import { Row, Col, Card, CardBody, CardText, CardTitle } from "reactstrap";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

const PasmatMetricsCard = ({ metricsData }) => {
  return <Row style={{ marginTop: 20, marginBottom: 20 }}>
    <Col xs={4}>
      <Card data-testid="total-stubs-card">
        <CardBody>
          <CardTitle tag="h5" style={{ fontSize: 18 }} className="text-center">Total Stubs Created</CardTitle>
          <CardText>
            <div style={{ width: "40%", marginLeft: '30%' }}>
              <CircularProgressbar
                value={metricsData.totalStubsCreated}
                maxValue={metricsData.totalStubsCreated}
                text={metricsData.totalStubsCreated}
                styles={buildStyles({
                  textColor: "#3e7ad8",
                  pathColor: "#3e7ad8"
                })}
              />
            </div>
          </CardText>
        </CardBody>
      </Card>
    </Col>
    <Col xs={4}>
      <Card data-testid="total-used-stubs-card">
        <CardBody>
          <CardTitle tag="h5" style={{ fontSize: 18 }} className="text-center">Total Stubs Used</CardTitle>
          <CardText>
            <div style={{ width: "40%", marginLeft: '30%' }}>
              <CircularProgressbar
                value={metricsData.totalStubsUsed}
                maxValue={metricsData.totalStubsUsed}
                text={metricsData.totalStubsUsed}
                styles={buildStyles({
                  textColor: "#3e7ad8",
                  pathColor: "#3e7ad8"
                })}
              />
            </div>
          </CardText>
        </CardBody>
      </Card>
    </Col>
    <Col xs={4}>
      <Card data-testid="avg-res-time-card">
        <CardBody>
          <CardTitle tag="h5" style={{ fontSize: 18 }} className="text-center">Average Response Time</CardTitle>
          <CardText>
            <div style={{ width: "40%", marginLeft: '30%' }}>
              <CircularProgressbar
                value={metricsData.averageResponseTime}
                maxValue={metricsData.averageResponseTime}
                text={metricsData.averageResponseTime}
                styles={buildStyles({
                  textColor: "#3e7ad8",
                  pathColor: "#3e7ad8"
                })}
              />
            </div>
          </CardText>
        </CardBody>
      </Card>
    </Col>
  </Row>
}

export default PasmatMetricsCard;