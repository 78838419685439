import CommonService from "./common.service";
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
function generateUsageReport() {
  pdfMake.createPdf(docDefinition).open();

}
var column1_team_info =
{
  // auto-sized columns have their widths based on their content
  width: '50%',

  //   text:'j',
  stack: [
    // second column consists of paragraphs
    { text: 'Monthly Usage', fontSize: 30 },
    {
      text: [

        { text: 'Team: ', color: '#3C78D8' },
        'SV Team'

      ], margin: [10, 2]

    },

    {
      text: [

        { text: 'FTL: ', color: '#3C78D8' },
        'Prashant Sharmaaa11'

      ], margin: [10, 2]

    },
    {
      text: [

        { text: 'FTL Email: ', color: '#3C78D8' },
        { text: 'psharma@team.telstra.com: ' },


      ], margin: [10, 2]

    },
    {
      text: [

        { text: 'Admin: ', color: '#3C78D8' },
        { text: 'gaganl@team.telstra.com: ' },

      ], margin: [10, 2]

    }
  ],

}

var column2_summary = {

  width: '50%',
  stack: [
    { text: 'Summary', fontSize: 25 },

    {
      width: '50%',
      layout: 'lightHorizontalLines', // optional

      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        // widths: [ '*', 'auto', 100, '*' ],
        widths: ['*', 100],
        body: [
          ['First', 'Second'],
          ['Value 1', 'Value 2'],
          ['Value 1', 'Value 2'],
          ['Value 1', 'Value 2'],
          ['Value 1', 'Value 2'],
          ['Value 1', 'Value 2'],
          ['Value 1', 'Value 2'],
          ['Value 1', 'Value 2'],
          ['Value 1', 'Value 2'],
          [{ text: 'Bold value', bold: true }, 'Val 2']
        ],
        layout: 'lightHorizontalLines', // optional

      }
    }
  ]
}
var docDefinition = {
  content: [
    {
      columns: [
        column1_team_info

        ,
        column2_summary

      ],
      // optional space between columns
      columnGap: 10
    },

    {
      text: [

        { text: 'Graph ', color: '#3C78D8' },]
    }
  ]
};

const pdfConvertToFormat = (pdfEncoded64) => {
  let byteCharacters = atob(pdfEncoded64);
  let byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  let byteArray = new Uint8Array(byteNumbers);
  let file = new Blob([byteArray], { type: 'application/pdf;base64' });
  return file
}


function getPdfReport1(params) {
  const api = CommonService.getApiUrl("support/getUsageReportPdf");
  return CommonService.httpPost(api, params);
}
function getPdfReport(params) {
  const api = CommonService.getApiUrl("support/getPdfReportUsage");
  return CommonService.httpGet(api, params);
}
function getActiveKeys(params) {
  const api = CommonService.getApiUrl("getActiveKeys");
  return CommonService.httpGet(api, params);
}
function getFtlData(params) {
  const api = CommonService.getApiUrl("getFtlData");
  return CommonService.httpGet(api, params);
}
function getStubMetricsUsageReport(params) {
  const api = CommonService.getApiUrl("metrics/getStubMetricsUsageReport");
  return CommonService.httpGet(api, params);
}
const usageReportService = {
  generateUsageReport,
  getPdfReport,
  pdfConvertToFormat,
  getActiveKeys,
  getFtlData,
  getStubMetricsUsageReport


};
export default usageReportService;