
import config from "./config";
const { uri } = config.api;

// Purpose : Keep all end points in one place to be used by service

export const REGISTER = `${uri}user/register`;
export const CHANGEPASS = `${uri}user/changepass`;
export const SIGNIN = `${uri}signin`;
export const SIGNIN_ACCOUNT01 = `${uri}telstraSignIn`;
export const LOGOUT = `${uri}logout`;
export const ACTIVATE_USER = `${uri}user/active`;
export const PROGRAM_LIST = `${uri}user/programList`;
export const TEAM_LIST_ByProgramId = `${uri}user/teamListByProgramId`;
export const REQUEST_LIST = `${uri}support/registeration/list`;
export const NEW_REQUEST_LIST = `${uri}support/registeration/newrequest`;
export const CREATE_REQUEST = `${uri}support/registeration/create`;
export const PROCESS_REQUEST = `${uri}support/registeration/process`;
export const ADD_USER_TEAMS = `${uri}support/registeration/addUserTeams`
export const DASH_METRICS = `${uri}metrics/getdashboardMetrics`;
export const FOLDERID_REQUEST = `${uri}folder/fetchFolderStructureId`;
export const PROGRAM_TEAM_REQUEST = `${uri}folder/getProgramAndTeamByUserId`;
export const TESTMAIL_REQUEST = `${uri}testmail`;
export const API_GET_METRICS = `${uri}metrics/getAdminMetrics`;
export const GET_STUB_METRICS_APP = `${uri}metrics/getStubMetricsApp`;
export const GET_STUB_DTL_METRICS = `${uri}metrics/getStubDetialsMetrics`;
export const GET_USER_METRICS = `${uri}metrics/getUserMetrics`;
export const API_GET_STREAMS = `${uri}support/getStreamsByProgramId`;
export const API_GET_APPBySTREAM = `${uri}support/getAppByStreamId`;
export const CHECK_USER_EXISTS = `${uri}checkemailexists`;
export const SIGNIN_ACCOUNT01_WITH_DNUMBER = `${uri}telstraSignInWithDnumber`;
export const GET_TOKEN_EXPIRY = `${uri}getTokenExpiry`;
export const SIGN_IN_WITH_TAAS = `${uri}SignInWithTaas`;
