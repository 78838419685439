import React, { useEffect, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Jumbotron,
  Spinner,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { Typography, Divider } from "@material-ui/core";
import MaterialTable from "material-table";
import { palette } from "../../assets/colors";
import { getRole } from "../../services/authentication";
import SecretsManager from "./SecretsManager";
import FileManager from "./FileManager";
import DebugLogs from "./DebugLogs";
import General from "./General";
import workspaceSettingsService from "../../services/workspaceSettings.service";

const mASSLClientsCertColumns = [
  { title: "Name", field: "Filename" },
  {
    title: "Created On",
    field: "LastModified",
    type: "datetime",
    dateSetting: { locale: "en-AU" },
  },
];

const mASSLHTTPFwdClientsCertColumns = [
  { title: "Certificate", field: "Filename" },
  { title: "Key", field: "keyFile.Filename" },
  {
    title: "Created On",
    field: "LastModified",
    type: "datetime",
    dateSetting: { locale: "en-AU" },
  },
];

const isValidCertificateFile = (certFileContents) => {
  return true;
};

const isValidKeyFile = (keyFileContents) => {
  return true;
};
const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

export default function Settings(props) {
  const [activeTab, setActiveTab] = useState("0");
  const [
    mASSLClientsCertificateToUploadWarning,
    setMASSLClientsCertificateToUploadWarning,
  ] = useState(false);
  const [
    mASSLHTTPFwdClientsCertToUploadWarning,
    setMASSLHTTPFwdClientsCertToUploadWarning,
  ] = useState(false);
  const [
    mASSLClientsCertificateToUpload,
    setMASSLClientsCertificateToUpload,
  ] = useState({});
  const [
    mASSLHTTPFwdClientsCertToUpload,
    setMASSLHTTPFwdClientsCertToUpload,
  ] = useState({});
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const makeMessagesDisappearAfterSometime = () => {
    delay(20000).then(() => {
      setApiErrorMessage("");
    });
  };

  const [loading, setLoading] = useState(true);
  const [mASSLClientsCertificates, setMASSLClientsCertificates] = useState([]);
  const [mASSLHTTPFwdClientsCerts, setMASSLHTTPFwdClientsCerts] = useState([]);
  const handleMASSLClientsDeleteCertificate = (rowData) => {
    workspaceSettingsService
      .removeAAMASSLClientsCertificate(rowData.Key)
      .then(() => {
        updateData();
      })
      .catch((error) => {
        setApiErrorMessage(`Error when updating data. ${error}`);
        makeMessagesDisappearAfterSometime();
      });
  };
  const handleMASSLHTTPFwdClientsDeleteCert = (rowData) => {
    workspaceSettingsService
      .removeAAMASSLHTTPFwdClientsCert(rowData.Key, rowData.keyFile.Key)
      .then(() => {
        updateData();
      })
      .catch((error) => {
        setApiErrorMessage(`Error when updating data. ${error}`);
        makeMessagesDisappearAfterSometime();
      });
  };
  const handleMASSLClientsAddCertificate = () => {
    if (
      mASSLClientsCertificateToUpload.content &&
      mASSLClientsCertificateToUpload.fileInfo &&
      mASSLClientsCertificateToUpload.fileInfo.size &&
      isValidCertificateFile(mASSLClientsCertificateToUpload.content)
    ) {
      workspaceSettingsService
        .addAMASSLClientsCertificate(
          props.sVHost,
          mASSLClientsCertificateToUpload
        )
        .then(() => {
          updateData();
        })
        .catch((error) => {
          setApiErrorMessage(`Error when updating data. ${error}`);
          makeMessagesDisappearAfterSometime();
        });
    } else {
      setMASSLClientsCertificateToUploadWarning(true);
    }
  };
  const handleMASSLHTTPFwdClientsAddCert = () => {
    const isMASSLHTTPFwdClientsCertToUploadValid = () => {
      return (
        mASSLHTTPFwdClientsCertToUpload.alias &&
        mASSLHTTPFwdClientsCertToUpload.alias.length &&
        mASSLHTTPFwdClientsCertToUpload.certFile &&
        mASSLHTTPFwdClientsCertToUpload.keyFile &&
        mASSLHTTPFwdClientsCertToUpload.certFile.content &&
        mASSLHTTPFwdClientsCertToUpload.certFile.fileInfo &&
        mASSLHTTPFwdClientsCertToUpload.certFile.fileInfo.size &&
        mASSLHTTPFwdClientsCertToUpload.keyFile.content &&
        mASSLHTTPFwdClientsCertToUpload.keyFile.fileInfo &&
        mASSLHTTPFwdClientsCertToUpload.keyFile.fileInfo.size &&
        isValidCertificateFile(
          mASSLHTTPFwdClientsCertToUpload.certFile.content
        ) &&
        isValidKeyFile(mASSLHTTPFwdClientsCertToUpload.keyFile.content)
        // && (mASSLHTTPFwdClientsCertToUpload.certFile.fileInfo.name.split('.').slice(0, -1).join('.') === mASSLHTTPFwdClientsCertToUpload.keyFile.fileInfo.name.split('.').slice(0, -1).join('.'))
      );
    };
    if (isMASSLHTTPFwdClientsCertToUploadValid()) {
      workspaceSettingsService
        .addAMASSLHTTPFwdClientsCert(
          props.sVHost,
          mASSLHTTPFwdClientsCertToUpload
        )
        .then(() => {
          updateData();
        })
        .catch((error) => {
          setApiErrorMessage(`Error when updating data. ${error}`);
          makeMessagesDisappearAfterSometime();
        });
    } else {
      setMASSLHTTPFwdClientsCertToUploadWarning(true);
    }
  };
  const handleMASSLClientsAddCertificateChooseFile = (e) => {
    const certFile = e.target.files[0];
    const mASSLClientsCertificateFileReader = new FileReader();
    mASSLClientsCertificateFileReader.onloadend = () => {
      setMASSLClientsCertificateToUpload({
        fileInfo: {
          name: certFile.name,
          size: certFile.size,
          lastModified: certFile.lastModified,
        },
        content: mASSLClientsCertificateFileReader.result,
      });
    };
    mASSLClientsCertificateFileReader.readAsBinaryString(certFile);
  };
  const handleMASSLHTTPFwdClientsAddCertChooseFileChange = (e) => {
    switch (e.target.name) {
      case "certUpload": {
        const certFile = e.target.files[0];
        const mASSLHTTPFwdClientsCertFileReader = new FileReader();
        mASSLHTTPFwdClientsCertFileReader.onloadend = () => {
          setMASSLHTTPFwdClientsCertToUpload({
            ...mASSLHTTPFwdClientsCertToUpload,
            certFile: {
              fileInfo: {
                name: certFile.name,
                size: certFile.size,
                lastModified: certFile.lastModified,
              },
              content: mASSLHTTPFwdClientsCertFileReader.result,
            },
          });
        };
        mASSLHTTPFwdClientsCertFileReader.readAsBinaryString(certFile);
        break;
      }
      case "keyUpload": {
        const keyFile = e.target.files[0];
        const mASSLHTTPFwdClientsCertFileReader = new FileReader();
        mASSLHTTPFwdClientsCertFileReader.onloadend = () => {
          setMASSLHTTPFwdClientsCertToUpload({
            ...mASSLHTTPFwdClientsCertToUpload,
            keyFile: {
              fileInfo: {
                name: keyFile.name,
                size: keyFile.size,
                lastModified: keyFile.lastModified,
              },
              content: mASSLHTTPFwdClientsCertFileReader.result,
            },
          });
        };
        mASSLHTTPFwdClientsCertFileReader.readAsBinaryString(keyFile);
        break;
      }
      case "alias": {
        const alias = e.target.value;
        setMASSLHTTPFwdClientsCertToUpload({
          ...mASSLHTTPFwdClientsCertToUpload,
          alias: alias,
        });
        break;
      }
      default: {
        break;
      }
    }
  };
  const mASSLClientsCertActions = [
    {
      icon: "delete",
      iconProps: {
        style: { color: palette.danger },
      },
      tooltip: "Delete",
      position: "row",
      onClick: (event, rowData) => handleMASSLClientsDeleteCertificate(rowData),
    },
  ];

  const mASSLHTTPFwdClientsCertActions = [
    {
      icon: "delete",
      iconProps: {
        style: { color: palette.danger },
      },
      tooltip: "Delete",
      position: "row",
      onClick: (event, rowData) => handleMASSLHTTPFwdClientsDeleteCert(rowData),
    },
  ];

  const renderMASSLStubEndpointsCertificates = () => {
    return (
      <TabPane tabId="1" className="p-3">
        {loading ? (
          <Jumbotron className="text-center">
            <Spinner className="p-5" color="primary"></Spinner>
          </Jumbotron>
        ) : (
          <>
            <div className="m-0">
              <Row className="mt-2">
                <Col>
                  <Typography variant="subtitle1">
                    This is an admin only feature which provides UI to append to the existing MASSL server certificate.
                  </Typography>
                </Col>
              </Row>
              <Divider className="m-2"></Divider>
              <Typography variant="h6">Upload new certificate</Typography>
              <Row className="mt-2">
                <Col xs="auto" className="pl-2">
                  <Input
                    size="sm"
                    type="file"
                    name="certUpload"
                    onChange={handleMASSLClientsAddCertificateChooseFile}
                    accept=".pem, application/pem-certificate-chain"
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs="auto">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => handleMASSLClientsAddCertificate()}
                  >
                    Upload
                </Button>
                </Col>
                <Col>
                  <Typography
                    variant="subtitle1"
                    color="error"
                    hidden={!mASSLClientsCertificateToUploadWarning}
                  >
                    <span role="img" aria-label="warning emoji">
                      &#9888;&#65039;
                  </span>{" "}
                  Choose non-empty file to upload. The certificate file
                  should follow PKCS #8 format.
                </Typography>
                </Col>
              </Row>
            </div>
            <Row className="mt-2 mb-1">
              <Col>
                <Typography
                  variant="body2"
                  color="error"
                  hidden={!apiErrorMessage}
                >
                  <span role="img" aria-label="warning emoji">
                    &#9888;&#65039;
            </span>{" "}
                  {apiErrorMessage}
                </Typography>
              </Col>
            </Row>
            <MaterialTable
              columns={mASSLClientsCertColumns}
              title={<Typography variant="h6">{`Certificates`}</Typography>}
              data={mASSLClientsCertificates}
              actions={mASSLClientsCertActions}
              options={{
                headerStyle: {
                  fontWeight: "bold",
                  backgroundColor: palette.blueseq[3],
                },
                paging: mASSLClientsCertificates.length > 5,
                actionsColumnIndex: -1,
              }}
            />
          </>
        )}
      </TabPane>
    );
  };

  const renderMASSLForHTTPForwardActionsCertificates = () => {
    return (
      <TabPane tabId="2" className="p-3">
        {loading ? (
          <Jumbotron className="text-center">
            <Spinner className="p-5" color="primary"></Spinner>
          </Jumbotron>
        ) : (
          <>
            <div className="m-0">
              <Row className="mt-2">
                <Col>
                  <Typography variant="subtitle1">
                    Use the setting when you need to access MASSL enabled HTTP forward actions in your virtual services.
                    You can store multiple certificate, key pairs for the workspace.
                    Refer to the certificate, key pair in MASSL enabled HTTP forward action by means of it's alias.
                  </Typography>
                </Col>
              </Row>
              <Divider className="m-2"></Divider>
              <Typography variant="h6">
                Upload new certificate and key
                  </Typography>
              <Row className="mt-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <Typography variant="subtitle1" className="m-0">
                    Certificate
                      </Typography>
                </Col>
                <Col className="pl-2">
                  <Input
                    size="sm"
                    type="file"
                    name="certUpload"
                    onChange={
                      handleMASSLHTTPFwdClientsAddCertChooseFileChange
                    }
                    accept=".pem, application/pem-certificate-chain"
                  />
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <Typography variant="subtitle1" className="m-0">
                    Key
                      </Typography>
                </Col>
                <Col className="pl-2">
                  <Input
                    size="sm"
                    type="file"
                    name="keyUpload"
                    onChange={
                      handleMASSLHTTPFwdClientsAddCertChooseFileChange
                    }
                    accept=".pem, application/pem-certificate-chain, .key"
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <Typography variant="subtitle1" className="m-0">
                    Alias
                      </Typography>
                </Col>
                <Col xs="6" className="pl-2">
                  <Input
                    size="sm"
                    name="alias"
                    onChange={
                      handleMASSLHTTPFwdClientsAddCertChooseFileChange
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs="auto">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => handleMASSLHTTPFwdClientsAddCert()}
                  >
                    Upload
                      </Button>
                </Col>
                <Col>
                  <Typography
                    variant="body2"
                    color="error"
                    hidden={!mASSLHTTPFwdClientsCertToUploadWarning}
                  >
                    <span role="img" aria-label="warning emoji">
                      &#9888;&#65039;
                        </span>{" "}
                        Provide a valid alias and choose non-empty certificate
                        and key files to upload. The certificate and key file
                        should follow PKCS #8 format.
                      </Typography>
                </Col>
              </Row>
            </div>
            <Row className="mt-2 mb-1">
              <Col>
                <Typography
                  variant="body2"
                  color="error"
                  hidden={!apiErrorMessage}
                >
                  <span role="img" aria-label="warning emoji">
                    &#9888;&#65039;
                  </span>{" "}
                  {apiErrorMessage}
                </Typography>
              </Col>
            </Row>
            <MaterialTable
              columns={mASSLHTTPFwdClientsCertColumns}
              title={<Typography variant="h6">{`Certificates`}</Typography>}
              data={mASSLHTTPFwdClientsCerts}
              actions={mASSLHTTPFwdClientsCertActions}
              options={{
                headerStyle: {
                  fontWeight: "bold",
                  backgroundColor: palette.blueseq[3],
                },
                paging: mASSLClientsCertificates.length > 5,
                actionsColumnIndex: -1,
              }}
            />
          </>
        )}
      </TabPane>
    );
  };

  const renderActiveTabContents = () => {
    switch (activeTab) {
      case "0":
        return <General sVHost={props.sVHost} activeTab={activeTab}></General>;
      case "1":
        return renderMASSLStubEndpointsCertificates();
      case "2":
        return renderMASSLForHTTPForwardActionsCertificates();
      case "3":
        return (
          <SecretsManager sVHost={props.sVHost} activeTab={activeTab} />
        );
      case "4":
        return (
          <FileManager sVHost={props.sVHost} activeTab={activeTab} />
        );
      case "5":
        return (
          <DebugLogs sVHost={props.sVHost} activeTab={activeTab} />
        );
      default:
        return null;
    }
  };

  const updateErrorDetails = (error) => {
    setApiErrorMessage(`Error when updating data. ${error}`);
    makeMessagesDisappearAfterSometime();
    setLoading(false);
  }
  
  const updateData = () => {
    setLoading(true);
    setMASSLClientsCertificateToUploadWarning(false);
    setMASSLHTTPFwdClientsCertToUploadWarning(false);
    if (activeTab === "1") {
      workspaceSettingsService
        .getMASSLClientsCertificates(props.sVHost)
        .then((response) => {
          setMASSLClientsCertificates(response.data);
          makeMessagesDisappearAfterSometime();
          setLoading(false);
        })
        .catch((error) => {
          updateErrorDetails(error);
        });
    } else if (activeTab === "2") {
      workspaceSettingsService
        .getMASSLHTTPFwdClientsCerts(props.sVHost)
        .then((response) => {
          setMASSLHTTPFwdClientsCerts(response.data);
          makeMessagesDisappearAfterSometime();
          setLoading(false);
        })
        .catch((error) => {
          updateErrorDetails(error);
        });
    }
  };
  useEffect(() => {
    updateData();
  }, [activeTab]);

  return (
    <>
      <div className="white mt-3 text-left">
        <Typography variant="h5">Workspace settings</Typography>
        <Typography variant="subtitle1" className="mt-2"><strong>Virual service host: </strong>{`https://${props.sVHost}`}</Typography>
        <Typography variant="subtitle1" className="mt-2">Allows you to configure settings specific to your workspace sandbox. Workspace is defined as a combination of team-application-valuechain-program and identified by the virtual service host.</Typography>
        <Nav tabs className="mt-3">
          <NavItem className="border-top border-right border-left rounded">
            <NavLink
              className={classnames({ active: activeTab === "0" })}
              onClick={() => {
                toggleTab("0");
              }}
            >
              General
            </NavLink>
          </NavItem>
          {getRole() === "support" ? (
            <NavItem className="border-top border-right border-left rounded">
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                MASSL stub endpoints
              </NavLink>
            </NavItem>
          ) : null}
          <NavItem className="border-top border-right border-left rounded">
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggleTab("2");
              }}
            >
              MASSL for HTTP forward actions
            </NavLink>
          </NavItem>
          <NavItem className="border-top border-right border-left rounded">
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggleTab("3");
              }}
            >
              Secrets manager
              </NavLink>
          </NavItem>
          <NavItem className="border-top border-right border-left rounded">
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => {
                toggleTab("4");
              }}
            >
              File manager
              </NavLink>
          </NavItem>
          <NavItem className="border-top border-right border-left rounded">
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => {
                toggleTab("5");
              }}
            >
              Debug logs
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          {renderActiveTabContents()}
        </TabContent>
      </div>
    </>
  );
}
