import React from 'react'
import { Row, Col, Input, FormFeedback } from "reactstrap";
import { AddToolTip } from "../../common/custom/AddToolTip";
import { Verbs } from "../../../services/verbiage";
import { InputLabel } from "@material-ui/core";
import {
  MAX_BODY_SIZE,
} from "../../utils/stubValidators";

export default function Kinesis({
  actionData,
  index,
  actionType,
  handleFEPKinesisResponseActionsUpdate
}) {
  return (
    <div>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.httpForward.url}>
            <InputLabel className="p-0 asterisk">Protocol proxy base URL</InputLabel>
          </AddToolTip>
        </Col>
        {/* </Row>
      <Row className="mt-2"> */}
        <Col>
          <Input
            placeholder="https://<hostname>:<port>"
            bsSize="sm"
            name="protocolProxyURL"
            value={(actionData.protocolProxyURL) ? actionData.protocolProxyURL : ""}
            onChange={(e) => handleFEPKinesisResponseActionsUpdate(e, index)}
          ></Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.pathInvalid}
          </FormFeedback>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.kinesisWrite.authorizationToken}>
            <InputLabel className="p-0">Authorization token</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            placeholder="enter authorization token"
            bsSize="sm"
            name="authorization"
            value={(actionData.authorization) ? actionData.authorization : ""}
            onChange={(e) => handleFEPKinesisResponseActionsUpdate(e, index)}
          ></Input>
        </Col>

      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          {/* TODO correct the tool tip here */}
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.httpForward.url}>
            <InputLabel className="p-0 asterisk">{(actionType === 'kinesisWrite') ? 'Enter Stream Name' : '<Some value in future>'}</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            placeholder="Enter stream name"
            bsSize="sm"
            name="streamName"
            value={(actionData.streamName) ? actionData.streamName : ""}
            onChange={(e) => handleFEPKinesisResponseActionsUpdate(e, index)}
          ></Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.pathInvalid}
          </FormFeedback>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.httpForward.body}>
            <InputLabel className="p-0 asterisk">Partition key</InputLabel>
          </AddToolTip>
        </Col>

        <Col>
          <Input
            placeholder="Enter partition key"
            bsSize="sm"
            name="partitionKey"
            value={(actionData.partitionKey) ? actionData.partitionKey : ""}
            onChange={(e) => handleFEPKinesisResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.responseAction.contentType}>
            <InputLabel className="p-0">Content type</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            placeholder="add content-type"
            bsSize="sm"
            name="contentType"
            value={(actionData.contentType) ? actionData.contentType : "text/plain"}
            onChange={(e) => handleFEPKinesisResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.httpForward.body}>
            <InputLabel className="p-0">Message</InputLabel>
          </AddToolTip>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Input
            type="textarea"
            bsSize="sm"
            maxLength={MAX_BODY_SIZE}
            rows="4"
            name="streamMessage"
            value={(actionData.streamMessage) ? actionData.streamMessage : ""}
            onChange={(e) => handleFEPKinesisResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>


    </div>
  )
}
