import React from "react";
import { Row, Col, Input, FormFeedback } from "reactstrap";
import { AddToolTip } from "../../common/custom/AddToolTip";
import { Verbs } from "../../../services/verbiage";
import { InputLabel } from "@material-ui/core";
import { Validator } from "react";
import {
  MAX_BODY_SIZE
} from "../../utils/stubValidators";
export default function SMTP({
  actionData,
  index,
  handleFEPSMTPResponseActionsUpdate,
}) {
  const isInavalidEmailAddress = (emailAddress) => {
    if(emailAddress){
      if(validateEmail(emailAddress)){
        return false;
      }else return true;
    }else return false;
  }
  const isInavalidToEmailAddress = (emailAddress) => {
    if(emailAddress){
      if(validateEmail(emailAddress)){
        return false;
      }else return true;
    }else return false;
  }

  const validateEmail = (emailAddress) => {
    return /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?team.telstra\.com$/.test(emailAddress)
  }
  return (
    <>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.smtp.from}
          >
            <InputLabel className="p-0 asterisk">From</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            invalid={isInavalidEmailAddress(actionData.fromAddress)}
            placeholder="example@team.telstra.com"
            bsSize="sm"
            name="fromAddress"
            value={actionData.fromAddress ? actionData.fromAddress : ""}
            onChange={(e) => handleFEPSMTPResponseActionsUpdate(e, index)}
          ></Input> 
          <FormFeedback invalid>
            {Verbs.validationMessages.fromAddressInvalid}
          </FormFeedback>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.smtp.to}
          >
            <InputLabel className="p-0 asterisk">To</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            invalid={isInavalidToEmailAddress(actionData.toAddress)}
            placeholder="example@team.telstra.com"
            bsSize="sm"
            name="toAddress"
            value={actionData.toAddress ? actionData.toAddress : ""}
            onChange={(e) => handleFEPSMTPResponseActionsUpdate(e, index)}
          ></Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.toAddressInvalid}
          </FormFeedback>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.smtp.subject}
          >
            <InputLabel>Subject</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            bsSize="sm"
            name="subject"
            value={actionData.subject ? actionData.subject : ""}
            onChange={(e) => handleFEPSMTPResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.smtp.attachment}
          >
            <InputLabel>Attachment(s)</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            placeholder="Attachment file name(s)"
            bsSize="sm"
            name="emailAttachments"
            value={actionData.emailAttachments ? actionData.emailAttachments : ""}
            onChange={(e) => handleFEPSMTPResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.smtp.body}>
            <InputLabel className="p-0">Body</InputLabel>
          </AddToolTip>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Input
            type="textarea"
            bsSize="sm"
            maxLength={MAX_BODY_SIZE}
            rows="4"
            name="emailBody"
            value={(actionData.emailBody) ? actionData.emailBody : ""}
            onChange={(e) => handleFEPSMTPResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>
    </>
  );
}
