import React from "react";
import oneTelstra from "./assets/images/backgrounds/onetelstra.webp";
import telstraAir from "./assets/images/backgrounds/telstraAir.jpg";
import telstraAir2 from "./assets/images/backgrounds/telstraAir2.jpg";
import BackgroundSlideshow from "react-background-slideshow";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/App.css";
import TelstraStyles from "./telstraStyles";
import Routes from "../src/routes/index";

import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
require("dotenv").config();

function App() {
  const images = [
    oneTelstra,
    telstraAir,
    telstraAir2
  ];


  return (

    <React.Fragment>
      <p hidden>API_URL: {window._env_.API_URL}</p>
      <p hidden>SSO_URL: {window._env_.SSO_URL}</p>
      <div>
        <TelstraStyles />
        <BackgroundSlideshow className animationDelay={60000} images={images} />
        <ThemeProvider theme={theme}>
          <Routes></Routes>
        </ThemeProvider>
      </div>
    </React.Fragment>

  );
}

export default App;
