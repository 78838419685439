import React from 'react'
import { Row, Col, Input, FormFeedback } from "reactstrap";
import { AddToolTip } from "../../common/custom/AddToolTip";
import { Verbs } from "../../../services/verbiage";
import { InputLabel } from "@material-ui/core";
import {
  MAX_BODY_SIZE,
} from "../../utils/stubValidators";

const activity_types = [
  { label: 'Execute query', value: 'executeQuery' },
  { label: 'Execute update', value: 'executeUpdate' },
]

export default function Database({
  actionData,
  index,
  actionType,
  handleFEPDatabaseResponseActionsUpdate
}) {
  return (
    <div>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.protocolProxyBaseURL}>
            <InputLabel className="p-0 asterisk">Protocol proxy base URL</InputLabel>
          </AddToolTip>
        </Col>
        {/* </Row>
      <Row className="mt-2"> */}
        <Col>
          <Input
            placeholder="https://<hostname>:<port>"
            bsSize="sm"
            name="protocolProxyURL"
            value={(actionData.protocolProxyURL) ? actionData.protocolProxyURL : ""}
            onChange={(e) => handleFEPDatabaseResponseActionsUpdate(e, index)}
          ></Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.pathInvalid}
          </FormFeedback>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.database.authorizationToken}>
            <InputLabel className="p-0">Authorization token</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            placeholder="enter authorization token"
            bsSize="sm"
            name="authorization"
            value={(actionData.authorization) ? actionData.authorization : ""}
            onChange={(e) => handleFEPDatabaseResponseActionsUpdate(e, index)}
          ></Input>
        </Col>

      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.database.activityType}>
            <InputLabel className="p-0 asterisk">{(actionType === 'database') ? 'Activity type' : '<Some value in future>'}</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          {/* <Input
            placeholder="Enter stream name"
            bsSize="sm"
            name="activityType"
            value={(actionData.activityType) ? actionData.activityType : ""}
            onChange={(e) => handleFEPDatabaseResponseActionsUpdate(e, index)}
          ></Input> */}
          <Input
            type="select"
            bsSize="sm"
            name="activityType"
            value={(actionData.activityType) ? actionData.activityType : activity_types[0].value}
            onChange={(e) => handleFEPDatabaseResponseActionsUpdate(e, index)}
          >
            {activity_types.map(({ label, value }) => {
              return <option key={value} value={value}>{label}</option>;
            })}
          </Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.pathInvalid}
          </FormFeedback>
        </Col>
      </Row>
      <Row className="mt-2" hidden>
        <Col xs="auto" className="d-flex align-items-center" hidden>
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.responseAction.contentType}>
            <InputLabel className="p-0">Content type</InputLabel>
          </AddToolTip>
        </Col>
        <Col hidden>
          <Input
            placeholder="add content-type"
            bsSize="sm"
            disabled
            name="contentType"
            value={(actionData.contentType) ? actionData.contentType : "text/plain"}
            onChange={(e) => handleFEPDatabaseResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.database.query}>
            <InputLabel className="p-0">Query</InputLabel>
          </AddToolTip>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Input
            type="textarea"
            bsSize="sm"
            maxLength={MAX_BODY_SIZE}
            rows="4"
            name="query"
            value={(actionData.query) ? actionData.query : ""}
            onChange={(e) => handleFEPDatabaseResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>


    </div>
  )
}
