import React, { useContext, useState, useEffect } from 'react';

import { Route, Redirect } from "react-router-dom";
import { isLoggedIn, getRole, getTokenUnauthenticated, getProfileDataFromToken, getUserInfo } from "../services/authentication";
import { Container, Spinner, Jumbotron } from "reactstrap";
import HeaderNav from "../components/common/Header/HeaderNav";
import { GlobalContext } from '../context/GlobalState';
import ErrorBoundary from '../components/ErrorHandling/ErrorBoundary'
import AppFeedback from '../components/common/custom/AppFeedback';
const PrivateRoute = ({ component: Component, ...rest }) => {
  let {
    setUserRoles,
    setIsAdmin,
    setProfileData
  } = useContext(GlobalContext);
  const [role, setRole] = useState('')
  const [email, setemail] = useState('')
  const [name, setName] = useState('')
  const [dnumber, setDnumber] = useState(null);

  // Purpose : For normal user we are using this layout

  const giveAdminStates = (roleInput) => {
    if (roleInput === 'user') {
      setIsAdmin(false);
    }
    else if (roleInput === 'support') {
      setIsAdmin(true)
    }
  };

  const updateChatbotConfiguration = async () => {
    await window.botpressWebChat.mergeConfig(
      {
        userId: localStorage.getItem("dnumber"),
        hideWidget: false
      }
    );

    await window.botpressWebChat.sendEvent({ type: 'show' });

    await window.botpressWebChat.sendEvent({
      type: 'proactive-trigger',
      channel: 'web',
      payload: { text: '' }
    });
  };

  const getData = async () => {
    const isAuthenticated = isLoggedIn();
    if (isAuthenticated) {
      setRole(getRole())
      setUserRoles(getRole());
      giveAdminStates(getRole());
    }
    else {
      // role = 'unauthenticated'

      //  gets token if the token is there in URL
      // check if user exists, if user exists, then set the token, returns 'valid'
      // if user Doesn't exist return 'registeration' and redirect to registration page
      let response = await getTokenUnauthenticated();
      setemail(response.email)
      setName(response.name)
      setDnumber(response.dnumber)
      let result = response.status;
      if (result) {
        if (result === 'valid') {
          let user = getProfileDataFromToken(localStorage.getItem('accessToken'));
          setUserRoles(getRole());
          setRole(getRole())
          giveAdminStates(getRole())
          setProfileData({ user_name: user.user_name, email: user.email, dnumber });
          updateChatbotConfiguration();
        }

        else if (result === 'registeration') {
          setRole('registeration')
          setUserRoles('registeration');
        }
        else if (result === 'unapproved_yet') {
          setRole('unapproved_yet')
          setUserRoles('unapproved_yet');
        }
        else {
          setRole('unauthenticated')
          setUserRoles('unauthenticated');
        }
        // first check if user has token in url, if yes, then go and set Token, and set role
        // make sure the if
        // block is portable for signin page as well.

        // else set the role to something non useful in switch,
        // redirect tp Sign in Page()
        // Depending on which button(Telstra Login) you press, it will call Telstra URL. Take token and create portal token.
      }
    }
  }

  const getUserData = async () => {
    try {
      let response = await getUserInfo();
      let result = response.status;
      if (result) {
        // set user info
        if (response.user) {
          setemail(response.user.email)
          setName(response.user.name)
          setDnumber(response.user.number)
        }
        // check result status
        if (result === 'valid') {
          let user = getProfileDataFromToken(localStorage.getItem('accessToken'));
          setUserRoles(getRole());
          setRole(getRole())
          giveAdminStates(getRole())
          setProfileData({ user_name: user.user_name, email: user.email, dnumber });
          updateChatbotConfiguration();
        }
        else if (result === 'registeration') {
          setRole('registeration')
          setUserRoles('registeration');
        }
        else if (result === 'unapproved_yet') {
          setRole('unapproved_yet')
          setUserRoles('unapproved_yet');
        }
        else {
          setRole('unauthenticated')
          setUserRoles('unauthenticated');
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    // checks if Token has expired or token is not present
    let isCancelled = false;
    if (!isCancelled) {
      const ssoCallbackURLWithAccessToken = window.location.href;
      const telstraToken = ssoCallbackURLWithAccessToken.split('=')[1];
      if (telstraToken) {
        getData();
      } else {
        getUserData();
      }
    }
    return () => {
      isCancelled = true;
    };

  }, [])
  const renderSwitch = (props) => {
    switch (role) {
      case "user":
        return <ErrorBoundary><Component {...props} {...rest} /></ErrorBoundary>;
      case "support":
        return (
          <Redirect
            to={{
              pathname: "/support/dashboard",
              state: {
                from: props.location,
              },
            }}
          />
        );
      case 'unapproved_yet':
        return (
          <Redirect
            to={{
              pathname: "/signin",
              state: {
                from: props.location,
                status: 'unapproved_yet'
              },
            }}
          />
        );
      case "registeration":
        return (
          <Redirect
            to={{
              pathname: "/register",
              state: {
                from: props.location
              },
              details: {
                name: name,
                email: email,
                dnumber: dnumber
              }
            }}
          />
        );
      default:
        return (
          <Redirect
            to={{
              pathname: "/signin",
              state: {
                from: props.location,
              },
            }}
          />
        );
    }
  };

  return (
    <>
      <HeaderNav></HeaderNav>
      <AppFeedback></AppFeedback>
      <Container
        id="main-content"
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        {(role !== '') ? (<Route
          {...rest}
          render={(props) => (
            renderSwitch(props))

          }
        />) : <Container>
          <div className='whiteTable mt-1'>
            <Jumbotron className="text-center"><Spinner className="p-5" color="primary" ></Spinner></Jumbotron>
            {/* <Spinner size="lg"color="primary" /> */}

          </div></Container>
        }
      </Container>
    </>
  );
};

export default PrivateRoute;

