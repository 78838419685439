import CommonService from "./common.service";
import { simulationSchema } from "./simulationSchema";
import Ajv from "ajv";
import { Row, Col, Table } from "reactstrap";
import React from "react";
const objectPath = require("object-path");
const _ = require("lodash");

// Purpose : Edit and Save stub related method

function getVirtualServices(params) {
  const api = CommonService.getApiUrl("stubs");
  return CommonService.httpGet(api, params);
}
function checkIfDeploymentInProgress(params) {
  const api = CommonService.getApiUrl("stubs/deployment/isanyinprogress");
  return CommonService.httpGet(api, params);
}
function getSVHost(params) {
  const api = CommonService.getApiUrl(`teamapplication/${params.teamApplication}/svhost`);
  return CommonService.httpGet(api);
}
function getSVHostHeader(params) {
  const api = CommonService.getApiUrl(`teamapplication/${params.teamApplication}/svhostheader`);
  return CommonService.httpGet(api);
}
function postVSDeployment(params) {
  const api = CommonService.getApiUrl("stubs/deployment");
  return CommonService.httpPost(api, params);
}

function getZipFileData(params) {
  const api = CommonService.getApiUrl("stubs/download");
  return CommonService.httpPost(api, params);
}

function deleteVS(params) {
  const api = CommonService.getApiUrl(`stub/${params.id}`);
  return CommonService.httpDelete(api);
}
function getS3ObjectVerions(params) {
  const api = CommonService.getApiUrl("s3/getS3ObjectVerions");
  return CommonService.httpPost(api, params);
}

function getFileDataS3(params) {
  // Params should include the path of S3
  const api = CommonService.getApiUrl("s3/getFileData");
  return CommonService.httpPost(api, params);
}
function changeDeploymentStatus(params) {
  // Params should include the path of S3
  const api = CommonService.getApiUrl("stub/changeDeploymentStatus");
  return CommonService.httpPost(api, params);
}





function getRawFileData(params) {
  const api = CommonService.getApiUrl("stub/getFileJustData");
  return CommonService.httpPost(api, params);
}

function createSimulation(params) {
  let newConfigData = params.configData;

  // after validation, this needs changing
  let valid = true




  let ajv = new Ajv({ allErrors: true });
  valid = ajv.validate(simulationSchema, newConfigData);


  if (valid) {
    if (
      params.modifiedHost !== undefined &&
      params.modifiedHost !== ""
    ) {
      newConfigData.data.pairs.forEach((pair, index) => {
        try {
          delete pair.request.headers.Svhost
        }
        catch (error) {
        }

      });

      newConfigData.data.pairs.forEach((pair, index) => {
        let newObj = {};
        Object.assign(newObj, params.modifiedHost);
        pair.request.headers = Object.assign(newObj, pair.request.headers);
      });
    }

    params.configData = newConfigData;
    const api = CommonService.getApiUrl("s3/addFileBucket");
    return CommonService.httpPost(api, params, { "content-type": "text/plain" });
  } else {
    return new Promise((resolve, reject) => {
      let error = formatError(ajv.errors);
      return reject(error);
    });
  }
}

function formatError(errorArray) {
  console.log("errorArray::", errorArray);
  let errorComp = (
    <Row className="mt-3 mb-0 mr-0 ml-0">
      <Col xs="12">
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th>Issue Type</th>
              <th>Issue</th>
              <th>Suggestion</th>
            </tr>
          </thead>
          <tbody>
            {errorArray.map((item, index) => {
              return (
                <>
                  <tr key={index}>
                    <td>{item.keyword}</td>
                    <td>{item.dataPath} {JSON.stringify(item.params)}</td>
                    <td>{item.message}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
  return { errorComp: errorComp, type: "validation" };
}

function saveSimulation(params) {
  let newConfigData = params.updatedData;
  let ajv = new Ajv({ allErrors: true });
  let valid = ajv.validate(simulationSchema, newConfigData);
  if (valid) {
    const api = CommonService.getApiUrl("stub/saveStub");
    return CommonService.httpPost(api, params, { "content-type": "text/plain" });
  } else {
    return new Promise((resolve, reject) => {
      let error = formatError(ajv.errors);
      return reject(error);
    });
  }

}

function getTagedObject(headers) {
  let finalTag = {};

  _.forEach(headers, (item) => {
    let arr = getMatcherObject(item);
    objectPath.set(finalTag, item.key, arr);
  });

  return finalTag;
}

function getMatcherObject(item) {
  let obj = {};
  let arr = [];
  objectPath.set(obj, "value", item.value);
  objectPath.set(obj, "matcher", item.matcher);
  arr.push(obj);
  return arr;
}

function resonseObject(formdata) {
  let blk = {};
  objectPath.set(blk, "status", parseInt(formdata.resStatus.value));
  // Add the fixedDelay key value if value of the delay is true. 0 resolves as false
  // Add fixedDelay key if delay value is more than 0
  if (formdata.resDelay.value) {
    objectPath.set(blk, "fixedDelay", parseInt(formdata.resDelay.value));
  }
  objectPath.set(blk, "body", formdata.resBody.value);
  objectPath.set(blk, "templated", formdata.templated.value);
  objectPath.set(blk, "encodedBody", formdata.encodedBody.value);
  let headersObj = formatResHeaders(formdata.resHeaders);
  objectPath.set(blk, "headers", headersObj);
  return blk;
}

function formatResHeaders(headers) {
  let formattedResHeaders = {};
  _.forEach(headers, (item) => {
    objectPath.set(formattedResHeaders, item.resHeaderKey, [item.resHeaderValue]);
  });
  return formattedResHeaders;
}

const generateEpjson = (formdata) => {
  let blank = {};
  let sets = {};

  let pathObj = getMatcherObject(formdata.path);
  objectPath.set(blank, "path", pathObj);
  let headersObj = getTagedObject(formdata.reqHeaders);
  objectPath.set(blank, "headers", headersObj);
  let queryObj = getTagedObject(formdata.query);
  objectPath.set(blank, "query", queryObj);
  let methodObj = getMatcherObject(formdata.reqMethod);
  objectPath.set(blank, "method", methodObj);
  let reqBodyObj = getMatcherObjectResponse(formdata.reqBody);
  objectPath.set(blank, "body", reqBodyObj);
  objectPath.set(sets, "request", blank);

  let resonseObjectValue = resonseObject(formdata);
  objectPath.set(sets, "response", resonseObjectValue);

  let delaysObject = getDelayObject(formdata.delay);
  objectPath.set(sets, "delays", delaysObject);

  return sets;
};

const generatePreview = (epdata) => {
  let pairs = [];
  let root = {};
  let data = {};
  let delays = [];
  let meta = {
    schemaVersion: "v5",
  };

  epdata.forEach((element) => {
    let sets = {};
    objectPath.set(sets, "request", element.epjson.request);
    objectPath.set(sets, "response", element.epjson.response);
    delays = element.epjson.delays;
    // pairs contains sets of requests and responses
    pairs.push(sets);
  });

  objectPath.set(root, "pairs", pairs);

  if (delays.length > 0) {
    let globalActionObj = {};
    let delayObj = getDelayObject(delays);
    if (delayObj.length > 0) {
      objectPath.set(globalActionObj, "delays", delayObj);
      // root contains delays and pairs of req and response
      objectPath.set(root, "globalActions", globalActionObj);
    }

  }

  objectPath.set(data, "data", root);
  objectPath.set(data, "meta", meta);
  return data;
};
function getDelayMatcherObject(item) {
  let blk = {};
  if (item.urlPattern === undefined || item.urlPattern === "") {
    //do nothing
  } else if (item.delay === undefined || item.delay === null || item.delay === "NaN" || item.delay === "") {
    // do nothing
  } else {

    if (item.httpMethod === null || item.httpMethod === "" || item.httpMethod === undefined) {
      item.httpMethod = "GET";
    }
    objectPath.set(blk, "urlPattern", item.urlPattern);
    objectPath.set(blk, "httpMethod", item.httpMethod);
    objectPath.set(blk, "delay", parseInt(item.delay));
  }

  return blk;
}
function getDelayObject(delays) {
  let arr = [];
  _.forEach(delays, (item) => {
    let obj = getDelayMatcherObject(item);
    let emptyObject = _.isEmpty(obj);
    if (obj !== undefined && !emptyObject && obj != "") {
      arr.push(obj);
    }
  });

  return arr;
}
function getMatcherObjectResponse(item) {
  let obj = {};
  let arr = [];
  if (item.value != "") {
    objectPath.set(obj, "value", item.value);
    objectPath.set(obj, "matcher", item.matcher);
    arr.push(obj);
  }
  return arr;
}

function getObjectByVersion(params) {
  const api = CommonService.getApiUrl("s3/getS3ObjectByVersion");
  return CommonService.httpPost(api, params);
}
function setApplicationDeploymentStatus(params) {
  const api = CommonService.getApiUrl("application/setApplicationDeploymentStatus");
  return CommonService.httpPost(api, params);
}
function checkApplicationDeploymentStatus(params) {
  const api = CommonService.getApiUrl("application/checkApplicationDeploymentStatus");
  return CommonService.httpPost(api, params);
}

function checkendpointexists(path, method, svhost){
  const api = CommonService.getApiUrl("checkendpointexists");
  return CommonService.httpGet(api, { endpoint_path: path, endpoint_method: method, sv_host: svhost});
}

const simulationService = {
  getVirtualServices,
  getSVHost,
  getSVHostHeader,
  postVSDeployment,
  checkIfDeploymentInProgress,
  deleteVS,
  getFileDataS3,
  getZipFileData,
  generateEpjson,
  generatePreview,
  getRawFileData,
  saveSimulation,
  createSimulation,
  getS3ObjectVerions,
  changeDeploymentStatus,
  getObjectByVersion,
  setApplicationDeploymentStatus,
  checkApplicationDeploymentStatus,
  checkendpointexists,
};
export default simulationService;