import React, { useEffect, useState, useContext } from 'react';
import Swal from "sweetalert2";
import ReactFlow, { isNode, Controls } from 'react-flow-renderer';
import { Container, Button } from "reactstrap"
import dagre from 'dagre';
import _ from "lodash";
import { useHistory } from "react-router-dom";

import ReportroblemTwoToneIcon from '@material-ui/icons/ReportProblemTwoTone';
import cjvDefaultNode from './cjvDefaultNode'
import CjvConflictNode from "./cjvConflictNode";
import { nodeColors, palette } from "../../../assets/colors";

import CJVScenarioService from '../../../services/cJVScenarios.service';
import errorResponses from "../../ErrorHandling/errorResponses";

import { GlobalContext } from "../../../context/GlobalState";
import {
    swalBootstrapButtonsQuestion
} from "../../utils/swal";
import "./cjv.css"






export const getLayoutedElements = (elements, direction = 'TB') => {

    try {
        const isHorizontal = direction === 'LR';
        const dagreGraph = new dagre.graphlib.Graph();
        dagreGraph.setDefaultEdgeLabel(() => ({}));
        dagreGraph.setGraph({ rankdir: direction });
        elements.forEach((elementItem) => {
            if (isNode(elementItem)) {
                dagreGraph.setNode(elementItem.id, { width: 225, height: 70 });
            } else {
                dagreGraph.setEdge(elementItem.source, elementItem.target);
            }
        });
        dagre.layout(dagreGraph);
        let res = elements.map((elementItem) => {
            if (isNode(elementItem)) {
                const nodeWithPosition = dagreGraph.node(elementItem.id);
                elementItem.targetPosition = isHorizontal ? 'left' : 'top';
                elementItem.sourcePosition = isHorizontal ? 'right' : 'bottom';
                // unfortunately we need this little hack to pass a slighltiy different position
                // in order to notify react flow about the change
                elementItem.position = {
                    x: nodeWithPosition.x + Math.random() / 100000,
                    y: nodeWithPosition.y,
                };
            }
            return elementItem;
        });
        return res
    }
    catch (error) {
    }
};
const nodeTypes = {
    cjvParentNode: cjvDefaultNode,
    cjvDefaultNode: cjvDefaultNode,
    cjvConflictNode: CjvConflictNode
};


export default function CJVScenarios({
    showCjvLoader,
    vsName,
    setDefaultCJVInVS,
    isCJVGenerated,
    collapseEverything,
    sVHostHeader,
    stubS3Path,
    toggleVSRevision,
    cjvFilename,
    endPointIndexAndId,
    goToManagePage,
    handleSaveVSAfterCJV,
    defaultCJVScenario,
    removeDefaultCJV
}) {
    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const onLoad = (rf) => {
        setReactFlowInstance(rf);
    };

    let {
        cjvElements
    } = useContext(GlobalContext);
    let history = useHistory();
    let [cjvGraphData, setCjvGraphData] = useState(getLayoutedElements(CJVScenarioService.egdeConversion(cjvElements.uiDefinition), 'LR'))
    const [showLoader, setShowLoader] = useState(true);

    const isConflictPresent = () => {
        try {
            let response = cjvElements.uiDefinition.filter(elementItem => elementItem.type === 'cjvConflictNode')
            let returnValue = false;
            if (response.length > 0) {
                returnValue = true
            }
            return returnValue
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: `${errorResponses.GENERIC_ERROR}. ${error}`
                }
            });
        }
    }
    const removeDefault = () => {
        Swal.fire({
            title: 'After removing default CJV , VS needs to be re-deployed, do you want to proceed?',
            // text: 'You are about to delete the virtual service',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    let resEndpointPairs = removeDefaultCJV();
                    handleSaveVSAfterCJV(resEndpointPairs).then((res) => {
                        if (res) {
                            goToManagePage()
                        }
                    })
                        .catch((error) => {
                            Swal.fire(
                                "Error !",
                                `Could not save CJV data due to an internal error. ${error}`,
                                "error"
                            );
                        });
                }
            })
    }
    const makeDefault = () => {
        let fileName = _.cloneDeep(cjvFilename);

        Swal.fire({
            title: 'After adding default CJV , VS needs to be re-deployed, do you want to proceed?',
            // text: 'You are about to delete the virtual service',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    let resEndpointPairs = setDefaultCJVInVS(fileName);

                    handleSaveVSAfterCJV(resEndpointPairs).then((res) => {
                        if (res) {
                            goToManagePage()
                        }
                    })
                        .catch((error) => {
                            Swal.fire(
                                "Error !",
                                `Could not save CJV data due to an internal error. ${error}`,
                                "error"
                            );
                        });



                }
            })

    }
    const handleSaveAsCjv = async () => {
        try {
            let dataToSave = _.cloneDeep(cjvElements);

            for (let item of dataToSave.uiDefinition) {
                delete item.position;
                delete item.sourcePosition;
                delete item.targetPosition;
                delete item.isHidden;
                delete item.uniqueId;
            }

            swalBootstrapButtonsQuestion.fire({
                title: "Enter CJV filename",
                input: "text",
                inputValue:
                    cjvFilename.replace(".json", "")
                ,
                inputAttributes: {
                    autocapitalize: "off"
                },
                inputValidator: (value) => {
                    if (!value) {
                        return "Please provide a name for your CJV file";
                    }
                },
                onOpen: function () {
                    if (!isCJVGenerated) {
                        Swal.disableInput();
                    }
                },
                showCancelButton: true,
                confirmButtonText: "Save",
                cancelButtonText: "Cancel",
                showLoaderOnConfirm: true,
                preConfirm: (value) => {
                    return new Promise((resolve) => {
                        let fileName = ''
                        if (!isCJVGenerated) {
                            fileName = _.cloneDeep(cjvFilename)
                        }
                        else {
                            // if passed through generate CJV button
                            fileName = value.replace(" ", "_") + '.json'
                        }
                        // let resEndpointPairs = setDefaultCJVInVS(fileName);
                        CJVScenarioService.createCJVScenario(fileName, dataToSave, vsName, sVHostHeader, stubS3Path, endPointIndexAndId.endpointIndex, endPointIndexAndId.endpointId)
                            .then((result) => {
                                //     handleSaveVSAfterCJV(resEndpointPairs).then((res) => {
                                //         // if (res) {
                                //         //     goToManagePage()
                                //         // }
                                Swal.fire({
                                    title: "CJV Saved !",
                                    html:
                                        "CJV has been saved",
                                    icon: "success",
                                }).then((res) => {
                                    if (res.value) {
                                        collapseEverything()

                                    }
                                })
                                //     })
                            }
                            )


                            .catch((error) => {
                                Swal.fire(
                                    "Error !",
                                    `Could not fetch data due to an internal error. ${error}`,
                                    "error"
                                );
                            });




                    }
                    )
                }

            })


        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: `${errorResponses.GENERIC_ERROR}. ${error}`
                }
            });
        }
    }


    const addHiddenTags = (cjvCloneElements) => {
        let returnData = _.cloneDeep(cjvCloneElements)
        for (let [key, value] of Object.entries(cjvCloneElements.cjvMapper)) {
            if (value.replace === true) {


                let elementToHide = cjvCloneElements.uiDefinition.filter(
                    filterElement => {

                        return filterElement.data.httpURL === key
                    }
                )[0]

                returnData = CJVScenarioService.addGreyNodes(returnData, elementToHide.data)
            }
        }
        return returnData;
    }

    useEffect(() => {
        try {

            setShowLoader(false)
            let cjvCloneElements = _.cloneDeep(cjvElements);
            // now remove elements with remove true in it .
            // add uniqueID as uniqueId mimics the if that every node has.

            cjvCloneElements = CJVScenarioService.addUniqueId(cjvCloneElements)
            // add hidden Tag for children of cjvMappers.

            cjvCloneElements = addHiddenTags(cjvCloneElements)

            // removes elements wherever isHidden = true for display purposes.
            let newCjvData = CJVScenarioService.removeTaggedElements(cjvCloneElements);

            let initialElementsResponse = getLayoutedElements(CJVScenarioService.egdeConversion(newCjvData.uiDefinition), 'LR')

            setCjvGraphData(initialElementsResponse)
            setShowLoader(true)
            if (reactFlowInstance && initialElementsResponse.length) {
                reactFlowInstance.fitView();
            }

        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: `${errorResponses.GENERIC_ERROR}. ${error}`
                }
            });
        }
    }, [cjvElements, reactFlowInstance]);

    useEffect(() => {
        if (reactFlowInstance && cjvGraphData.length) {
            reactFlowInstance.fitView();
        }
    }, [cjvGraphData])

    return (
        <div>

            {(showCjvLoader && showLoader) ? <></> : (<div className='cjvClass border'>
                {isConflictPresent() === true ? <div style={{ display: 'flexbox', flexDirection: 'row', overflow: 'hidden' }}>
                    <p className="movingtext"

                    >
                        <span style={{ color: 'red' }}>Conflict resolution required, click on </span> <ReportroblemTwoToneIcon fontSize="small" style={{ color: palette.yellow_secondary }} /> <span style={{ color: 'red' }}> to resolve.</span>
                    </p>
                </div> :
                    <></>}

                <div className="mr-2 ml-2">

                    <ReactFlow
                        elements={cjvGraphData}
                        onLoad={onLoad}
                        elementsSelectable={true}
                        style={{
                            height: '550px',
                        }}
                        nodeTypes={nodeTypes}
                        nodesDraggable={false}
                    >
                        <Controls style={{ position: 'absolute', left: '1vw', top: '1vh', height: '10vh' }} />

                        <Container >
                            <div className='mt-1  sticky-bottom' style={{ position: 'absolute', left: '1vw', top: '450px', zIndex: 4 }}>

                                <div className="d-flex flex-column float-left cjvLegend">
                                    <div className="float-right ml-1  d-flex flex-row mr-2 " style={{ justifyContent: 'center', verticalAlign: 'center' }} >
                                        < div
                                            style={{
                                                width: '1vh', height: '1vh', backgroundColor: nodeColors.defaultNode, marginTop: '1px'
                                            }}></div>
                                        <div className="ml-1" style={{ fontSize: '10px' }}> Virtual endpoint </div>



                                    </div>

                                    <div className="float-right mt-1 ml-1 mb-1 d-flex flex-row"  >
                                        < div
                                            style={{
                                                width: '1vh', height: '1vh', backgroundColor: nodeColors.legend2, marginTop: '1px'
                                            }}></div>
                                        <div className="ml-1" style={{ fontSize: '10px' }}> Toggled endpoint </div>



                                    </div>

                                </div>
                            </div>
                        </Container>

                    </ReactFlow>
                </div>

            </div>)}

            {showCjvLoader ? <></> : (
                <div className="d-flex flex-row mt-3 mr-3 float-right">

                    {(defaultCJVScenario === cjvFilename) ?
                        <Button color={nodeColors.info} className="ml-2"
                            disabled={isConflictPresent() || isCJVGenerated ? true : false} onClick=
                            {removeDefault}>
                            Remove Default
                    </Button>
                        : <Button color={nodeColors.info} className="ml-2"
                            disabled={isConflictPresent() || isCJVGenerated ? true : false} onClick=
                            {makeDefault}>
                            Make Default
                    </Button>}



                    <Button color="primary" className="ml-2"
                        disabled={isConflictPresent() ? true : false} onClick={handleSaveAsCjv}>
                        Save CJV
                    </Button>





                </div>)
            }

        </div>
    );
}
