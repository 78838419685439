import React from 'react';
import './UsageBilling.css';
import {   Row, Col} from "reactstrap";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

const TransactionTimeSelect = (props) => {

// onChangeGetDateFormat
    
    const handleOnChange = (type,dateValue) => {
      
        if(type === 'start'){
            props.onDateChange('start', dateValue)
        } else if(type === 'end') {
            props.onDateChange('end', dateValue)
        }
    }

    return (
       
        <><Row>
            <Col>
            
             <MuiPickersUtilsProvider  libInstance={moment} utils={MomentUtils} >
                <KeyboardDateTimePicker
                className="mt-0"
                    value={props.startDate}
                    onChange={(event, value) => {
                        handleOnChange( 'start',value)
                    }}
                    label="Start date"
                    animateYearScrolling = 'true'
                    onError={console.log}
                    disableFuture = 'true'
                    format='YYYY-MM-DD HH:mm:ss'

                    style={{ marginLeft: 30, marginTop: 10, marginRight: 30 }} 
                
                />
                
            </MuiPickersUtilsProvider>
            {/* </Card> */}
            </Col>
           <Col>
           {/* <Card className="ssp-columnBorder" > */}

           <MuiPickersUtilsProvider  libInstance={moment} utils={MomentUtils} >
            <KeyboardDateTimePicker
                    value={props.endDate}
                    className="mt-0"
                    onChange={(event, value) => {
                        handleOnChange( 'end',value)
                    }}
                    label="End date"
                    animateYearScrolling = 'true'
                    onError={console.log}
                    disableFuture = 'true'
                    format='YYYY-MM-DD HH:mm:ss'

                    style={{ marginLeft: 30, marginTop: 10, marginRight: 30 }} 
                     
                />
                
            </MuiPickersUtilsProvider>
            {/* </Card> */}
           </Col>
           
            </Row>
            </>
        // </Card>
    )
}

export default TransactionTimeSelect;