import React from "react";
import DashboardCard from "../../DashboardNew/Cards/DashboardCard";

export const UsageDashboardCard = (props) => (
  <DashboardCard
    title={"Usage Dashboard"}
    subtitle="Virtual service usage"
    icon=""
    color="green"
    to="UsageDashboard"
  />
);

export default UsageDashboardCard;
