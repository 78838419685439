import React, { useEffect, useState } from 'react';
import { PasmatReport } from '@COE-Telstra/pasmat-ui_coe'
import pasmatService from '../../services/pasmat.service';
import { maturityTable, pasmatReportMetrics } from './constants';
import PasmatMainWrapper from './PasmatMainWrapper';
import Recommendations from './Recommendations';
import { Table, Row, Col } from "reactstrap";

import PasmatMetricsCard from './PasmatMetricsCard';



const PasmatReportUI = (props) => {
    const [stubMetrics, setStubMetrics] = useState([]);
    const [metrics, setMetrics] = useState(pasmatReportMetrics({
        productivityPercentage: 0,
        transactionPercentage: 0,
        answerPercentage: 0,
        percentEndpointsUsed: 0
    }));
    const [metricsData, setMetricsData] = useState({})

    const getReport = async () => {
        let team_id = props.history.location.state.team_data.team_id;
        let team_code = props.history.location.state.team_data.team_code;
        let response = await pasmatService.getPasmatReport(team_id, team_code);
        if (response.data.metricsReport) {
            setMetricsData(response.data.metricsReport);
            setMetrics(pasmatReportMetrics(response.data.metricsReport));
        }
    }

    const getStubMetrics = async () => {
        let team_code = props.history.location.state.team_data.team_code;
        let response = await pasmatService.getPasmatTeamsStubMetrics(team_code);
        setStubMetrics(response.data || []);
    }

    useEffect(() => {
        getReport();
        getStubMetrics();
    }, []);

    const generateStubMetricsListTableBody = () => {
        return stubMetrics.map(stub => {
            return (
                <tr>
                    <td scope="row">{stub.stub_name}</td>
                    <td className="text-center">{stub.cum_req_count}</td>
                    <td className="text-center">{stub.cum_err_count}</td>
                    <td className="text-center">{stub.cum_res_time / 1000}</td>
                </tr>
            )
        })
    }

    return <PasmatMainWrapper>
        <PasmatReport
            metrics={metrics}
            maturityTable={maturityTable}
            message="The PASMAT Assessment is submitted successfully. Below is the assessment summary and the adoption maturity level of your team."
            hideMessage={false}
            header="Adoption Maturity Level"
            hideHeader={false}
        />

        <Recommendations />

        <PasmatMetricsCard
            metricsData={metricsData}
        />

        <Row style={{ marginTop: 20, marginBottom: 20 }}>
            {stubMetrics.length === 0 &&
                <Col className="text-center">
                    <h3>No stubs created for current team. Please create scenarios for this team to view PASMAT recommendations.</h3>
                </Col>
            }
            {stubMetrics.length > 0 &&
                <>
                    <Col xs={1}></Col>
                    <Col xs={10}>
                        <Table hover data-testid="execution-list-table">
                            <thead>
                                <tr>
                                    <th>Stub Name</th>
                                    <th className="text-center">Number Of Transactions</th>
                                    <th className="text-center">Number Of Failed Transactions</th>
                                    <th className="text-center">Average Response Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {generateStubMetricsListTableBody()}
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={1}></Col>
                </>
            }
        </Row>
    </PasmatMainWrapper>
}

export default PasmatReportUI;