import React from 'react';
import { Container, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { Divider, Link } from "@material-ui/core";
import { confluenceURLs } from '../../constants';
import {downloadComponent, downloadLocalSVZip} from '../../services/support.service';


export default function InfoDialog(props) {
  return (
    <Container className="text-left mb-0">
      <ListGroupItemHeading className="mt-4">HTTP/s</ListGroupItemHeading>
      <ListGroupItemText>
        <ListGroup flush>
          <ListGroupItem className="m-1 p-2">Append the defined endpoint to the virtual service host<br></br><br></br><strong>Virtual service host:</strong> {"https://" + props.sVHost}</ListGroupItem>
        </ListGroup>
      </ListGroupItemText>
      <ListGroupItemHeading className="mt-4">MASSL</ListGroupItemHeading>
      <ListGroupItemText>
        <ListGroup flush>
          <ListGroupItem className="m-1 p-2">
            Append the defined endpoint to the virtual service host
            <br></br><br></br>
            <strong>Virtual service host:</strong> {"https://" + props.sVHost.replace('sv.', 'sv-massl.')}
            <br></br><br></br>
            Refer to <a href={confluenceURLs.masslUserDoc.link} target="_blank" rel="noopener noreferrer">{confluenceURLs.masslUserDoc.displayText}</a> for further information
          </ListGroupItem>
        </ListGroup>
      </ListGroupItemText>
      <ListGroupItemHeading className="mt-4">Portable workspace</ListGroupItemHeading>
      <ListGroupItemText>
        <ListGroup flush>
          <ListGroupItem className="m-1 p-2">
            You can now install SV in any machine which runs Docker. Refer to <a href={confluenceURLs.portableWorkspaceDoc.link} target="_blank" rel="noopener noreferrer">{confluenceURLs.portableWorkspaceDoc.displayText}</a> for further information on how to use portable workspace.
            <br />
            <br />
            Download <Link component="button" variant="body1" onClick={() => { downloadLocalSVZip() }}>Local SV Artifact</Link>
            <br />
            <br></br>
          </ListGroupItem>
        </ListGroup>
      </ListGroupItemText>
      <Divider></Divider>
      <ListGroupItemHeading className="mt-4">JMS</ListGroupItemHeading>
      <ListGroupItemText>
        <ListGroup flush>
          <ListGroupItem className="m-1 p-2">Follow the steps mentioned in the <a href={confluenceURLs.jmsStubsDoc.link} target="_blank" rel="noopener noreferrer">{confluenceURLs.jmsStubsDoc.displayText}</a></ListGroupItem>
        </ListGroup>
      </ListGroupItemText>
    </Container>
  );
}
