import React, { useEffect, useState } from 'react'
import { Button as RButton, Row, Col, Input, Jumbotron, Spinner } from "reactstrap"

import Button from '@material-ui/core/Button';

import { Container } from "reactstrap"
import MaterialTable from 'material-table';

import usageReportService from "../../services/usageReport.service";
import UsageDashboardService from '../../services/UsageDashboard.service';
import Swal from 'sweetalert2';
import DashboardStatCard from '../Support/Dashboard/Cards/DashboardStatCard';

import GraphsUserReport from './GraphsUserReport';
import { TelstraIcon } from "../common/icons/GradientIcons";
import UsageTimeSelect from './UsageTimeSelect';
import errorResponses from "../../components/ErrorHandling/errorResponses"

import {
    getDetailedDataUserNewFormat

} from "../../services/folderStructure.service";
import {
    getDetailedDataAdminNewFormat

} from "../../services/folderStructure.service";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

import SyncAltIcon from '@material-ui/icons/SyncAlt';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ErrorIcon from '@material-ui/icons/Error';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import accessManageService from '../../services/accessManagement.service';
import { palette } from "../../assets/colors"


export default function UsageReportComp() {
    let history = useHistory();


    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const [showAnimation, setShowAnimation] = useState(false);
    const BLUE = { textcolor: 'white', color: '#3f51b5' }
    const GREY = { textcolor: 'black', color: '#e0e0e0' }
    const [, setPdfEncoded64] = useState('');
    const [adminRole, setAdminRole] = useState('GENERAL')
    const [showPdfButton, setShowPdfButton] = useState(false)
    const [data, setData] = useState('');
    const defaultFtlValues = [
        {
            "team_name": "N/A",
            "team_id": 3,
            "team_code": "teamnft",
            "user_name": "N/A",
            'email': 'N/A',
            "id": 2
        }]

    const [, setFtlDataValues] = useState(defaultFtlValues);
    // contains data for this month, so from 1st until current date
    const [monthlyData, setMonthlyData] = useState('')
    const [graphicalData, setGraphicalData] = useState([]);
    const [dashBoardMetrics, setDashBoardMetrics] = useState('')
    const [dashBrdMetricsCreatedDeployed, setDashBrdMetricsCreatedDeployed] = useState('')
    const [dateSelection, setDateSelection] = useState('')
    const [revision, setRevision] = useState(false);
    const [dateChanged, setDateChanged] = useState(false)
    const [argumentsGetPdf, setArgumentsGetPdf] = useState('')
    const teamDefault = { team_code: '', team_id: 0, team_name: 'All Teams' }
    const programDefault = {
        program_code: '', program_id: 0, program_name: 'SV Product',
        teams: [{ team_code: '', team_id: 0, team_name: '' }]
    }


    // TODO change this default state
    const [selectedTeam, setSelectedTeam] = useState(teamDefault);
    const [allTeamPrograms, setAllTeamPrograms] = useState([{
        program_id: 0,
        program_code: '',
        program_name: '',
        teams: [{ team_code: '', team_id: 0, team_name: '' }]
    }])
    const [showLoader, setShowLoader] = useState(true)
    const [selectedProgram, setSelectedProgram] = useState(programDefault);
    const [activeKeys, setActiveKeys] = useState(0);

    const columns = [
        {
            title: 'Date',
            field: 'grouped_time',
            type: 'datetime',
            width: 100,
            render: rowData => { var dt = new Date(rowData.grouped_time); return dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate() }
        },
        { title: 'Number of transactions', field: 'cum_req_count', type: 'numeric' },

        { title: 'Average response time(sec)', field: 'avg_res_time', type: 'numeric', render: rowData => (rowData.avg_res_time / 1000).toFixed(4) },

        { title: 'Error Response %', field: 'cum_err_count', type: 'numeric', render: rowData => (rowData.cum_err_count === 0) ? 0 : (rowData.cum_err_count / rowData.cum_req_count * 100).toFixed(1) },


    ]

    const getFormattedTeamPrograms = (userData, role) => {
        // all the userData gathered is formatted into programTeams format

        const getTeamsForAdmin = (programTeams) => {
            let result = []
            programTeams.map(programTeamsItem => {
                result = result.concat(programTeamsItem['teams'])
            })


            return removeDuplicates(result, "team_id")
        }
        const getTeamsForNonAdmin = (programTeams) => {
            let result = []
            programTeams.map(programTeamsItem => {
                result = result.concat(programTeamsItem['teams'])
            })


            return [{ teams: removeDuplicates(result, "team_id") }]
        }
        function removeDuplicates(originalArray, prop) {
            // prop is the is based on which removal of duplicates will happen, in this case "team_id"
            var newArray = [];
            var lookupObject = {};

            for (var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
            }

            for (i in lookupObject) {
                newArray.push(lookupObject[i]);
            }
            return newArray;
        }
        //TODO  change it to value of Fomattednew Results
        // let userData = []

        let programTeams = [];
        programTeams['teams'] = []
        userData.map((userDataItem) => {
            let teamsPerProgram = []

            let programTeamsItem = {};
            programTeamsItem.program_id = userDataItem.program_id;
            programTeamsItem.program_name = userDataItem.program_name;
            programTeamsItem.program_code = userDataItem.program_code
            userDataItem.valuechains.map(userDataItemVcApplication => {
                userDataItemVcApplication.applications.map(userDataItemVcAppTeam => {
                    userDataItemVcAppTeam.teams.map((teamItem) => {
                        let teams = {}
                        teams['team_id'] = teamItem['team_id'];
                        teams['team_code'] = teamItem['team_code'];
                        teams['team_name'] = teamItem['team_name'];

                        teamsPerProgram.push(teams)
                        programTeamsItem['teams'] = teamsPerProgram
                        return 1
                    })

                    return 1
                })
                return 1
            })

            programTeamsItem['teams'] = removeDuplicates(programTeamsItem['teams'], "team_id");

            programTeams.push(programTeamsItem)

            return 1


        })
        // add if block just for non admins
        if (role !== 'ADMIN') {
            let finalResult = getTeamsForNonAdmin(programTeams)
            return finalResult
        }
        else {
            let SVObject = {}
            SVObject['program_id'] = 0;
            SVObject['program_code'] = '';
            SVObject['program_name'] = 'SV Product';
            SVObject['teams'] = getTeamsForAdmin(programTeams);
            programTeams.push(SVObject)
            return programTeams
        }

    }

    const getData = async () => {
        // once dashBoardMetrics are obtained send them to get backend call for pdf generation.
        try {
            let date = new Date()
            const month = date.getMonth() + 1
            const dateSel = date.getFullYear() + '-' + month
            // setting date for initial rendering of Date Selector
            setDateSelection(dateSel)
            let adminRoleResponse = (await accessManageService.isFtlOrAdmin()).data.data.role;
            setAdminRole(adminRoleResponse)
            let userDetails;
            if (adminRoleResponse === 'ADMIN') {
                userDetails = await getDetailedDataAdminNewFormat()


            }
            else {
                userDetails = await getDetailedDataUserNewFormat()

            }
            let formattedTeamProg = getFormattedTeamPrograms(userDetails.data.programs, adminRoleResponse)
            // contains teams and programs in a specific format
            setAllTeamPrograms(formattedTeamProg)


            if (adminRoleResponse === 'ADMIN') {
                setSelectedProgram((formattedTeamProg.filter(
                    allTeamProgramItem => allTeamProgramItem.program_id === 0))[0])
                getResults(teamDefault.team_code, programDefault.program_code);

            }
            else {
                setSelectedProgram(formattedTeamProg[0])
                setSelectedTeam(formattedTeamProg[0]['teams'][0])

                getResults(formattedTeamProg[0]['teams'][0].team_code, programDefault.program_code);

            }

            // sending empty values here will mean, retrieve data for product Level
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: errorResponses.GENERIC_ERROR
                }
            });
        }

    }

    const onDateChange = (selection) => {
        setDateSelection(selection)
        setDateChanged(true)
        loadReport(selection)
    }
    const getDateFormat = (currentOrPrev, valuePassedForDate) => {
        // sets the date format for dates picked from the picker
        let [year, month] = valuePassedForDate.split("-")
        year = parseInt(year)
        month = parseInt(month)
        let startDate; let endDate;
        let startDateFilter; let endDateFilter;
        let monthToSubstract = 0
        if (currentOrPrev === 'current') {
            monthToSubstract = 1

        }
        else {
            monthToSubstract = 2
        }
        // will get the exact month for dateSelection while any other value of current 
        // will go one month back for provided dateSelection.
        startDate = new Date(year, month - monthToSubstract, 1)
        startDateFilter = moment(startDate).format("YYYY-MM-DDTHH:mm:ss");
        // lasT DATE of month
        endDate = new Date(year, (month + 1 - monthToSubstract), 0, 23, 59, 59)
        endDateFilter = moment(endDate).format("YYYY-MM-DDTHH:mm:ss");

        // gets correct date based on value from Date Picker
        return [startDateFilter, endDateFilter]
    }

    const getResults = async (team_code, program_code, valuePassedForDate) => {
        try {
            // third parameter is only passed when date is changed
            if (valuePassedForDate === undefined) {
                valuePassedForDate = dateSelection
            }


            let responseActiveKeys = await usageReportService.getActiveKeys({ team_code: team_code, program_code });
            setActiveKeys(responseActiveKeys.data.count)
            let startDate = ''; let endDate = ''; let startDatePreviousMonth = ''; let endDatePreviousMonth = '';
            let teamResponse = defaultFtlValues;
            if (team_code !== "") {
                teamResponse = await usageReportService.getFtlData({ team_code: team_code })
                if (teamResponse.data.ftlData.length > 0) {
                    setFtlDataValues(teamResponse.data.ftlData)
                }
                else {
                    let tempTeamResposne = JSON.parse(JSON.stringify(teamResponse));
                    // it means no FTl is present for the given team
                    teamResponse = defaultFtlValues;

                    teamResponse[0].team_name = tempTeamResposne.data.team_name[0].team_name;


                    setFtlDataValues(defaultFtlValues)
                }
            }


            if (dateChanged) {

                let response = getDateFormat('current', valuePassedForDate);
                //  start and end date is coming up as Australian time
                // example includes: 2020-12-01T00:00:00
                startDate = response[0];
                endDate = response[1]
                let responsePrev = getDateFormat('previous', valuePassedForDate);
                startDatePreviousMonth = responsePrev[0];
                endDatePreviousMonth = responsePrev[1]
            } else {
                let response = dateSetter('current');
                startDate = response[0];
                endDate = response[1]
                let responsePrev = dateSetter('previous');
                startDatePreviousMonth = responsePrev[0];
                endDatePreviousMonth = responsePrev[1]
                // previous means get dates for previous month
            }

            // folowing two lines are used for name of months in graphs.
            let prevMonth = new Date(endDatePreviousMonth).getMonth();
            let curretMonth = new Date(endDate).getMonth();
            let query_params = {};
            if (team_code !== '') {
                query_params.team_code = team_code;
            }
            if (program_code !== '') {
                query_params.program_code = program_code
            }
            query_params.groupby = 'day';
            query_params.from = startDate;
            query_params.to = endDate;
            // this gets daily data
            let response = await UsageDashboardService.getVsMetrics(
                query_params
            );
            // response.data = convertUtcToLocal(response.data)
            // changing query_params to monthly to get monthly data.
            query_params.groupby = 'month'
            let responseMonthly = await UsageDashboardService.getVsMetrics(query_params)

            // get metrics for previous month
            query_params.groupby = 'day';
            query_params.from = startDatePreviousMonth;
            query_params.to = endDatePreviousMonth;
            // Data for previous month which is grouped by day 
            let responsePrevMonthDaily = await UsageDashboardService.getVsMetrics(query_params);
            // let dashBoardMetricsData = await getDashboardMetrics();
            let dashBoardMetricsData = await usageReportService.getStubMetricsUsageReport(
                {
                    program_code: program_code,
                    team_code: team_code,
                    startDate: startDate,
                    endDate: endDate
                }
            );
            setDashBoardMetrics(dashBoardMetricsData.data.data);
            if (dashBoardMetricsData.data.data) {
                let arrDashMetricsCreatedDeployed = []
                arrDashMetricsCreatedDeployed.push(dashBoardMetricsData.data.data['totalStubs'][0])
                arrDashMetricsCreatedDeployed.push(dashBoardMetricsData.data.data['deployedStubs'][0])
                setDashBrdMetricsCreatedDeployed(arrDashMetricsCreatedDeployed);
            }

            if (responseMonthly.data.length > 0) {
                setMonthlyData(responseMonthly.data);


            }
            // ############################################################################################
            // response conatins  data grouped by day while, responseMonthly contains data grouped by month
            if (response.data.length > 0) {
                // sort by date
                // sort response by groupted_time
                let sortedResponse = response.data.sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(a.grouped_time) - new Date(b.grouped_time);
                });

                let sortedResponsePrevMonthDaily = responsePrevMonthDaily.data.sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(a.grouped_time) - new Date(b.grouped_time);
                });

                setData(sortedResponse);
                let resposneVsMetricsPrevMnthDaily = sortedResponsePrevMonthDaily;
                var resposneVsMetrics = sortedResponse;
                let graphData = {};
                let graphDataPrevMonthDaily = {};
                const keys = Object.keys(sortedResponse[0]);
                keys.forEach((keyItem) => {
                    graphData[keyItem] = { x: [], y: [] };
                    graphDataPrevMonthDaily[keyItem] = { x: [], y: [] };
                });

                resposneVsMetrics.forEach((resposneVsMetricsItem) => {
                    keys.forEach((keyItem) => {
                        if (resposneVsMetricsItem[keyItem] !== 0) {
                            graphData[keyItem]['x'].push(
                                resposneVsMetricsItem['grouped_time']
                            );
                            if (keyItem === 'avg_res_time') {
                                // conversion to Sec
                                graphData[keyItem]['y'].push(
                                    resposneVsMetricsItem[keyItem] / 1000
                                );
                            }
                            else {
                                graphData[keyItem]['y'].push(
                                    resposneVsMetricsItem[keyItem]
                                );
                            }
                            graphData[keyItem]['type'] = 'line';
                            graphData[keyItem]['name'] = monthNames[curretMonth];
                        }
                    });
                });
                resposneVsMetricsPrevMnthDaily.forEach((resposneVsMetricsItem) => {

                    keys.forEach((keyItem) => {
                        if (resposneVsMetricsItem[keyItem] !== 0) {
                            graphDataPrevMonthDaily[keyItem]['x'].push(
                                resposneVsMetricsItem['grouped_time']
                            );
                            if (keyItem === 'avg_res_time') {
                                // conversion to Sec
                                graphDataPrevMonthDaily[keyItem]['y'].push(
                                    resposneVsMetricsItem[keyItem] / 1000
                                );
                            }
                            else {
                                graphDataPrevMonthDaily[keyItem]['y'].push(
                                    resposneVsMetricsItem[keyItem]
                                );
                            }
                          
                            graphDataPrevMonthDaily[keyItem]['type'] = 'line';
                            graphDataPrevMonthDaily[keyItem]['name'] = monthNames[prevMonth];
                        }
                    });
                });

                const keysGraphData = Object.keys(graphData);
                let finalGraphData = {}
                keysGraphData.forEach((keyGraphItem) => {
                    var arr = [];
                    arr.push(graphData[keyGraphItem]);
                    arr.push(graphDataPrevMonthDaily[keyGraphItem]);
                    finalGraphData[keyGraphItem] = arr;

                });




                Object.keys(finalGraphData).forEach((finalGraphDataItem) => {
                    // finalGraphDataItem contains key names
                    let dates = []
                    finalGraphData[finalGraphDataItem][0]['x'].map((dateItems) => {
                        let date = new Date(dateItems)
                        dates.push(date.getDate())
                    })
                    finalGraphData[finalGraphDataItem][0]['x'] = dates
                    dates = []
                    finalGraphData[finalGraphDataItem][1]['x'].map((dateItems) => {
                        let date = new Date(dateItems)
                        dates.push(date.getDate())
                    })
                    finalGraphData[finalGraphDataItem][1]['x'] = dates
                })

                if (responseMonthly.data.length > 0) {

                    setArgumentsGetPdf({

                        startDate: startDate, endDate: endDate, team_code: team_code, program_code: program_code,
                        prevMonthStartDate: startDatePreviousMonth, prevMonthEndDate: endDatePreviousMonth
                    })


                }
                setGraphicalData(finalGraphData);
                setShowLoader(false)
                setShowPdfButton(true)
            }

            else {
                Swal.fire(
                    'No Data',
                    'No Data for the provided Parameters'
                ).then(() => {
                    setShowPdfButton(false);
                    // setGraphicalData(no_graph_data)
                    setData([])
                }).then(setShowLoader(false));
            }
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: errorResponses.GENERIC_ERROR
                }
            });
        }
    }



    const dateSetter = (currentOrPrev) => {
        // gets called by default
        // argument, determines, whether to get dates for current or previous month. Accepted values are 'current' and 'previous'
        // according to Aussie time, it gets start date as 1 and end date as today's time. After getting that, it converts the Australian 
        // dates to UTC time
        try {

            const dateNow = moment().tz("Australia/Sydney")


            let tempDate = new Date(dateNow.year(), dateNow.month(), dateNow.date(), dateNow.hour(), dateNow.minute(), dateNow.second())
            let startDateFilter;
            let endDateFilter;
            var date = new Date(dateNow.year(), dateNow.month(), dateNow.date(), dateNow.hour(), dateNow.minute(), dateNow.second())

            // TODO change this back to zero
            let monthsToSubstract = 0
            if (currentOrPrev === 'previous') {
                monthsToSubstract = 1;
                // gets the last date of previous month
                let lastDay = new Date(date.getFullYear(), (date.getMonth() - monthsToSubstract) + 1, 0, 23, 59, 59);

                endDateFilter = moment(lastDay).format("YYYY-MM-DDTHH:mm:ss");

            }
            else {
                // gets the current date of present month
                endDateFilter = new Date(tempDate.setDate(tempDate.getDate()));
                endDateFilter = moment(endDateFilter).format("YYYY-MM-DDTHH:mm:ss");

            }

            // 1 will be substracted if argument to this function is 1.
            // gets the start date of previous month if argument is previous, else it gets start date of present month
            var firstDay = new Date(date.getFullYear(), date.getMonth() - monthsToSubstract, 1);
            startDateFilter = moment(firstDay).format("YYYY-MM-DDTHH:mm:ss");


            return [startDateFilter, endDateFilter];
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: errorResponses.GENERIC_ERROR
                }
            });
        }
    }


    const getCummulativeData = (fieldName) => {
        // lets say value is 'cum_req_count'
        try {
            if (monthlyData !== '') {
                return monthlyData[0][fieldName]
            }

        }
        catch (error) {
            return 0
        }
    }

    const loadReport = (valuePassedForDate) => {
        setShowLoader(true)
        setRevision(!revision)
        if (adminRole === 'ADMIN') {
            getResults(selectedTeam.team_code, selectedProgram.program_code, valuePassedForDate)

        }
        else {
            getResults(selectedTeam.team_code, '', valuePassedForDate)

        }
    }
    const downloadReport = async () => {


        let link = document.createElement('a');
        setShowAnimation(true)
        let responsePdf = await usageReportService.getPdfReport(argumentsGetPdf)
        setPdfEncoded64(responsePdf.data);
        let fileFormat = usageReportService.pdfConvertToFormat(responsePdf.data);
        link.href = URL.createObjectURL(fileFormat);
        setShowAnimation(false);

        link.download = "Usage report.pdf";
        link.click();

    }
    const generateGradient = (index) => {
        return index === 0 || index === 3 ? 'custom-gradient-2' : 'custom-gradient-3'
    }

    const noDataComponent = () => {
        return (
            <>
                <Container>
                    <div className='whiteTable mt-3'>
                        <Row>
                            <Col xs="4">
                                <UsageTimeSelect
                                    onDateChange={onDateChange}
                                    dateSelect={dateSelection}

                                />
                            </Col>
                            <Col xs="8"><h2> No Data found </h2></Col>

                        </Row>


                    </div>
                </Container>
            </>
        )
    }
    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        if (dateChanged) {
            loadReport()
        }
    }, [dateChanged]);
    return (
        <div>
            <Container>
                <>
                    <div className='whiteTable mt-3'>

                        <Row>
                            <Col xs={11}><h2>
                                Usage Report - Service Virtualisation</h2></Col>
                            <Col xs={1}><TelstraIcon primary icon="check-usage" color="blue" /></Col>

                        </Row>
                        <hr></hr>

                        <Row className="mt-1">
                            {/* <Col xs={(adminRole)?"3":"0"}> */}
                            {(adminRole === 'ADMIN') ?
                                (<><Col xs="1.5"><h6>Program</h6> </Col>
                                    <Col xs="2" >
                                        <Input
                                            type="select"
                                            name="program_id"
                                            value={selectedProgram.program_id}
                                            onChange={(e) => {

                                                let selectedProgramDetails = allTeamPrograms.filter(
                                                    allTeamProgram => String(allTeamProgram.program_id) === e.target.value)
                                                setSelectedProgram(selectedProgramDetails[0])
                                                setSelectedTeam(teamDefault)

                                            }
                                            }
                                            bsSize="sm"
                                        >

                                            {
                                                allTeamPrograms.map((allTeamProgramsItem) => {
                                                    return <option value={allTeamProgramsItem.program_id}>{allTeamProgramsItem.program_name}</option>
                                                })
                                            }

                                        </Input>
                                    </Col></>) : <></>}
                            <Col xs="1.5">
                                <h6>Team</h6>
                            </Col>
                            <Col xs="2" >
                                <Input
                                    type="select"
                                    name="team_id"
                                    // disabled = {(selectedProgram.program_id===0)? true:false}
                                    bsSize="sm"
                                    value={selectedTeam.team_id}
                                    onChange={
                                        (e) => {
                                            if (e.target.value !== String(0)) {
                                                let selectedTeamDetails = selectedProgram.teams.filter(
                                                    teamItem => String(teamItem.team_id) === e.target.value)
                                                setSelectedTeam(selectedTeamDetails[0])

                                            }
                                            else {
                                                setSelectedTeam(teamDefault)
                                            }

                                        }
                                    }
                                >
                                    {(adminRole === 'ADMIN') ?
                                        (<option value={teamDefault.team_id}> {teamDefault.team_name}</option>) :
                                        null}
                                    {
                                        selectedProgram.teams.map((teamItem) => {
                                            return <option value={teamItem.team_id}>
                                                {teamItem.team_name}</option>
                                        })
                                    }

                                </Input>
                            </Col>
                            <Col xs="2" >
                                <RButton color="primary"
                                    onClick={() => {

                                        loadReport();

                                    }}
                                >Load Report</RButton>
                            </Col>
                        </Row>
                        <Row className="mt-1">

                        </Row>
                    </div>
                </>

            </Container>
            {(showLoader === false) ? (<>
                <Container><><div>
                    {(data !== '' && data.length && typeof data !== 'undefined' && data !== []) ? (
                        <div className='whiteTable mt-3'>
                            <Row>
                                <Col xs="9"><h2>Summary</h2></Col>
                                <Col xs="3" >
                                    {/* <RButton color="info"  onClick={viewReport}>View report</RButton> */}
                                    <RButton className="float-right"
                                        disabled={!showPdfButton}

                                        onClick={downloadReport}>
                                        {(showAnimation === true) ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> :
                                            ""}
                                            Download Report</RButton>

                                </Col>

                            </Row>

                            {/* <h2>Summary</h2> */}
                            {/* #!#!#!#!#!#!#!#!#!#!#!# ADD Row and column */}
                            <Row className="mt-2">
                                <Col xs="4">
                                    <Row>
                                        <Col >

                                            {
                                                (dashBrdMetricsCreatedDeployed !== '' && typeof dashBrdMetricsCreatedDeployed !== 'undefined') ?

                                                    (<DashboardStatCard gradient={generateGradient(0)} metrics={dashBrdMetricsCreatedDeployed} />) : <></>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col >
                                            <UsageTimeSelect
                                                onDateChange={onDateChange}
                                                dateSelect={dateSelection}

                                            />
                                        </Col>
                                    </Row>

                                </Col>
                                {/* OTher half on right with stast */}
                                <Col xs="8">

                                    <Row className="">


                                        <Col xs="9">
                                            <Button
                                                variant="contained"
                                                style={{ fontWeight: 'bold', fontSize: 14, width: '90%', color: GREY.textcolor, backgroundColor: GREY.color }}
                                                endIcon={<ControlPointIcon />}>
                                                Virtual services created
                             {/* (last 30 days) */}
                                            </Button></Col>
                                        <Col xs="3" >
                                            <Button
                                                variant="contained"
                                                style={{ fontWeight: 'bold', fontSize: 14, color: BLUE.textcolor, width: '80%', backgroundColor: BLUE.color, marginLeft: '1vw', float: 'right' }}
                                            >
                                                {(dashBoardMetrics !== '') ? dashBoardMetrics['totalStubs'][1].count : ''}
                                            </Button></Col>
                                    </Row>
                                    <Row className="mt-3">


                                        <Col xs="9">
                                            <Button
                                                variant="contained"
                                                style={{ fontWeight: 'bold', fontSize: 14, width: '90%', color: GREY.textcolor, backgroundColor: GREY.color }}
                                                endIcon={<SyncAltIcon />}>Number of transactions</Button></Col>
                                        <Col xs="3" ><Button
                                            variant="contained"
                                            style={{ fontWeight: 'bold', fontSize: 14, color: BLUE.textcolor, width: '80%', backgroundColor: BLUE.color, marginLeft: '1vw', float: 'right' }}
                                        >
                                            {getCummulativeData('cum_req_count')}
                                        </Button></Col>
                                    </Row>



                                    <Row className="mt-3">


                                        <Col xs="9"><Button
                                            variant="contained"
                                            style={{ fontWeight: 'bold', fontSize: 14, width: '90%', color: GREY.textcolor, backgroundColor: GREY.color }}
                                            endIcon={<AccessTimeIcon />}> Average Response Time(s)</Button></Col>
                                        <Col xs="3" ><Button
                                            variant="contained"
                                            style={{ fontWeight: 'bold', fontSize: 14, color: BLUE.textcolor, width: '80%', backgroundColor: BLUE.color, marginLeft: '1vw', float: 'right' }}
                                        > {(getCummulativeData('avg_res_time') / 1000).toFixed(4)}</Button></Col>
                                    </Row>



                                    <Row className="mt-3">


                                        <Col xs="9">
                                            <Button
                                                variant="contained"
                                                style={{ fontWeight: 'bold', fontSize: 14, width: '90%', color: GREY.textcolor, backgroundColor: GREY.color }}
                                                endIcon={<ErrorIcon />}>
                                                Erroneous transactions
                                </Button></Col>
                                        <Col xs="3" >
                                            <Button
                                                variant="contained"
                                                style={{ fontWeight: 'bold', fontSize: 14, width: '80%', color: BLUE.textcolor, backgroundColor: BLUE.color, marginLeft: '2vw', float: 'right' }}
                                            >
                                                {getCummulativeData('cum_err_count')}
                                            </Button></Col>
                                    </Row>


                                    <Row className="mt-3">
                                        <Col xs="6"></Col>

                                        <Col xs="9">
                                            <Button
                                                variant="contained"
                                                style={{ fontWeight: 'bold', fontSize: 14, width: '90%', color: GREY.textcolor, backgroundColor: GREY.color }}
                                                endIcon={<VpnKeyIcon />}>
                                                Number of Keys issues
                                </Button> </Col>
                                        <Col xs="3" ><Button
                                            variant="contained"
                                            style={{ fontWeight: 'bold', fontSize: 14, width: '80%', color: BLUE.textcolor, backgroundColor: BLUE.color, marginLeft: '2vw', float: 'right' }}
                                        >
                                            {activeKeys}
                                        </Button></Col>

                                    </Row>
                                </Col>
                            </Row>

                        </div>) : <></>}


                </div>
                </>
                </Container>
                <>
                    {(data !== '' && data.length && typeof data !== 'undefined' && data !== []) ? (
                        <>
                            <Container>
                                <div className='whiteTable mt-3'>

                                    <div className='white mt-3'>
                                        <Row>
                                            <Col>
                                                <MaterialTable
                                                    title=''
                                                    columns={columns}
                                                    data={data}
                                                    style={{ marginTop: '3vh' }}
                                                    options={{

                                                        headerStyle: {
                                                            fontWeight: "bold",
                                                            backgroundColor: palette.blueseq[3],
                                                        },

                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                </div>

                            </Container>
                            <Container>
                                <div className='whiteTable mt-3'>

                                    <div className='white mt-3'>
                                        <Row>
                                            <Col>
                                                {graphicalData.length === 0 ? (
                                                    <></>
                                                ) : (
                                                    <div className='white mt-3'>
                                                        <GraphsUserReport value={graphicalData} />
                                                    </div>

                                                )}
                                            </Col>
                                        </Row>
                                    </div>

                                </div>

                            </Container></>) : noDataComponent()}
                </>
            </>) :
                <div className='whiteTable mt-3'>
                    <Container>
                        <><Jumbotron className="text-center"><Spinner className="p-5" color="primary" ></Spinner></Jumbotron>
                        </></Container></div>
            }
        </div>
    )
}
