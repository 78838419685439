import React, { useEffect, useState } from 'react';
import { getExpiryCountdown, session_logout } from '../../services/authentication';
import { useHistory } from "react-router-dom";
import { getTokenExpiry } from '../../services/user.service';
import Swal from 'sweetalert2'

const SessionTimeout = () => {
  const [expTime, setExpTime] = useState(null);
  const [tokenExpTime, setTokenExpTime] = useState(null);
  const [interval, setIntervalTime] = useState(2 * 60 * 1000);

  let history = useHistory();

  const sessionExpired = async () => {
    session_logout()
      .then(() => {
        history.push("/expired");
      }).catch(() => {
        history.push("/expired");
      });
  }

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timerProgressBar: true,
  })

  useEffect(() => {
    getTokenExpiry()
      .then(response => {
        let time = response.data.expiryTime;
        setTokenExpTime(time);
        let expiry = getExpiryCountdown(time, 'seconds')
        if (expiry > 0)
          setExpTime(expiry);
        else {
          sessionExpired();
        }
      }).catch(err => {
        setExpTime(0);
      });
  }, []);

  const showDialogWindow = (time) => {
    Swal.fire({
      icon: 'warning',
      title: 'Session Expire Warning',
      html: `<p variant="subtitle1">
      The current session will expire within in
      <span className={classes.countdown}> ${Math.ceil((time || interval) / (60 * 1000))}</span> minutes. Please save your changes.
    </p>`,
      timer: 10 * 1000
    }).then((result) => {
      if (result.isConfirmed || result.isDismissed) {
        Toast.fire({
          icon: 'success',
          html: `Session will expire soon`,
          timer: ((time || interval) - (10 * 1000)),
        }).then(() => {
          sessionExpired();
        })
      }
    })
  }

  useEffect(() => {
    if (expTime) {
      if (expTime >= (2 * 60)) {
        let timeout = setTimeout(() => {
          showDialogWindow();
          let logoutTime = setTimeout(() => {
            Swal.close()
            sessionExpired();
          }, interval);
          return () => clearTimeout(logoutTime);
        }, ((expTime - (2 * 60)) * 1000));
        return () => clearTimeout(timeout);
      } else {
        let exp = getExpiryCountdown(tokenExpTime, 'seconds');
        setIntervalTime(exp);
        showDialogWindow(exp);
        let logoutTime = setTimeout(() => {
          Swal.close()
          sessionExpired();
        }, exp * 1000);
        return () => clearTimeout(logoutTime);
      }
      //(expTime - 2) * 60 * 1000)
    }
  }, [expTime]);

  return <></>
};

export default SessionTimeout;