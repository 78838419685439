import CommonService from "./common.service";
import { getUserDetails } from './authentication';

const requestWorkspaceAccess = (reqTeamsAccessParams) => {
  const api = CommonService.getApiUrl("requestWorkspaceAccess");
  let user = getUserDetails();
  let params = {
    team_id : reqTeamsAccessParams.team_id,
    user_id : user.id,
    program_id : reqTeamsAccessParams.program_id
}
  return CommonService.httpPost(api, params);
}

const getWorkspaceAccessRequests = () => {
    const api = CommonService.getApiUrl("getWorkspaceAccessRequests");
    let user = getUserDetails();
    let params = {
      user_id : user.id,
  }
    return CommonService.httpGet(api, params);
}

const getWorkspaceApprovals = () => {
    const api = CommonService.getApiUrl("getWorkspaceApprovals");
    let user = getUserDetails();
    let params = {
      user_id : user.id,
  }
    return CommonService.httpGet(api, params);
}

const updateWorkspaceAccessRequest = (updateTeamsAccessParams) => {
    const api = CommonService.getApiUrl("updateWorkspaceAccessRequest");
    return CommonService.httpPost(api, updateTeamsAccessParams);
}

const getRequestAccessTeamsList = (program_id) => {
  const api = CommonService.getApiUrl("getRequestAccessTeamsList");
  return CommonService.httpGet(api, {program_id});
}



export default {
    requestWorkspaceAccess,
    getWorkspaceAccessRequests,
    getWorkspaceApprovals,
    updateWorkspaceAccessRequest,
    getRequestAccessTeamsList
}