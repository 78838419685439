
// If the component is reactstrap component, use color = "primary" and not the hex code
// https://reactstrap.github.io/components/buttons/
// If you **have** to use color codes in any component use these
const palette = {
    // These are color codes for the reactstrap or bootstrap primary, secondary, success, info, warning, danger colors
    primary: '#0275d8',
    primaryshade2: '#007bff',
    secondary: '#6c757d',
    success: '#5cb85c',
    info: '#5bc0de',
    warning: '#f0ad4e',
    danger: '#d9534f',
    purple: '#9932CC',
    blue: '#3266a8',
    yellow_secondary: '#f57c00',
    // Lightest to darkest - background grays
    // Generated from https://colorbrewer2.org/#type=sequential&scheme=Greys&n=4
    // Click on Export and copy the array from 'Javascript' section 
    bggray: ['#f7f7f7', '#cccccc', '#969696', '#525252'],
    blueseq: ['#f5faff', '#e1f1ff', '#cde8fe', '#b9dffe'],
    purpleseq: ['#f2f0f7', '#cbc9e2', '#9e9ac8', '#6a51a3'],
    // This can be used to represent multicolor categories of upto 6 categories, for example in graphs etc
    categories: ['#8dd3c7', '#ffffb3', '#ff59e3', '#fb8072', '#80b1d3', '#fdb462']
}

const nodeColors = {
    parentNode: '#007BFF',
    defaultNode: '#66B1FF',
    realEndpoint: '#5CB85C',
    stubEndpoint: '#66B1FF',
    conflictNode: '#E6F2FF',
    conflictingEndpoints: '#B3D7FF',
    arrowColor: '#4F81BD',
    legend1: '#66B1FF',
    legend2: '#5cb85c',
    legend0: '#007BFF',
    stubToggle: '#007BF0',
    realToggle: '#006633'
}

export {
    palette,
    nodeColors
};