import './HeaderNavUserMenu.css';
import { Link } from 'react-router-dom';
import React from 'react';
import { session_logout } from '../../../services/authentication';
import config from '../../../services/config';
export const HeaderNavUserMenu = (props) => {
    const signout = (e) => {
        session_logout()
    }

    return (
        <div id="user-menu" style={{ "zIndex": "2000" }}>
            <Link to="changepass">
                <div className="user-menu-item clickable" hidden={!config.enablePasswordLogin}>
                    <i className="td-icon-sm icon-my-profile position-static text-primary"></i>
                    <span className="item-title">Change Password</span>
                </div>
            </Link>
            <Link to="profile">
                <div className="user-menu-item clickable" >
                    <i className="td-icon-sm icon-security-shield position-static text-primary"></i>
                    <span className="item-title">Manage tokens</span>
                </div>
            </Link>
            <div className="item-break" />
            <Link to="/signin" onClick={signout}>
                <div className="user-menu-item clickable">
                    <i className="td-icon-sm icon-lock position-static text-warning"></i>
                    <span className="item-title">Logout</span>
                </div>
            </Link>
        </div>
    )
}