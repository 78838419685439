import React, { useEffect, useState } from "react";
import {
  TabPane,
  Jumbotron,
  Spinner,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import { Divider, Typography } from "@material-ui/core";
import workspaceSettingsService from "../../services/workspaceSettings.service";
import commonService from "../../services/common.service"
import MaterialTable from "material-table";
import { palette } from "../../assets/colors";

const filesColumns = [
  { title: "#", field: "sno", width: 10 },
  { title: "Name", field: "Filename" },
  {
    title: "Last Updated At", field: "LastModified", type: 'datetime', render: rowData => {
      let date = new Date(rowData.LastModified);
      return <span>{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}</span>
    }
  },
  { title: "Size(kB)", field: "Size", align: "center", width: 10, render: rowData => rowData.Size / 1000 },
];

const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

export default function FileManager({ sVHost, activeTab }) {
  const [files, setFiles] = useState([]);
  const [fileToUpload, setFileToUpload] = useState({ Filename: "", Contents: "", OriginalFileExtension: "" });
  const [showWarning, setShowWarning] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const makeMessagesDisappearAfterSometime = () => {
    delay(20000).then(() => {
      setSuccessMessage("");
      setApiErrorMessage("");
    });
  };
  const handleFileToUploadChange = (e) => {
    switch (e.target.name) {
      case "fileToUploadName":
        setFileToUpload({ ...fileToUpload, Filename: e.target.value });
        break;
      case "fileToUploadContents":
        const file = e.target.files[0];
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          setFileToUpload({ ...fileToUpload, Contents: fileReader.result.split(',')[1], OriginalFileExtension: file.name.split('.').pop() });
        };
        fileReader.readAsDataURL(file);
        break;
      default:
        break;
    }
  };

  const handleFileUploadSubmit = () => {
    if (fileToUpload.Filename.length && fileToUpload.Contents) {
      fileToUpload.Filename =
        (fileToUpload.Filename.lastIndexOf(fileToUpload.OriginalFileExtension) + fileToUpload.OriginalFileExtension.length === fileToUpload.Filename.length) ?
          fileToUpload.Filename
          :
          `${fileToUpload.Filename}.${fileToUpload.OriginalFileExtension}`;
      workspaceSettingsService
        .addFileToWorkspace(fileToUpload, sVHost)
        .then(() => {
          setSuccessMessage(
            "File added to workspace. You can now use the file as an attachment in your virtual service response."
          );
          updateData();
        })
        .catch((error) => {
          setApiErrorMessage(`File upload failed. ${error}`);
          updateData();
        });
    } else {
      setShowWarning(true);
    }
  };

  const handleFileDelete = (rowData) => {
    workspaceSettingsService
      .removeFileFromWorkspace(rowData.Filename, sVHost)
      .then(() => {
        setSuccessMessage(`Removed file ${rowData.Filename} from workspace`);
        updateData();
      })
      .catch((error) => {
        setApiErrorMessage(`File removal failed. ${error}`);
        updateData();
      });
  };

  const handleFileView = (rowData) => {
    workspaceSettingsService
      .getFileContents(rowData.Filename, sVHost)
      .then(({ data }) => {
        const link = document.createElement('a');
        const convertedFile = commonService.transformS3ResponseToBLOB(data)
        link.href = URL.createObjectURL(convertedFile);
        link.download = rowData.Filename;
        link.click();
      })
      .catch((error) => {
        setApiErrorMessage(`File download failed. ${error}`);
        updateData();
      });
  };

  const filesTableActions = [
    {
      icon: 'description',
      iconProps: {
        style: { color: palette.info },
      },
      tooltip: 'View',
      position: 'row',
      onClick: (event, rowData) => handleFileView(rowData),
    },
    {
      icon: "delete",
      iconProps: {
        style: { color: palette.danger },
      },
      tooltip: "Delete",
      position: "row",
      onClick: (event, rowData) => handleFileDelete(rowData),
    }
  ];

  const updateData = () => {
    setShowWarning(false);
    setLoading(true);
    workspaceSettingsService
      .getFilesInformation(sVHost)
      .then(({ data }) => {
        setFiles(data);
        setLoading(false);
        makeMessagesDisappearAfterSometime();
      })
      .catch((error) => {
        setLoading(false);
        setApiErrorMessage(`Error when updating data. ${error}`);
        makeMessagesDisappearAfterSometime();
      });
  };

  useEffect(() => {
    if (activeTab === "4") {
      updateData();
    }
  }, [activeTab]);

  return (
    <>
      <TabPane tabId="4" className="p-3">
        {loading ? (
          <Jumbotron className="text-center">
            <Spinner className="p-5" color="primary"></Spinner>
          </Jumbotron>
        ) : (
          <>
            <div className="m-0">
              <Row className="mt-2">
                <Col>
                  <Typography variant="subtitle1">
                    Upload files that can be used as attachments in virtual service responses.
                    This allows you to virtualise endpoints that need to respond with PDF and other type of files.
                  </Typography>
                </Col>
              </Row>
              <Divider className="m-2"></Divider>
              <Typography variant="h6">Add a file to workspace</Typography>
              <Row className="ml-0 mr-2 mb-2 mt-2">
                <Col xs="2" className="pl-0 d-flex align-items-center">
                  <Typography variant="subtitle1" className="m-0">
                    Name
                  </Typography>
                </Col>
                <Col xs="4" className="pl-2">
                  <Input
                    size="sm"
                    name="fileToUploadName"
                    onChange={handleFileToUploadChange}
                  />
                </Col>
              </Row>
              <Row className="ml-0 mr-2 mb-2 mt-2">
                <Col xs="2" className="pl-0 d-flex align-items-center">
                  <Typography variant="subtitle1" className="m-0">
                    Contents
                  </Typography>
                </Col>
                <Col xs="4" className="pl-2">
                  <Input
                    size="sm"
                    type="file"
                    name="fileToUploadContents"
                    onChange={handleFileToUploadChange}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs="auto">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => handleFileUploadSubmit()}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="text-center">
                  <Typography
                    variant="body2"
                    color="error"
                    hidden={!showWarning}
                  >
                    <span role="img" aria-label="warning emoji">
                      ⚠️
                    </span>{" "}
                    Provide non-empty values for file name and upload a non-empty file to
                    proceed.
                  </Typography>
                </Col>
              </Row>
            </div>
            <Divider className="mb-1 mt-0"></Divider>
            <Row className="mt-2 mb-1">
              <Col>
                <Typography
                  variant="body2"
                  className="text-success"
                  hidden={!successMessage}
                >
                  <span role="img" aria-label="check mark emoji">
                    ✔️
                  </span>{" "}
                  {successMessage}
                </Typography>
                <Typography
                  variant="body2"
                  color="error"
                  hidden={!apiErrorMessage}
                >
                  <span role="img" aria-label="warning emoji">
                    &#9888;&#65039;
                  </span>{" "}
                  {apiErrorMessage}
                </Typography>
              </Col>
            </Row>
            <MaterialTable
              columns={filesColumns}
              title={<Typography variant="h6">{`Files`}</Typography>}
              data={files}
              actions={filesTableActions}
              options={{
                headerStyle: {
                  fontWeight: "bold",
                  backgroundColor: palette.blueseq[3],
                },
                paging: files.length > 5,
                actionsColumnIndex: -1,
              }}
              localization={{
                header: {
                  actions: ''
                }
              }}
            />
          </>
        )}
      </TabPane>
    </>
  );
}
