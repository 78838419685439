import "./DashboardNew.css";
import React from "react";
import { Container, Row, Col } from "reactstrap";
import RequestAccessCard from "./Cards/RequestAccessCard";
import { faTools } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FolderStructure from "../FolderStructure/FolderStructure";
import { getToken, getUserRolesFromToken, getProfileDataFromToken } from '../../services/authentication';
import LibraryCard from "../Support/Library/LibraryCard";
import UsageReportCard from "../../components/UsageReport/UsageReportCard";
import TransactionMetricsCard from "../TransactionMetrics/TransactionMetricsCard";
import PasmatCard from "../PASMAT/PasmatCard";
import LatestActivity from "../LatestActivity/LatestActivity";


export const DashboardNew = (props) => {
  //Purpose : This is the dashboad of normal user from here user can see all valuechains
  let token = getToken();
  getUserRolesFromToken(token);
  getProfileDataFromToken(token);
  return (
    <Container>
      <>
        <LatestActivity />
        <div className="white mt-3">
          <FolderStructure></FolderStructure>
        </div>
        <div className="white mt-3">
          <h3>
            SV Utilities
            <FontAwesomeIcon className="ml-2" icon={faTools} size="1x" />
          </h3>
          <hr />
          <Row>
            <Col xs={3}>
              <LibraryCard />
            </Col>
            <Col xs={3}>
              <UsageReportCard />
            </Col>
            <Col xs={3}>
              <TransactionMetricsCard />
            </Col>
            <Col xs={3}>
              <PasmatCard />
            </Col>

          </Row>
          <Row className="mt-3">
            {
              <Col xs={3}>
                <RequestAccessCard />
              </Col>
            }
          </Row>
        </div>
      </>
    </Container>
  );
};

export default DashboardNew;
