import React, { useState, useEffect } from "react";
import { getUserDetails } from "../../services/authentication";
import Swal from "sweetalert2";
import _ from "lodash";
import { Card, CardContent, Typography } from "@material-ui/core";

import {
  Container,
  Button,
  Input,
  Row,
  Col,
  Spinner,
  Jumbotron,
} from "reactstrap";
import { AddToolTip } from "../common/custom/AddToolTip";
import { Verbs } from "../../services/verbiage";
import {
  deleteUserAPIToken,
  generateUserAPIToken,
  getUserAPITokens,
} from "../../services/user.service";
import errorResponses from "../ErrorHandling/errorResponses";
import { swalBootstrapButtonsDangerousQuestion } from "../utils/swal";
import { confluenceURLs } from "../../constants";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const storedUser = getUserDetails();
  const [userTokenInfo, setUserTokenInfo] = useState({
    description: "",
  });
  const handleGenerateTokenSubmitButtonClick = () => {
    if (!userTokenInfo.description) {
      Swal.fire({
        icon: "warning",
        text: "Fill all mandatory fields before proceeding.",
      });
    }
    else {
      setLoading(true);
      generateUserAPIToken(userTokenInfo.description)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            setUserTokenInfo(response.data);
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Could not generate api token. Error: ${error}`,
            footer: errorResponses.GENERIC_FOOTER_ERROR,
          });
        });
    }

  };

  const getUserTokens = () => {
    setLoading(true);
    getUserAPITokens(storedUser.id)
      .then((response) => {
        setLoading(false);
        if (response.data && response.data.length) {
          setUserTokenInfo(response.data[0]);
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Could not fetch token data. Error: ${error}`,
          footer: errorResponses.GENERIC_FOOTER_ERROR,
        });
      });
  };
  const handleDeleteTokenButtonClick = () => {
    swalBootstrapButtonsDangerousQuestion
      .fire({
        title: "Are you sure?",
        text: "Once deleted, you CANNOT revive the token",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          swalBootstrapButtonsDangerousQuestion
            .fire({
              title: "Acknowledge and confirm",
              text:
                "I understand that SV SSP external API's currently using this token will stop working after this deletion.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, delete",
              cancelButtonText: "Cancel",
              reverseButtons: true,
            })
            .then(async (result) => {
              if (result.value) {
                deleteUserAPIToken(userTokenInfo.id)
                  .then(() => {
                    setLoading(true);
                    setUserTokenInfo({
                      description: "",
                    });
                    getUserTokens();
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Error",
                      text: `Could not delete api token. Error: ${error}`,
                      footer: errorResponses.GENERIC_FOOTER_ERROR,
                    });
                  });
              }
            });
        }
      });
  };
  const handleUserTokenDescriptionChange = (e) => {
    let newUserTokenInfo = _.cloneDeep(userTokenInfo);
    newUserTokenInfo.description = e.target.value;
    setUserTokenInfo(newUserTokenInfo);
  };

  useEffect(() => {
    getUserTokens();
  }, []);

  return (
    <Container>
      {loading ? (
        <Jumbotron className="text-center">
          <Spinner className="p-5" color="primary"></Spinner>
        </Jumbotron>
      ) : (
          <div className="white mt-3">
            <h3>API Tokens</h3>
            <Card className="mt-2">
              <CardContent>
                {userTokenInfo.authToken ? null : (
                  <Container>
                    {/* <Typography variant="h3">API Tokens</Typography>
                    <Divider className="mt-2 mb-2" /> */}
                    <Typography variant="h4" className="mt-3">
                      Generate new token
                  </Typography>
                    <Row className="mt-3">
                      <Col xs={2} className="d-flex align-items-center">
                        <AddToolTip
                          placement="top-start"
                          title={Verbs.apiTokens.tokenDescription}
                        >
                          <Typography variant="subtitle1" className="asterisk">
                            Token description
                        </Typography>
                        </AddToolTip>
                      </Col>
                      <Col xs={5}>
                        <Input
                          placeholder="enter text"
                          bsSize="sm"
                          value={userTokenInfo.description}
                          onChange={(e) => handleUserTokenDescriptionChange(e)}
                        ></Input>
                      </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                      <Col>
                        <Button
                          color="primary"
                          onClick={handleGenerateTokenSubmitButtonClick}
                        >
                          Submit
                      </Button>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>Token generation process is briefly explained <a href={confluenceURLs.externalAPIsTokenDoc.link} target="_blank" rel="noopener noreferrer">{confluenceURLs.externalAPIsTokenDoc.displayText}</a>
                      </Col>
                    </Row>
                  </Container>
                )}
                {userTokenInfo.authToken ? (
                  <Container>
                    <Typography variant="h4">Existing token</Typography>
                    <Row className="mt-2">
                      <Col xs={2}>Token description</Col>
                      <Col>
                        <Typography variant="subtitle1">
                          {userTokenInfo.description}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col xs={2}>API token</Col>
                      <Col>
                        <Input
                          type="textarea"
                          rows="10"
                          value={userTokenInfo.authToken}
                          onChange={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col className="text-right">
                        <Button
                          color="danger"
                          onClick={handleDeleteTokenButtonClick}
                        >
                          Delete token
                      </Button>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        API Usage information can be found <a href={confluenceURLs.externalAPIsDoc.link} target="_blank" rel="noopener noreferrer">{confluenceURLs.externalAPIsDoc.displayText}</a>
                      </Col>
                    </Row>
                  </Container>
                ) : null}
              </CardContent>
            </Card>
          </div>
        )}
    </Container>
  );
};

export default Profile;
