import React from 'react';
import { Container, Row, Col, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import "./pasmat.css"

export const HeaderTitle = () => (
  <Row className="pasmat-borderBottom">
    <Col xs={10}>
      <CardTitle style={{ fontSize: 30, marginTop: 20 }}>
        Product Adoption Self-Maturity Assessment (PASMAT)
      </CardTitle>
    </Col>
    <Col xs={2}>
      <FontAwesomeIcon className="ml-2" icon={faHandshake} size="4x" />
    </Col>
  </Row>
)

export const pasmatBody = (children) => {
  return (
    <div className="mb-3">
      <SubHeading
        content="Collaborative framework to capture qualitative and quantitative product adoption metrics across software engineering teams."
      />
      {children}
    </div>
  )
}

export const SubHeading = ({ content }) => (
  <Row>
    <Col>
      <h5 className="pb-0 pt-2 pl-2 pm-2 font-weight-bold">{content}</h5>
    </Col>
  </Row>
)

export const PasmatMainWrapper = (props) => {
  return (
    <div>
      <div>
        <Container>
          <div className="white mt-3">
            <Container style={{
              border: '1px solid grey',
              borderRadius: '20px'
            }}>
              {HeaderTitle()}
              {pasmatBody(props.children)}
            </Container>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PasmatMainWrapper;