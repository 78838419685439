import { Clear } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import workspaceAccessService from "../../../services/workspaceAccessManagement.service";
import errorResponses from "../../ErrorHandling/errorResponses";
import { useHistory } from "react-router-dom";


const WorkspaceAccessApproval = () =>{
    
    const tableAction = [
        {
          icon: 'done',
          tooltip: 'Approved',
          onClick: (event, rowData) => {
            swalWithBootstrapButtons
              .fire({
                title: 'Approve?',
                text: 'Are you sure you want to approve the request?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, approve it!',
                confirmButtonColor: '#007bff',
                cancelButtonText: 'Cancel',
                cancelButtonColor: '#6c757d',
                reverseButtons: true,
              })
              .then((result) => {
                if (result.value) {
                    updateTeamAccessStatus(rowData , 'APPROVED' );
                }
              })
          },
        },
        {
          icon: () => <Clear />,
          tooltip: 'Reject',
          onClick: (event, rowData) => {
            swalWithBootstrapButtons
              .fire({
                title: 'Reject?',
                text: 'Are you sure you want to reject the request?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, reject it!',
                confirmButtonColor: '#007bff',
                cancelButtonText: 'Cancel',
                cancelButtonColor: '#6c757d',
                reverseButtons: true,
              })
              .then((result) => {
                if (result.value) {
                    updateTeamAccessStatus(rowData , 'REJECTED' );
                }
              });
          },
        },
      ];
    const [columns, setColums] = useState([
        { title: 'User Name', field: 'user.user_name', editable: 'never' },
        { title: 'User Email', field: 'user.email', editable: 'never' },
        { title: 'Team Name', field: 'team.team_name', editable: 'never' },
        // { title: 'Status', field: 'status' },
        { title: 'Created On', field: 'createdAt' , type: 'datetime' , defaultSort: 'desc'}
        ,
    ]);
    let history = useHistory();

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: true,
      });


    const [accessApprovalList , setAccessApprovalList] = useState([]);


    const updateTeamAccessStatus = (data , status) => {
      workspaceAccessService.updateWorkspaceAccessRequest({
          user_id : data.user_id,
          team_id : data.team_id,
          request_id : data.id,
          status 
      })
      .then(response => {
          if(response.data.code === 200){
              Swal.fire("Success", response.data.message, "success");
              getApprovalsList()
          }
      })
      .catch(error => {
          Swal.fire("Error", "No response due to internal error", "error");
          history.push({
            pathname: "errorPage",
            state: {
              errorMessage: errorResponses.GENERIC_ERROR
            }
          });
      });
    }

    const getApprovalsList = () => {
        workspaceAccessService.getWorkspaceApprovals()
        .then(response => {
            if(response.data.code === 200 && response?.data?.data?.length){
                setAccessApprovalList(response.data.data);
            }
            else setAccessApprovalList([]);
        })
        .catch(error => {
            Swal.fire("Error", "No response due to internal error", "error");
            history.push({
              pathname: "errorPage",
              state: {
                errorMessage: errorResponses.GENERIC_ERROR
              }
            });
        });
    }
     
    useEffect(()=>{
        getApprovalsList()
      },[]);


    return (
                <MaterialTable
              style={{ boxShadow: 'none', width: '100%', display: 'block' }}
              columns={columns}
              data={accessApprovalList}
              actions={tableAction}
              title={''}
              options={{
                headerStyle: {
                  fontWeight: 'bold',
                },
                actionsColumnIndex: -1,
              }}
            />
    )
}

export default WorkspaceAccessApproval;