import MaterialTable from 'material-table'
import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Label, Row, Col, Button, Container, Card, TabContent, TabPane, Nav,
        NavItem, NavLink } from 'reactstrap'
import { palette } from "../../../../src/assets/colors";
import classnames from 'classnames';
import Swal from 'sweetalert2';

import { getAllTeams, addOrUpdateTeam, getAllApplicationsAdmin, getAllPrograms } from "../../../services/support.service";
import TeamApplications from "./TeamApplications"

import {
  
    Collapse
 
  } from "@material-ui/core";

const columns = [
    { title: "Team Name", field: "team_name", width: "30%" },
    { title: "Team Code", field: "team_code", width: "30%" },
    {title:'Program Name', field:"program_name", width:"30%"}
];
// this means initial data
const dataConst = [
    {
        team_id: 1,
        team_name: 'Team 1',
        team_code: 'team1'
    },
    {
        team_id: 2,
        team_name: 'Team 2',
        team_code: 'team2'
    },

]

  const dataConstApplication = [
    {
        application_id: 1,
        application_name: 'My Telstra',
        description: 'My Telstra App Description',
        application_code: 'mytelstra'
    },
    {
        application_id: 2,
        application_name: 'My Telstra 2',
        description: 'My Telstra App Description',
        application_code: 'mytelstra2'
    },

]
export default function TeamEdit() {

    const [activeTab, setActiveTab] = useState('1');
    
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
      };
    const [selectedProgram, setSelectedProgram ] = useState({program_name:'test', program_id:1, program_code:"code"});
    
    const teamListTableRef = useRef(null);
    const [applicationList, setApplicationList] = useState(dataConstApplication);

    const [programList, setProgramList] = useState([{
        program_id:3,
        program_name:"O M P",
        program_code:"omp"
    }])
    const teamData = useRef(null);
    const [ showForm, setShowForm] = useState(false);
    const [revision, setRevision] = useState(false)
    const [teamList, setTeamList] = useState(dataConst);
    // a Team is new if it is not present in database
    const [isNewTeam, setIsNewTeam] = useState(false);

    // TeamData is used for handling form data
    const [selectedTeamData, setSelectedTeamData] = useState({
        team_name: '',
        team_code: ''
    })

    const scrollToRef = (ref) => {
        window.scrollTo({
          behavior: "smooth",
          top: ref.current.offsetTop,
        });
      };
    
    const actions = [

        {
            icon: "add_circle",
            iconProps: { style: { fontSize: "35px", color:  palette.primaryshade2} },
            tooltip: "Add Team",
            isFreeAction: true,

            onClick: () => {
                setSelectedProgram(programList[0])
                setSelectedTeamData({
                    team_name: '',
                    
                    team_code: '',
                    program_id: programList[0].program_id
                    
                })
                setTeamList([...teamList, {
                    team_name: 'Default Name',
                    
                    team_code: 'Default team code',
                    program_id:programList[0].program_id
                }])
                setIsNewTeam(true);
                setShowForm(true)
            },
        },
    ];


    const onTeamDataChange = (e) => {
       
        let objectValue = selectedTeamData;
        objectValue[e.target.name] = e.target.value;
        setSelectedTeamData(objectValue);
      

        setRevision(!revision)
       
    }
    const getData = async () => {
        try {
        const response = await getAllTeams();
        setTeamList(response.data.data);

        const responseApplication = await getAllApplicationsAdmin();

        setApplicationList(responseApplication.data.data)
        
        const responseProgram = await getAllPrograms();
        setProgramList(responseProgram.data.data)
        }
        catch(error){
            Swal.fire(`Something went wrong. Error ${error}`)
        }

    }

    const saveButton = async () => {
        try {
        await addOrUpdateTeam({team_name: selectedTeamData.team_name,
                    team_code: selectedTeamData.team_code, program_id:parseInt(selectedTeamData.program_id)});
        getData();
        Swal.fire("Team Added");
        }
        catch(error){
            Swal.fire(`Something went wrong. Error ${error}`);
        }

    }
    
    useEffect(() => {
        getData()
    }, []);
    return (<div>
        <div className='white mt-3'>

      <Nav tabs className='mt-3'>
        <NavItem hidden={false}>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
            }}
          >
            <h5>
              {' '}
              Teams{' '}
             
            </h5>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2');
            }}
          >
            <h5>
              Team Applications
             
            </h5>
          </NavLink>
        </NavItem>
        
      </Nav>
    </div>
    <TabContent activeTab={activeTab}>
    <TabPane tabId='1'>
    <div>
<Row>
            {/* <h1>Teams</h1> */}
        </Row>
        {/* <Row>
            {(showBanner) ? (
                <h3 style={{ "color": "red" }}> Please save current Program before Proceeding</h3>) : (<></>)}

        </Row> */}
  <div ref={teamListTableRef}>
            <Container className="mt-3 p-2 pl-0 pr-0">

        <Form mt-3 p-2>
            
            

                <MaterialTable
                    style={{ width: "100%" }}
                    columns={columns}
                    data={teamList}
                    actions={actions}
                    onRowClick={(evt, selectedRow) => {
                        
                        setShowForm(true);
            
                        setSelectedTeamData({team_name:selectedRow.team_name,
                                             team_code: selectedRow.team_code,
                                              program_id:selectedRow.program_id})
                        setSelectedProgram({program_id:selectedRow.program_id, program_name:selectedRow.program_name})
                        
                        // only row fetched from database will have program_id and the newly added program won't have it.
                        if (selectedRow.hasOwnProperty('team_id')){
                            setIsNewTeam(false)
                        }
                        else{
                            setIsNewTeam(true)
                        }
                        scrollToRef(teamData)


                    }}
                    options={{


                        headerStyle: {
                            fontWeight: "bold",
                            backgroundColor: palette.blueseq[3],
                        },
                        filtering: true,

                    }}
                    title="Teams"

                />
                
               
            
        </Form>
        </Container>
        </div>
        <br /><br />
        <Collapse in = {showForm}>

        <Form className= "float-left w-100 p-2" ref={teamData}>

            {/* {(showForm === false) ? (<></>) : ( */}
                <Card
            className="p-3"
            style={{ backgroundColor: palette.blueseq[0] }}
          >
                <Row>
                    <Col xs="3">
                        <Label for="program_name" className="mt-3 float-left">Program  Name</Label>
                    </Col>
                        <Col xs="4">
                            <Input
                                type="select"
                                name="program_id"
                                // key={Math.random()} 
                                value={selectedProgram['program_id']}
                                onChange={(e) => {
                                
                                    const progName = programList.filter((programItm) => programItm.program_id === parseInt(e.target.value));
                                    setSelectedProgram(progName[0])
                                    onTeamDataChange(e)



                                    

                                }
                                }
                            >
                                {
                                    programList.map((programItem, index) => {
                                        return <option key={index}
                                            value={parseInt(programItem.program_id)}
                                        >{programItem.program_name}</option>
                                    })
                                }

                            </Input>
                        </Col></Row>
                    <Row className="mt-3"><Col xs="3">
                        <Label for="team_name" className="mt-3 float-left">Team name</Label>
                    </Col>
                        <Col xs="4" ><Input type="text" name="team_name" id="team_name" placeholder="Enter Team name"
                           onChange={(e) => { onTeamDataChange(e) }} value={selectedTeamData.team_name} />
                        </Col>
                    
                    </Row>
                    
                    <Row className="mt-3">
                        <Col xs="3">
                          <Label for="team_code" className="mt-3 float-left">Team code</Label>
                        </Col>
                        <Col xs="4">
                          <Input type="text" name="team_code" id="team_code" placeholder="Enter team code"
                               onChange={(e) => { onTeamDataChange(e) }} value={selectedTeamData.team_code} disabled={(isNewTeam) ? false : true} />
                        </Col>
                    </Row>
                    <br />
                    <Row className="mt-3">
                      
                        <Col xs="5">
                            <Button
                               key={Math.random()} 
                                color="success"
                                className="mt-3 float-left btn-lg"
                                onClick={saveButton}>
                                Save
                           </Button>
                        </Col>
                    </Row>
            
                </Card>
                {/* } */}


        </Form>
    
        </Collapse>
        </div>
      </TabPane>
      <TabPane tabId='2'>
        <TeamApplications allTeams={teamList} allApps = {applicationList}/>
      </TabPane>
      
    </TabContent>
      

    </div>
       
    )
}
