import React, { memo, useEffect, useContext, useState } from 'react';
import { Input, Button, Jumbotron, Spinner, Row } from "reactstrap"
import { GlobalContext } from "../../../context/GlobalState";

import { Handle } from 'react-flow-renderer';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import _ from "lodash";
import { useHistory } from "react-router-dom";

import { nodeColors, palette } from "../../../assets/colors";
import CJVScenarioService from "../../../services/cJVScenarios.service"
import errorResponses from "../../ErrorHandling/errorResponses";

import MaterialTable from 'material-table';
const MySwal = withReactContent(Swal)

export default memo(({ data }) => {
    let history = useHistory();

    const {
        cjvElements, setCjvElements
    } = useContext(GlobalContext);
    const [showLoader, setShowLoader] = useState(false);


    const ConflictResolutionTable = () => {
        const [selectedRowData, setSelectedRowData] = useState({
            "stubId": 9999099,
            "stubName": "Stub2",
            "endpointPath": "/path1",
            "endpointMethod": "GET",
            "endpointId": "123",
            "endpointLabel": "label",
            "source": "2"
        })


        const columns = [
            {
                title: 'Checkbox',
                field: 'testColumn',
                width: '10%',

                render: rowData => <Input type="checkbox" className=" checkboxAlign d-flex flex-column  my-auto ml-3"

                    style={{

                    }} checked={selectedRowData.stubId === rowData.stubId &&
                        selectedRowData.stubName === rowData.stubName && selectedRowData.endpointMethod === rowData.endpointMethod
                        && selectedRowData.endpointPath === rowData.endpointPath} onChange={() => {

                            setSelectedRowData(rowData)


                        }
                        } />
            },
            {
                title: 'Stub name',
                field: 'stubName',
            },
            {
                title: 'Endpoint type',
                field: 'endpointMethod',
            },
            {
                title: 'Endpoint path',
                field: 'endpointPath',
            },
            {
                title: 'Endpoint label',
                field: 'endpointLabel',
            }


        ]
        return (
            <div>{showLoader === false ? (<div>
                <p >Multiple matching endpoints found, choose appropriate one to resolve the conflict.</p>
                <MaterialTable
                    columns={columns}
                    data={data.conflicts}
                    title="Endpoints"
                    options={{
                        headerStyle: {
                            fontWeight: "bold",
                            backgroundColor: palette.blueseq[3],
                        },
                        actionsColumnIndex: 1

                    }}
                >
                </MaterialTable>
                <div className="mt-3 float-right">
                    <Button color="danger" className="mr-2"
                        onClick={
                            () => {
                                MySwal.close();

                            }
                        }
                    >
                        Cancel
                </Button>
                    <Button color="primary" onClick={
                        async () => {
                            try {
                                let cjvElementsclone = _.cloneDeep(cjvElements)
                                setShowLoader(true);
                                let sourceId;
                                let selectedItemIndex;
                                let httpURLSelected;

                                for (let [index, item] of cjvElementsclone.uiDefinition.entries()) {
                                    // unique id is id that is being assigned in every data of diagram
                                    // it is a copy of id passed in every node
                                    // making sure we are entering for conflict one only
                                    if (item.data && data.uniqueId === item.id) {

                                        sourceId = item.data.source;
                                        httpURLSelected = item.data.httpURL;

                                        selectedItemIndex = index;
                                        item.data = selectedRowData;
                                        item.httpURL = item.data.httpURL
                                        if (item.data.httpMethod) {
                                            item.httpMethod = item.data.httpMethod
                                        }

                                        item['uniqueId'] = data.uniqueId
                                        item['type'] = 'cjvDefaultNode'

                                    }

                                }
                                let inputValue = {};
                                inputValue.stub_id = selectedRowData.stubId;
                                if (selectedRowData.endpointId) {
                                    inputValue.endpointId = selectedRowData.endpointId;
                                }
                                else {
                                    inputValue.endpointIndex = selectedRowData.endpointIndex;

                                }
                                inputValue.isParentNode = false
                                inputValue.sourceId = sourceId
                                let responseGenerate = await CJVScenarioService.generateCJV(inputValue)
                                let response = { cjvMapper: {}, uiDefinition: responseGenerate.data }
                                cjvElementsclone.uiDefinition.splice(selectedItemIndex, 1);
                                for (let item of response.uiDefinition) {

                                    cjvElementsclone.uiDefinition.push(item);
                                }
                                cjvElementsclone.uiDefinition.map((elementItem) => {
                                    if (sourceId === elementItem.data.source) {
                                        elementItem.data.httpURL = httpURLSelected

                                    }
                                    return 1
                                })

                                setShowLoader(false)
                                setCjvElements(cjvElementsclone)

                                MySwal.close()
                            }
                            catch (error) {
                                history.push({
                                    pathname: "errorPage",
                                    state: {
                                        errorMessage: `${errorResponses.GENERIC_ERROR}. ${error}`
                                    }
                                });
                            }
                        }
                    }>
                        Select endpoint
                </Button>
                </div>
            </div>) : <div>
                <Jumbotron className="text-center">
                    <Spinner className="p-5" color="primary"></Spinner>
                </Jumbotron>
            </div>}
            </div>
        )
    }
    const resolveConflict = async () => {
        MySwal.fire({
            icon: "warning",
            width: 1000,
            allowOutsideClick: false,
            title: 'Resolve endpoint conflict',
            text: 'Please select one endpoint',
            html: <ConflictResolutionTable />,
            showCancelButton: false,
            showConfirmButton: false,
            showLoaderOnConfirm: true,


        })
    }
   
    return (
        <div className="hoverClass1 " onClick={() => {
            resolveConflict()
        }} >
            <Handle
                type="target"
                position="left"
                style={{ background: '#555' }}
            />
            <Handle
                type="source"
                position="right"
                id="a"
            />
            < div
                style={{
                    display: 'flexbox', flexDirection: 'column', border: '1px dashed #777', width: '10vw', height: '16vh', backgroundColor: nodeColors.conflictNode, padding: '1px',
                    borderRadius: '4px'

                }}
            >
                <ReportProblemIcon
                    onClick={() => {
                        resolveConflict()
                    }} className="hoverClass1 float-right d-flex flex-row"
                    style={{ marginLeft: '5px', color: palette.yellow_secondary, fontColor: 'black' }}
                />

                {(data.conflicts && data.conflicts.length > 1) ?
                    data.conflicts.map((stubItem, index) =>
                        index <= 1 ? (<div
                            className="cjvConflictChild"

                            style={{
                                marginTop: index === 1 ? '2vh' : '3vh',
                                border: '1px solid #777', width: '7vw', height: '4vh', backgroundColor: nodeColors.conflictingEndpoints,

                            }}
                        >




                            <Row style={{
                                fontSize: '7px'
                            }}>
                                {stubItem.endpointLabel || ""}
                            </Row>
                            <Row style={{
                                fontSize: '7px'
                            }}>
                                {stubItem.endpointMethod || ""}{" "} {`${stubItem.endpointPath || ""}` || `${stubItem.httpURL}`}
                            </Row>
                            <Row style={{
                                fontSize: '7px'
                            }}>
                                {stubItem.stubName}
                            </Row>

                        </div>) : null
                    ) : <></>}




            </div>

        </div>
    );
})