import React, { useState } from "react";
import { Row, Col, Input, FormFeedback } from "reactstrap";
import {
  InputLabel,
  IconButton,
  Collapse,
  Typography,
} from "@material-ui/core";
import {
  AddCircle,
  RemoveCircle,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { FaExclamationCircle } from "react-icons/lib/fa";
import Switch from "react-switch";

import { http_methods } from "../constants";
import { AddToolTip } from "../../common/custom/AddToolTip";
import { Verbs } from "../../../services/verbiage";
import { palette } from "../../../assets/colors";
import {
  hasExceededQueryRows,
  hasExceededResponseHeaderRows,
  MAX_BODY_SIZE,
} from "../../utils/stubValidators";

const grant_types = [
  { label: 'Personal', value: 'personal-authorization-token' },
  { label: 'Robo', value: 'robo-authorization-token' },
]

export default function TDMHttpForward({
  actionData,
  index,
  handleFEPHTTPResponseActionsUpdate,
  isEditable,
  mASSLCertAliases,
  isTemplate,
  isVirtualServiceSubmitted
}) {
  const [showMASSLPassword, setShowMASSLPassword] = useState(false);
  const handleShowMASSLPasswordToggle = () => {
    setShowMASSLPassword(!showMASSLPassword);
  };

  const isMethodTemplateInvalid = (httpMethod) => {
    if (isTemplate && isVirtualServiceSubmitted) {
      if (httpMethod) {
        return !(
          http_methods.includes(httpMethod) ||
          (httpMethod.startsWith('{{') && httpMethod.endsWith('}}'))
        )
      } else return false;
    }
    else return false;
  }



  return (
    <>
      <Row className="mt-2 d-flex align-items-center">
        <Col xs="auto">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.httpForward.method}
          >
            <InputLabel className="p-0 asterisk m-0">Method</InputLabel>
          </AddToolTip>
        </Col>
        <Col xs={6}>
          <Input
            invalid={isMethodTemplateInvalid(actionData.httpMethod)}
            type={isTemplate ? "text" : "select"}
            name="httpMethod"
            bsSize="sm"
            value={actionData.httpMethod}
            onChange={(e) => handleFEPHTTPResponseActionsUpdate(e, index)}
          >
            {http_methods.map((item) => {
              return <option key={item}>{item}</option>;
            })}
          </Input>
          <FormFeedback
            invalid>
            {Verbs.validationMessages.methodTemplateInvalid}
          </FormFeedback>
        </Col>
        <Col xs="auto" className="m-0 border-left border-dark">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.httpForward.mASSL}
          >
            <InputLabel className="p-0 m-0">MASSL</InputLabel>
          </AddToolTip>
        </Col>
        <Col className="text-right p-0" xs="auto">
          <Switch
            className="d-flex align-items-center"
            onChange={(checked) =>
              handleFEPHTTPResponseActionsUpdate(
                { target: { name: "mASSLSwitch", value: checked } },
                index
              )
            }
            checked={actionData.massl}
            height={20}
            width={40}
            disabled={!isEditable}
            onColor={palette.primary}
          />
        </Col>

      </Row>
      <Collapse in={actionData.massl} timeout="auto">
        {mASSLCertAliases.length ? (
          <>
            <Row className="mt-2 d-flex align-items-center">
              <Col xs="auto">
                <AddToolTip
                  placement="top-start"
                  title={Verbs.title.responseActions.httpForward.mASSLCertAlias}
                >
                  <InputLabel className="p-0 m-0">Certificate alias</InputLabel>
                </AddToolTip>
              </Col>
              <Col xs="auto">
                <Input
                  type="select"
                  name="mASSLCertAlias"
                  bsSize="sm"
                  value={actionData.masslAlias}
                  onChange={(e) => handleFEPHTTPResponseActionsUpdate(e, index)}
                >
                  {mASSLCertAliases.map((item) => {
                    return (
                      <option key={item.alias} value={item.alias}>
                        {item.aliasLabel}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </Row>
            <Row className="mt-2 d-flex align-items-center">
              <Col xs="auto">
                <AddToolTip
                  placement="top-start"
                  title={Verbs.title.responseActions.httpForward.masslPassword}
                >
                  <InputLabel className="p-0 m-0">MASSL password</InputLabel>
                </AddToolTip>
              </Col>
              <Col xs="auto">
                <Input
                  type={showMASSLPassword ? "text" : "password"}
                  name="masslPassword"
                  bsSize="sm"
                  value={actionData.masslPassword}
                  onChange={(e) => handleFEPHTTPResponseActionsUpdate(e, index)}
                />
              </Col>
              <Col xs="auto" className="p-0">
                {
                  showMASSLPassword ?
                    <IconButton
                      aria-label="show massl password"
                      component="span"
                      className="p-0 pt-1"
                      onClick={handleShowMASSLPasswordToggle}
                    >
                      <Visibility></Visibility>
                    </IconButton>
                    :
                    <IconButton
                      aria-label="hide massl password"
                      component="span"
                      className="p-0 pt-1"
                      onClick={handleShowMASSLPasswordToggle}
                    >
                      <VisibilityOff></VisibilityOff>
                    </IconButton>
                }
              </Col>
            </Row>
          </>
        ) : (
          <Row className="mt-2 d-flex align-items-center">
            <Col>
              <Typography variant="subtitle1" color="error">
                <span role="img" aria-label="warning emoji">
                  &#9888;&#65039;
                </span>{" "}
                You have to upload the certificate and key file in settings
                before using this feature
              </Typography>
            </Col>
          </Row>
        )}
      </Collapse>
      <Row className="mt-2">
        <Col xs="2" className="d-flex align-items-center">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.httpForward.url}
          >
            <InputLabel className="p-0 asterisk">URL</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            // invalid={isPathInvalid(fEPRequestPath[0].value)}
            placeholder="enter url"
            bsSize="sm"
            name="httpUrl"
            value={actionData.httpUrl}
            onChange={(e) => handleFEPHTTPResponseActionsUpdate(e, index)}
          ></Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.pathInvalid}
          </FormFeedback>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.tdmHttpForward.basicToken}>
              <InputLabel className="p-0 asterisk">Basic Token</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input            
            placeholder="taas basic token"
            bsSize="sm"
            name="taasBasicToken"
            value={actionData.taasBasicToken}
            onChange={(e) => handleFEPHTTPResponseActionsUpdate(e, index)}
          ></Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.pathInvalid}
          </FormFeedback>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.tdmHttpForward.grantType}>
            <InputLabel className="p-0 asterisk">Grant Type</InputLabel>
          </AddToolTip>
        </Col>
        <Col>        
          <Input
            type="select"
            bsSize="sm"
            name="grantType"
            value={(actionData.grantType) ? actionData.grantType : grant_types[0].value}
            onChange={(e) => handleFEPHTTPResponseActionsUpdate(e, index)}>
            {grant_types.map(({ label, value }) => {
              return <option key={value} value={value}>{label}</option>;
            })}
          </Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.grantTypeInvalid}
          </FormFeedback>
        </Col>
      </Row>
      { actionData.grantType === 'robo-authorization-token' ? (
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.tdmHttpForward.secretKey}>
              <InputLabel className="p-0 asterisk">Secret Token</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input            
            placeholder="taas secret key"
            bsSize="sm"
            name="taasSecretKey"
            value={actionData.taasSecretKey}
            onChange={(e) => handleFEPHTTPResponseActionsUpdate(e, index)}
          ></Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.taasSecretKeyInvalid}
          </FormFeedback>
        </Col>
      </Row>
      ) : (
        <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.tdmHttpForward.accessToken}>
              <InputLabel className="p-0 asterisk">Access Token</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input            
            placeholder="taas access token"
            bsSize="sm"
            name="taasAccessToken"
            value={actionData.taasAccessToken}
            onChange={(e) => handleFEPHTTPResponseActionsUpdate(e, index)}
          ></Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.taasAccessTokenInvalid}
          </FormFeedback>
        </Col>
      </Row>
      )}


      {/* Query parameters */}
      <Row className="mt-3">
        <Col xs="auto">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.httpForward.queryParams}
          >
            <InputLabel>Query parameters</InputLabel>
          </AddToolTip>
        </Col>
      </Row>
      {
        <>
          {actionData.params.map((item, hindex) => {
            return (
              <Row className="mt-2" key={hindex}>
                <Col xs={5}>
                  <Input
                    placeholder="Key"
                    bsSize="sm"
                    value={item.querykey}
                    name="queryKey"
                    onChange={(e) =>
                      handleFEPHTTPResponseActionsUpdate(e, index, hindex)
                    }
                  ></Input>
                </Col>
                <Col xs={5}>
                  <Input
                    placeholder="Value"
                    bsSize="sm"
                    name="queryValue"
                    value={item.value}
                    onChange={(e) =>
                      handleFEPHTTPResponseActionsUpdate(e, index, hindex)
                    }
                  ></Input>
                </Col>
                {actionData.params.length === 1 ? null : (
                  <Col xs="auto" className="pl-1 pr-1">
                    <IconButton
                      aria-label="remove query"
                      component="span"
                      className="p-0 pt-1"
                      onClick={() =>
                        handleFEPHTTPResponseActionsUpdate(
                          { target: { name: "removeQuery" } },
                          index,
                          hindex
                        )
                      }
                      hidden={!isEditable}
                    >
                      <RemoveCircle
                        fontSize="small"
                        style={{ color: palette.danger }}
                      />
                    </IconButton>
                  </Col>
                )}
                {hindex === actionData.params.length - 1 ? (
                  <Col xs="auto" className="pl-1 pr-1">
                    <IconButton
                      aria-label="add query"
                      component="span"
                      className="p-0 pt-1"
                      onClick={() =>
                        handleFEPHTTPResponseActionsUpdate(
                          { target: { name: "addQuery" } },
                          index,
                          hindex
                        )
                      }
                      hidden={!isEditable}
                    >
                      <AddCircle fontSize="small" color="secondary" />
                    </IconButton>
                  </Col>
                ) : null}
              </Row>
            );
          })}
          {hasExceededQueryRows(actionData.params.length) && (
            <Row className="text-center mt-3">
              <Col className="text-center">
                <span className="text-danger" style={{ fontSize: 15 }}>
                  <FaExclamationCircle
                    className="mr-2"
                    size="1.1rem"
                    style={{ marginBottom: "0.1rem" }}
                  />
                  {Verbs.validationMessages.queryListLengthInvalid}
                </span>
              </Col>
            </Row>
          )}
        </>
      }
      {/* Headers */}
      <Row className="mt-3">
        <Col xs="auto">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.httpForward.headers}
          >
            <InputLabel>Headers</InputLabel>
          </AddToolTip>
        </Col>
      </Row>
      {
        <>
          {actionData.headers.map((item, hindex) => {
            return (
              <Row className="mt-2" key={hindex}>
                <Col xs={5}>
                  <Input
                    placeholder="Key"
                    bsSize="sm"
                    value={item.headerkey}
                    name="headerKey"
                    onChange={(e) =>
                      handleFEPHTTPResponseActionsUpdate(e, index, hindex)
                    }
                  ></Input>
                </Col>
                <Col xs={5}>
                  <Input
                    placeholder="Value"
                    bsSize="sm"
                    name="headerValue"
                    value={item.value ? item.value : ""}
                    onChange={(e) =>
                      handleFEPHTTPResponseActionsUpdate(e, index, hindex)
                    }
                  ></Input>
                </Col>
                {actionData.headers.length === 1 ? null : (
                  <Col xs="auto" className="pl-1 pr-1">
                    <IconButton
                      aria-label="remove header"
                      component="span"
                      className="p-0 pt-1"
                      onClick={() =>
                        handleFEPHTTPResponseActionsUpdate(
                          { target: { name: "removeHeader" } },
                          index,
                          hindex
                        )
                      }
                      hidden={!isEditable}
                      name="removeHeader"
                    >
                      <RemoveCircle
                        fontSize="small"
                        style={{ color: palette.danger }}
                      />
                    </IconButton>
                  </Col>
                )}
                {hindex === actionData.headers.length - 1 ? (
                  <Col xs="auto" className="pl-1 pr-1">
                    <IconButton
                      aria-label="add header"
                      component="span"
                      className="p-0 pt-1"
                      onClick={() =>
                        handleFEPHTTPResponseActionsUpdate(
                          { target: { name: "addHeader" } },
                          index,
                          hindex
                        )
                      }
                      hidden={!isEditable}
                      name="addHeader"
                    >
                      <AddCircle fontSize="small" color="secondary" />
                    </IconButton>
                  </Col>
                ) : null}
              </Row>
            );
          })}
          {hasExceededResponseHeaderRows(actionData.headers.length) && (
            <Row className="text-center mt-3">
              <Col className="text-center">
                <span className="text-danger" style={{ fontSize: 15 }}>
                  <FaExclamationCircle
                    className="mr-2"
                    size="1.1rem"
                    style={{ marginBottom: "0.1rem" }}
                  />
                  {Verbs.validationMessages.responseHeaderListLengthInvalid}
                </span>
              </Col>
            </Row>
          )}
        </>
      }
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.httpForward.body}
          >
            <InputLabel className="p-0">Body</InputLabel>
          </AddToolTip>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Input
            // invalid={isPathInvalid(fEPRequestPath[0].value)}
            type="textarea"
            bsSize="sm"
            maxLength={MAX_BODY_SIZE}
            rows="4"
            name="body"
            value={actionData.body}
            onChange={(e) => handleFEPHTTPResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>
    </>
  );
}
