
/*
  helper functions and constants for stub validators
  these values can be changed in the future
*/

const { http_methods } = require("../VirtualService/constants");

const MAX_QUERY_ROW_SIZE = 100;
const MAX_REQUEST_HEADER_SIZE = 100;
const MAX_RESPONSE_HEADER_SIZE = 100;
const MAX_RESPONSE_ACTION_SIZE = 30;
const MAX_PATH_SIZE = 2000;
const MAX_BODY_SIZE = 1500000;
const MAX_BODY_RESPONSE_SIZE = 1500000;

const isPathInvalid = (path) => {
    let flag = false;
    if (path.length > MAX_PATH_SIZE) {
        // if path length is greater than max size
        flag = true;
    }
    if (!(path.startsWith("/"))) {
        // if path doesn't start with /
        flag = true;
    }
    return flag;
};

const isMethodTemplateValid = (httpMethod) => {
    return (
        http_methods.includes(httpMethod) ||
        (httpMethod.startsWith('{{') && httpMethod.endsWith('}}'))
    )
}

const isResponseStatusInvalid = (status) => {
    return ((status > 599) || (status < 100));
};

const checkIsPathInvalid = (path) => {
    let flag = false;
    if (path.length > MAX_PATH_SIZE) {
        flag = true;
    }
    if (path.length === 0) {
        flag = true
    }
    return flag;
}
const isJMSPathInvalid = (path) => {
   return checkIsPathInvalid(path);
};

const isTibcoPathInvalid = (path) => {
    return checkIsPathInvalid(path);
};

const isCometDPathInvalid = (path) => {
    return checkIsPathInvalid(path);
};


const isKinesisNameInvalid = (path) => {
    let flag = false;
    if (path.length > MAX_PATH_SIZE) {
        flag = true;
    }
    return flag;
};


const hasExceededQueryRows = (queryRowsLength) => {
    return queryRowsLength >= MAX_QUERY_ROW_SIZE ? true : false;
};

const hasExceededRequestHeaderRows = (headersLength) => {
    return headersLength >= MAX_REQUEST_HEADER_SIZE ? true : false;
};

const hasExceededResponseHeaderRows = (headersLength) => {
    return headersLength >= MAX_RESPONSE_HEADER_SIZE ? true : false;
};

const hasExceededResponseActionRows = (actionsLength) => {
    return actionsLength >= MAX_RESPONSE_ACTION_SIZE ? true : false;
};

const MAX_ACTION_DELAY_SECONDS = 3600;

module.exports = {
    hasExceededQueryRows, isPathInvalid, isJMSPathInvalid, isKinesisNameInvalid,
    hasExceededRequestHeaderRows, hasExceededResponseHeaderRows, hasExceededResponseActionRows, MAX_BODY_SIZE,
    isResponseStatusInvalid,
    isTibcoPathInvalid,
    isCometDPathInvalid,
    MAX_ACTION_DELAY_SECONDS,
    MAX_BODY_RESPONSE_SIZE,
    isMethodTemplateValid
};
