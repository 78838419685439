import React, { useState, useEffect } from 'react';

import { Row, Col, Button, Container, Label } from 'reactstrap';
import { DialogActions } from '@material-ui/core';
import MaterialTable from 'material-table';
import Select from 'react-select';
import useDebounce from './useDebounceFunc';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import accessManageService from '../../../services/accessManagement.service';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import errorResponses from "../../ErrorHandling/errorResponses";

export const ManageUsers = () => {
  let history = useHistory();

  const [userSearchKey, setUserSearchKey] = useState('');
  const debouncedSearchTerm = useDebounce(userSearchKey, 200);
  const [ranOnce, setRanOnce] = useState(false);
  const [renderAgain, setRenderAgain] = useState(false);
  const [filteredUserResults, setFilteredUserResults] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [isFtlOrAdmin, setIsFtlOrAdmin] = useState(false);
  const [columns, setColums] = useState([
    { title: 'Name', field: 'user_name', editable: 'never' },
    { title: 'Email', field: 'email', editable: 'never' },
    { title: 'Team Name', field: 'team_name', editable: 'never' },
    {
      title: 'Role',
      field: 'role_id',
      lookup: { 1: 'GENERAL', 2: 'FTL', 4: 'DELEGATE' },
    },
  ]);
  const [teamNames, setTeamNames] = useState([
    {
      team_id: '',
      team_name: '',
    },
  ]);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [myRole, setMyRole] = useState();
  const [teamSelection, setTeamSelection] = useState({});

  const [data, setData] = useState([
    { user_name: '', email: '', team_name: '', role_id: 2 },
    { user_name: '', email: '', team_name: '', role_id: 3 },
  ]);

  const fetchUserList = async (teamData) => {
    if (teamData.data.data.length >= 1) {
      let userList;
      if (renderAgain === false) {
        userList = await accessManageService.getUserList(
          teamData.data.data[0].team_id
        );
      }
      else {
        userList = await accessManageService.getUserList(
          teamSelection.team_id
        );
      }
      setData(userList.data.data);
    }
  }

  const fetchAvailableRoles = async () => {
    var lookup_var = await accessManageService.getAvailableRoles();
    setColums([
      { title: 'Name', field: 'user_name', editable: 'never' },
      { title: 'Email', field: 'email', editable: 'never' },
      { title: 'Team Name', field: 'team_name', editable: 'never' },
      {
        title: 'Role',
        field: 'role_id',
        lookup: lookup_var.data.roles,
      },
    ]);
    setUserSearchKey('');
  }

  const getData = async () => {
    try {
      let role = await accessManageService.isFtlOrAdmin();
      setIsFtlOrAdmin(role.data.data.isFtlOrAdmin);
      setMyRole(role.data.data.role);
      let teamData = await accessManageService.getTeamNames();
      setTeamNames(teamData.data.data);
      if (renderAgain === false) {
        setTeamSelection(teamData.data.data[0]);
      }
      fetchUserList(teamData);
      fetchAvailableRoles();
    }
    catch (error) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        },
      });
    }
  };

  useEffect(() => {
    if (ranOnce === false || renderAgain === true) {
      getData();
      setRanOnce(true);
      setRenderAgain(false)
    }
    if (debouncedSearchTerm) {
      accessManageService.searchUser(debouncedSearchTerm).then((results) => {
        // Filter out results with no thumbnail
        const filteredUserList = [];
        results.data.result.map(value => {
          if (!data.some(item => item.email === value.email)) {
            filteredUserList.push({ label: value.email, value: value });
          }
        })
        setFilteredUserResults(filteredUserList);
      }).catch((error) => {
        showErrorMessage();
      });
    } else {
      setFilteredUserResults([]);
    }
  }, [debouncedSearchTerm, renderAgain]);

  const addUserDialogue = () => {
    setOpenDialogue(true);
  };

  const showErrorMessage = () => {
    history.push({
      pathname: 'errorPage',
      state: {
        errorMessage: errorResponses.GENERIC_ERROR
      },
    });
  }

  const isEmailSelected = () => {
    if (selectedEmail?.length) {
      return false;
    }
    return true;
  }

  return (
    <Container>
      {isFtlOrAdmin ? (
        <div className='white mt-3'>
          <Row
            style={{
              marginTop: '4vh',
              zIndex: 1000,
            }}
          >
            <Col xs='4'>
              <Select
                options={teamNames}
                style={{
                  width: '20vw',
                }}

                onChange={async (e) => {
                  try {
                    setTeamSelection(e);

                    let userList = await accessManageService.getUserList(
                      e.team_id
                    );
                    setData(userList.data.data);
                  }
                  catch (error) {
                    showErrorMessage();
                  }
                }}
                value={teamSelection}
                placeholder='Select Team'
              />
            </Col>

            <Col xs='8'>
              <Button
                style={{ marginRight: '1vw' }}
                color="primary"
                className={classNames(
                  'btn',
                  'btn-md',
                  'ld-ext-right',
                  'float-right'
                )}
                onClick={addUserDialogue}
              >
                Add More Users
              </Button>
              <Dialog
                onClose={() => setOpenDialogue(false)}
                open={openDialogue}
                fullWidth={true}
                maxWidth={'sm'}
              >
                <DialogContent>
                  <h4>Add New User</h4>
                  <hr></hr>
                  <Row style={{ height: '120px' }}>
                    <Col xs='4'>
                      <Label className="mt-1">
                        <b>Enter Email:</b>
                      </Label>
                    </Col>
                    <Col xs='8'>
                      <Select
                        required
                        placeholder="Enter User Email"
                        name="useremail"
                        data-testid="useremail"
                        maxMenuHeight={90}
                        options={filteredUserResults}
                        onInputChange={(value) => { setUserSearchKey(value); }}
                        onChange={(selectedUser) => {
                          setSelectedEmail(selectedUser.value.email);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginTop: '2vh',
                      zIndex: 1000,
                    }}
                  >
                  </Row>

                </DialogContent>
                <DialogActions>
                  <Button
                    variant='contained'
                    onClick={() => setOpenDialogue(false)}
                    color='secondary'
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={isEmailSelected()}
                    name='add'
                    onClick={() => {
                      setOpenDialogue(false)
                      accessManageService.addUserToTeam(selectedEmail, teamSelection.team_id, myRole).then(
                        setRenderAgain(true)
                      ).catch((e) => {
                        showErrorMessage();
                      })
                    }
                    }
                  >
                    Add User
                  </Button>
                </DialogActions>
              </Dialog>
            </Col>
          </Row>
          <MaterialTable
            title=''
            columns={columns}
            data={data}
            style={{ marginTop: '2vh' }}
            localization={{ body: { editRow: { deleteText: 'Remove User from this Team' } } }}

            editable={{
              // only that row where user to be edited  is not admin, will be editable
              isEditable: (rowData) =>
                rowData.role_name !== 'ADMIN' || myRole === 'ADMIN',
              onRowUpdate: (newData, oldData) =>
                new Promise(async (resolve) => {
                  try {
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    await accessManageService.changeRole(
                      oldData.user_id,
                      oldData.role_id,
                      newData.role_id,
                      oldData.team_id
                    );

                    resolve();
                  }
                  catch (error) {
                    showErrorMessage();
                  }
                }),
              onRowDelete: (oldData) =>
                new Promise(async (resolve) => {
                  try {
                    const dataDelete = [...data];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setData([...dataDelete]);
                    await accessManageService.deleteUserTeam(
                      oldData.user_id,
                      oldData.team_id
                    );
                    resolve();
                  }
                  catch (error) {
                    showErrorMessage();
                  }
                }),
            }}

            options={{
              headerStyle: {
                fontWeight: 'bold',
              },
              actionsColumnIndex: -1,
            }}

          />
        </div>
      ) : (
        <div className='white mt-3'></div>
      )}
    </Container>
  );
};
export default ManageUsers;
