import MaterialTable from 'material-table'
import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Label, Row, Col, Button, Container, Card } from 'reactstrap'
import { palette } from "../../../../src/assets/colors";
import Swal from 'sweetalert2';

import { getAllPrograms, addOrUpdateProgram } from "../../../services/support.service";
import {

    Collapse

} from "@material-ui/core";

const columns = [
    { title: "Program Name", field: "program_name", width: "10%" },
    { title: "Program Description", field: "description", width: "10%" },
    { title: "Program Code", field: "program_code", width: "80%" },
];
// this means initial data
const dataConst = [
    {
        program_id: 1,
        program_name: 'B2B Digitisation',
        description: 'b2b Digitisation',
        program_code: 'b2b'
    },
    {
        program_id: 2,
        program_name: 'B2C Digitisation',
        description: 'b2c Digitisation',
        program_code: 'b2c'
    },

]

export default function ProgramEdit() {
    const programListTableRef = useRef(null);
    const progData = useRef(null);
    const [showForm, setShowForm] = useState(false);
    // defines the current selected index of the materialTable
    const [revision, setRevision] = useState(false)
    // data is used to display the material table at the top
    const [programList, setProgramList] = useState(dataConst);
    // a Program is new if it is not present in database
    const [isNewProgram, setIsNewProgram] = useState(false);

    // programData is used for handling form data
    const [selectedProgramData, setSelectedProgramData] = useState({
        program_name: '', description: 'set Description',
        program_code: ''
    })

    const scrollToRef = (ref) => {
        window.scrollTo({
            behavior: "smooth",
            top: ref.current.offsetTop,
        });
    };
    const actions = [

        {
            icon: "add_circle",
            iconProps: { style: { fontSize: "35px", color: palette.primaryshade2 } },
            tooltip: "Add endpoint",
            isFreeAction: true,

            onClick: () => {
                setSelectedProgramData({
                    program_name: '',
                    description: '',
                    program_code: '',

                })
                setProgramList([...programList, {
                    program_name: '',
                    description: '',
                    program_code: ''
                }])
                setIsNewProgram(true);
                setShowForm(true)
            },
        },
    ];



    const onProgramDataChange = (e) => {
        try {
            let objectValue = selectedProgramData;
            objectValue[e.target.name] = e.target.value;
            setSelectedProgramData(objectValue);


            setRevision(!revision)
        }
        catch (error) {
            Swal.fire("Something went wrong")
        }
    }
    const getData = async () => {
        try {
            const response = await getAllPrograms();
            setProgramList(response.data.data)
        }
        catch (error) {
            Swal.fire("Something went wrong")
        }

    }
    const saveButton = async () => {
        try {
            await addOrUpdateProgram({
                program_name: selectedProgramData.program_name, description: selectedProgramData.description,
                program_code: selectedProgramData.program_code
            });
            // by calling following function, it is made sure that the data is fetched from the database.
            getData();
            Swal.fire("Program Added")
        }
        catch (error) {
            Swal.fire(`Something went wrong. Error ${error}`)
        }

    }
    useEffect(() => {
        getData()
    }, []);
    return (
        <div>
            <Row>
                <h1>Programs</h1>
            </Row>
            {/* <Row>
                {(showBanner) ? (
                    <h3 style={{ "color": "red" }}> Please save current Program before Proceeding</h3>) : (<></>)}

            </Row> */}
            <div ref={programListTableRef}>
                <Container className="mt-3 p-2 pl-0 pr-0">

                    <Form mt-3 p-2>



                        <MaterialTable
                            style={{ width: "100%" }}
                            columns={columns}
                            data={programList}
                            actions={actions}
                            onRowClick={(evt, selectedRow) => {

                                setShowForm(true);
                                setSelectedProgramData({
                                    program_name: selectedRow.program_name, description: selectedRow.description,
                                    program_code: selectedRow.program_code
                                })

                                // only row fetched from database will have program_id and the newly added program won't have it.
                                if (selectedRow.hasOwnProperty('program_id')) {
                                    setIsNewProgram(false)
                                }
                                else {
                                    setIsNewProgram(true)
                                }
                                scrollToRef(progData)


                            }}
                            options={{


                                headerStyle: {
                                    fontWeight: "bold",
                                    backgroundColor: palette.blueseq[3],
                                },
                            }}
                            title="Programs"

                        />



                    </Form>
                </Container>
            </div>
            <br /><br />
            <Collapse in={showForm}>

                <Form className="float-left w-100 p-2" ref={progData}>

                    <Card
                        className="p-3"
                        style={{ backgroundColor: palette.blueseq[0] }}
                    >

                        <Row ><Col xs="3">
                            <Label for="program_name" className="mt-3 float-left">Program name</Label>
                        </Col>
                            <Col xs="4" ><Input type="text" name="program_name" id="program_name" placeholder="Enter program name"
                                onChange={(e) => { onProgramDataChange(e) }} value={selectedProgramData.program_name} />
                            </Col>

                        </Row>
                        <Row className="mt-3">
                            <Col xs="3">
                                <Label for="program_name" className="mt-3 float-left">Program description</Label>
                            </Col>
                            <Col xs="4"><Input type="text" name="description" id="description" placeholder="Enter description"
                                onChange={(e) => { onProgramDataChange(e) }} value={selectedProgramData.description} /></Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="3">
                                <Label for="program_code" className="mt-3 float-left">Program code</Label>
                            </Col>
                            <Col xs="4">
                                <Input type="text" name="program_code" id="program_code" placeholder="Enter program code"
                                    onChange={(e) => { onProgramDataChange(e) }} value={selectedProgramData.program_code} disabled={(isNewProgram) ? false : true} />
                            </Col>
                        </Row>
                        <br />
                        <Row className="mt-3">

                            <Col xs="5">
                                <Button

                                    color="success"
                                    className="mt-3 float-left btn-lg"
                                    onClick={saveButton}>
                                    Save
                               </Button>
                            </Col>
                        </Row>

                    </Card>
                    {/* } */}


                </Form>
            </Collapse>

        </div>
    )
}
