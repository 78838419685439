import React from 'react';
import DashboardCard from '../DashboardNew/Cards/DashboardCard';

const PasmatCard = () => ( 
	<DashboardCard 
		title = {'PASMAT'}
		subtitle = 'PASMAT'
		icon = ''
		color = 'green'
		to = 'pasmatUI'
	/>
);

export default PasmatCard;