// TODO remove password related codes
import React, { useState, useEffect } from "react";

import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { FormHeader } from "../common/custom/FormHeader";
import LinkButton from "../common/custom/LinkButton";
import Select from "react-select";
import {
  register,
  getProgramList,
  getTeamList,
} from "../../services/user.service";
import Swal from "sweetalert2";
import "../SignIn/Signin.css";
import errorResponses from "../ErrorHandling/errorResponses"
import { Button, Form, Input, Row, Col } from "reactstrap";
import { getUserInfoFromHangar } from "../../services/authentication";
import config from "../../services/config";
const Register = (props) => {

  let history = useHistory();
  const [teamList, setTeamList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [team_id, setTeam] = useState("");
  const [program_id, setProgram] = useState("");
  const [team_name, setTeamName] = useState([]);
  const [program_name, setProgramName] = useState([]);
  const [dNumber, setDNumber] = useState('');
  // const [password, setPassword] = useState('');
  const handleDNumberChange = (e) => {
    setDNumber(e.target.value);
  };

  const initialUserData = {};
  // TODO1 if props has name and email, then use them
  // else, show only dnumber field and one click of a button fetch information from hangar and populate the remaining fields as is - keep them NOT editable
  if (props.location && props.location.details) {
    initialUserData.username = props.location.details.name;
    initialUserData.useremail = props.location.details.email;
    initialUserData.dnumber = props.location.details.dnumber;
  }
  const [user, setUser] = useState(initialUserData);

  const getTeam = (program_id) => {
    getTeamList(program_id).then((response) => {
      if (response) {
        let allTeams = response?.data?.data;
        let selectOptions = [];
        for (const team of allTeams) {
          selectOptions.push({ value: team.value, label: team.display });
        }
        setTeamList(selectOptions);
      }
      else {
        Swal.fire("Error!", errorResponses.REGISTER_TEAMS_ERROR, "error")
      }
    }).catch((error) => {
      Swal.fire("Error!", errorResponses.REGISTER_TEAMS_ERROR, "error")
    })
  };

  const getPrograms = () => {
    getProgramList().then((response) => {
      if (response) {
        let allPrograms = response?.data?.data;
        let selectOptions = [];
        for (const program of allPrograms) {
          selectOptions.push({ value: program.value, label: program.display });
        }
        setProgramList(selectOptions);
      }
      else {
        Swal.fire("Error!", errorResponses.REGISTER_PROGRAM_ERROR, "error")
      }
    })
      .catch((error) => {
        Swal.fire("Error!", errorResponses.REGISTER_PROGRAM_ERROR, "error")
      })
  };

  const fetchUserInformation = () => {
    getUserInfoFromHangar(dNumber)
      .then(({ data }) => {
        setUser({
          username: `${data.first_name} ${data.last_name}`,
          useremail: data.mail,
          dnumber: dNumber
        });
      })
      .catch((error) => {
        Swal.fire("Error!", errorResponses.REGISTER_HANGAR_ERROR, "error")
      });
  };

  const changeProgram = (e) => {
    setProgram(e.value);
    setProgramName(e.label);
    getTeam(e.value);
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      user.program_id = program_id;
      user.program_name = program_name;
      user.team_id = team_id;
      user.team_name = team_name;
      // user.password = password;
      if (user.program_id === "") {
        Swal.fire("Error", "Please select a program.", "error");
      }
      else if (user.team_id === "") {
        Swal.fire("Error", "Please select a team.", "error");
      }
      // else if (!user.password && !config.enableAccount01Login) {
      //   Swal.fire("Error", "Please choose a password.", "error");
      // }
      else {
        let response = await register(user);
        if (response.success) {
          Swal.fire("Congratulation !", response.message, "success");
          history.push("/signin");
        } else {
          Swal.fire("Error", response.message, "error");
        }
      }
    }
    catch (error) {
      Swal.fire(
        'Error !',
        'Something went wrong while registering. Contact Admin',
        'error'
      );
    }
  };

  useEffect(() => {
    getPrograms();
  }, []);

  return (
    <div>
      <div className="form-register text-center white" hidden={user.useremail && user.username}>
        <FormHeader
          title={`Registration`}
          gradient="theme-gradient-primary-secondary"
          icon="telstra"
          size={"sm"}
        />
        <Row className="mt-2 d-flex align-items-center justify-content-center">
          <Col xs="auto" className="d-flex align-items-center">
            <label className="asterisk m-0">d-number</label>
          </Col>
          <Col xs={4}>
            <Input
              type="text"
              bsSize="sm"
              name="dNumber"
              maxLength={7}
              data-testid='dNumber'
              placeholder="Enter your telstra dnumber"
              value={dNumber}
              onChange={handleDNumberChange}
            />
          </Col>
        </Row>
        {/* <Col xs="auto" className="d-flex align-items-center"> */}
        <Button className="mt-3" onClick={fetchUserInformation} color="primary">Get user information</Button>
        {/* </Col> */}
      </div>
      <Form onSubmit={submit} className="form-register text-center white" hidden={!user.useremail || !user.username}>
        <FormHeader
          title={`Registration`}
          gradient="theme-gradient-primary-secondary"
          icon="telstra"
          size={"sm"}
        />
        <Row className="text-left">
          <Col xs="6">
            <label className="asterisk">Name</label>
            <Input
              type="text"
              name="Name"
              data-testid='name'
              placeholder="Enter name"
              required
              value={user.username}
              readOnly={true}
            />
          </Col>
          <Col xs="6">
            <label className="asterisk"> Email ID </label>
            <Input
              type="email"
              name="email"
              id="email"
              required
              value={user.useremail}
              readOnly={true}
            />
          </Col>
        </Row>
        <Row className="mt-2 text-left">
          <Col xs="6">
            <label className="asterisk">Program Name</label>
            <Select
              required
              id="programName"
              name="programName"
              data-testid="programName"
              onChange={(e) => changeProgram(e)}
              options={programList}
            />
          </Col>
          <Col xs="6">
            <label className="asterisk">Team Name</label>
            <Select
              required
              options={teamList}
              onChange={(e) => {
                setTeam(e.value);
                setTeamName(e.label);
              }}
            />
          </Col>
        </Row>
        {/* <Row className="mt-2 text-left">
          <Col>
            <label className="asterisk">Password</label>
            <Input
              type="password"
              name="Password"
              data-testid='password'
              placeholder="Enter password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Col>
        </Row> */}
        <Row style={{ marginTop: "4%" }}>
          <Col xs="12">
            <Button
              className={classNames(
                "btn",
                "btn-lg",
                "btn-success",
                "btn-block",
                "ld-ext-right"
              )}
              type="submit"
            >
              Register
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "3%" }}>
          <Col xs="12">
            <LinkButton
              to="/signin"
              className="btn btn-lg btn-secondary btn-block"
              type="button"
              role="button"
            >
              Sign In
            </LinkButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Register;
