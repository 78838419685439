import axios from "axios";
import { getToken } from "./authentication";
import {
  CREATE_REQUEST,
  NEW_REQUEST_LIST,
  REQUEST_LIST,
  PROCESS_REQUEST,
  ADD_USER_TEAMS
} from "./endpoints";


export const addUserTeams = async (team_id, email, role) => {
  let response;
  var user_team_request = {
    email: email,
    team_id: team_id,
    adminOrFtl: role
  }
  try {
    response = await axios.post(ADD_USER_TEAMS, user_team_request);
  } catch (error) {
    response = error.response;
  }
  return response.data;
};

//Purpose : user registeration methods

export const register = async (user) => {
  let response;

  try {
    response = await axios.post(CREATE_REQUEST, user);
  } catch (error) {
    response = error.response;
  }
  return response.data;
};

export const process = async (params) => {
  let response;
  axios.defaults.headers.common = { Authorization: `Bearer ${getToken()}` };
  try {
    response = await axios.post(PROCESS_REQUEST, params);
  } catch (error) {
    response = error.response;
  }
  return response.data;
};

const handleErrorResponse = (error) => {
  let apiResponse;
  const { response } = error;
      if (response) {
        apiResponse = error;
      }
    return apiResponse;
}

export const getRequestList = async () => {
  axios.defaults.headers.common = { Authorization: `Bearer ${getToken()}` };
  return await axios
    .get(REQUEST_LIST)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return handleErrorResponse(e);
    });
};

export const getNewRequestList = async () => {
  axios.defaults.headers.common = { Authorization: `Bearer ${getToken()}` };
  return await axios
    .get(NEW_REQUEST_LIST)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return handleErrorResponse(e);
    });
};