import React, { useEffect, useState } from "react";
import {
  TabPane,
  Jumbotron,
  Spinner,
  Input,
  Button,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
import { Divider, Typography, InputLabel } from "@material-ui/core";
import workspaceSettingsService from "../../services/workspaceSettings.service";

const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

export default function DebugLogs({ sVHost, activeTab }) {
  const [loading, setLoading] = useState(true);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const makeMessagesDisappearAfterSometime = () => {
    delay(20000).then(() => {
      setSuccessMessage("");
      setApiErrorMessage("");
    });
  };
  const [logLoading, setLogLoading] = useState(true);
  const [debugLoggingStatus, setDebugLoggingStatus] = useState({ isEnabled: false, expiryDays: 1 });
  const [selectedExpiryDays, setSelectedExpiryDays] = useState(1);
  const [transactionCount, setTransactionCount] = useState(3);
  const [logs, setLogs] = useState("");

  const handleDebugLoggingStatus = (checked) => {
    setLoading(true);
    let debugLogRequestBody = { isDebugLoggingEnabled: checked }
    if (checked) {
      debugLogRequestBody.expiryDays = selectedExpiryDays
    } else {
      setSelectedExpiryDays(1);
    }
    workspaceSettingsService
      .manageDebugLogStatus(sVHost, debugLogRequestBody)
      .then((response) => {
        updateDebugLoggingStatus();
        setSuccessMessage(response.data);
        makeMessagesDisappearAfterSometime();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setApiErrorMessage(`Unable to ${checked ? "enable" : "disable"} debug logging. ${error}`);
        makeMessagesDisappearAfterSometime();
      });
  };

  const handleTransactionCountUpdate = (e) => {
    setTransactionCount(e.target.value);
  };

  const handleFetchLogsClick = () => {
    setLogLoading(true);
    workspaceSettingsService
      .getDebugLogs(sVHost, { size: transactionCount })
      .then((response) => {
        if (!response.data) {
          setApiErrorMessage("No logs found. Call virtual service endpoints to see logs.");
          makeMessagesDisappearAfterSometime();
        }
        setLogs(response.data);
        setLogLoading(false);
      })
      .catch((error) => {
        setLogLoading(false);
        setApiErrorMessage(`Unable to fetch logs. ${error}`);
        makeMessagesDisappearAfterSometime();
      });
  };

  const handleLogsDownloadClick = () => {
    const data = new Blob([logs], { type: "text/plain" });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `sv-debug-logs-${Date.now()}`);
    link.click();
  };

  const updateDebugLoggingStatus = () => {
    setLoading(true);
    workspaceSettingsService
      .getDebugLoggingStatus(sVHost)
      .then((response) => {
        setDebugLoggingStatus({ isEnabled: response.data.isEnabled, expiryDays: response.data.expiryDays || 1, expiryHuman: response.data.expiryHuman });
        setSelectedExpiryDays(response.data.expiryDays || 1);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setApiErrorMessage(`Unable to obtain debug logging status. ${error}`);
        makeMessagesDisappearAfterSometime();
      });
  };

  const setExpiryDays = (e) => {
    setSelectedExpiryDays(e.target.value);
  }

  const renderDebugLogStatusBlock = () => {
    return (<Row className="mt-1">
      <Col xs="5" className="pl-0 ml-3 d-flex align-items-center border">
        <Col xs="auto" className="p-0">
          <InputLabel className="m-0 pl-2 pr-2 text-dark font-weight-bold">
            {selectedExpiryDays} {selectedExpiryDays > 1 ? 'days' : 'day'}
          </InputLabel>
        </Col>
        <Col className="p-0">
          <CustomInput
            name="expiryDays"
            id={1}
            bsSize="lg"
            type="range"
            step={1}
            min={1}
            max={5}
            value={selectedExpiryDays}
            onChange={(e) => { setExpiryDays(e) }}
          />
        </Col>
        <Col xs="auto" className="p-0">
          <Button
            color="primary"
            size="sm"
            name="enable"
            onClick={() => { handleDebugLoggingStatus(true) }}
            className="m-2"
          >{debugLoggingStatus.isEnabled ? "Update expiry" : "Enable"}</Button>
        </Col>
      </Col>
      <Col xs="2" className="d-flex align-items-center">
        {debugLoggingStatus.isEnabled && <Button
          color="danger"
          size="sm"
          name="disable"
          onClick={() => { handleDebugLoggingStatus(false) }}
          className="ml-2"
        >Disable</Button>
        }
      </Col>
    </Row>)
  }

  useEffect(() => {
    if (activeTab === "5") {
      updateDebugLoggingStatus();
    }
  }, [sVHost, activeTab]);

  return (
    <>
      <TabPane tabId="5" className="p-3">
        {loading ? (
          <Jumbotron className="text-center">
            <Spinner className="p-5" color="primary"></Spinner>
          </Jumbotron>
        ) : (
          <div className="m-0">
            <Row className="mt-2">
              <Col>
                <Typography variant="subtitle1">
                  You can enable debug logging for the workspace to start capturing
                  detailed logs for virtual service transactions. This will help you
                  identify any issues you might be facing with templating, actions
                  and so on.
                  <br />
                  <span className="font-italic mt-1">
                    <strong>Note :- </strong>
                    This feature strictly should NOT be used for non-functional
                    testing as it can have performance impacts.
                  </span>
                </Typography>
              </Col>
            </Row>
            <Divider className="m-2"></Divider>
            <Typography variant="subtitle2"><strong>Current status:</strong>Debug log is currently
              {` ${debugLoggingStatus.isEnabled ? `enabled. It will be automatically disabled ${debugLoggingStatus.expiryHuman}` : "disabled"}`}</Typography>
            {renderDebugLogStatusBlock()}
            {debugLoggingStatus.isEnabled ? (
              <>
                <Row className="ml-0 mr-2 mb-2 mt-2">
                  <Col xs="2" className="pl-0 d-flex align-items-center">
                    <Typography variant="subtitle1" className="m-0">
                      Transaction count
                    </Typography>
                  </Col>
                  <Col xs="3" className="pl-2 d-flex align-items-center">
                    <InputLabel className="m-0 pr-3 text-dark font-weight-bold">
                      {transactionCount}
                    </InputLabel>
                    <CustomInput
                      id={1}
                      bsSize="lg"
                      type="range"
                      step={1}
                      min={1}
                      max={20}
                      value={transactionCount}
                      onChange={(e) => handleTransactionCountUpdate(e)}
                    />
                  </Col>
                </Row>
                <Row className="ml-0 mr-2 mb-2 mt-2">
                  <Col xs="2" className="pl-0 d-flex align-items-center">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => handleFetchLogsClick()}
                    >
                      Fetch logs
                    </Button>
                    {logs ? (
                      <Col xs="2" className="pl-3 d-flex align-items-center">
                        <Button
                          color="info"
                          size="sm"
                          onClick={() => handleLogsDownloadClick()}
                        >
                          Download
                        </Button>
                      </Col>
                    ) : null}
                  </Col>
                </Row>
                <Row className="mt-2 mb-1">
                  <Col>
                    <Typography
                      variant="body2"
                      className="text-success"
                      hidden={!successMessage}
                    >
                      <span role="img" aria-label="check mark emoji">
                        ✔️
                      </span>{" "}
                      {successMessage}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="error"
                      hidden={!apiErrorMessage}
                    >
                      <span role="img" aria-label="warning emoji">
                        &#9888;&#65039;
                      </span>{" "}
                      {apiErrorMessage}
                    </Typography>
                  </Col>
                </Row>
                <Row className="ml-0 mr-2 mb-2 mt-3">
                  <Col className="p-0">
                    {logs
                      ? [
                        logLoading ? (
                          <Jumbotron className="text-center" key="secondJumotron">
                            <Spinner
                              className="p-5"
                              color="primary"
                            ></Spinner>
                          </Jumbotron>
                        ) : (
                          <>
                            <Input
                              type="textarea"
                              readonly
                              bsSize="sm"
                              rows="10"
                              value={logs}
                              style={{ resize: "none" }}
                              className="bg-white"
                            ></Input>
                          </>
                        ),
                      ]
                      : null}
                  </Col>
                </Row>
              </>
            ) : null}
          </div>
        )}
      </TabPane>
    </>
  );
}
