import CommonService from './common.service';
import _ from "lodash";
import { nodeColors } from '../assets/colors';


function getCJVScenarios(endpointId, stub_name, sVHostHeader) {
    const api = CommonService.getApiUrl("cjvscenarios");
    return CommonService.httpGet(api, { endpointId: endpointId, stub_name: stub_name, sVHostHeader: sVHostHeader });
}
function readCJVFileS3(params) {
    const api = CommonService.getApiUrl("readCJVFileS3");
    return CommonService.httpGet(api, params);
}

function createCJVScenario(cJVScenarioName, cJVScenario, stubName, sVHostHeader, stubS3Path, endpointIndex, endpointId) {
    let params = { cJVScenarioName: cJVScenarioName, stubName: stubName, sVHostHeader: sVHostHeader, stubS3Path: stubS3Path };
    if (!endpointId) {
        params['endpointIndex'] = endpointIndex
    }
    else {
        params['endpointId'] = endpointId;

    }
    const api = CommonService.getApiUrl("cjvscenarios");
    return CommonService.httpPost(api, cJVScenario, undefined, params);
}

function generateCJV(params) {
    const api = CommonService.getApiUrl("generateCJV");
    return CommonService.httpPost(api, params);
}
function deleteCJV(params) {
    const api = CommonService.getApiUrl("deletecjvscenario");
    return CommonService.httpDelete(api, params);
}
const removeDuplicates = (arr) => {
    const tempSet = new Set();
    let value = arr.filter(arrItem => {
        const duplicate = tempSet.has(arrItem.id);
        tempSet.add(arrItem.id);
        return !duplicate;
    });
    return value
}


const findChildren = (parent, mainElements) => {

    let children = mainElements.filter(
        mainFilterItem => {
            if (parent.data) {
                return mainFilterItem.data.source === parent.id

            }
            else {
                return mainFilterItem.data.source === parent.uniqueId

            }
        }
    )
    return children;
}

const addGreyNodes = (cjvData, ParentData) => {
    // parent Data is uiDefinition.element.data
    let hiddenElements = []

    const getAllChildren = (parent, mainElements) => {


        const children = findChildren(parent, mainElements);
        hiddenElements = hiddenElements.concat(children);
        if (children.length === 0) {
            return hiddenElements;
        }
        for (let child of children) {
            // TODO We will check and fix this bug(sonarQube) later
            hiddenElements.concat(getAllChildren(child, mainElements))
        }
        return hiddenElements
    }
    // grey Parent is the parent node after which all the nodes will be greyed out.
    let cjvDataClone = _.cloneDeep(cjvData)


    let elementToHide = getAllChildren(ParentData, cjvDataClone.uiDefinition)



    for (let cjvDataItem of cjvData.uiDefinition) {
        for (let hiddenItem of elementToHide) {
            if (hiddenItem.id === cjvDataItem.id) {
                cjvDataItem.data.isHidden = true
            }
        }
    }
    return cjvData


}

const removeTaggedElements = (cjvData) => {
    let returnData = _.cloneDeep(cjvData)
    cjvData.uiDefinition = returnData.uiDefinition.filter(returnDataItem => returnDataItem.data.isHidden !== true)
    return cjvData
}
const addUniqueId = (cjvData) => {
    try {
        let final = _.cloneDeep(cjvData);
        final.uiDefinition.map((finalItem) => {

            if (finalItem.data) {
                finalItem.data['uniqueId'] = finalItem.id;
            }
            return 1
        });
        return final;
    }
    catch (error) {
    }
}
const egdeConversion = (cjvData) => {
    try {
        let final = _.cloneDeep(cjvData);

        final.map((finalItem) => {

            if (finalItem.data) {
                finalItem.data['uniqueId'] = finalItem.id;
                if (finalItem.data.source || finalItem.data.conflicts) {

                    let edgeObject = {};
                    edgeObject['id'] = "egde-" + (finalItem.data.source || finalItem.data.conflicts[0].source) + "-" + finalItem.id;

                    edgeObject["source"] = finalItem.data.source || finalItem.data.conflicts[0].source
                    edgeObject["target"] = finalItem.id;
                    edgeObject["arrowHeadType"] = "arrowclosed";
                    edgeObject["animated"] = false;
                    // switch to smoothstep if step type is not needed

                    edgeObject['type'] = 'step'




                    edgeObject['style'] = { stroke: nodeColors.arrowColor }
                    final.push(edgeObject);
                }
            }

            return 1

        })
        final = removeDuplicates(final)
        return final
    }
    catch (error) {
        return {}
    }
}

const CJVScenarioService = {
    getCJVScenarios,
    readCJVFileS3,
    egdeConversion,
    createCJVScenario,
    generateCJV,
    deleteCJV,
    addGreyNodes,
    removeTaggedElements,
    addUniqueId
};

export default CJVScenarioService;
