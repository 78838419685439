import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import EditIcon from '@material-ui/icons/Edit';
import {
  getUserProgramsList,
  getProgramStreams,
  getStreamApplication
} from "../../services/folderStructure.service";
import DashboardCard from "../DashboardNew/Cards/DashboardCard";
import {
  faLayerGroup,
  faTasks
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { TelstraIcon } from "../common/icons/GradientIcons";

import errorResponses from "../ErrorHandling/errorResponses"
import ProgramEdit from "../Support/AdminSupport/ProgramEdit"
import ValuechainEdit from "../Support/AdminSupport/ValuechainEdit"
const MySwal = withReactContent(Swal);

const SupportFolderStructure = (props) => {

  // Purpose : To show all value chain and program in the dashboard

  let history = useHistory();
  const [program, setProgram] = useState(null);
  const [programdata, setProgramData] = useState([]);
  const [vcdata, setVCData] = useState([]);

  const handleProgramClick = async (dataValue) => {
    setProgram(dataValue);
    let streams = await getProgramStreams(dataValue.program_id);
    setVCData(streams.data);
    if (programdata.length > 1) {
      scrollToBottom();
    }
  };

  const handleValueChainClick = async (dataVal) => {
      try {
        let applicationsResponse = await getStreamApplication(dataVal.valueChain_id);
        history.push({
          pathname: "applications",
          state: {
            program_chosen:program,
            userData: {
                Programs: [
                    {
                        ...program,
                        valuechains : [{
                            vc_code: dataVal.valuechain_code,
                            vc_name: dataVal.valueChain_name,
                            vc_id: dataVal.valueChain_id,
                            applications: applicationsResponse.data.data
                        }]
                    }
                  ]
            },
            valueChain: {
              valueChain_id: dataVal.valueChain_id,
              valueChain_name: dataVal.valueChain_name,
              valueChain_code: dataVal.valuechain_code
            }
          }
        });
      } catch (error) {
        history.push({
            pathname: "errorPage",
            state: {
              errorMessage: errorResponses.GENERIC_ERROR
            }
          });
      }
  };

  const getUserPrograms = async () => {
    try {
      let programs =  await getUserProgramsList();
      setProgramData(programs.data)
    } catch (error) {
      history.push({
        pathname: "errorPage",
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        }
      });
    }
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      var scrollEl = document.getElementById("view-content");
      if (scrollEl != null) window.scrollTo({ top: scrollEl.scrollHeight + 40, behavior: "smooth" })
    }, 100);
  }

  useEffect(() => {
    getUserPrograms();
  }, []);

  const swalCompToLoad = (caller) => {
    switch (caller) {
      case 'programs':
        return <ProgramEdit></ProgramEdit>

      case 'valuechains':
        return <ValuechainEdit></ValuechainEdit>

      default:

    }
  }
  const programEditFunc = (callerName) => {
    MySwal.fire({
      html: swalCompToLoad(callerName)
      ,
      confirmButtonText: 'Close',
      width: 1200,
      padding: '2em',
    })
  }

  const ValueChain = () => {
    return (
      <Row>
        {vcdata.map((m, index) => (
            <Col xs={4} className="p-3" key={index}>
              <DashboardCard
                color={m.color}
                icon={m.icon}
                title={m.valueChain_name + " Stream"}
                subtitle={m.description}

                onClick={(e) => {
                  handleValueChainClick(m);
                }}
              ></DashboardCard>
            </Col>
          ))}
      </Row>
    );
  };

  const Program = () => {
    return (
      <Row>
        {programdata.map((m, index) => (
          <Col xs={4} className="p-3" key={index}>
            <DashboardCard
              color={"turquoise"}
              //icon={"crowd-support"}
              title={m.program_name}
              subtitle={m.description}
              onClick={(e) => {

                handleProgramClick(m);
              }}
            ></DashboardCard>
          </Col>
        ))}
      </Row>
    );
  };

  const showIsProgramSelected = () => {
      if (program && program.program_name.length)
        return <Col xs={1}>
            <TelstraIcon primary icon="tick" color="green" />
          </Col>
      else return <> </> ;
  }

  const renderProgramsBlock = () => {
    if(programdata.length > 0) {
      return  <>
      <Row>
            <Col xs={10}>
              <h3>
                Programs
              <FontAwesomeIcon className="ml-2" icon={faTasks} size="1x" />{" "}
              </h3>
            </Col>


            <Col xs={1}>
              <EditIcon onClick={() => programEditFunc("programs")} fontSize="large" color="secondary" style={{ cursor: "pointer" }} />
            </Col>
              {showIsProgramSelected()}
          </Row>
          <Program></Program>  <br></br>
      </>
      } else return <></>
  }

  const renderStreamsBlock = () => {
    if(vcdata.length){
      return <div id="view-content">
              <Row>
                <Col xs={11}>
                  <h3>
                    Streams
                    <FontAwesomeIcon
                      className="ml-2"
                      icon={faLayerGroup}
                      size="1x"
                    />

                    <hr></hr>
                  </h3>
                </Col>
                <Col xs={1}>
                  <EditIcon onClick={() => programEditFunc("valuechains")} fontSize="large" color="secondary" style={{ cursor: "pointer" }} />
                </Col>
              </Row>

              <ValueChain></ValueChain>
            </div>
    } else return <></>;
  }

  return (
    <>
      {renderProgramsBlock()}
      {renderStreamsBlock()}
    </>
  );
};

export default SupportFolderStructure;
