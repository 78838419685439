import Swal from "sweetalert2";

const Toast = Swal.mixin({
  showConfirmButton: false,
  toast: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
  position: "bottom",
  timer: 2000,
  timerProgressBar: true,
  showClass: {
    popup: 'swal2-noanimation',
    backdrop: 'swal2-noanimation'
  },
  hideClass: {
    popup: '',
    backdrop: ''
  }
});

const swalBootstrapButtonsQuestion = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-lg m-2',
    cancelButton: 'btn btn-secondary btn-lg m-2'
  },
  buttonsStyling: false
});

const swalBootstrapButtonsDangerousQuestion = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-danger btn-lg m-2',
    cancelButton: 'btn btn-secondary btn-lg m-2'
  },
  buttonsStyling: false
});
 
export {
  Toast,
  swalBootstrapButtonsQuestion,
  swalBootstrapButtonsDangerousQuestion
};
