import CommonService from "./common.service";



function getMASSLClientsCertificates(sVHost) {
  const api = CommonService.getApiUrl("settings/massl/clients/certificates");
  return CommonService.httpGet(api, undefined, { 'sVHost': sVHost });
}

function addAMASSLClientsCertificate(sVHost, certificate) {
  const api = CommonService.getApiUrl("settings/massl/clients/certificates");
  return CommonService.httpPost(api, certificate, { 'sVHost': sVHost });
}

function removeAAMASSLClientsCertificate(S3Key) {
  const api = CommonService.getApiUrl("settings/massl/clients/certificates");
  return CommonService.httpDelete(api, undefined, { 'S3Key': S3Key });
}

function getMASSLHTTPFwdClientsCerts(sVHost) {
  const api = CommonService.getApiUrl("settings/massl/httpfwd/clients/certificates");
  return CommonService.httpGet(api, undefined, { 'sVHost': sVHost });
}

function addAMASSLHTTPFwdClientsCert(sVHost, certificate) {
  const api = CommonService.getApiUrl("settings/massl/httpfwd/clients/certificates");
  return CommonService.httpPost(api, certificate, { 'sVHost': sVHost });
}

function removeAAMASSLHTTPFwdClientsCert(certS3Key, keyS3Key) {
  const api = CommonService.getApiUrl("settings/massl/httpfwd/clients/certificates");
  return CommonService.httpDelete(api, undefined, { 'certS3Key': certS3Key, 'keyS3Key': keyS3Key });
}

const getSecretsNames = (sVHost) => {
  const api = CommonService.getApiUrl("settings/secrets");
  return CommonService.httpGet(api, undefined, { 'sVHost': sVHost });
};

const addSecretToWorkspace = (secret, sVHost) => {
  const api = CommonService.getApiUrl('settings/secrets');
  return CommonService.httpPost(api, secret, { 'sVHost': sVHost });
};

const removeSecretFromWorkspace = (secretName, sVHost) => {
  const api = CommonService.getApiUrl(`settings/secrets/${secretName}`);
  return CommonService.httpDelete(api, undefined, { 'sVHost': sVHost });
};

const addFileToWorkspace = (fileToUpload, sVHost) => {
  const api = CommonService.getApiUrl("settings/files");
  return CommonService.httpPost(api, fileToUpload, { 'sVHost': sVHost });
};

const getFilesInformation = (sVHost) => {
  const api = CommonService.getApiUrl("settings/files");
  return CommonService.httpGet(api, undefined, { 'sVHost': sVHost });
};

const getFileContents = (name, sVHost) => {
  const api = CommonService.getApiUrl(`settings/files/${name}`);
  return CommonService.httpGet(api, undefined, { 'sVHost': sVHost });
};

const removeFileFromWorkspace = (name, sVHost) => {
  const api = CommonService.getApiUrl(`settings/files/${name}`);
  return CommonService.httpDelete(api, undefined, { 'sVHost': sVHost });
};

const getDebugLoggingStatus = (sVHost) => {
  const api = CommonService.getApiUrl(`settings/debuglogging/status`);
  return CommonService.httpGet(api, undefined, { 'sVHost': sVHost });
};

const manageDebugLogStatus = (sVHost, options) => {
  const api = CommonService.getApiUrl(`settings/debuglogging/manage`);
  return CommonService.httpPost(api, options, { 'sVHost': sVHost });
};

const getDebugLogs = (sVHost, options) => {
  const api = CommonService.getApiUrl(`settings/debuglogging/logs`);
  return CommonService.httpGet(api, options, { 'sVHost': sVHost });
};

const getGeneralSettings = (sVHost) => {
  const api = CommonService.getApiUrl(`settings/general`);
  return CommonService.httpGet(api, undefined, { 'sVHost': sVHost });
};

const manageGeneralSettings = (sVHost, options) => {
  const api = CommonService.getApiUrl(`settings/general`);
  return CommonService.httpPost(api, options, { 'sVHost': sVHost });
};

export default {
  getMASSLClientsCertificates,
  addAMASSLClientsCertificate,
  removeAAMASSLClientsCertificate,
  getMASSLHTTPFwdClientsCerts,
  addAMASSLHTTPFwdClientsCert,
  removeAAMASSLHTTPFwdClientsCert,
  getSecretsNames,
  addSecretToWorkspace,
  removeSecretFromWorkspace,
  addFileToWorkspace,
  getFilesInformation,
  getFileContents,
  removeFileFromWorkspace,
  getDebugLoggingStatus,
  manageDebugLogStatus,
  getDebugLogs,
  getGeneralSettings,
  manageGeneralSettings,
}
