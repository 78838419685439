import React from "react";
import { Row, Col, Input, FormFeedback } from "reactstrap";
import { InputLabel } from "@material-ui/core";

import { AddToolTip } from "../../common/custom/AddToolTip";
import { Verbs } from "../../../services/verbiage";
import {
  MAX_BODY_SIZE
} from "../../utils/stubValidators";

export default function SQS({
  actionData,
  index,
  actionType,
  handleFEPSQSResponseActionsUpdate,
}) {
  return (
    <>

      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.httpForward.url}>
            <InputLabel className="p-0 asterisk">Protocol proxy base URL</InputLabel>
          </AddToolTip>
        </Col>
        {/* </Row>
      <Row className="mt-2"> */}
        <Col>
          <Input
            placeholder="https://<hostname>:<port>"
            bsSize="sm"
            name="protocolProxyURL"
            value={(actionData.protocolProxyURL) ? actionData.protocolProxyURL : ""}
            onChange={(e) => handleFEPSQSResponseActionsUpdate(e, index)}
          ></Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.protocolProxyEmpty}
          </FormFeedback>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.sqsQueue.authorizationToken}>
            <InputLabel className="p-0">Authorization token</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            placeholder="enter authorization token"
            bsSize="sm"
            name="authorization"
            value={(actionData.authorization) ? actionData.authorization : ""}
            onChange={(e) => handleFEPSQSResponseActionsUpdate(e, index)}
          ></Input>
        </Col>

      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.httpForward.url}>
            <InputLabel className="p-0 asterisk">Queue name</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            placeholder="enter value"
            bsSize="sm"
            name="qOrTName"
            value={(actionData.qOrTName) ? actionData.qOrTName : ""}
            onChange={(e) => handleFEPSQSResponseActionsUpdate(e, index)}
          ></Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.pathInvalid}
          </FormFeedback>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.responseAction.contentType}>
            <InputLabel className="p-0">Content type</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            placeholder="add content-type"
            bsSize="sm"
            name="contentType"
            value={(actionData.contentType) ? actionData.contentType : "text/plain"}
            onChange={(e) => handleFEPSQSResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.httpForward.body}>
            <InputLabel className="p-0">Message</InputLabel>
          </AddToolTip>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Input
            type="textarea"
            bsSize="sm"
            maxLength={MAX_BODY_SIZE}
            rows="4"
            name="qOrTMessage"
            value={(actionData.qOrTMessage) ? actionData.qOrTMessage : ""}
            onChange={(e) => handleFEPSQSResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>
    </>
  );
}
