import React from "react";
import { Row, Col, Input, FormFeedback } from "reactstrap";
import { InputLabel, IconButton } from "@material-ui/core";
import { FaExclamationCircle } from "react-icons/lib/fa";
import {
  AddCircle,
  RemoveCircle,
} from "@material-ui/icons";
import { AddToolTip } from "../../common/custom/AddToolTip";
import { Verbs } from "../../../services/verbiage";
import {
  hasExceededResponseHeaderRows,
  MAX_BODY_SIZE
} from "../../utils/stubValidators";
import { palette } from "../../../assets/colors";

export default function JMS({
  actionData,
  index,
  actionType,
  handleFEPJMSResponseActionsUpdate,
  isEditable
}) {
  return (
    <>

      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.httpForward.url}>
            <InputLabel className="p-0 asterisk">Protocol proxy base URL</InputLabel>
          </AddToolTip>
        </Col>
        {/* </Row>
      <Row className="mt-2"> */}
        <Col>
          <Input
            placeholder="https://<hostname>:<port>"
            bsSize="sm"
            name="protocolProxyURL"
            value={(actionData.protocolProxyURL) ? actionData.protocolProxyURL : ""}
            onChange={(e) => handleFEPJMSResponseActionsUpdate(e, index)}
          ></Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.protocolProxyEmpty}
          </FormFeedback>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.jmsWrite.authorizationToken}>
            <InputLabel className="p-0">Authorization token</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            placeholder="enter authorization token"
            bsSize="sm"
            name="authorization"
            value={(actionData.authorization) ? actionData.authorization : ""}
            onChange={(e) => handleFEPJMSResponseActionsUpdate(e, index)}
          ></Input>
        </Col>

      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.httpForward.url}>
            <InputLabel className="p-0 asterisk">{(actionType === 'jmsWriteQueue') ? 'Queue name' : 'Topic name'}</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            placeholder="enter value"
            bsSize="sm"
            name="qOrTName"
            value={(actionData.qOrTName) ? actionData.qOrTName : ""}
            onChange={(e) => handleFEPJMSResponseActionsUpdate(e, index)}
          ></Input>
          <FormFeedback invalid>
            {Verbs.validationMessages.pathInvalid}
          </FormFeedback>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.responseAction.contentType}>
            <InputLabel className="p-0">Content type</InputLabel>
          </AddToolTip>
        </Col>
        <Col>
          <Input
            placeholder="add content-type"
            bsSize="sm"
            name="contentType"
            value={(actionData.contentType) ? actionData.contentType : "text/plain"}
            onChange={(e) => handleFEPJMSResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>
      {/* #### put headers here START */}
      {/* Headers */}
      <Row className="mt-3">
        <Col xs="auto">
          <AddToolTip
            placement="top-start"
            title={Verbs.title.responseActions.httpForward.headers}
          >
            <InputLabel>Headers</InputLabel>
          </AddToolTip>
        </Col>
      </Row>
      {
        <>
          {actionData.headers.map((item, hindex) => {
            return (
              <Row className="mt-2" key={hindex}>
                <Col xs={5}>
                  <Input
                    placeholder="Key"
                    bsSize="sm"
                    value={item.headerkey}
                    name="headerKey"
                    onChange={(e) =>
                      handleFEPJMSResponseActionsUpdate(e, index, hindex)
                    }
                  ></Input>
                </Col>
                <Col xs={5}>
                  <Input
                    placeholder="Value"
                    bsSize="sm"
                    name="headerValue"
                    value={item.value ? item.value : ""}
                    onChange={(e) =>
                      handleFEPJMSResponseActionsUpdate(e, index, hindex)
                    }
                  ></Input>
                </Col>
                {actionData.headers.length === 1 ? null : (
                  <Col xs="auto" className="pl-1 pr-1">
                    <IconButton
                      aria-label="remove header"
                      component="span"
                      className="p-0 pt-1"
                      onClick={() =>
                        handleFEPJMSResponseActionsUpdate(
                          { target: { name: "removeHeader" } },
                          index,
                          hindex
                        )
                      }
                      hidden={!isEditable}
                      name="removeHeader"
                    >
                      <RemoveCircle
                        fontSize="small"
                        style={{ color: palette.danger }}
                      />
                    </IconButton>
                  </Col>
                )}
                {hindex === actionData.headers.length - 1 ? (
                  <Col xs="auto" className="pl-1 pr-1">
                    <IconButton
                      aria-label="add header"
                      component="span"
                      className="p-0 pt-1"
                      onClick={() =>
                        handleFEPJMSResponseActionsUpdate(
                          { target: { name: "addHeader" } },
                          index,
                          hindex
                        )
                      }
                      hidden={!isEditable}
                      name="addHeader"
                    >
                      <AddCircle fontSize="small" color="secondary" />
                    </IconButton>
                  </Col>
                ) : null}
              </Row>
            );
          })}
          {hasExceededResponseHeaderRows(actionData.headers.length) && (
            <Row className="text-center mt-3">
              <Col className="text-center">
                <span className="text-danger" style={{ fontSize: 15 }}>
                  <FaExclamationCircle
                    className="mr-2"
                    size="1.1rem"
                    style={{ marginBottom: "0.1rem" }}
                  />
                  {Verbs.validationMessages.responseHeaderListLengthInvalid}
                </span>
              </Col>
            </Row>
          )}
        </>
      }
      {/* #### put headers here END */}
      <Row className="mt-2">
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.httpForward.body}>
            <InputLabel className="p-0">Message</InputLabel>
          </AddToolTip>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Input
            type="textarea"
            bsSize="sm"
            maxLength={MAX_BODY_SIZE}
            rows="4"
            name="qOrTMessage"
            value={(actionData.qOrTMessage) ? actionData.qOrTMessage : ""}
            onChange={(e) => handleFEPJMSResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>
    </>
  );
}
