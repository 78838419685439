import MaterialTable from 'material-table'
import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Label, Row, Col, Button, Container, Card } from 'reactstrap'
import { palette } from "../../../../src/assets/colors"
import { getAllTeamApplications, addTeamApplications } from "../../../services/support.service";
import Swal from 'sweetalert2';

import {

    Collapse

} from "@material-ui/core";

const columns = [
    { title: "Team Name", field: "team_name", width: "30%" },
    { title: "Application Name", field: "application_name", width: "30%" },
];
// this means initial data
const dataConst = [
    {
        id: 1,
        team_id: 2,
        application_id: 31,
        team_name: 'Team A',
        application_name: 'SalesForce1'
    },
    {
        id: 2,
        team_id: 3,
        application_id: 32,
        team_name: 'Team B',
        application_name: 'SalesForce2'
    },

]

export default function TeamApplications(props) {

    const teamAppListTableRef = useRef(null);
    const teamAppData = useRef(null);
    const [showForm, setShowForm] = useState(false);
    // defines the current selected index of the materialTable
    const [revision, setRevision] = useState(false)
    // data is used to display the material table at the top
    const [teamAppList, setTeamAppList] = useState(dataConst);
    // a Program is new if it is not present in database

    const [selectedTeam, setSelectedTeam] = useState(props.allTeams[0])
    const [selectedApplication, setSelectedApplication] = useState(props.allApps[0])
    const [selectedTeamAppData, setSelectedTeamAppData] = useState(
        {

            team_id: props.allTeams[0].team_id,
            application_id: props.allApps[0].application_id,
            team_name: props.allTeams[0].team_name,
            application_name: props.allApps[0].application_name
        }
    )

    const actions = [

        {
            icon: "add_circle",
            iconProps: { style: { fontSize: "35px", color: palette.primaryshade2 } },
            tooltip: "Add endpoint",
            isFreeAction: true,

            onClick: () => {
                setSelectedTeam(props.allTeams[0])
                setSelectedApplication(props.allApps[0])
                setSelectedTeamAppData({

                    team_id: props.allTeams[0].team_id,
                    application_id: props.allApps[0].application_id,
                    team_name: props.allTeams[0].team_name,
                    application_name: props.allApps[0].application_name
                })
                setTeamAppList([...teamAppList, {

                    team_id: props.allTeams[0].team_id,
                    application_id: props.allApps[0].application_id,
                    team_name: props.allTeams[0].team_name,
                    application_name: props.allApps[0].application_name
                }])
                setShowForm(true)
            },
        },
    ];



    const onTeamAppDataChange = (e) => {

        let objectValue = selectedTeamAppData;
        objectValue[e.target.name] = e.target.value;
        setSelectedTeamAppData(objectValue);


        setRevision(!revision)

    }
    const getData = async () => {
        try {
            const response = await getAllTeamApplications();
            setTeamAppList(response.data.data)
        }
        catch (error) {
            Swal.fire(`Something went wrong. Error ${error}`)
        }

    }
    const saveButton = async () => {
        try {
            await addTeamApplications({
                team_id: selectedTeamAppData.team_id,
                application_id: selectedTeamAppData.application_id
            });
            // // by calling following function, it is made sure that the data is fetched from the database.
            getData();
            Swal.fire("Successfully Added Application to Team")
        }
        catch (error) {
        }

    }

    useEffect(() => {
        getData()
    }, []);
    return (
        <div>
            <div ref={teamAppListTableRef}>
                <Container className="mt-3 p-2 pl-0 pr-0">

                    <Form mt-3 p-2>



                        <MaterialTable
                            style={{ width: "100%" }}
                            columns={columns}
                            data={teamAppList}
                            actions={actions}

                            options={{


                                headerStyle: {
                                    fontWeight: "bold",
                                    backgroundColor: palette.blueseq[3],
                                },
                                filtering: true,

                            }}
                            title="Team Applications"

                        />



                    </Form>
                </Container>
            </div>
            <br /><br />
            <Collapse in={showForm}>

                <Form className="float-left w-100 p-2" ref={teamAppData}>
                    <Card
                        className="p-3"
                        style={{ backgroundColor: palette.blueseq[0] }}
                    >
                        <Row>
                            <Col xs="3">
                                <Label for="team_name" className="mt-3 float-left">Team  Name</Label>
                            </Col>
                            <Col xs="4">
                                <Input
                                    type="select"
                                    name="team_id"
                                    key={Math.random()}
                                    value={selectedTeam['team_id']}
                                    onChange={(e) => {
                                        const teamApplicationData = props.allTeams.filter((teamItem) => teamItem.team_id === parseInt(e.target.value));
                                        setSelectedTeam(teamApplicationData[0])
                                        onTeamAppDataChange(e)
                                    }
                                    }>
                                    {
                                        props.allTeams.map((teamItem, index) => {
                                            return <option key={index}
                                                value={parseInt(teamItem.team_id)}
                                            >{teamItem.team_name}</option>
                                        })
                                    }
                                </Input>
                            </Col></Row>

                        <Row className="mt-3">
                            <Col xs="3">
                                <Label for="application_name" className="mt-3 float-left">Application  Name</Label>
                            </Col>
                            <Col xs="4">
                                <Input
                                    type="select"
                                    name="application_id"
                                    key={Math.random()}
                                    value={selectedApplication['application_id']}
                                    onChange={(e) => {
                                        const applicationData = props.allApps.filter((appItem) => appItem.application_id === parseInt(e.target.value));
                                        setSelectedApplication(applicationData[0])
                                        onTeamAppDataChange(e)
                                    }
                                    }>
                                    {
                                        props.allApps.map((appItem, index) => {
                                            return <option key={index}
                                                value={parseInt(appItem.application_id)}
                                            >{appItem.application_name}({appItem.program_code}-{appItem.valuechain_code}-{appItem.application_code})</option>
                                        })
                                    }
                                </Input>
                            </Col></Row>

                        <br />
                        <Row className="mt-3">

                            <Col xs="5">
                                <Button

                                    color="success"
                                    className="mt-3 float-left btn-lg"
                                    onClick={saveButton}>
                                    Save
                               </Button>
                            </Col>
                        </Row>

                    </Card>
                    {/* } */}


                </Form>
            </Collapse>

        </div>
    )
}
