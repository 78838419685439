import React, { useState, useContext, useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { FormHeader } from "../common/custom/FormHeader";
import { Button, Form, FormGroup, Label, Input, Container } from "reactstrap";
import Swal from "sweetalert2";
import {
  signin,
  storeToken,
  getProfileDataFromToken,
  getUserInfoFromHangar,
} from "../../services/authentication";

import { GlobalContext } from "../../context/GlobalState";
import "../SignIn/Signin.css";
import logger from "../../utilities/logger";
import config from "../../services/config";
import { addUserDnumber } from "../../services/user.service";
import { confluenceURLs } from "../../constants";
import withReactContent from "sweetalert2-react-content";
import { Info } from "@material-ui/icons";
const ssoEndpoint = config.telstraUrl;
const taasSSOEndpoint = config.taasSSO;

const MySwal = withReactContent(Swal);


export function sum(x, y) {
  return x + y;
}
const SignIn = (props) => {
  let history = useHistory();
  const initialUserData = {
    useremail: null,
    password: null,
  };

  let {
    setLoginType,
    setUserRoles,
    setIsAdmin,
    setIsAuthenticated,
    setProfileData,
    user_role
  } = useContext(GlobalContext);

  const [user, setUser] = useState(initialUserData);

  const telstraLogin = () => {
    window.location.href = ssoEndpoint
  }

  const taasLogin = () => {
    window.location.href = taasSSOEndpoint
  }

  const handleRegisterButtonClick = () => {
    if (config.enableAccount01Login) {
      // If account-01 login is enabled, redirect them to account-01 sso page for authentication
      window.location.href = taasSSOEndpoint
    }
    else {
      history.push("/register");
    }
  }

  /**
   * Searches hangar to obtain user information
   * @param {*} email
   * The email to be searched
   * @returns
   * Telstra user information
   */
  const fetchUserInformation = async (email) => {
    try {
      let userDetails = await getUserInfoFromHangar(email);
      return userDetails?.data?.mAGS || null;
    } catch (err) {
      // Ignore the error from Hangar.
      console.log(`Error when fetching information from hangar using email ${email}`, err);
      return null;
    }
  };

  // Redirects to appropriate dashboards on successful login
  const redirectOnSuccess = (userData) => {
    if (userData.role === "support") {
      setIsAdmin(true);
      history.push("/support/dashboard");
    } else {
      setIsAdmin(false);
      history.push("/dashboard");
    }
  }
  // Ensures dnumber is added if it is not already present.
  // The dnumber will be attempted to be fetched from hangar API using provided email.
  const addDnumberToUserDetails = async () => {
    const dnumber = localStorage.getItem('dnumber');
    if (!dnumber) {
      let userEmail = localStorage.getItem('userEmail');
      let userDnumber = await fetchUserInformation(userEmail);
      if (userDnumber)
        await addUserDnumber(userEmail, userDnumber);
    }
    else {
      return;
    }
  }

  const troubleshootSignInHtml = <Container>
    <div style={{ textAlign: 'left', lineHeight: '1.5' }} className='mt-4'>
      <p>Please refer to "Registering to SV SSP Portal" section in <a href='https://confluence.tools.telstra.com/display/SERVIRT/Virtual+Services' rel="noopener noreferrer" target='_blank'>Service Virtualisation</a> confluence page.</p>
      <br />
      <p>While registering to SV Portal if you are facing any login issues, please refer <a href='https://confluence.tools.telstra.com/display/SERVIRT/Steps+to+Resolve+SV+Portal+Login+Issues' rel="noopener noreferrer" target='_blank'>Steps to Resolve SV Portal Login Issues</a>.</p>
    </div>
  </Container>


  const troubleshootSignInContent = () => {
    MySwal.fire({
      icon: 'info',
      title: confluenceURLs.troubleLoggingIn.displayText,
      html: troubleshootSignInHtml,
      confirmButtonText: 'Close',
      width: 800
    })
  }

  // Handles submit for the sign-in form
  const submit = async (e) => {
    e.preventDefault();
    signin(user)
      .then(async (response) => {
        if (response) {
          if (response.success) {
            logger.info("Password sign in successful")
            storeToken(response.token);
            setLoginType("LOCAL");
            setIsAuthenticated(true);
            localStorage.setItem("dnumber", response.dnumber)
            let user = getProfileDataFromToken(response.token);
            addDnumberToUserDetails();
            setUserRoles(user.role);
            setProfileData({ user_name: user.user_name, email: user.email });
            redirectOnSuccess(user);
          } else {
            logger.error("signin Error", response.message)
            Swal.fire("Error", response.message, "error");
          }
        }
      })
      .catch(error => {
        logger.error("signin->", error)
        Swal.fire("Error", "No response due to internal error", "error");
      });
  };

  useEffect(() => {
    if (user_role === 'unapproved_yet') {
      Swal.fire("Awaiting approval", "Contact Admin")
    }
  }, [user_role])
  return (
    <div>
      <Form onSubmit={submit} className="form-signin text-center white">
        <FormHeader
          title={`Login`}
          gradient="theme-gradient-primary-secondary"
          data-testid='login_gradient'
          icon="telstra"
          size={"sm"}
        />
        {/* {config.enablePasswordLogin && (<>
          <FormGroup>
            <Label for="email">Telstra Email-Id</Label>
            <Input
              type="email"
              name="email"
              id="email"
              data-testid='email'
              required
              placeholder="Enter Telstra Email-Id"
              onInput={(e) => setUser({ ...user, useremail: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="Password">Password</Label>
            <Input
              type="password"
              name="Password"
              id="Password"
              data-testid='password'
              required
              placeholder="Enter your password"
              onInput={(e) => setUser({ ...user, password: e.target.value })}
            />
          </FormGroup>
          <Button
            id="signinButton"
            data-testid="signinButton"
            className={classNames(
              "btn",
              "btn-lg",
              "btn-success",
              "btn-block",
              "ld-ext-right"
            )}
            type="submit"
          >
            {" "}
            Sign In{" "}
          </Button>
        </>)} */}
        {/* <Button
          className={classNames(
            "btn",
            "btn-lg",
            "btn-block",
            "ld-ext-right"
          )}
          color="primary"
          onClick={telstraLogin}
          hidden={!config.enableAccount01Login}
        >
          {" "}
          Account-01 Sign In{" "}
        </Button> */}
        <Button
          className={classNames(
            "btn",
            "btn-lg",
            "btn-block",
            "ld-ext-right"
          )}
          color="primary"
          onClick={taasLogin}
          hidden={!config.enableAccount01Login}
        >
          {" "}
          Account-01 Sign In{" "}
        </Button>
        <Button
          onClick={handleRegisterButtonClick}
          className={classNames(
            "btn",
            "btn-lg",
            "btn-block",
            "ld-ext-right"
          )}
          color="secondary"
        >
          Register an account
        </Button>
        <Label className="pt-2">
          <p style={{ display: "inline-block", cursor: "pointer", color: 'blue' }}
            className="text-primary mb-0"
            onClick={troubleshootSignInContent}>
            <Info fontSize="small"></Info><span>{confluenceURLs.troubleLoggingIn.displayText}</span>
          </p>
        </Label>
      </Form>


    </div>
  );
};

export default SignIn;
