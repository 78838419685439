import React from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import SignIn from "../components/SignIn/SignIn";
import DashboardNew from "../components/DashboardNew/DashboardNew";

import Register from "../components/SignIn/Register";
import PortalInsights from "../components/Support/PortalInsights/PortalInsights";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import VirtualService from "../components/VirtualService/VirtualService";
import Simulations from "../components/ManageStub/Simulations";
import ChangePassword from "../components/SignIn/ChangePassword";
import Notfound from "../components/common/custom/notfound";
import Expired from "../components/common/custom/expired";
import SupportDashboard from "../components/Support/Dashboard/SupportDashboard";
import Main from "../components/Support/Layouts/Main";
import RouteWithLayout from "./RouteWithLayout";
import ApplicationsStructure from "../components/FolderStructure/ApplicationsStructure";
import AdminApproval from "../components/Support/Request/AdminApproval";
import AccessManagement from "../components/Support/AccessManagement/AccessManagement";
import Library from "../components/Support/Library/Library";
import LibrarySearch from "../components/Support/Library/LibrarySearch";
import UsageReportComp from "../components/UsageReport/UsageReportComp";
import TransactionMetricsComp from "../components/TransactionMetrics/TransactionMetricsComp";
import UsageDashboard from "../components/Support/UsageDashboard/UsageDashboard";
import ErrorPage from "../components/ErrorHandling/ErrorPage";
import Profile from "../components/Profile/Profile";
import PasmatUI from "../components/PASMAT/PasmatUI";
import PasmatSurvey from "../components/PASMAT/PasmatSurvey";
import PasmatReportUI from "../components/PASMAT/PasmatReportUI";
import WorkspaceAccessManagement from "../components/Support/WorkspaceAccessManagment/WorkspaceAccessManagement";
import ViewPasmatSurvey from "../components/PASMAT/ViewPasmatSurvey";
import PasmatTrend from "../components/PASMAT/PasmatTrend";
import LoginSuccess from "../components/LoginSuccess/LoginSuccess";
export default function index() {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path="/expired" component={Expired} />
        <PrivateRoute exact path="/errorPage" component={ErrorPage} />
        <RouteWithLayout
          component={ErrorPage}
          exact
          layout={Main}
          path="/support/errorPage"
        />
        <PublicRoute exact path="/unauthorized" component={Notfound} />
        <Redirect exact from="/" to="/dashboard" component={SignIn} />
        <PublicRoute exact path="/signin" component={SignIn} />
        <PublicRoute exact path="/register" component={Register} />
        <PrivateRoute exact path="/loginsuccess" component={LoginSuccess} />

        <PrivateRoute exact path="/changepass" component={ChangePassword} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/managestub/createstub" component={VirtualService} />
        <PrivateRoute exact path="/createstub" component={VirtualService} />
        <PrivateRoute exact path="/virtualservice" component={VirtualService} />
        <PrivateRoute exact path="/accessManagement" component={AccessManagement} />
        <PrivateRoute exact path="/requestworkspaceaccess" component={WorkspaceAccessManagement} />
        <PrivateRoute exact path="/adminapproval"
          component={AdminApproval}
        />
        <PrivateRoute
          exact
          path="/library"
          component={Library}
        />
         <PrivateRoute
          exact
          path="/library-search"
          component={LibrarySearch}
        />
        <PrivateRoute
          exact
          path="/usageReport"
          component={UsageReportComp}
        />

        <PrivateRoute
          exact
          path="/transactionMetrics"
          component={TransactionMetricsComp}
        />


        {/* pasmat 2 */}
        <PrivateRoute
          exact
          path="/pasmatUI"
          component={PasmatUI}
        />
        <RouteWithLayout
          component={PasmatUI}
          exact
          layout={Main}
          path="/support/pasmatUI"
        />
        <PrivateRoute
          exact
          path="/pasmatSurvey"
          component={PasmatSurvey}
        />
        <RouteWithLayout
          exact
          component={PasmatSurvey}
          layout={Main}
          path="/support/pasmatSurvey"
        />
        <PrivateRoute
          exact
          path="/viewPasmatSurvey"
          component={ViewPasmatSurvey}
        />
        <RouteWithLayout
          exact
          component={ViewPasmatSurvey}
          layout={Main}
          path="/support/viewPasmatSurvey"
        />
        <PrivateRoute
          exact
          path="/pasmatReport"
          component={PasmatReportUI}
        />
        <RouteWithLayout
          component={PasmatReportUI}
          exact
          layout={Main}
          path="/support/pasmatReport"
        />

        <PrivateRoute
          exact
          path="/pasmatTrend"
          component={PasmatTrend}
        />
        <RouteWithLayout
          component={PasmatTrend}
          exact
          layout={Main}
          path="/support/pasmatTrend"
        />




        <PrivateRoute
          exact
          path="/managestub/managestub"
          component={Simulations}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/managestub"
          component={Simulations}
        ></PrivateRoute>

        {/* <PrivateRoute
          exact
          path="/managestub/editstub"
          component={EditSimulation}
        ></PrivateRoute> */}
        <PrivateRoute
          exact
          path="/managestub/editstub"
          component={VirtualService}
        ></PrivateRoute>
        {/* <PrivateRoute
          exact
          path="/managestub/editstubJms"
          component={EditSimulationJms}
        ></PrivateRoute> */}
        <PrivateRoute
          exact
          path="/managestub/editstubJms"
          component={VirtualService}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/dashboard"
          component={DashboardNew}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/applications"
          component={ApplicationsStructure}
        ></PrivateRoute>

        <RouteWithLayout
          exact
          layout={Main}
          path="/support/insights"
          component={PortalInsights}
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          layout={Main}
          path="/support/applications"
          component={ApplicationsStructure}
        ></RouteWithLayout>

        <RouteWithLayout
          component={ChangePassword}
          exact
          layout={Main}
          path="/support/changepass"
        />
        <RouteWithLayout
          component={Profile}
          exact
          layout={Main}
          path="/support/profile"
        />
        <RouteWithLayout
          component={WorkspaceAccessManagement}
          exact
          layout={Main}
          path="/support/requestworkspaceaccess"
        />
        <RouteWithLayout
          component={VirtualService}
          exact
          layout={Main}
          path="/support/createstub"
        />
        <RouteWithLayout
          component={VirtualService}
          exact
          layout={Main}
          path="/support/virtualservice"
        />
        <RouteWithLayout
          path="/support/managestub"
          component={Simulations}
          exact
          layout={Main}
        />
        {/* <RouteWithLayout
          path="/support/managestub/editstub"
          component={EditSimulation}
          exact
          layout={Main}
        ></RouteWithLayout> */}
        <RouteWithLayout
          path="/support/managestub/editstub"
          component={VirtualService}
          exact
          layout={Main}
        ></RouteWithLayout>
        {/* <RouteWithLayout
          path="/support/managestub/editstubJms"
          component={EditSimulationJms}
          exact
          layout={Main}
        ></RouteWithLayout> */}
        <RouteWithLayout
          path="/support/managestub/editstubJms"
          component={VirtualService}
          exact
          layout={Main}
        ></RouteWithLayout>
        <RouteWithLayout
          component={SupportDashboard}
          exact
          layout={Main}
          path="/support/dashboard"
        />
        <RouteWithLayout
          component={AdminApproval}
          exact
          layout={Main}
          path="/support/adminapproval"
        />
        <RouteWithLayout
          component={AccessManagement}
          exact
          layout={Main}
          path="/support/accessManagement"
        />
        <RouteWithLayout
          component={LibrarySearch}
          exact
          layout={Main}
          path="/support/library-search"
        />

        <RouteWithLayout
          component={Library}
          exact
          layout={Main}
          path="/support/library"
        />
        <RouteWithLayout
          exact
          path="/support/usageReport"
          layout={Main}
          component={UsageReportComp}
        />
        <RouteWithLayout
          exact
          path="/support/transactionMetrics"
          layout={Main}
          component={TransactionMetricsComp}
        />

        <RouteWithLayout
          component={UsageDashboard}
          exact
          layout={Main}
          path="/support/usageDashboard"
        />
      </Switch>
    </BrowserRouter>
  );
}
