import React from 'react';
import { Row, Col } from 'reactstrap'
import IframeResizer from 'iframe-resizer-react';
import './PortalHealthMetrics.css';
import CommonService from '../../../services/common.service';

const NewPortalHealthMetrics = () => {
    return <>
        <div className="white mt-3">

            {/* ECS CPU Utilisation */}
            <h2 style={{ marginLeft: "1vw" }}>ECS</h2>

            <Row className="mb-n1">
                <Col className="mb-4" xs={12}>
                    <div className="chart">
                        <IframeResizer
                            // log
                            // src={iFrameSrc}
                            src={CommonService.getEcsCpuUrl(CommonService.getCurrentEnv())}
                            style={{ width: '1px', minWidth: '100%', height: '425px' }}
                        />
                    </div>
                </Col></Row>

            {/* Ecs Memory Utilisation */}
            <Row className="mb-n1"><Col className="mb-4" xs={12}>
                <div className="chart">
                    <IframeResizer
                        src={CommonService.getEcsMemoryUrl(CommonService.getCurrentEnv())}
                        style={{ width: '1px', minWidth: '100%', height: '425px' }}
                    />
                </div>
            </Col>

            </Row>

        </div>
        <div className="white mt-3">

            {/*Number of  Requests by ALB */}
            <h2 style={{ marginLeft: "1vw" }}>Load Balancers</h2>

            <Row className="mb-n1"><Col className="mb-4" xs={12}>
                <div className="chart">
                    <IframeResizer
                        src={CommonService.getAlbNoOfReq1Url(CommonService.getCurrentEnv())}
                        style={{ width: '1px', minWidth: '100%', height: '425px' }}
                    />
                </div>
            </Col>


            </Row>
            <Row className="mb-n1">
                <Col className="mb-4" xs={6}>
                    <div className="chart">
                        <IframeResizer
                            src={CommonService.getAlbNoOfReq2Url(CommonService.getCurrentEnv())}
                            style={{ width: '1px', minWidth: '100%', height: '425px' }}
                        />
                    </div>
                </Col>
                {/* Backend Response Time ALB */}
                {/* <Row className="mb-n1"> */}
                <Col className="mb-4" xs={6}>
                    <div className="chart">
                        <IframeResizer
                            src={CommonService.getAlbBackendResTimeUrl(CommonService.getCurrentEnv())}
                            style={{ width: '1px', minWidth: '100%', height: '425px' }}
                        />
                    </div>
                </Col>
            </Row>
        </div>        
        <div className="white mt-3">
            <h2 style={{ marginLeft: "1vw" }}>Other Graphs</h2>
            {/* HTTP Request Errors */}
            <Row className="mb-n1"><Col className="mb-4" xs={12}>
                <div className="chart">
                    <IframeResizer
                        src={CommonService.getHttpReqErrorUrl(CommonService.getCurrentEnv())}
                        style={{ width: '1px', minWidth: '100%', height: '425px' }}
                    />
                </div>
            </Col>
            </Row>
        </div>
    </>;

}

export default NewPortalHealthMetrics;