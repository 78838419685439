import CommonService from './common.service';
import { getUserDetails } from './authentication';

function getAllPrograms() {
    const api = CommonService.getApiUrl("support/getAllPrograms");
    return CommonService.httpGet(api);
}

function getAllTeams(role) {
    const api = CommonService.getApiUrl("getAllTeams");
    let user = getUserDetails();
    var params = { user_id: user.id, role: role };
    return CommonService.httpGet(api, params);
}

function getftlOrDelegateTeams() {
    const api = CommonService.getApiUrl("getftlOrDelegateTeams");
    let user = getUserDetails();
    var params = { user_id: user.id };
    return CommonService.httpGet(api, params);
}
function getQuestions() {
    const api = CommonService.getApiUrl("getPasmatQuestions");
    return CommonService.httpGet(api)

}

function insertPasmatAnswers(answers, team_id, role) {
    let user = getUserDetails();
    const api = CommonService.getApiUrl("insertPasmatAnswers");
    let params = {
        pasmat_answers: answers,
        team_id: team_id,
        user_id: user.id,
        role: role
    }
    return CommonService.httpPost(api, params)
}
function getPasmatAnswers(team_id) {
    const api = CommonService.getApiUrl("getPasmatAnswers");

    let params = { team_id: team_id };
    return CommonService.httpGet(api, params);
}
function getPasmatReport(team_id, team_code) {
    const api = CommonService.getApiUrl("getPasmatReport");
    const params = { team_id: team_id, team_code: team_code };
    return CommonService.httpGet(api, params)
}
function getReportApprovalStatus(team_id) {
    const api = CommonService.getApiUrl("getReportApprovalStatus");
    const params = { team_id: team_id }
    return CommonService.httpGet(api, params)
}
function getPasmatTeamsStubMetrics(team_code) {
    const api = CommonService.getApiUrl("getPasmatTeamStubMetrics");
    const params = { team_code: team_code }
    return CommonService.httpGet(api, params);
}

function getTeamsByPrograms(program_id) {
    const api = CommonService.getApiUrl('support/getTeamsByPrograms');
    const params = { program_id };
    return CommonService.httpGet(api, params);
}

function getCurrentCycle() {
    const api = CommonService.getApiUrl('getCurrentCycle');
    return CommonService.httpGet(api);
}

function getPASMATCylceData() {
    const api = CommonService.getApiUrl('getPASMATCylceData');
    return CommonService.httpGet(api);
}

function getPASMATProgramsTrendReport(cycle) {
    const api = CommonService.getApiUrl('getPASMATProgramsTrendReport');
    const params = { cycle };
    return CommonService.httpGet(api, params);
}

function getPASMATValueChainsTrendReport(cycle, program) {
    const api = CommonService.getApiUrl('getPASMATValueChainsTrendReport');
    const params = { cycle, program };
    return CommonService.httpGet(api, params);
}

function getPASMATApplicationsTrendReport(cycle, valueChain) {
    const api = CommonService.getApiUrl('getPASMATApplicationsTrendReport');
    const params = { cycle, valueChain };
    return CommonService.httpGet(api, params);
}

function getPASMATTeamsTrendReport(cycle, application) {
    const api = CommonService.getApiUrl('getPASMATTeamsTrendReport');
    const params = { cycle, application };
    return CommonService.httpGet(api, params);
}


function getPASMATTeamDetailedTrendReport(team_id, team_code) {
    const api = CommonService.getApiUrl('getPASMATTeamDetailedTrendReport');
    const params = { team_id, team_code };
    return CommonService.httpGet(api, params);
}
const pasmatService = {
    getAllTeams,
    getQuestions,
    insertPasmatAnswers,
    getPasmatReport,
    getftlOrDelegateTeams,
    getReportApprovalStatus,
    getPasmatAnswers,
    getPasmatTeamsStubMetrics,
    getAllPrograms,
    getTeamsByPrograms,
    getCurrentCycle,
    getPASMATCylceData,
    getPASMATProgramsTrendReport,
    getPASMATValueChainsTrendReport,
    getPASMATApplicationsTrendReport,
    getPASMATTeamsTrendReport,
    getPASMATTeamDetailedTrendReport
};

export default pasmatService;
