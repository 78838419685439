import CommonService from './services/common.service';

export const confluenceURLs = {
  createVSCookbook: {
    displayText: 'https://confluence.tools.telstra.com/display/SERVIRT/Create+Virtual+Service+-+Cookbook',
    link: 'https://confluence.tools.telstra.com/display/SERVIRT/Create+Virtual+Service+-+Cookbook'
  },
  masslUserDoc: {
    displayText: 'user doc',
    link: 'https://confluence.tools.telstra.com/display/SERVIRT/Feature+team+user+-+MASSL+support+in+SV#Featureteamuser-MASSLsupportinSV-MASSLenabledstubendpoints',
  },
  svEngineJarFile: {
    displayText: 'SV engine JAR file',
  },
  svProtocolProxyJarFile: {
    displayText: 'SV Protocol Proxy JAR file',
  },
  portableWorkspaceDoc: {
    displayText: 'user doc',
    // TODO update this to user document link when documentation is complete
    link: 'https://confluence.tools.telstra.com/display/SERVIRT/SV+Portable+Workspace++Using+Docker'
  },
  jmsStubsDoc: {
    displayText: 'user handbook',
    link: 'https://confluence.tools.telstra.com/display/SERVIRT/JMS+support+in+SV+Stubs'
  },
  externalAPIsDoc: {
    displayText: 'here',
    link: 'https://confluence.tools.telstra.com/display/SERVIRT/How+to+use+SV+SSP+external+APIs'
  },
  externalAPIsTokenDoc: {
    displayText: 'here',
    link: 'https://confluence.tools.telstra.com/display/SERVIRT/How+to+use+SV+SSP+external+APIs#HowtouseSVSSPexternalAPIs-APItokens'
  },
  troubleLoggingIn: {
    displayText: 'Trouble logging in?',
    link: 'https://confluence.tools.telstra.com/display/SERVIRT/Steps+to+Resolve+SV+Portal+Login+Issues'
  }
};

