import React from 'react'
import { Container } from 'reactstrap';
export default function ErrorPage(props) {
    return (
        <div>
            <Container>
                <div className="white mt-3">
                    <h1>Error</h1>
                    <p>{props.location.state.errorMessage}
                    , please contact SV Support at

                 <a href="mailto:SECOETesting-ServiceVirtualisation@team.telstra.com"><span> SECOETesting-ServiceVirtualisation@team.telstra.com</span></a>
                    </p>
                </div>
            </Container>
        </div>
    )
}
