import React from 'react';
import CommonService from './common.service';
import { getUserDetails } from './authentication';


function isFtlOrAdmin() {
  try {
    const api = CommonService.getApiUrl('accessManagement/isFtlOrAdmin');
    let user = getUserDetails();
    var params = { user_id: user.id };
    return CommonService.httpPost(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}
function isFtlOrDelegate() {
  try {
    const api = CommonService.getApiUrl('accessManagement/isFtlOrDelegate');
    let user = getUserDetails();
    var params = { user_id: user.id };
    return CommonService.httpPost(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}

function getTeamNames() {
  try {
    const api = CommonService.getApiUrl('accessManagement/getListOfTeams');
    let user = getUserDetails();
    var params = { user_id: user.id };
    return CommonService.httpPost(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}
function getAvailableRoles() {
  try {
    const api = CommonService.getApiUrl(
      'accessManagement/rolesAvailableForChange'
    );
    let user = getUserDetails();
    var params = { user_id: user.id };
    return CommonService.httpPost(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}
function getUserList(team_id) {
  try {
    const api = CommonService.getApiUrl('accessManagement/getUsersListForTeam');
    var params = { team_id: team_id };
    return CommonService.httpPost(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}
function changeRole(user_id, older_role_id, newer_role_id, team_id) {
  try {
    const api = CommonService.getApiUrl('accessManagement/changeRole');
    var params = {
      user_id: user_id,
      older_role_id: older_role_id,
      newer_role_id: newer_role_id,
      team_id: team_id,
    };
    return CommonService.httpPost(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}

function deleteUserTeam(user_id, team_id) {
  try {
    const api = CommonService.getApiUrl('accessManagement/deleteUserTeam');
    var params = { user_id: user_id, team_id: team_id };
    return CommonService.httpPost(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}

function addUserToTeam(user_email, team_id, myrole) {
  try {
    const api = CommonService.getApiUrl('accessManagement/addUserToTeam');
    var params = {
      user_email: user_email,
      team_id: team_id,
      adminOrFtl: myrole,
    };
    return CommonService.httpPost(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}

function searchUser(searchKey) {
  try {
    const api = CommonService.getApiUrl('accessManagement/searchUser');
    var params = { user_email_serachkey: searchKey };
    return CommonService.httpGet(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}

function getAllRequests(searchKey) {
  try {
    const api = CommonService.getApiUrl('approvals/getAllRequests');

    return CommonService.httpGet(api);
  } catch (error) {
    throw new Error('Error');
  }
}

function getAllFtlRequests(searchKey) {
  try {
    const api = CommonService.getApiUrl('approvals/getFTLAllRequests');
    let user = getUserDetails();
    var params = { user_id: user.id };
    return CommonService.httpGet(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}

function responseFtl(params) {
  try {
    const api = CommonService.getApiUrl('approvals/responseFtlRequests');
    return CommonService.httpPost(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}
function responseAdminUserRequest(params) {
  try {
    const api = CommonService.getApiUrl('approvals/responseAdminUserRequests');
    return CommonService.httpPost(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}
function responseAdminUserTeamsReq(params) {
  try {
    const api = CommonService.getApiUrl('approvals/responseAdminUserTeamsReq');
    return CommonService.httpPost(api, params);
  } catch (error) {
    throw new Error('Error');
  }
}
const accessManageService = {
  isFtlOrAdmin,
  getTeamNames,
  getAvailableRoles,
  getUserList,
  changeRole,
  deleteUserTeam,
  searchUser,
  addUserToTeam,
  isFtlOrDelegate,
  getAllRequests,
  getAllFtlRequests,
  responseFtl,
  responseAdminUserRequest,
  responseAdminUserTeamsReq,
};
export default accessManageService;
