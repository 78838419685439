import React from 'react';
import {
	Row,
	Col,
	Input,
	FormFeedback,
} from 'reactstrap';
import {
	InputLabel,
} from '@material-ui/core';
import { Verbs } from '../../../services/verbiage';
import { AddToolTip } from '../../common/custom/AddToolTip';
import {
	isTibcoPathInvalid,
	MAX_BODY_SIZE,
} from '../../utils/stubValidators';
import { http_body_matchers, matchers } from '../constants';
const requestType = 'Tibco';

export default function Tibco({
	fEPRequestPath,
	handleFEPRequestPathUpdate,
	fEPRequestBody,
	handleFEPRequestBodyUpdate,
}) {
	return (
		<>
			<Row className="mt-2">
				<Col xs={3}>
					<AddToolTip placement="top-start" title={Verbs.title.request[requestType].tibcoTypeMessage}>
						<InputLabel className="asterisk">Queue/Topic</InputLabel>
					</AddToolTip>
				</Col>
			</Row>
			<Row className="mt-2">
				<Col   >
					<Input
						type="select"
						bsSize="sm"
						name="tibcoType"
						value={fEPRequestPath[0].tibcoType}
						onChange={(e) => handleFEPRequestPathUpdate(e)}
					>
						<option value="queue">Queue</option>
						<option value="topic">Topic</option>
					</Input>
				</Col>
			</Row>
			<Row className="mt-3">
				<Col xs="auto">
					<AddToolTip placement="top-start" title={Verbs.title.request[requestType].queueTopicName}>
						<InputLabel className="asterisk">Queue/ topic name</InputLabel>
					</AddToolTip>
				</Col>
			</Row>
			<Row className="mt-2">
				<Col xs={3}>
					<Input
						type="select"
						bsSize="sm"
						name="tibcoNameMatcher"
						value={fEPRequestPath[0].matcher}
						onChange={(e) => handleFEPRequestPathUpdate(e)}
					>
						{matchers.map((item) => {
							return <option key={item}>{item}</option>;
						})}
					</Input>
				</Col>
				<Col xs={9}>
					<Input
						invalid={isTibcoPathInvalid(fEPRequestPath[0].value)}
						placeholder="enter name"
						bsSize="sm"
						name="tibcoNameValue"
						value={(fEPRequestPath[0].value === '/') ? ('') : (fEPRequestPath[0].value)}
						onChange={(e) => handleFEPRequestPathUpdate(e)}
					></Input>
					<FormFeedback invalid>
						{Verbs.validationMessages.pathInvalid}
					</FormFeedback>
				</Col>
			</Row>
			{/* Message matcher */}
			<Row className="mt-3">
				<Col xs="auto">
					<AddToolTip placement="top-start" title={Verbs.title.request[requestType].message}>
						<InputLabel>Message</InputLabel>
					</AddToolTip>
				</Col>
			</Row>
			<Row className="mt-2">
				<Col>
					<Input
						type="select"
						bsSize="sm"
						name="tibcoMessageMatcher"
						value={fEPRequestBody[0].matcher}
						onChange={(e) => handleFEPRequestBodyUpdate(e)}
					>
						{http_body_matchers.map((item) => {
							return <option key={item}>{item}</option>;
						})}
					</Input>
				</Col>
			</Row>
			<Row className="mt-2">
				<Col>
					<Input
						type="textarea"
						bsSize="sm"
						maxLength={MAX_BODY_SIZE}
						rows="4"
						name="tibcoMessageValue"
						value={fEPRequestBody[0].value}
						onChange={(e) => handleFEPRequestBodyUpdate(e)}
					></Input>
				</Col>
			</Row>
		</>
	);
}