import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import {
  Container,
  Row,
  Col,
  Spinner,
  Input,
  Button
} from "reactstrap";
import LibraryService from '../../../services/library.service';
import errorResponses from '../../ErrorHandling/errorResponses';
import { getUserDetails } from '../../../services/authentication';
import {
  getDetailedDataUserNewFormat,
  getDetailedDataAdminNewFormat,
  getUserFromEnv
} from '../../../services/folderStructure.service';

import CommonService from '../../../services/common.service';

const options_map_default = [
  {
    "description": "",
    "program_name": "Select Program",
    "id": "0",
    "valuechains": [
      {
        "vc_code": "",
        "valueChain_name": "Select Valuechain",
        "valueChain_id": "0",
        "applications": [
          {
            "application_name": "Select Application",
            "application_code": "",
            "application_id": "0",
            "app_icon": "",
            "teams": [
              {
                "team_id": "0",
                "team_name": "Select Team",
              }
            ]
          }
        ]
      }
    ]
  }
];

export default function Checkout(props) {
  // const userTeamsHierarchy = props.userTeamsHierarchy;
  const selectedRows = props.selectedRows;
  const handleSwalClose = props.handleSwalClose;
  const loadData = props.loadData;

  const [userTeamsHierarchy, setUserTeamsHierarchy] = useState(props.userTeamsHierarchy);

  const [selectedTargetProgram, setSelectedTargetProgram] = useState(options_map_default[0]);
  const [selectedTargetValuechain, setSelectedTargetValuechain] = useState(options_map_default[0].valuechains[0]);
  const [selectedTargetApplication, setSelectedTargetApplication] = useState(options_map_default[0].valuechains[0].applications[0]);
  const [selectedTargetTeam, setSelectedTargetTeam] = useState(options_map_default[0].valuechains[0].applications[0].teams[0]);

  const [selectedEnv, setSelectedEnv] = useState('prod');

  const [loading, setLoading] = useState(true);


  const handleTargetEnvChange = async (selecteddata) => {
    const selectedEnv = selecteddata.target.value;
    setSelectedEnv(selectedEnv);
    try {
      let userInfo = await getUserDetails();
      if (userInfo.role === 'support') {
        let newTeamsHierarchy = await getDetailedDataAdminNewFormat(selectedEnv);
        setUserTeamsHierarchy(newTeamsHierarchy.data);
      } else {
        let detailedUserData = await getDetailedDataUserNewFormat(selectedEnv);
        setUserTeamsHierarchy(detailedUserData.data);
      }
      // Reset dependent dropdowns
      setSelectedTargetProgram(options_map_default[0]);
      setSelectedTargetValuechain(options_map_default[0].valuechains[0]);
      setSelectedTargetApplication(options_map_default[0].valuechains[0].applications[0]);
      setSelectedTargetTeam(options_map_default[0].valuechains[0].applications[0].teams[0]);
    } catch (error) {
      console.error("Error fetching user teams hierarchy:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch data for the selected environment.',
        footer: errorResponses.GENERIC_FOOTER_ERROR
      });
    }
  };


  const handleTargetProgramChange = async (selecteddata) => {
    const selectedvalue = selecteddata.target.value;
    if (selectedvalue != 0) {
      const [selectedProgram] = userTeamsHierarchy.programs.filter(programvalue => programvalue.program_id == selectedvalue);
      setSelectedTargetProgram(selectedProgram);
    }
    else {
      const [selectedProgram] = options_map_default;
      setSelectedTargetProgram(selectedProgram);
    }
    setSelectedTargetValuechain(options_map_default[0].valuechains[0]);
    setSelectedTargetApplication(options_map_default[0].valuechains[0].applications[0]);
    setSelectedTargetTeam(options_map_default[0].valuechains[0].applications[0].teams[0]);
  };

  const handleTargetValuechainChange = async (selecteddata) => {
    const selectedvalue = selecteddata.target.value;
    if (selectedvalue != 0) {
      const [selectedValuechain] = selectedTargetProgram.valuechains.filter(valuechainvalue => valuechainvalue.valueChain_id == selectedvalue);
      setSelectedTargetValuechain(selectedValuechain);
    }
    else {
      const [selectedValuechain] = options_map_default[0].valuechains;
      setSelectedTargetValuechain(selectedValuechain);
    }
    setSelectedTargetApplication(options_map_default[0].valuechains[0].applications[0]);
    setSelectedTargetTeam(options_map_default[0].valuechains[0].applications[0].teams[0]);
  }

  const handleTargetTeamChange = async (selecteddata) => {
    const selectedvalue = selecteddata.target.value;
    if (selectedvalue != 0) {
      const [selectedTeam] = selectedTargetApplication.teams.filter(teamvalue => teamvalue.team_id == selectedvalue);
      setSelectedTargetTeam(selectedTeam);
    }
    else {
      const [selectedTeam] = options_map_default[0].valuechains[0].applications[0].teams;
      setSelectedTargetTeam(selectedTeam);
    }
  }

  const resetTeamDropdown = () => {
    const teams = document.getElementById('teams-select');
    teams.value = 0;
  }

  const handleTargetApplicationChange = async (selecteddata) => {
    const selectedvalue = selecteddata.target.value;
    if (selectedvalue != 0) {
      const [selectedApplication] = selectedTargetValuechain.applications.filter(applicationvalue => applicationvalue.application_id == selectedvalue);
      setSelectedTargetApplication(selectedApplication);
    }
    else {
      const [selectedApplication] = options_map_default[0].valuechains[0].applications;
      setSelectedTargetApplication(selectedApplication);
    }
    setSelectedTargetTeam(options_map_default[0].valuechains[0].applications[0].teams[0]);
    resetTeamDropdown();
  }

  const handleCheckInButtonClick = async () => {
    let user = await getUserFromEnv(selectedEnv)
    // validate if target teamApplicationId is in any of the stubs? Error to user "You can't copy stubs from your team" : continue
    let folderStructureS3 = selectedTargetProgram.program_name + '/' +
      selectedTargetValuechain.valueChain_name + '/' +
      selectedTargetApplication.application_name + '/' +
      selectedTargetTeam.team_name;
    folderStructureS3 = folderStructureS3.replace(/ /g, "_");
    const target = {
      sourceEnv : CommonService.getCurrentEnv(),
      userId: user.id,
      program: {
        program_id: selectedTargetProgram.program_id,
        program_code: selectedTargetProgram.program_code
      },
      valuechain: {
        valueChain_id: selectedTargetValuechain.valueChain_id,
        valuechain_code: selectedTargetValuechain.valuechain_code
      },
      application: {
        application_id: selectedTargetApplication.application_id,
        application_code: selectedTargetApplication.application_code
      },
      team: {
        team_id: selectedTargetTeam.team_id,
        team_code: selectedTargetTeam.team_code,
        team_application_id: selectedTargetTeam.id,
        team_name: selectedTargetTeam.team_name
      },
      folder: folderStructureS3
    }
    // Validation check to see if user is trying to checkout stubs from target team (verifying source and destination of VSs are not same)
    if (selectedRows.map(rowvalue => rowvalue.team_application_id).includes(target.team.team_application_id) && CommonService.getCurrentEnv() == selectedEnv) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Virtual service already exists',
        footer: 'Source team of at least one of the selected virtual services is same as the target team'
      });
    }
    else {
      Swal.fire({
        onBeforeOpen: () => {
          Swal.showLoading();
          LibraryService.checkinVirtualServices(selectedRows, target, selectedEnv)
            .then((response) => {
              if (response.data.success) {
                Swal.fire({
                  title: "Congratulations !",
                  html:
                    "Virtual services checked-in. \n Now you can deploy the services from Manage Virtual Services page",
                  icon: "success",
                });
              }
              else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Internal error occured',
                  footer: 'Response from server' + JSON.stringify(response.data)
                });
              }

            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Error while checking out stub. ${error}`,
                footer: errorResponses.GENERIC_FOOTER_ERROR
              }
              );

            })
        },
        onDestroy: () => {
          // This function triggers when the next Swal (that opens the actual view virtual service) opens
        }
      });
    }

  }

  const getData = async () => {
    setLoading(false);
    if(loadData){
      await handleTargetEnvChange({target : {value : CommonService.getCurrentEnv()}})
     }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Container key={props.key}>
      <h4>Select Virtual Service(s) Destination</h4>
      <hr></hr>
      {loading === true ? (
        <Spinner animation="border" variant="secondary" />
      ) : (
          <div>
          <Row className='d-flex align-items-center'>
              <Col xs={1}></Col>
              <Col xs={4} className="text-left">
                ENV
              </Col>
              <Col xs={6} className="text-left">
                <Input
                  type='select'
                  size='sm'
                  onChange={(selecteddata) => handleTargetEnvChange(selecteddata)}
                  name='env_input'
                  defaultValue={CommonService.getCurrentEnv()}
                // disabled={(userTeamsHierarchy.programs.length > 1) ? false : true}
                >
                  <option key={0} value="0">Select Env</option>
                  <option key={1} value="prod">PROD</option>
                  <option key={2} value="stage">Staging</option>
                  <option key={3} value="qa">QA</option>
                </Input>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <br></br>
            <Row className='d-flex align-items-center'>
              <Col xs={1}></Col>
              <Col xs={4} className="text-left">
                Program
              </Col>
              <Col xs={6} className="text-left">
                <Input
                  type='select'
                  size='sm'
                  onChange={(selecteddata) => handleTargetProgramChange(selecteddata)}
                  name='program_input'
                  defaultValue={0}
                // disabled={(userTeamsHierarchy.programs.length > 1) ? false : true}
                >
                  <option key={0} value={0}>Select Program</option>
                  {userTeamsHierarchy.programs && userTeamsHierarchy.programs.map((programvalue) => (
                    <option key={programvalue.program_id} value={programvalue.program_id}>{programvalue.program_name}</option>
                  ))}
                </Input>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <br></br>
            <Row className='d-flex align-items-center'>
              <Col xs={1}></Col>
              <Col xs={4} className="text-left">
                Valuechain
              </Col>
              <Col xs={6} className="text-left">
                <Input
                  type='select'
                  size='sm'
                  onChange={(selecteddata) => handleTargetValuechainChange(selecteddata)}
                  name='valuechain_input'
                  defaultValue={0}
                // disabled={(selectedTargetProgram.valuechains.length > 1) ? false : true}
                >
                  <option key={0} value={0}>Select Valuechain</option>
                  {selectedTargetProgram.valuechains.map((valuechainvalue) => (
                    <option value={valuechainvalue.valueChain_id} key={valuechainvalue.valueChain_id}>{valuechainvalue.valueChain_name}</option>
                  ))}
                </Input>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <br></br>
            <Row className='d-flex align-items-center'>
              <Col xs={1}></Col>
              <Col xs={4} className="text-left">
                Application
              </Col>
              <Col xs={6} className="text-left">
                <Input
                  type='select'
                  size='sm'
                  onChange={(selecteddata) => handleTargetApplicationChange(selecteddata)}
                  name='application_input'
                  defaultValue={0}
                // disabled={(selectedTargetValuechain.applications.length > 1) ? false : true}
                >
                  <option key={0} value={0}>Select Application</option>
                  {selectedTargetValuechain.applications.map((applicationvalue) => (
                    <option value={applicationvalue.application_id} key={applicationvalue.application_id}>{applicationvalue.application_name}</option>
                  ))}
                </Input>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <br></br>
            <Row className='d-flex align-items-center'>
              <Col xs={1}></Col>
              <Col xs={4} className="text-left">
                Team
              </Col>
              <Col xs={6} className="text-left">
                <Input
                  id="teams-select"
                  type='select'
                  size='sm'
                  onChange={(selecteddata) => handleTargetTeamChange(selecteddata)}
                  name='team_input'
                  defaultValue={0}
                // disabled={(selectedTargetApplication.teams.length > 1) ? false : true}
                >
                  <option key={0} value={0}>Select Team</option>
                  {selectedTargetApplication.teams.map((teamvalue) => (
                    <option key={teamvalue.team_id} value={teamvalue.team_id}>{teamvalue.team_name}</option>
                  ))}
                </Input>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <Row style={{ marginTop: '3vh' }} className='d-flex align-items-end'>
              <Col>
                <Button style={{ width: 100 }} color="secondary" onClick={() => handleSwalClose()}>
                  Close
                </Button>
                <Button
                  style={{ width: 100 }}
                  className="ml-2"
                  color="primary"
                  active={!(selectedTargetProgram.program_id == 0 || selectedTargetValuechain.valueChain_id == 0 || selectedTargetApplication.application_id == 0 || selectedTargetTeam.team_id == 0)}
                  disabled={(selectedTargetProgram.program_id == 0 || selectedTargetValuechain.valueChain_id == 0 || selectedTargetApplication.application_id == 0 || selectedTargetTeam.team_id == 0)}
                  onClick={async () => handleCheckInButtonClick()}
                >
                  Check-In
                </Button>
              </Col>
            </Row>
          </div>
        )
      }
    </Container >
  );
}
