import React from 'react';
import {
  Input,
  Row,
  Col,
} from "reactstrap";

const FilePreview = (props) => {
  // For a tree implementation refer https://github.com/reduxjs/redux-devtools/tree/master/packages/react-json-tree
  return (
    <div className="white mt-3">
      <Row>
        <Col>
          <Input
            className="min-vh-100"
            type="textarea"
            value={JSON.stringify(props.virtualService, null, 2)}
            onChange={(e) => {
              e.preventDefault();
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default FilePreview;