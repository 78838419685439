import React from "react";
import DashboardCard from "../DashboardNew/Cards/DashboardCard";

export const TransactionMetricsCard = (props) => (
  <DashboardCard
    title={"Transaction Metrics"}
    subtitle="Transaction Metrics"
    icon=""
    color="green"
    to="transactionMetrics"
  />
);

export default TransactionMetricsCard;
