import React from 'react'
import Plotly from 'plotly.js';

import {
    
    Row,
    Col,
   
  } from 'reactstrap';
import createPlotlyComponent from 'react-plotly.js/factory';

const Plot = createPlotlyComponent(Plotly);

export default function Graphs({value}) {
    return (
       
        <div>
            
            <Row style = {{marginTop:"2vh"}}>
                <Col style = {{marginLeft:"10vw"}}>
                  
                 <Plot data={value['cum_req_count']} 
                         layout={ {width: "700", height: "500", title: 'Incoming Requests'} }
      
                 />
                
                 </Col>
                 </Row>

                 <Row style = {{marginTop:"5vh"}}>
                <Col style = {{marginLeft:"10vw"}}>
                  
                 <Plot data={value['avg_res_time']} 
                         layout={ {width: "700", height: "500", title: 'Average Response Time'} }
      
                 />
                
                 </Col>
               </Row>
               <Row style = {{marginTop:"5vh"}}>
                <Col style = {{marginLeft:"10vw"}}>
                  
                 <Plot data={value['cum_err_count']} 
                         layout={ {width: "700", height: "500", title: 'Errored Responses'} }
      
                 />
                
                 </Col>
               </Row>
               <Row style = {{marginTop:"5vh"}}>
                <Col style = {{marginLeft:"10vw"}}>
                  
                 <Plot data={value['cum_deployments_count']} 
                         layout={ {width: "700", height: "500", title: 'Total number of Deployments'} }
      
                 />
                
                 </Col>
               </Row>
            

        </div>
    )
}
