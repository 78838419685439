import _ from "lodash";
import config from "../../services/config";

const default_vs_template = {
  data: {
    pairs: [{
      request: {
        requestType: "HTTP",
        method: [
          {
            value: "GET",
            matcher: "exact"
          }
        ],
        path: [
          {
            value: "",
            matcher: "exact",
          }
        ],
        query: {},
        headers: {},
        body: [],
      },
      response: {
        status: 200,
        body: "",
        headers: {},
        actions: [],
        templated: false
      }
    }]
  }
}

const matchers = ["exact", "glob", "regex"];
const pathType = ["queue", "topic"];
const http_body_matchers = ["exact", "glob", "regex", "JSONPath", "XPath"];
const http_methods = ["GET", "PUT", "POST", "DELETE", "PATCH", "HEAD", "OPTIONS", "TRACE"]

// When new protocols are added, append the request types array and implement corresponding request type form
const request_types = [
  { label: 'HTTP', value: 'HTTP' },
  { label: 'JMS', value: 'JMS' },
  { label: 'Tibco', value: 'Tibco' },
  { label: 'Kinesis', value: 'Kinesis' },
  { label: 'CometD', value: 'CometD' },
  { label: 'SQS', value: 'SQS' },
  { label: 'IBMMQ', value: 'IBMMQ' }
];

const response_action_types = [
  { label: 'Properties', value: 'properties' },
  { label: 'HTTP forward', value: 'httpForward' },
  { label: 'TDM HTTP forward', value: 'tdmHttpForward' },
  { label: 'Database', value: 'database' },
  { label: 'Write to JMS topic', value: 'jmsWriteTopic' },
  { label: 'Write to JMS queue', value: 'jmsWriteQueue' },
  { label: 'Write to Tibco topic', value: 'tibcoWriteTopic' },
  { label: 'Write to Tibco queue', value: 'tibcoWriteQueue' },
  { label: 'Write to Kinesis', value: 'kinesisWrite' },
  { label: 'Execute javascript', value: 'executeJS' },
  { label: 'Write to SQS queue', value: 'sqsQueue' },
  { label: 'Write to IBM MQ queue', value: 'ibmmqWriteQueue' },
  { label: 'SMTP', value: 'smtp' }
];



const default_http_forward_action_data = {
  httpMethod: "GET",
  massl: false,
  httpUrl: "https://",
  headers: [{ headerkey: "", value: "" }],
  params: [{ querykey: "", value: "" }],
  body: "",
};

const default_response_action = {
  actionType: "httpForward",
  condition: "",
  forEach: "",
  templated: false,
  synchronous: true,
  label: "",
  actionData: _.cloneDeep(default_http_forward_action_data),
};

const default_vs = Object.freeze({
  data: {
    pairs: [
      {
        label: "",
        request: {
          requestType: "HTTP",
          method: [
            {
              value: "GET",
              matcher: "exact",
            },
          ],
          path: [
            {
              value: "/",
              matcher: "exact",
              jsmType: "queue",
              tibcoType: "queue"
            },
          ],
          query: {
            "": [{ value: "", matcher: "exact" }],
          },
          headers: {
            "": [{ value: "", matcher: "exact" }],
          },
          body: [{ value: "", matcher: "exact" }],
        },
        response: {
          status: 200,
          body: "",
          headers: { "": [""] },
          actions: [_.cloneDeep(default_response_action)],
          templated: false,
        },
      },
    ],
  },
});

const virtualServiceMetaData = {
  data: { pairs: [] },
  meta: { schemaVersion: "v5" },
};

const SV_SCRIPT_ENGINE_URL = config.scriptEngineUrl;


export {
  pathType,
  default_vs_template,
  http_methods,
  matchers,
  http_body_matchers,
  request_types,
  response_action_types,
  default_http_forward_action_data,
  default_response_action,
  default_vs,
  virtualServiceMetaData,
  SV_SCRIPT_ENGINE_URL
};