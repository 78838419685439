import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import Rating from '@material-ui/lab/Rating';
import "./AppFeedback.css";
import { Button, Input } from "reactstrap";
import Select from 'react-select';
import { Typography, Badge } from "@material-ui/core";
import { getTeams, submitUserFeedback } from "../../../services/user.service";
import { Toast } from "../../utils/swal";
const MySwal = withReactContent(Swal)

const ratingLabels = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

const FeedbackPopUp = () => {
  const [value, setValue] = useState(0);
  const [hover, setHover] = useState(-1);
  const [comment, setComment] = useState("");
  const [teams, setTeams] = useState("");
  const [team, setTeam] = useState("");
  const handleRatingChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleFeedbackSubmit = () => {
    if (value) {
      let userFeedback = {
        rating: value,
        team_name: team.team_name
      };
      if (comment.length) {
        userFeedback.ratingData = {
          comment: comment
        }
      }
      submitUserFeedback(userFeedback)
        .then(() => {
          MySwal.close();
          Toast.fire({
            icon: 'success',
            title: 'Feedback submitted',
          });
        })
        .catch((error) => {
          MySwal.close();
          Toast.fire({
            icon: 'warning',
            title: 'Unable to submit feedback.',
          });
        })
    }
    else {
      Toast.fire({
        icon: "warning",
        title: "Star rating is mandatory"
      })
    }
  };

  useEffect(() => {
    if (!teams) {
      getTeams().then((response) => {
        console.log(response.data);
        setTeams(response.data.map(item => { return { value: item.team_name, label: item.team_name } }));
      })
    }
  }, []);
  return (
    <div>
      <Typography variant="h4" className="m-2">SV SSP - Feedback</Typography>
      <Typography variant="subtitle2" className="mt-2 p-2">This is not official support channel. For immediate support contact SECOETesting-ServiceVirtualisation@team.telstra.com<br /><strong>Only star rating is mandatory</strong></Typography>

      {teams ? <Select
        options={teams}
        onChange={setTeam}
        value={team}
        placeholder='Choose team'
      /> : null}
      <Rating
        precision={0.5}
        size="large"
        onChange={handleRatingChange}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        className="mt-2"
      />
      {value !== null && <Typography ml={2}>{ratingLabels[hover !== -1 ? hover : value]}</Typography>}
      <Input
        type="textarea"
        rows="10"
        value={comment}
        // style={{ resize: "none" }}
        className="bg-white mt-2"
        hidden={value === 0}
        onChange={(e) => { setComment(e.target.value) }}
        placeholder="Please enter any additional information you wish to share"
      ></Input>
      <Button color="primary" onClick={handleFeedbackSubmit} className="mt-2" size="lg">Submit</Button>
    </div>
  )
}

const handleFeedbackButtonClick = async () => {
  MySwal.fire({
    // width: 1200,
    html: <FeedbackPopUp></FeedbackPopUp>,
    showConfirmButton: false,
  }).then(() => {

  });
};

export default function AppFeedback() {
  return (
    <Badge badgeContent="New!" color="secondary">
      <Button className="feedback-button" color="info" onClick={() => handleFeedbackButtonClick()}>FEEDBACK</Button>
    </Badge>
  );
}
