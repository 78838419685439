import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { GlobalContext } from "../../context/GlobalState";

import EditIcon from '@material-ui/icons/Edit';
import {
  // getVCData,
  // getProgram,
  getDetailedDataUser,
  getFormattedProgramData,
  getFormattedValueChainData
} from "../../services/folderStructure.service";
import DashboardCard from "../DashboardNew/Cards/DashboardCard";
import {
  faLayerGroup,
  faTasks
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { TelstraIcon } from "../common/icons/GradientIcons";

import errorResponses from "../ErrorHandling/errorResponses"
import ProgramEdit from "../Support/AdminSupport/ProgramEdit"
import ValuechainEdit from "../Support/AdminSupport/ValuechainEdit"
const MySwal = withReactContent(Swal);

export const FolderStructure = (props) => {

  // Purpose : To show all value chain and program in the dashboard

  const { isAdmin } = useContext(GlobalContext);

  let history = useHistory();
  const [userData, setUserData] = useState({
    "Programs": [
      {
        "description": "",
        "program_name": "",
        "valuechains": [
          {
            "vc_code": "",
            "vc_name": "",
            "vc_id": 1,
            "applications": [

              {
                "application_name": "",
                "application_code": ""
              }

            ]
          }

        ]
      }
    ]
  })
  const [program1, setProgram1] = useState({
    program_id: null,
    program_name1: "",
    team_id: "",
    team_name: "",
    team_code: ""
  });
  const [valueChain, setValueChain] = useState({
    valueChain_id: null,
    valueChain_name: "",
  });

  const [programdata, setProgramData] = useState([]);
  const [vcdata, setVCData] = useState([]);

  const handleProgramClick = async (dataValue) => {
    setValueChain({ valueChain_id: null, valueChain_name: "" });
    setProgram1({
      // program_id: data.program_id,
      program_name: dataValue.program_name,

      team_id: 99999,
      // team_name: data.team_name,
      // team_code: data.team_code
    });

    renderValueChain(dataValue.description);
    if (programdata.length > 1) {
      scrollToBottom();
    }
  };

  const handleValueChainClick = async (dataVal) => {
    setValueChain({
      valueChain_id: dataVal.valueChain_id,
      valueChain_name: dataVal.valueChain_name,
      valueChain_code: dataVal.vc_code
    });
    history.push({
      pathname: "applications",
      state: {
        program_chosen:
          program1,
        userData: userData,
        valueChain: {
          valueChain_id: dataVal.vc_id,
          valueChain_name: dataVal.valueChain_name,
          valueChain_code: dataVal.vc_code
        }
      }
    });
  };


  const ValueChain = () => {
    return (
      <Row>
        {

          vcdata.map((m, index) => (

            <Col xs={4} className="p-3" key={index}>
              <DashboardCard
                color={m.color}
                icon={m.icon}
                title={m.valueChain_name + " Stream"}
                subtitle={m.description}

                onClick={(e) => {
                  handleValueChainClick(m);
                }}
              ></DashboardCard>
            </Col>
          ))}
      </Row>
    );
  };

  const Program = () => {
    return (
      <Row>
        {programdata.map((m, index) => (
          <Col xs={4} className="p-3" key={index}>
            <DashboardCard
              color={"turquoise"}
              //icon={"crowd-support"}
              title={m.program_name}
              subtitle={m.description}
              onClick={(e) => {

                handleProgramClick(m);
              }}
            ></DashboardCard>
          </Col>
        ))}
      </Row>
    );
  };

  const renderValueChain = async (program_description) => {
    try {
      let response = getFormattedValueChainData(program_description, userData)
      // let response = await getVCData({ program_id: pid, team_id: tid });
      if (response.code == 200) {
        setVCData(response.data);
      }
    }
    catch (error) {
      history.push({
        pathname: "errorPage",
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        }
      });
    }
  };

  const renderProgram = async () => {
    try {
      let userDataResponse = await getDetailedDataUser()
      let response = getFormattedProgramData(userDataResponse.data);
      setUserData(userDataResponse.data)
      // let response = await getProgram();
      if (userDataResponse.data.code === 200) {
        setProgramData(response.result);
        if (response.result.length === 1) {
          handleProgramClick(response.result[0]);
        }
      }
    }
    catch (error) {
      history.push({
        pathname: "errorPage",
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        }
      });
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      var scrollEl = document.getElementById("view-content");
      if (scrollEl != null) window.scrollTo({ top: scrollEl.scrollHeight + 40, behavior: "smooth" })
    }, 100);
  }

  const swalCompToLoad = (caller) => {
    switch (caller) {
      case 'programs':
        return <ProgramEdit></ProgramEdit>

      case 'valuechains':
        return <ValuechainEdit></ValuechainEdit>

      default:

    }
  }
  const programEditFunc = (callerName) => {
    MySwal.fire({
      html: swalCompToLoad(callerName)
      ,
      confirmButtonText: 'Close',
      width: 1200,
      padding: '2em',
    })
  }

  useEffect(() => {
    renderProgram();
    // getdUserDataFunc()
  }, [userData.Programs[0].description]);

  const EditProgramData = () => {
    if(isAdmin){
      return(<EditIcon onClick={() => programEditFunc("programs")} fontSize="large" color="secondary" style={{ cursor: "pointer" }} />)
    }else{
      return (<></>);
    }
  };
  const EditValueChainData = () => {
    if(isAdmin){
      return(<EditIcon onClick={() => programEditFunc("valuechains")} fontSize="large" color="secondary" style={{ cursor: "pointer" }} />)
    }else{
      return (<></>);
    }
  };
  const valueChainName = valueChain.valueChain_name !== ""  ? " / " + valueChain.valueChain_name  : "";
  
  return (
    <>
      {programdata.length > 1 ? (
        <Row>
          <Col xs={10}>
            <h3>
              Programs
            <FontAwesomeIcon className="ml-2" icon={faTasks} size="1x" />{" "}
            </h3>
          </Col>


          <Col xs={1}>{
          <EditProgramData></EditProgramData>
          }
          </Col>
          <Col xs={1}>
            {program1.program_name1 !== "" && (
              <TelstraIcon primary icon="tick" color="green" />
            )}
          </Col>
        </Row>) : ""}

      {programdata.length > 1 ? (<><Program></Program>  <br></br></>) : ""}

      {vcdata.length > 0 ? (
        <div id="view-content">
          <Row>
            <Col xs={11}>
              <h3>
                Streams
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faLayerGroup}
                  size="1x"
                />

                <hr></hr>
                {valueChainName}
              </h3>
            </Col>
            <Col xs={1}>
              {
                <EditValueChainData></EditValueChainData>
              }
            </Col>
          </Row>

          <ValueChain></ValueChain>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default FolderStructure;
