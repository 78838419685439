// 'https://cave-dev.sdpamp.com/backend/api/'
const config = {
  title: "Service Virtualisation",
  color: 'magenta',
  env: (window._env_ && window._env_.ENV) ? window._env_.ENV : "qa",
  api: {
    uri: (window._env_ && window._env_.API_URL) ? window._env_.API_URL : "http://localhost:5000/",
    qa: (window._env_ && window._env_.API_URL_QA) ? window._env_.API_URL_QA : "http://localhost:5000/",
    stage: (window._env_ && window._env_.API_URL_STAGE) ? window._env_.API_URL_STAGE : "http://localhost:5000/",
    prod: (window._env_ && window._env_.API_URL_PROD) ? window._env_.API_URL_PROD : "http://localhost:5000/"
  },
  telstraUrl: (window._env_ && window._env_.SSO_URL) ? window._env_.SSO_URL : "http://testcoe.in.telstra.com.au/authQa?clientId=56086316-b34e-4568-8678-189335576020",
  taasSSO: `${(window._env_ && window._env_.TAAS_SSO_URL) ? window._env_.TAAS_SSO_URL : 'https://auth.sso-dev.taas.telstra-cave.com/saml/login'}?redirect_uri=${window.location.origin}/dashboard`,
  scriptEngineUrl: (window._env_ && window._env_.SCRIPT_ENGINE_URL) ? window._env_.SCRIPT_ENGINE_URL : "https://script-engine.sv.telstra-cave.com/",
  enablePasswordLogin: (window._env_ && window._env_.ENABLE_PASSWORD_LOGIN) ? (window._env_.ENABLE_PASSWORD_LOGIN === 'true') : true,
  enableAccount01Login: (window._env_ && window._env_.ENABLE_SSO_LOGIN) ? (window._env_.ENABLE_SSO_LOGIN === 'true') : true,
  ecs_cpu:{
    qa:"https://chart-embed.service.newrelic.com/herald/84536024-046b-4cac-a1c1-b4b1947a5c70?height=400px&timepicker=true",
    stage:"https://chart-embed.service.newrelic.com/herald/2818f7f0-5a82-4eae-b768-98efbd410ee8?height=400px&timepicker=true",
    prod:"https://chart-embed.service.newrelic.com/herald/97ffad8b-b469-42bf-b482-9a1cccd26f06?height=400px&timepicker=true"
  },
  ecs_memory:{
    qa:"https://chart-embed.service.newrelic.com/herald/b7929010-2211-446b-a882-fc41db9fe45d?height=400px&timepicker=true",
    stage:"https://chart-embed.service.newrelic.com/herald/cf1eb05e-635d-4f26-b945-57294413ba3c?height=400px&timepicker=true",
    prod:"https://chart-embed.service.newrelic.com/herald/cb1646a6-9517-4a7e-87cb-457679b8880b?height=400px&timepicker=true"
  },
  alb_no_of_req_1:{
    qa:"https://chart-embed.service.newrelic.com/herald/680de31e-de89-4761-9cf2-8a41c56e1cd0?height=400px&timepicker=true",
    stage:"https://chart-embed.service.newrelic.com/herald/211041c6-7d25-4202-bdf3-3d9c90b86c7d?height=400px&timepicker=true",
    prod:"https://chart-embed.service.newrelic.com/herald/150c55d8-c47b-435b-a622-7c244ff1f368?height=400px&timepicker=true"
  },
  alb_no_of_req_2:{
    qa:"https://chart-embed.service.newrelic.com/herald/f1ab66f5-9436-4063-ab8f-0d186b69aa0f?height=400px&timepicker=true",
    stage:"https://chart-embed.service.newrelic.com/herald/60d3879c-3578-4678-904f-d877cbbadab4?height=400px&timepicker=true",
    prod:"https://chart-embed.service.newrelic.com/herald/348f9604-b4de-4bf7-b7ef-55bb2184e9e3?height=400px&timepicker=true"
  },
  alb_backend_res_time:{
    qa:"https://chart-embed.service.newrelic.com/herald/75ac40aa-5e15-481e-a8b3-6d6774aa1a26?height=400px&timepicker=true",
    stage:"https://chart-embed.service.newrelic.com/herald/1bdcf3b7-fe32-4a51-bb15-9c3d9e7f210f?height=400px&timepicker=true",
    prod:"https://chart-embed.service.newrelic.com/herald/ca974f73-944c-498f-a28d-ebff26c228f6?height=400px&timepicker=true"
  },
  http_req_error:{
    qa:"https://chart-embed.service.newrelic.com/herald/acd8d9a3-3cdb-4b6c-a939-003cc58b846a?height=400px&timepicker=true",
    stage:"https://chart-embed.service.newrelic.com/herald/7cecde86-0cab-4312-bc80-88361aaabda1?height=400px&timepicker=true",
    prod:"https://chart-embed.service.newrelic.com/herald/195d5860-f95b-4fcf-89b4-64a11f1181cc?height=400px&timepicker=true"
  },
  lambda_invocation:{
    qa:"",
    stage:"",
    prod:"https://chart-embed.service.newrelic.com/herald/e4c27eb5-077a-4a0c-bc98-009a7e2583e4?height=400px&timepicker=true"
  },
  lambda_error:{
    qa:"",
    stage:"",
    prod:"https://chart-embed.service.newrelic.com/herald/e85a75e8-1810-4f36-81e5-6c55f82e61bc?height=400px&timepicker=true"
  }
};

export default {
  ...config
}
