import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { TelstraIcon } from '../../common/icons/GradientIcons';
import { Container, Row, Col, Button, Input, Spinner } from 'reactstrap';
import LibraryService from '../../../services/library.service';
import { getUserDetails } from '../../../services/authentication';
import {
  getDetailedDataUserNewFormat,
  getDetailedDataAdminNewFormat,
} from '../../../services/folderStructure.service';
import ViewSimulation from '../../VirtualService/VirtualService';
import Checkout from './Checkout';
import MaterialTable from 'material-table';
import { Toast } from '../../utils/swal';
import errorResponses from '../../ErrorHandling/errorResponses';
import { useHistory } from 'react-router-dom';
const MySwal = withReactContent(Swal);

const options_map_default = [
  {
    description: '',
    program_name: '--All--',
    program_id: '0',
    valuechains: [
      {
        valuechain_code: '',
        valueChain_name: '--All--',
        valueChain_id: '0',
        applications: [
          {
            application_name: '--All--',
            application_code: '',
            application_id: '0',
            app_icon: '',
            teams: [
              {
                team_id: '0',
                team_name: '--All--',
              },
            ],
          },
        ],
      },
    ],
  },
];

export default function Library(props) {
  let history = useHistory();

  const [queryParameter, setQueryParameter] = useState('');
  const [selectedProgramData, setSelectedProgramData] = useState(
    options_map_default[0]
  );
  const [selectedValueChain, setSelectedValueChain] = useState(
    options_map_default[0].valuechains[0]
  );
  const [selectedApplication, setSelectedApplication] = useState(
    options_map_default[0].valuechains[0].applications[0]
  );
  const [selectedTeam, setSelectedTeam] = useState(
    options_map_default[0].valuechains[0].applications[0].teams[0]
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [teamsHierarchy, setTeamsHierarchy] = useState({
    programs: options_map_default,
    code: 200,
  });
  const [userTeamsHierarchy, setUserTeamsHierarchy] = useState('');



  const columns = [
    {
      title: 'Name',
      field: 'stub_name',
    },
    {
      title: 'Status',
      field: 'deployment_status',
    },
    {
      title: 'POC/ FTL',
      field: 'email'
    },
    {
      title: 'Team',
      field: 'team_name',
    },
    {
      title: 'Application',
      field: 'application_name',
    },
    {
      title: 'Value Chain',
      field: 'valueChain_name',
    },
    {
      title: 'Program',
      field: 'program_name',
    },
    {
      title: 'Last Updated On',
      field: 'updated_datetime',
      type: 'date',
    },
    {
      title: 'Created On',
      field: 'created_datetime',
      type: 'date',
      defaultSort: 'desc',
    },
  ];
  const actions = [
    {
      icon: 'description',
      iconProps: {
        color: 'secondary',
      },
      tooltip: 'View Virtual Service',
      position: 'row',
      onClick: (event, rowData) => onViewClick(rowData),
    },
  ];

  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState();
  const [searchInput, setSearchInput] = useState(''); // Define state for the input value
  const [searchMode, setSearchMode] = useState('startsWith'); // Define state for the input value

  //const [isEndPointExist, setIsEndPointExist] = useState();

  let checkout_key = 0;

  const handleSelectButtonClick = async () => {
    try {
      setIsFetching(true);
      console.log('Search Input:', searchInput); // Use the state variable to get the input value
      let query_params = {};
      query_params.search = searchInput;
      query_params.searchMode = searchMode;
      let response = await LibraryService.searchStubs(query_params);
      if (response.data.length == 0) {
        Toast.fire({
          icon: 'warning',
          title: 'No virutal services found',
        });
      }
      setData(response.data);
      setIsFetching(false);
    } catch (error) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR,
        },
      });
    }
  };

  const getStubDuplicatEndpointDetails = async (selectedDetails) => {
    try {
      setIsFetching(true);

      let query_params = {};

      setQueryParameter(query_params);
      let response = await LibraryService.getStubs(query_params);
      if (response.data.length == 0) {
        Toast.fire({
          icon: 'warning',
          title: 'No virutal services found',
        });
      }
      const filterData = response.data.find((stubDetail) => stubDetail.s3_path === selectedDetails.s3_path);
      filterData ? setData([filterData]) : setData([]);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR,
        },
      });
    }
  };

  const onViewClick = async (rowData) => {
    Swal.fire({
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      onDestroy: () => {
        // This function triggers when the next Swal (that opens the actual view virtual service) opens
      },
    });
    MySwal.fire({
      html: <ViewSimulation
        location={{ pathname: '', state: { record: rowData, team_Application_id: rowData.team_application_id } }}
      ></ViewSimulation>,
      confirmButtonText: 'Close',
      width: 1200,
      padding: '2em',
    }).then((result) => {
      if (result.value) {
        // This executes on swal 'Ok'
        // If you want to refresh material-table data based on this swal closure, start here
        // https://material-table.com/#/docs/features/remote-data
      }
    });
  };

  const handleSwalClose = () => {
    MySwal.close();
  };

  const handleCheckoutButtonClick = async () => {
    MySwal.fire({
      html: (
        <Checkout
          selectedRows={selectedRows}
          userTeamsHierarchy={userTeamsHierarchy}
          setUserTeamsHierarchy={setUserTeamsHierarchy}
          key={++checkout_key}
          handleSwalClose={handleSwalClose}
          loadData={true}
        ></Checkout>
      ),
      // title: 'Choose Destination',
      showConfirmButton: false,
      // confirmButtonText: 'Check-In',
      width: 500,
      padding: '1em',
    }).then((result) => {
      if (result.value) {
        // This executes on swal 'Ok'
        // If you want to chosen manage virtual services on checkin completion, here is the place to do that
      }
    });
    // Call the copy function based on options
  };
  const getData = async () => {
    try {
      let userInfo = await getUserDetails();
      let newTeamsHierarchy = await getDetailedDataAdminNewFormat();
      setTeamsHierarchy(newTeamsHierarchy.data);
      if (userInfo.role === 'support') {
        setUserTeamsHierarchy(newTeamsHierarchy.data);
      } else {
        let detailedUserData = await getDetailedDataUserNewFormat();
        setUserTeamsHierarchy(detailedUserData.data);
      }
      if (queryParameter != '') {
        // Why are we doing this?
        let response = await LibraryService.getStubs(queryParameter);
        if (response.data.length >= 0) {
          // Do you need to do something if no stubs are returned for that filter?
        }
        setData(response.data);
      }
    } catch (error) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR,
        },
      });
    }
  };

  return (
    <div>
      <Container>
        <div className='whiteTable mt-3'>
          <Row className='mt-3'>
            <Col xs={11}>
              <h1>Virtual Services Library</h1>
            </Col>
            <Col xs={1}>
              <TelstraIcon primary icon='sale' color='blue' />
            </Col>
          </Row>
          <hr></hr>
          <Row
            xs='3'
            className='d-flex align-items-center'
            style={{ marginTop: '2vh', marginLeft: '1vh', bottom: '0' }}
          >
            <Col xs='3'>
              <Input
                type='select'
                size='lg'
                onChange={(e) => setSearchMode(e.target.value)}
                name='search_mode'
                defaultValue='startsWith'
              >
                <option key={0} value="startsWith">Starts With</option>
                <option key={1} value="includes">Includes</option>
              </Input>
            </Col>

            <Col xs='6'>
              <Input
                type='text'
                size='lg'
                name='search_input'
                placeholder='Search'
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)} // Update state on input change
              >
              </Input>
            </Col>
            <Col className='p-0' xs='3'>
              <Button
                color='primary'
                size='lg'
                active={true}
                onClick={async () => handleSelectButtonClick()}
              >
                Search
              </Button>
            </Col>
          </Row>
          <hr></hr>
          <Col className='text-center'>
            {isFetching && <Spinner className='p-5' color='primary' />}
          </Col>

          {data !== undefined && !isFetching && (
            <>
              <MaterialTable
                style={{ boxShadow: 'none', width: '100%', display: 'block', marginTop: '3vh' }}
                title=''
                columns={columns}
                data={data}
                actions={actions}
                options={{
                  selection: true,
                  headerStyle: {
                    fontWeight: 'bold',
                    backgroundColor: '#bdbdbd',
                  },
                  actionsColumnIndex: -1,
                }}
                onSelectionChange={(rows) => setSelectedRows(rows)}
              />
              <Row style={{ marginTop: '1vh', bottom: '0' }}>
                <Col>
                  <Button
                    className={classNames('float-right')}
                    color='primary'
                    active={selectedRows.length === 0 ? true : false}
                    disabled={selectedRows.length === 0 ? true : false}
                    onClick={async () => handleCheckoutButtonClick()}
                  >
                    Checkout
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Container>
    </div>
  );
}
