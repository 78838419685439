import React, { memo, useState, useContext, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';
import { nodeColors } from '../../../assets/colors';
import { GlobalContext } from "../../../context/GlobalState";
import { AddToolTip } from "../../common/custom/AddToolTip";
import { Verbs } from "../../../services/verbiage";

import _ from "lodash";
import { useHistory } from "react-router-dom";

import errorResponses from "../../ErrorHandling/errorResponses";
import ReplaceToggleURL from "./ReplaceToggleURL"
import EditIcon from '@material-ui/icons/Edit';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export default memo(({ data }) => {
    const [isChecked, setIsChecked] = useState(false);
    let history = useHistory();

    const {
        cjvElements, setCjvElements
    } = useContext(GlobalContext);



    const getbgColor = () => {
        try {
            let bgColor;
            if (data.source) {
                if (data.stubId) {
                    // if stubbed endpoint
                    if (isChecked)
                    // it means if it is a stubbed endpoint
                    {
                        bgColor = nodeColors.stubEndpoint

                    }
                    else {
                        bgColor = nodeColors.realEndpoint
                    }
                }
                else {
                    bgColor = nodeColors.realEndpoint
                }
            }
            else {
                bgColor = nodeColors.defaultNode
            }
            return bgColor
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: `${errorResponses.GENERIC_ERROR}. ${error}`
                }
            });
        }
    }
    const isStubBeingDisplayed = (dataHttpUrl) => {
        try {
            // determines whether to display stub data or real endpoint data 
            let cjVFileMapper = _.cloneDeep(cjvElements.cjvMapper);
            let returnValue = false;
            if (data.stubId) {
                // if originally a stubbed endpoint, and if replacement is true then dont display a name
                if (cjVFileMapper.hasOwnProperty(data.httpURL) && typeof data.httpURL !== 'undefined') {
                    returnValue = true;

                }
                else {
                    returnValue = true
                }
            }
            return returnValue
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: `${errorResponses.GENERIC_ERROR}. ${error}`
                }
            });
        }
    }
    const gethttpURL = (dataHttpUrl) => {
        try {
            let cjVFileMapper = _.cloneDeep(cjvElements.cjvMapper);

            if (cjVFileMapper.hasOwnProperty(dataHttpUrl) && typeof dataHttpUrl !== 'undefined') {
                if (cjVFileMapper[dataHttpUrl].replace === true) {
                    return cjVFileMapper[dataHttpUrl].replaceHTTPURL
                }
                else {
                    return dataHttpUrl
                }
            }
            else {
                return dataHttpUrl
            }
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: `${errorResponses.GENERIC_ERROR}. ${error}`
                }
            });
        }
    }
    const getNodeFieldData = (fieldData) => {
        let returnValue = ''
        if (fieldData.length > 20) {
            returnValue = fieldData.substring(0, 18) + '...';
        }
        else {
            returnValue = fieldData
        }
        return returnValue
    }

    useEffect(() => {

        try {
            let mounted = true
            if (mounted) {
                if (data.stubId) {
                    let cjVFileMapper = _.cloneDeep(cjvElements.cjvMapper);
                    if (cjVFileMapper.hasOwnProperty(data.httpURL)) {
                        if (cjVFileMapper[data.httpURL].replace === true) {
                            // isChecked false means real endpoint displayed
                            setIsChecked(false)
                        }
                        else {
                            setIsChecked(true)
                        }
                    }
                    else {
                        setIsChecked(true)
                    }
                }
            }
            return function cleanup() {
                mounted = false
            }

        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: `${errorResponses.GENERIC_ERROR}. ${error}`
                }
            });
        }
    }, [])

    const closeDialog = () => {
        MySwal.close();
    }
    const setCjvElementsMethod = (cjvData) => {
        setCjvElements(cjvData)
    }
    const setCheckedMethod = (checkedData) => {
        setIsChecked(checkedData)
    }
    const togglePopup = () => {
        MySwal.fire({
            title: 'Toggle URL',
            width: 600,
            // text: 'You are about to delete the virtual service',
            html: <ReplaceToggleURL
                endpointMethod={data.endpointMethod}
                endpointPath={data.endpointPath}
                closeDialog={closeDialog}
                setCheckedMethod={setCheckedMethod}
                cjvElementsCloneData={_.cloneDeep(cjvElements)}

                setCjvElementsMethod={setCjvElementsMethod}
                data={data}></ReplaceToggleURL >,
            showCancelButton: false,
            showConfirmButton: false


        })
    }
    return (
        < div
            className="parentDefaulutNode1"
            style={{
                border: '1px solid #777', width: '11vw', height: '6vh', backgroundColor: getbgColor(),
                borderRadius: '4px', color: 'white'
            }}
        >
            {data.source && data.stubId ? (
                <div
                    style={{ width: '100%' }}
                >
                    <AddToolTip placement="top-start" title={Verbs.title.CJV.toggle}>



                        <EditIcon
                            className="hoverClass1 float-right"
                            bsSize='sm'
                            style={{ 'fontSize': '12px' }}

                            onClick={togglePopup}
                        />

                    </AddToolTip>
                </div>) : null
            }
            <div className="parentDefaulutNode" style={{
                fontSize: '7px',
                justifyContent: 'center', alignContent: 'center',
                marginLeft: 'auto', marginRight: 'auto'
            }}>


                <AddToolTip placement="top-start" title={isStubBeingDisplayed() ? (data.stubName) : (gethttpURL(data.httpURL)) || 'N/A'}>
                    <div>
                        <span style={{

                        }}>{isStubBeingDisplayed() ? 'Stub Name: ' : 'URL: '}</span>   {isStubBeingDisplayed() ? getNodeFieldData(data.stubName) : getNodeFieldData(gethttpURL(data.httpURL)) || 'N/A'}
                    </div>
                </AddToolTip>


                <AddToolTip placement="top-start" title={isStubBeingDisplayed() ? (

                    (data.endpointMethod || "") + (" ") + (data.endpointPath || "")) : 'N/A'} >
                    <div>
                        <span >{'Stub Endpoint:'}</span>  {isStubBeingDisplayed() ? <span>{getNodeFieldData(

                            (data.endpointMethod || "") + (" ") + (data.endpointPath || "")
                        )}</span> : 'N/A'}
                    </div>
                </AddToolTip>


                <div

                    style={{

                        visibility: isStubBeingDisplayed() && data.source ? 'visible' : 'hidden'

                    }}
                >
                    <AddToolTip placement="top-start" title={isStubBeingDisplayed() ?

                        (gethttpURL(data.httpURL) || "") : 'N/A'} >
                        <div>
                            <span >{'URL:'}</span>  {isStubBeingDisplayed() ? <span>{getNodeFieldData(

                                (gethttpURL(data.httpURL) || "")
                            )}</span> : 'N/A'}
                        </div>
                    </AddToolTip>

                </div>
            </div>

            {data.source ? <Handle
                type="target"
                position="left"

            /> : null}

            <Handle
                type="source"
                position="right"
                id="a"
            />

        </div>
    );
});