import CommonService from './common.service';

function getStubs(params) {
  const api = CommonService.getApiUrl("stubs");
  return CommonService.httpGet(api, params);
}

function searchStubs(params) {
  const api = CommonService.getApiUrl("search-stubs");
  return CommonService.httpGet(api, params);
}

function checkinVirtualServices(virtualServices, target, env = null) {
  let api;
  if(env){
     api = CommonService.getEnvApiUrl("stubs", env);
  } else {
     api = CommonService.getApiUrl("stubs");
  }
  const data = {
    virtualServices: virtualServices,
    target: target
  }
  return CommonService.httpPost(api, data);
}

const LibraryService = {
  getStubs,
  searchStubs,
  checkinVirtualServices,
};

export default LibraryService;
