import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
} from "reactstrap";
import classNames from 'classnames';
import RequestTeamsAccess from "./RequestTeamAccess";
import WorkspaceAccessRequests from "./WorkspaceAccessRequests";
import WorkspaceAccessApproval from "./WorkspaceAccessApproval";
import accessManageService from "../../../services/accessManagement.service";
import workspaceAccessManagementService from "../../../services/workspaceAccessManagement.service";
import errorResponses from "../../ErrorHandling/errorResponses";


const WorkspaceAccessManagement = () =>{

    const [openDialog ,  setOpenDialog] = useState(false);
    const [isAdmin , setIsAdmin] = useState(false);
    const [accessRequestList ,setAccessRequestList] = useState([]);
    const [activeTab, setActiveTab] = useState("1");
    const [tabs , setTabs] = useState([]);
    let history = useHistory();

    const requestTeamsAccess = (reqParams) => {
      workspaceAccessManagementService.requestWorkspaceAccess(reqParams)
        .then(res=>{
            Swal.fire("Success", res.data.message, "success");
            setOpenDialog(false)
            getWorkspaceAccessRequests();
        })
        .catch(err => {
            Swal.fire("Error",err.response.data , "error");
            history.push({
              pathname: "errorPage",
              state: {
                errorMessage: errorResponses.GENERIC_ERROR
              }
            });
        })
    }

    const getWorkspaceAccessRequests = () =>{
      workspaceAccessManagementService.getWorkspaceAccessRequests()
        .then(response => {
            if(response.data.code === 200 && response?.data?.data?.length ){
                setAccessRequestList (response.data.data)
            }
            else setAccessRequestList([]);
        })
        .catch(error => {
            Swal.fire("Error", "No response due to internal error", "error");
            history.push({
              pathname: "errorPage",
              state: {
                errorMessage: errorResponses.GENERIC_ERROR
              }
            });
        });
    }


    const getUserRole=async ()=>{
      accessManageService.isFtlOrAdmin()
      .then(response=>{
        if(response?.data?.data){
          if(response?.data?.data?.role === 'ADMIN'){
            setTabs([{ tabName : 'Access Approvals' , tabCode : '1'}]);
            setIsAdmin(true);
            setActiveTab("1");
          }
          else if(response?.data?.data?.role !== 'FTL'){
            setTabs([{ tabName : 'Access Request' , tabCode : '0'}]);
            setActiveTab("0");
          }
          else {
            setTabs([
              { tabName : 'Access Approvals' , tabCode : '1'},
              { tabName : 'Access Request' , tabCode : '0'}
            ]);
            setActiveTab("1");
          }
        }
      })
      .catch(err=>{
        history.push({
          pathname: "errorPage",
          state: {
            errorMessage: errorResponses.GENERIC_ERROR
          }
        });
      })
      
    }

    const toggleActiveTab = (tab) => {
      if(tab !== activeTab){
        setActiveTab(tab);
        if(tab === "0" ){
          getWorkspaceAccessRequests();
        }
      }
    }

    const renderTabContent = () => {
      switch (activeTab) {
        case "0":
          return <WorkspaceAccessRequests
              requestList = {accessRequestList}
            />;
        case "1":
          return  <WorkspaceAccessApproval/>;
      
        default:
          return null;
      }
    }


    useEffect(()=>{
      getUserRole();
      getWorkspaceAccessRequests();
    },[])


    return (
        <Container>
            <div className='white mt-3'>
                <h2 style={{ marginTop: '7vh' }}>Workspace Access Management</h2>
                <hr />
                <Row
                    style={{
                    marginTop: '2vh',
                    zIndex: 1000,
                    }}
                >
                  {!isAdmin && <Col >
                      <Button
                          style={{ marginRight: '1vw' }}
                          color="primary"
                          className={classNames(
                          'btn',
                          'btn-md',
                          'ld-ext-right',
                          'float-right'
                          )}
                          onClick={()=>{setOpenDialog(true)}}
                      >
                          Request New Teams Access
                      </Button>
                      <RequestTeamsAccess
                        openDialog = {openDialog}
                        setOpenDialog = {setOpenDialog}
                        requestTeamsAccess = {requestTeamsAccess}
                      />
                  </Col>}
                </Row>

                <Nav tabs className="mt-3">
                  {tabs.map(tab=>{
                    return <NavItem className="border-top border-right border-left rounded">
                      <NavLink
                        className={classNames({ active: activeTab === tab.tabCode })}
                        onClick={() => {
                          toggleActiveTab(tab.tabCode);
                        }}
                      >
                        {tab.tabName}
                      </NavLink>
                    </NavItem>;
                  })}
                </Nav>
                {renderTabContent()}
            </div>
        </Container>
    )
}

export default WorkspaceAccessManagement;