import React, { useEffect, useState } from 'react';

const StatusWithTimer = ({ deploymentStatus, updatedDatetime, isDeployed }) => {
  const isStatusRelevant = ["Deployed", "Ready For Deployment"].includes(deploymentStatus) && isDeployed;
  const updatedTime = new Date(updatedDatetime);
  const timeDiff = (Date.now() - updatedTime.getTime()) / 1000; // in seconds
  const initialCounter = Math.max(60 - Math.floor(timeDiff), 0);
  const [counter, setCounter] = useState(initialCounter);

  const getDeployedVSClassNames = (deploymentStatus) => 
    deploymentStatus === "Deployed" ? "font-weight-bold" : "font-weight-normal";

  useEffect(() => {
    let timer;
    if (isStatusRelevant && counter > 0) {
      timer = setInterval(() => {
        setCounter((prev) => {
          if (prev > 1) return prev - 1;
          clearInterval(timer);
          return 0;
        });
      }, 1000);
    }
    return () => clearInterval(timer); // Cleanup
  }, [counter, isStatusRelevant]);

  let displayText;
  if (isStatusRelevant && counter > 0) {
    if (deploymentStatus.toLowerCase() === 'deployed' && isDeployed) {
      displayText = `Deploying (${counter}s)`;
    } else if (deploymentStatus.toLowerCase() === 'ready for deployment' && isDeployed) {
      displayText = `Undeploying (${counter}s)`;
    }
  } else {
    displayText = deploymentStatus; // Show final status
  }

  return <span className={getDeployedVSClassNames(deploymentStatus)}>{displayText}</span>;
};

export default StatusWithTimer;
