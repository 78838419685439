import React from 'react';
import './UsageBilling.css';
import { CardTitle, Card } from "reactstrap";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

const UsageTimeSelect = (props) => {

    // onChangeGetDateFormat

    const handleOnChange = (dateValue) => {
        props.onDateChange(dateValue)
    }

    return (
        <Card className="ssp-columnBorder" >
            <CardTitle
                className="mx-auto"
                style={{
                    fontSize: 20, fontWeight: 'bold', marginTop: 20,
                    // marginLeft: 100, marginRight: 10
                }}>
                Usage Filter
            </CardTitle>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} >
                <KeyboardDatePicker
                    value={props.dateSelect}
                    onChange={(event, value) => {
                        handleOnChange(value)
                    }}
                    label="Choose Month/Year"
                    animateYearScrolling='true'
                    onError={console.log}
                    disableFuture='true'
                    format='YYYY-MM'
                    views={["year", "month"]}
                    openTo="month"

                    style={{ marginLeft: 30, marginTop: 10, marginRight: 30 }}
                // onAccept = { dateTime => {
                //     if(dateTime > new Date(Date.now())){
                //         Swal.fire('Selected Time is in Future', 'Please select the appropriate time', 'error')
                //         .then(value => {
                //             handleOnChange('start', new Date(Date.now()))
                //         }) 
                //     }
                //     if(dateTime > new Date(props.states.endDate)){
                //         Swal.fire('Start Time cannot be greater than the End Time', 'Please select the appropriate time', 'error')
                //         .then(value => {
                //             handleOnChange('start', new Date(props.states.startDate))
                //         })
                //     }
                //     props.handleDateView('')
                // }}  
                />

            </MuiPickersUtilsProvider>
        </Card>
    )
}

export default UsageTimeSelect;