import React from "react";
import DashboardCard from "../../DashboardNew/Cards/DashboardCard";

export const AccessManagementCard = () => (
  <DashboardCard
    title={"Manage Access"}
    subtitle="Get access to another stream."
    icon=""
    color="green"
    to="accessmanagement"
  />
);

export default AccessManagementCard;
