import React, { useEffect, useState } from 'react';
import { Pasmat } from '@COE-Telstra/pasmat-ui_coe'
import pasmatService from '../../services/pasmat.service';
import { useHistory } from "react-router-dom";
import errorResponses from '../ErrorHandling/errorResponses';
import Swal from 'sweetalert2';

const PasmatSurvey = (props) => {

    let history = useHistory();

    const [pasmatQuestions, setPasmatQuestions] = useState([])

    const pasmatAnswers = (props.location.state.pasmatAnswers);

    const onSubmit = (answers) => {
        pasmatService.insertPasmatAnswers(answers, props.location.state.team_data.team_id, props.location.state.role).then(() => {
            //redirect to report page on success
            history.push({
                pathname: 'pasmatReport',
                state: {
                    team_data: props.history.location.state.team_data,
                    role: props.history.location.state.role
                }
            })
        })
            .catch(error => {
                history.push({
                    pathname: 'pasmatUI',
                })
                Swal.fire("Error", "No response due to internal error", "error");
            })
    }
    const setData = async () => {
        try {
            let questions = await pasmatService.getQuestions();
            if (questions.data.pages.length > 0) {
                setPasmatQuestions(questions.data.pages);
            }
        }
        catch (error) {
            history.push({
                pathname: 'errorPage',
                state: {
                    errorMessage: errorResponses.GENERIC_ERROR
                },
            });
        }
    }
    useEffect(() => {
        setData()
    }, [])

    return pasmatQuestions.length ? <Pasmat
        onSubmit={onSubmit}
        pasmatQuestions={pasmatQuestions}
        pasmatAnswers={pasmatAnswers}
        hideHeading={false}
        subHeading="Collaborative framework to capture qualitative and quantitative product adoption metrics across software engineering teams."
    /> : [];
};

export default PasmatSurvey;