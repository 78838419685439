import React, { useEffect, useState } from "react";
import {
  TabPane,
  Jumbotron,
  Spinner,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import { Divider, IconButton, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import workspaceSettingsService from "../../services/workspaceSettings.service";
import MaterialTable from "material-table";
import { palette } from "../../assets/colors";

const secretsColumns = [
  { title: "#", render: record => record.tableData.id + 1, width: 20 },
  { title: "Name", field: "secretKey" },
  { title: "Expires in", field: "secretTTLHuman" },
];

const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

export default function SecretsManager({ sVHost, activeTab }) {
  const [secretsNames, setSecretsNames] = useState([]);
  const [secretToAdd, setSecretToAdd] = useState({ name: "", value: "" });
  const [showSecretValue, setShowSecretValue] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const handleShowSecretValueToggle = () => {
    setShowSecretValue(!showSecretValue);
  };
  const [loading, setLoading] = useState(true);
  const makeMessagesDisappearAfterSometime = () => {
    delay(20000).then(() => {
      setSuccessMessage("");
      setApiErrorMessage("");
    });
  };
  const handleSecretToAddChange = (e) => {
    switch (e.target.name) {
      case "addSecretName":
        setSecretToAdd({ ...secretToAdd, name: e.target.value });
        break;
      case "addSecretValue":
        setSecretToAdd({ ...secretToAdd, value: e.target.value });
        break;
      default:
        break;
    }
  };

  const handleAddSecretSubmit = () => {
    if (secretToAdd.name.length && secretToAdd.value.length) {
      workspaceSettingsService
        .addSecretToWorkspace(secretToAdd, sVHost)
        .then(() => {
          updateData();
          setSuccessMessage(
            "Secret added to workspace. You can use the secret using the templating syntax {{getsecret <secret name>}} in actions section of virtual service response."
          );
        })
        .catch((error) => {
          setApiErrorMessage(`Add secret failed. ${error}`);
          updateData();
        });
    } else {
      setShowWarning(true);
    }
  };

  const handleSecretDelete = (rowData) => {
    workspaceSettingsService
      .removeSecretFromWorkspace(rowData.secretKey, sVHost)
      .then(() => {
        setSuccessMessage(`Secret deleted`);
        updateData();
      })
      .catch((error) => {
        setApiErrorMessage(`Secret delete failed. ${error}`);
        updateData();
      });
  };

  const secretsTableActions = [
    {
      icon: "delete",
      iconProps: {
        style: { color: palette.danger },
      },
      tooltip: "Delete",
      position: "row",
      onClick: (event, rowData) => handleSecretDelete(rowData),
    },
  ];

  const updateData = () => {
    setShowWarning(false);
    setLoading(true);
    workspaceSettingsService
      .getSecretsNames(sVHost)
      .then((response) => {
        setSecretsNames(response.data);
        setLoading(false);
        makeMessagesDisappearAfterSometime();
      })
      .catch((error) => {
        setLoading(false);
        setApiErrorMessage(`Error when updating data. ${error}`);
        makeMessagesDisappearAfterSometime();
      });
  };

  const secretManagerFormInputType = () => {
    if (showSecretValue) {
      return "text";
    } else {
      return "password";
    }
  };

  const showOrHidePassword = () => {
    if (showSecretValue) {
      return <IconButton
        aria-label="show secret value"
        component="span"
        className="p-0 pt-1"
        onClick={handleShowSecretValueToggle}
      >
        <Visibility></Visibility>
      </IconButton>
    } else {
      return <IconButton
        aria-label="hide secret value"
        component="span"
        className="p-0 pt-1"
        onClick={handleShowSecretValueToggle}
      >
        <VisibilityOff></VisibilityOff>
      </IconButton>
    }
  }

  useEffect(() => {
    if (activeTab === "3") {
      updateData();
    }
  }, [activeTab]);

  return (
    <>
      <TabPane tabId="3" className="p-3">
        {loading ? (
          <Jumbotron className="text-center">
            <Spinner className="p-5" color="primary"></Spinner>
          </Jumbotron>
        ) : (
          <>
            <div className="m-0">
              <Row className="mt-2">
                <Col>
                  <Typography variant="subtitle1">
                    Store sensitve information such as passwords, API keys and so on for use in virtual service actions.
                    The credentials are auto-deleted if inactive (not used for an extended duration).
                    Suggested way of using this feature is to create the credentials just before using the virtual services (for example, a regression test) and remove the credentials soon after testing is completed.
                  </Typography>
                </Col>
              </Row>
              <Divider className="m-2"></Divider>
              <Typography variant="h6">Add a secret to workspace</Typography>
              <Row className="ml-0 mr-2 mb-2 mt-2">
                <Col xs="auto" className="pl-0 d-flex align-items-center">
                  <Typography variant="subtitle1" className="m-0">
                    Name
                  </Typography>
                </Col>
                <Col className="pl-2">
                  <Input
                    size="sm"
                    name="addSecretName"
                    onChange={handleSecretToAddChange}
                  />
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <Typography variant="subtitle1" className="m-0">
                    Secret value
                  </Typography>
                </Col>
                <Col className="pl-2">
                  <Input
                    size="sm"
                    type={secretManagerFormInputType()}
                    name="addSecretValue"
                    onChange={handleSecretToAddChange}
                  />
                </Col>
                <Col xs="auto" className="p-0">
                  {showOrHidePassword()}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col></Col>
                <Col xs="auto">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => handleAddSecretSubmit()}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="text-center">
                  <Typography
                    variant="body2"
                    color="error"
                    hidden={!showWarning}
                  >
                    <span role="img" aria-label="warning emoji">
                      ⚠️
                    </span>{" "}
                    Provide non-empty values for secret name and value to
                    proceed.
                  </Typography>
                </Col>
              </Row>
            </div>
            <Divider className="mb-1 mt-0"></Divider>
            <Row className="mt-2 mb-1">
              <Col>
                <Typography
                  variant="body2"
                  className="text-success"
                  hidden={!successMessage}
                >
                  <span role="img" aria-label="check mark emoji">
                    ✔️
                  </span>{" "}
                  {successMessage}
                </Typography>
                <Typography
                  variant="body2"
                  color="error"
                  hidden={!apiErrorMessage}
                >
                  <span role="img" aria-label="warning emoji">
                    &#9888;&#65039;
                  </span>{" "}
                  {apiErrorMessage}
                </Typography>
              </Col>
            </Row>
            <MaterialTable
              columns={secretsColumns}
              title={<Typography variant="h6">{`Secrets`}</Typography>}
              data={secretsNames}
              actions={secretsTableActions}
              options={{
                headerStyle: {
                  fontWeight: "bold",
                  backgroundColor: palette.blueseq[3],
                },
                paging: secretsNames.length > 5,
                actionsColumnIndex: -1,
              }}
            />
            <Typography variant="body2" className="mt-2 mb-2" color="primary">
              Disclaimer: Secrets will be removed automatically when no access
              activity is observed for few weeks.
            </Typography>
          </>
        )}
      </TabPane>
    </>
  );
}
