import React, { useEffect } from 'react';

import { Row, Col, Button, Container, Nav, NavItem, NavLink } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import accessManageService from '../../../services/accessManagement.service';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import WorkspaceAccessRequests from '../WorkspaceAccessManagment/WorkspaceAccessRequests';
import WorkspaceAccessApproval from '../WorkspaceAccessManagment/WorkspaceAccessApproval';
import RequestTeamsAccess from '../WorkspaceAccessManagment/RequestTeamAccess';
import workspaceAccessManagementService from '../../../services/workspaceAccessManagement.service';
import errorResponses from "../../ErrorHandling/errorResponses";
import Swal from "sweetalert2";
import ManageUsers from './manageUsers';
import AdminApproval from '../Request/AdminApproval';

export const AccessManagement = () => {
  const { useState } = React;
  let history = useHistory();
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [accessRequestList, setAccessRequestList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const getTeamAccessRequests = () => {
    workspaceAccessManagementService.getWorkspaceAccessRequests()
      .then(response => {
        if (response.data.code === 200 && response?.data?.data?.length) {
          setAccessRequestList(response.data.data)
        }
        else setAccessRequestList([]);
      })
      .catch(error => {
        Swal.fire("Error", "No response due to internal error", "error");
        history.push({
          pathname: "errorPage",
          state: {
            errorMessage: errorResponses.GENERIC_ERROR
          }
        });
      });
  }

  const fetchUserRole = async () => {
    try {
      let response = await accessManageService.isFtlOrAdmin();
      if (response?.data?.data) {
        if (response?.data?.data?.role === 'ADMIN') {
          setTabs([{ tabName: 'Manage Users', tabCode: '1' },
          { tabName: 'User Approval', tabCode: '2' },
          { tabName: 'Team Access Requests', tabCode: '3' },]);
          setIsAdmin(true);
          setActiveTab("1");
        }
        else if (response?.data?.data?.role !== 'FTL') {
          setTabs([{ tabName: 'Access Request Status', tabCode: '4' }]);
          setActiveTab("4");
        }
        else {
          setTabs([
            { tabName: 'Manage Users', tabCode: '1' },
            { tabName: 'User Approval', tabCode: '2' },
            { tabName: 'Team Access Requests', tabCode: '3' },
            { tabName: 'Access Request Status', tabCode: '4' },
          ]);
          setActiveTab("1");
        }
      }
    }
    catch (error) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        },
      });
    }
  };

  useEffect(() => {
    fetchUserRole();
    getTeamAccessRequests();
  }, []);

  const toggleActiveTab = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case "1":
        return <ManageUsers />
          ;
      case "2":
        return <AdminApproval />;
      case "3":
        return <WorkspaceAccessApproval />;
      case "4":
        return <WorkspaceAccessRequests
          requestList={accessRequestList}
        />;
      default:
        return null;
    }
  }

  const requestTeamsAccess = (reqParams) => {
    workspaceAccessManagementService.requestWorkspaceAccess(reqParams)
      .then(res => {
        Swal.fire("Success", res.data.message, "success");
        setOpenDialog(false)
        getTeamAccessRequests();
      })
      .catch(err => {
        Swal.fire("Error", err.response.data, "error");
        history.push({
          pathname: "errorPage",
          state: {
            errorMessage: errorResponses.GENERIC_ERROR
          }
        });
      })
  }

  return (
    <Container>
      <div className='white mt-3'>
        <h2>Access Management</h2>
        <hr />
        <Row
          style={{
            marginTop: '2vh',
            zIndex: 1000,
          }}
        >
          {!isAdmin && <Col >
            <Button
              style={{ marginRight: '1vw' }}
              color="primary"
              className={classNames(
                'btn',
                'btn-md',
                'ld-ext-right',
                'float-right'
              )}
              onClick={() => { setOpenDialog(true) }}
            >
              Request New Teams Access
            </Button>
            <RequestTeamsAccess
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              requestTeamsAccess={requestTeamsAccess}
            />
          </Col>}
        </Row>
        <div>
          <Nav tabs className="mt-3">
            {tabs.map((tab, i) => {
              return <NavItem key={i} className="border-top border-right border-left rounded">
                <NavLink
                  className={classNames({ active: activeTab === tab.tabCode })}
                  onClick={() => {
                    toggleActiveTab(tab.tabCode);
                  }}
                >
                  {tab.tabName}
                </NavLink>
              </NavItem>;
            })}
            {renderTabContent()}
          </Nav>
        </div>
      </div>
    </Container>
  );
};
export default AccessManagement;
