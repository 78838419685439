import axios from "axios";
import {
  DASH_METRICS,
  TESTMAIL_REQUEST
} from "./endpoints";
import CommonService from "./common.service";

import { getToken } from "./authentication";

const handleErrorResponse = (error) => {
  let apiResponse;
  const { response } = error;
  if (response) {
    apiResponse = error;
  }
  return apiResponse;
}

export const getDashboardMetrics = async () => {

  axios.defaults.headers.common = { Authorization: `Bearer ${getToken()}` };
  return await axios
    .get(DASH_METRICS)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return handleErrorResponse(e);
    });
};

export const sendTestMail = async () => {
  axios.defaults.headers.common = { Authorization: `Bearer ${getToken()}` };
  return await axios
    .get(TESTMAIL_REQUEST)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return handleErrorResponse(e);
    });
};

export const getAllPrograms = async () => {
  const api = CommonService.getApiUrl("support/getAllPrograms");
  return CommonService.httpGet(api);
}

export const addOrUpdateProgram = async (params) => {
  const api = CommonService.getApiUrl("support/addOrUpdateProgram");
  return CommonService.httpPost(api, params);
}

export const getAllValueChainsForProgram = async (params) => {
  const api = CommonService.getApiUrl("support/getAllValueChainsForProgram");
  return CommonService.httpGet(api, params);
}
export const addOrUpdateValueChain = async (params) => {
  const api = CommonService.getApiUrl("support/addOrUpdateValueChain");
  return CommonService.httpPost(api, params);
}

export const getAllApplicationsAdmin = async () => {
  const api = CommonService.getApiUrl("support/getAllApplicationsAdmin");
  return CommonService.httpGet(api);
}

export const getAllTeams = async () => {
  const api = CommonService.getApiUrl("support/getAllTeams");
  return CommonService.httpGet(api);
}
export const getAllTeamApplications = async () => {
  const api = CommonService.getApiUrl("support/getAllTeamApplications");
  return CommonService.httpGet(api);
}

export const addOrUpdateApplication = async (params) => {
  const api = CommonService.getApiUrl("support/addOrUpdateApplication");
  return CommonService.httpPost(api, params);
}
export const addOrUpdateTeam = async (params) => {
  const api = CommonService.getApiUrl("support/addOrUpdateTeam");
  return CommonService.httpPost(api, params);
}
export const addTeamApplications = async (params) => {
  const api = CommonService.getApiUrl("support/addTeamApplications");
  return CommonService.httpPost(api, params);
}

export const downloadComponent = async (component) => {
  const api = CommonService.getApiUrl(`downloads/${component}`);
  CommonService.httpGet(api)
    .then((response) => {
      window.location.href = response.data;
    });
}

export const downloadLocalSVZip = async () => {
  const api = CommonService.getApiUrl(`download-local-sv`);
    CommonService.httpGet(api)
        .then((response) => {
            window.location.href = response.data.url;
        });
};
