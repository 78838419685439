import React, { useState, useEffect } from 'react';
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import pasmatService from '../../services/pasmat.service';
import "./pasmat.css"
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import errorResponses from '../ErrorHandling/errorResponses';
import { getUserRolesFromToken } from '../../services/authentication';
import PasmatMainWrapper from './PasmatMainWrapper';
import { CardPose } from '../utils/pose';
import Select from "react-select";



function PasmatUI() {
  let history = useHistory();

  const [currentCycle, setCurrentCycle] = useState({});
  const [isAdmin, setIsAdmin] = useState(false)
  const [teamRole, setTeamRole] = useState('GENERAL');
  const [allTeams, setAllTeams] = useState([]);
  const [allPrograms, setAllPrograms] = useState([]);
  const [pasmatQ, setPasmatQ] = useState([]);
  const [pasmatAnswers, setPasmatAnswers] = useState([]);
  const teamHasAnswers = () => pasmatAnswers.length > 0;
  // it gets data from teamCycles table, and is used to determine whether PASMAT answers
  //  has been approved by admin or not.
  const [reportStatus, setReportStatus] = useState({});
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);



  const isSurveyDisabled = () => {
    return (reportStatus.ftl_response === 1 || reportStatus.admin_approved === 1);
  }

  const isSurveyFTLApprovalDisabled = () => {
    return (reportStatus.admin_approved === 1 || teamRole !== 'FTL');
  }
  const getCurrentCycle = async () => {
    let response = await pasmatService.getCurrentCycle();
    setCurrentCycle(response.data.cycle);
  }

  const getStatus = () => {
    try {
      if (reportStatus.admin_approved === 1) {
        // status = 'Admin approved'
        return <div className="mt-3 mb-3 survey-info">
          <span className="ml-2">
            <FontAwesomeIcon style={{ color: "blue" }} className="mr-2" icon={faInfoCircle} size="sm" />
            Admin has approved your survey for this cycle <b>({currentCycle.cycle_name})</b>.
          </span>
        </div>;
      }
      else if (reportStatus.ftl_response === 1 && teamRole === 'FTL') {
        // status = 'FTL - Awaiting Admin approval'
        console.log("pasmatAnswers are ", pasmatAnswers)
        return <div className="mt-3 mb-3 survey-info">
          <span className="ml-2">
            <FontAwesomeIcon style={{ color: "blue" }} className="mr-2" icon={faInfoCircle} size="sm" />
            FTL submission is completed for this cycle <b>({currentCycle.cycle_name})</b> and admin approval is pending.
            <br /> At this stage you can still edit the survey and resubmit. The survey was last submitted by {pasmatAnswers[0].user}.
          </span>
        </div>
      }
      else if (reportStatus.ftl_response === 1) {
        // status = 'User - Awaiting Admin approval'
        return <div className="mt-3 mb-3 survey-info">
          <span className="ml-2">
            <FontAwesomeIcon style={{ color: "blue" }} className="mr-2" icon={faInfoCircle} size="sm" />
            FTL has submitted the survey for this cycle <b>({currentCycle.cycle_name})</b> and admin approval is pending.
          </span>
        </div>
      }
      else if (reportStatus.ftl_response === 0 && teamHasAnswers()) {
        // means neither ftl or admin has approved
        // status = 'FTL to complete survey'
        return <div className="mt-3 mb-3 survey-info">
          <span className="ml-2">
            <FontAwesomeIcon style={{ color: "blue" }} className="mr-2" icon={faInfoCircle} size="sm" />
            A user has already completed the survey for this cycle <b>({currentCycle.cycle_name})</b> and FTL submission is pending.<br />
            At this stage you can still edit the survey and resubmit. The survey was last submitted by {pasmatAnswers[0].user}.
          </span>
        </div>
      }
      else {
        // status = 'FTL to complete survey'
        return <></>
      }
    }
    catch (error) {
      console.log(error);
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR,
        },
      });
    }
  }

  const getPasmatQuestions = () => {
    pasmatService.getQuestions()
      .then(res => {
        setPasmatQ(res.data.pages);
      })
      .catch(err => {
        setPasmatQ([]);
      })
  }

  const getAllPrograms = async () => {
    try {
      let programsListResponse = []
      programsListResponse = await pasmatService.getAllPrograms();
      setAllPrograms(programsListResponse.data.data.map(program => {
        return { label: program.program_name, value: program.program_id, ...program }
      }));
    } catch (err) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR,
        },
      });
    }
  }

  const getTeamsList = async () => {
    try {
      let allTeamsResponse;
      allTeamsResponse = await pasmatService.getftlOrDelegateTeams();
      setAllTeams(allTeamsResponse.data.teams.map((team) => {
        return { label: team.team_name, value: team.team_id, ...team }
      }));
    }
    catch (err) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR,
        },
      });
    }
  }

  const getPasmatAnswers = async (team) => {
    try {
      let response = await pasmatService.getPasmatAnswers(team.team_id)
      console.log(response);
      if (response.data.pasmatAnswers.length > 0) {
        setPasmatAnswers(response.data.pasmatAnswers)
      }
      else {
        setPasmatAnswers([]);
      }
    }
    catch (err) {
      console.log(err);
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR,
        },
      });
    }

  }

  const getUserReportApprovalStatus = async (team) => {
    try {
      let res = await pasmatService.getReportApprovalStatus(team.team_id)
      if (res.data.reportStatusData.length > 0) {
        setReportStatus(res.data.reportStatusData[0]);
      } else {
        setReportStatus({});
      }
    }
    catch (err) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR,
        },
      });
    }
  }

  const getTeamsByPrograms = async (program_id) => {
    try {
      let allTeamsResponse = await pasmatService.getTeamsByPrograms(program_id);
      setAllTeams(allTeamsResponse.data.data.map((team) => {
        return { label: team.team_name, value: team.team_id, ...team }
      }));
    } catch (err) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR,
        },
      });
    }
  }

  const setTeam = (team) => {
    if (team) {
      setSelectedTeam(team)
      if (teamRole !== 'ADMIN') {
        if (team.role_name) {
          const role = team.role_name === 'DELEGATE' ? 'FTL' : team.role_name;
          setTeamRole(role)
        }
      }
      getPasmatAnswers(team);
      getUserReportApprovalStatus(team);
    }
  }

  const selectProgram = (program) => {
    setSelectedProgram(program);
    getTeamsByPrograms(program.program_id);
  }


  const onComponentMount = async () => {
    try {
      await getCurrentCycle();
      let token = localStorage.getItem("accessToken");
      let userRole = getUserRolesFromToken(token)
      getPasmatQuestions();
      if (userRole === 'support') {
        userRole = 'ADMIN';
        setIsAdmin(true);
        getAllPrograms();
      } else {
        getTeamsList();
      }
      setTeamRole(userRole);
    }
    catch (err) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR,
        },
      });
    }
  }

  useEffect(() => {
    onComponentMount();
  }, [])

  const pasmatTile = (label, icon, pathName, disabled = false) => {
    return (
      <div
        id='view-content'
        className={`tiles-grid flex base-default`}
      >
        <div
          data-size={"small"}
          className={`blue theme-gradient-secondary-primary tile-medium
                     ${"clickable"}`}
          style={{
            opacity: disabled === true ? "0.65" : "1"
          }}
          key={label}
          onClick={() => {
            if (pasmatQ.length > 0) {
              if (disabled !== true) {
                history.push({
                  pathname: pathName,
                  state: {
                    team_data: selectedTeam,
                    role: teamRole,
                    pasmatAnswers: pasmatAnswers
                  }

                })
              }
            }
            else {
              Swal.fire({
                icon: "warning",
                title: "Please ask admin to add PASMAT Questions"
              })
            }
          }}
        >
          {
            <React.Fragment>
              {<span className={`td-icon-md icon-${icon} icon`}></span>}
              <span className="branding-bar">{label}</span>
            </React.Fragment>
          }
        </div>
      </div>
    )
  }

  const renderSelectProgram = () => {
    return <Row className="mb-3">
      <Col xs={4} className="align-items-center offset-4 ">
        <h5 style={{ "margin-bottom": "15px", "text-align": "center", "font-weight": "bold" }}>Please Select a Program</h5>
      </Col>
      <Col xs={4} className="align-items-center offset-4">
        <Select
          isClearable
          placeholder={'Select Program'}
          options={allPrograms}
          onChange={(e) => {
            selectProgram(e)
          }}
          value={selectedProgram}
        />
      </Col>
    </Row>
  }

  const renderSelectTeam = () => {
    if (!isAdmin || (isAdmin && selectedProgram)) {
      return (
        <Row>
          <Col xs="4" className="align-items-center offset-4">
            <h5 style={{ "margin-bottom": "15px", "text-align": "center", "font-weight": "bold" }}>Please Select a Team</h5>
          </Col>
          <Col xs="4" className="align-items-center offset-4">
            <Select
              isClearable
              placeholder={'Select Team'}
              options={allTeams}
              onChange={(e) => {
                console.log("Selected team e", e);
                setTeam(e)
              }}
              value={selectedTeam}
            />
          </Col>
        </Row>
      )
    } else {
      return <></>
    }
  }


  const renderPasmatTiles = () => {
    return (
      <Row>
        {isAdmin === false && teamRole !== 'FTL' ? (
          <div className={`tiles-grid flex ${isSurveyDisabled() ? '' : 'base-blue'}`}>
            <CardPose>
              <div
                data-size="medium"
                className={`tile-medium clickable ${isSurveyDisabled() ? 'bg-gray' : 'blue'} theme-gradient-secondary-primary`}
                style={isSurveyDisabled() ? { cursor: 'default' } : {}}
                onClick={isSurveyDisabled() ? () => { 
                  //do nothing
                } : () => {
                  if (pasmatQ.length > 0) {
                    history.push({
                      pathname: 'pasmatSurvey',
                      state: {
                        team_data: selectedTeam,
                        role: teamRole,
                        pasmatAnswers: pasmatAnswers
                      }

                    })
                  }
                  else {
                    Swal.fire({
                      icon: "warning",
                      title: "Please ask admin to add PASMAT Questions"
                    })
                  }
                }}
              >
                <span
                  className={`td-icon-md icon-add-ons icon`}
                ></span>
                <span className="branding-bar">{teamHasAnswers() ? "Edit Survey" : "Start Survey"}</span>
              </div>
            </CardPose>
          </div>
        ) : null}
        {isAdmin === false ? (
          <div className="ml-3">
            {pasmatTile("View Survey", "telstra", 'viewPasmatSurvey', teamHasAnswers() ? false : true)}
          </div>) : null}
        {(teamRole === "FTL") ?
          (<div className="ml-3">
            {pasmatTile("FTL Approval", "telstra", 'pasmatSurvey', isSurveyFTLApprovalDisabled())}
          </div>) : null}
        {isAdmin ?
          (<div className="ml-3">
            {pasmatTile("Admin Approval", "telstra", 'pasmatSurvey', false)}
          </div>) : null}
        <div className="ml-3">
          {pasmatTile("View Report", "telstra", 'pasmatReport')}
        </div>
        {
          isAdmin &&
          <div className="ml-3">
            {pasmatTile("Trend analysis", "telstra", 'pasmatTrend')}
          </div>
        }

      </Row>
    )
  }

  return (
    <PasmatMainWrapper>
      <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Current Cycle ({currentCycle.cycle_name})</h4>
      {isAdmin ? renderSelectProgram() : []}
      {renderSelectTeam()}
      {selectedTeam && selectedTeam.team_id ?
        <>{isAdmin ? [] : getStatus()}
          <div style={{ padding: '0 2%' }}>
            <Row className="mt-4">
              <Col xs="auto"><h4>Select Action:</h4></Col>
            </Row>
            {renderPasmatTiles()}
          </div></>
        : []}
    </PasmatMainWrapper>
  )
}

export default PasmatUI;