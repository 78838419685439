import axios from 'axios';
import CommonService from './common.service';

import { getToken, getUserDetails } from './authentication';

import {

  FOLDERID_REQUEST,
} from './endpoints';

// Purpose : Keep all forlder structure related methods

export const getUserProgramsList = async () => {
  let user = getUserDetails();
  var params = { user_id: user.id };
  const api = CommonService.getApiUrl('userData/getUserProgramsList');
  return CommonService.httpPost(api , params);
}

export const getProgramStreams =  async (programId) => {
  let user = getUserDetails();
  var params = { user_id: user.id,
                program_id : programId };
  const api = CommonService.getApiUrl('userData/getSelectedProgramStreams');
  return CommonService.httpPost(api , params);
}

export const getStreamApplication =  async (valueChainId) => {
  var params = {valueChain_id : valueChainId };
  const api = CommonService.getApiUrl('support/getStreamApplication');
  return CommonService.httpPost(api , params);
}

export const getFolderIDData = async (params) => {
  let response;
  axios.defaults.headers.common = { Authorization: `Bearer ${getToken()}` };
  try {
    response = await axios.post(FOLDERID_REQUEST, params);
  } catch (error) {
    response = error.response;
  }
  return response.data;
};

export const getDetailedDataUser = async () => {
  let user = getUserDetails();
  var params = { user_id: user.id };
  const api = CommonService.getApiUrl('userData/getUserDetails');
  return CommonService.httpPost(api, params);
};


export const getUserFromEnv = async (env) => {
  let user = getUserDetails();
  if(env){
      let dnumberApi = CommonService.getEnvApiUrl('getUserInfoFromDnumber/' +   user.dnumber, env);
      let userResponse = await CommonService.httpGet(dnumberApi);
      return userResponse.data;
    }  
    return user
};

export const getDetailedDataUserNewFormat = async (env = null) => {
  let user = getUserDetails();
  if(env){
    let params;
    if(env == CommonService.getCurrentEnv()) {
      params = { user_id: user.id };
    } else {
      let dnumberApi = CommonService.getEnvApiUrl('getUserInfoFromDnumber/' +   user.dnumber, env);
      let userResponse = await CommonService.httpGet(dnumberApi);
      params = { user_id: userResponse.data.id };
    }
    const api = CommonService.getEnvApiUrl('userData/getUserDetailsNewFormat', env);
    return CommonService.httpPost(api, params);
  }
  let params = { user_id: user.id };
  const api = CommonService.getApiUrl('userData/getUserDetailsNewFormat');
  return CommonService.httpPost(api, params);
};

// export const getDetailedDataUser = async (userInfo) => {
//TODO change hardcoded value here.
export const getDetailedDataAdminNewFormat = async (env = null) => {
  var params = { user_id: 5 };
  let api;
  if(env){
    api = CommonService.getEnvApiUrl('userData/getUserDetailsNewFormat', env);
  } else {
     api = CommonService.getApiUrl('userData/getUserDetailsNewFormat');
  }
  return CommonService.httpPost(api, params);
};

export const getFormattedProgramData = (userData) => {
  const data = [];

  userData.Programs.map((programDataItem) => {
    var dataObject = {};
    dataObject['program_name'] = programDataItem.program_name;
    dataObject['description'] = programDataItem.description;
    data.push(dataObject);
  });
  return { result: data };
};

export const getFormattedValueChainData = (programDescription, userData) => {
  try {
    var valueChainData = '';
    userData.Programs.map((programItem) => {
      if (programItem.description == programDescription) {
        valueChainData = { data: programItem.valuechains, code: 200 };
      }
    });
    return valueChainData;
  } catch (error) {
    throw new Error('Error');
  }
};

export const getFormattedApplication = (
  userData,
  valueChain_id,
  program_name
) => {
  var result = '';
  try {
    userData.Programs.map((programItem) => {
      if (programItem.program_name == program_name) {
        programItem.valuechains.map((valueChainItem) => {
          if (valueChainItem.vc_id == valueChain_id) {
            result = valueChainItem.applications;
          }
        });
      }
    });
    return { data: result, code: 200 };
  } catch (error) {
    throw new Error('Error');
  }
};

export const getUserTeamsApplications = async (application_id) => {
  try {
    let user = getUserDetails();
    var params = { user_id: user.id, application_id: application_id };
    const api = CommonService.getApiUrl('userData/getTeams');
    return CommonService.httpPost(api, params);
  } catch (error) {
    throw new Error('Error');
  }
};
