import React from 'react'
import { Row, Col } from "reactstrap";
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

const Plot = createPlotlyComponent(Plotly);
export default function GraphsUserReport({ value }) {

  return (
    <div>
      <Row style={{ marginTop: "5vh" }}>
        <Col style={{ marginLeft: "10vw" }}>

          <Plot data={value['avg_res_time']}
            layout={{
              width: "700", height: "500", title: 'Average Response Time',
              xaxis: {
                title: 'Date',
              },
              yaxis: {
                title: 'Average response time(sec)',
              }
            }
            }

          />

        </Col>
      </Row>


      <Row style={{ marginTop: "5vh" }}>
        <Col style={{ marginLeft: "10vw" }}>
          <Plot data={value['cum_req_count']}
            layout={{
              width: "700", height: "500", title: 'Transactions per day',
              xaxis: {
                title: 'Date',
              },
              yaxis: {
                title: 'Number of transactions',
              }
            }}

          />

        </Col>
      </Row>

      <Row style={{ marginTop: "5vh" }}>
        <Col style={{ marginLeft: "10vw" }}>
          <Plot data={value['avg_deployed_endPoints_count']}
            layout={{
              width: "700", height: "500", title: 'Deployed endpoints',
              xaxis: {
                title: 'Date',
              },
              yaxis: {
                title: 'Number of deployed endpoints',
              }
            }}

          />

        </Col>
      </Row>
    </div>
  )
}
