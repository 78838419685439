import React from 'react'
import { Row, Col, Input } from "reactstrap";
import { AddToolTip } from "../../common/custom/AddToolTip";
import { Verbs } from "../../../services/verbiage";
import { InputLabel, Typography } from "@material-ui/core";
import {
  MAX_BODY_SIZE,
} from "../../utils/stubValidators";

export default function ExecuteJS({
  actionData,
  index,
  handleFEPExecuteJSResponseActionsUpdate
}) {
  return (
    // TODO it has to display warnings and that they have to first enable the script engine in workspace settings
    <div>
      <Row className="mt-2">
        <Col>
          <Typography variant="body1"><strong>
            Note: Adding return statement is now mandatory.</strong> <br></br>This feature will work only when scripting engine is enabled for your workspace.
            Scripting engine can be managed in your workspace settings in <i>General</i> tab.
            The script is executed in a <strong>sanboxed NodeJS</strong> environment using the vm2 (npm) library.<br></br>
            <strong>Libraries supported:</strong> 'xmldom'.<br></br>
            Example, const  {'{ '}DOMParser{' }'} = require('xmldom')
          </Typography>
        </Col>
      </Row>
      <Row className="mt-3">
        {/* TODO this would be modified as the actual script to execute */}
        <Col xs="auto" className="d-flex align-items-center">
          <AddToolTip placement="top-start" title={Verbs.title.responseActions.executeJS.script}>
            <InputLabel className="p-0">Script</InputLabel>
          </AddToolTip>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Input
            type="textarea"
            bsSize="sm"
            maxLength={MAX_BODY_SIZE}
            rows="8"
            name="script"
            value={(actionData.script) ? actionData.script : ""}
            onChange={(e) => handleFEPExecuteJSResponseActionsUpdate(e, index)}
          ></Input>
        </Col>
      </Row>


    </div>
  )
}
