/* eslint-disable default-case */
import React, { useState } from "react";
import Switch from "react-switch";

import {
  Row,
  Col,
  Input,
  Card
} from "reactstrap";
import {
  Divider,
  InputLabel,
  IconButton,
  Tooltip,
  Typography,
  Collapse,
} from "@material-ui/core";
import {
  AddCircle,
  RemoveCircle,
  KeyboardArrowRight,
  KeyboardArrowDown,
  FileCopy,
  Reorder
} from "@material-ui/icons";


import { response_action_types } from "../constants";
import { AddToolTip } from "../../common/custom/AddToolTip";
import { Verbs } from "../../../services/verbiage";
import { palette } from "../../../assets/colors";
import HttpForward from "./HttpForward";
import TDMHttpForward from "./TDMHttpForward";
import JMS from "../ResponseActions/JMS";
import Tibco from "../ResponseActions/Tibco";
import Kinesis from "../ResponseActions/Kinesis";
import ExecuteJS from "../ResponseActions/ExecuteJS";
import Database from "./Database";
import IBMMQ from "../ResponseActions/IBMMQ";
import { hasExceededResponseActionRows } from "../../utils/stubValidators";
import SQS from "../ResponseActions/SQS";
import SMTP from "./SMTP";
import Properties from "./Properties";

/**
 * Meta data about the response action which will be included as part of all actions
 * @param {*} param0
 */
export default function ResponseAction({
  actionItem,
  index,
  handleFEPResponseActionsUpdate,
  handleFEPHTTPResponseActionsUpdate,
  handleFEPJMSResponseActionsUpdate,
  handleFEPTibcoResponseActionsUpdate,
  handleFEPKinesisResponseActionsUpdate,
  handleFEPExecuteJSResponseActionsUpdate,
  handleFEPPropertiesResponseActionsUpdate,
  handleFEPDatabaseResponseActionsUpdate,
  handleFEPSQSResponseActionsUpdate,
  handleRemoveResAction,
  handleAddResAction,
  isEditable,
  areFEPResponseActionsEnabled,
  actionsLength,
  mASSLCertAliases,
  isVirtualServiceSubmitted,
  handleReorderResponseActions,
  handleCloneResponseAction,
  handleFEPSMTPResponseActionsUpdate
}) {

  const renderCorrespondingResAction = (action, indexValue) => {
    switch (action.actionType) {
      case "httpForward":
        return (
          <HttpForward
            actionData={action.actionData}
            index={indexValue}
            handleFEPHTTPResponseActionsUpdate={handleFEPHTTPResponseActionsUpdate}
            isEditable={isEditable}
            mASSLCertAliases={mASSLCertAliases}
            isTemplate={action.templated}
            isVirtualServiceSubmitted={isVirtualServiceSubmitted}
          />
        );
      case "tdmHttpForward":
        return (
          <TDMHttpForward
            actionData={action.actionData}
            index={indexValue}
            handleFEPHTTPResponseActionsUpdate={handleFEPHTTPResponseActionsUpdate}
            isEditable={isEditable}
            mASSLCertAliases={mASSLCertAliases}
            isTemplate={action.templated}
            isVirtualServiceSubmitted={isVirtualServiceSubmitted}
          />
        );  
      case "jmsWriteQueue":
      case "jmsWriteTopic":
        return (
          <JMS
            actionData={action.actionData}
            index={indexValue}
            actionType={action.actionType}
            handleFEPJMSResponseActionsUpdate={handleFEPJMSResponseActionsUpdate}
            isEditable={isEditable}
          />
        );
      case "tibcoWriteQueue":
      case "tibcoWriteTopic":
        return (
          <Tibco
            actionData={action.actionData}
            index={indexValue}
            actionType={action.actionType}
            handleFEPTibcoResponseActionsUpdate={handleFEPTibcoResponseActionsUpdate}
            isEditable={isEditable}
          />
        );
      case "kinesisWrite":
        return (
          <Kinesis
            actionData={action.actionData}
            index={indexValue}
            actionType={action.actionType}
            handleFEPKinesisResponseActionsUpdate={handleFEPKinesisResponseActionsUpdate}
            isEditable={isEditable}
          />)
      case "executeJS":
        return (
          <ExecuteJS
            actionData={action.actionData}
            index={indexValue}
            actionType={action.actionType}
            handleFEPExecuteJSResponseActionsUpdate={handleFEPExecuteJSResponseActionsUpdate}
            isEditable={isEditable}
          />
        )
      case "properties":
        return (
          <Properties
            actionData={action.actionData}
            index={indexValue}
            actionType={action.actionType}
            handleFEPPropertiesResponseActionsUpdate={handleFEPPropertiesResponseActionsUpdate}
            isEditable={isEditable}
          />
        )
      case "database":
        return (
          <Database
            actionData={action.actionData}
            index={indexValue}
            actionType={action.actionType}
            handleFEPDatabaseResponseActionsUpdate={handleFEPDatabaseResponseActionsUpdate}
            isEditable={isEditable}
          />)
      case "sqsQueue":
        return (
          <SQS
            actionData={action.actionData}
            index={indexValue}
            actionType={action.actionType}
            handleFEPSQSResponseActionsUpdate={handleFEPSQSResponseActionsUpdate}
            isEditable={isEditable}
          />
        )
        case "ibmmqWriteQueue":
          return (
            <IBMMQ
              actionData={action.actionData}
              index={indexValue}
              actionType={action.actionType}
              handleFEPSQSResponseActionsUpdate={handleFEPSQSResponseActionsUpdate}
              isEditable={isEditable}
            />
          )
        case "smtp":
          return (
            <SMTP
              actionData={action.actionData}
              index={indexValue}
              handleFEPSMTPResponseActionsUpdate={handleFEPSMTPResponseActionsUpdate}
            />
          )
      default: return [];
    }
  };
  const [isExpanded, setIsExpanded] = useState(true);
  const handleCollapseBtnClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <Card className="p-2" key={index} hidden={!areFEPResponseActionsEnabled}>
        <Row className="align-items-center">
          <Col xs="1">
            <Tooltip title="collapse/expand">
              <IconButton
                component="span"
                className="p-0"
                onClick={handleCollapseBtnClick}
              >
                {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </IconButton>
            </Tooltip>
          </Col>
          <Col xs="auto" className="pt-1 pl-1 pr-2">
            <Typography variant="h6" className="font-weight-bold">{`Name: action${index}`}</Typography>
          </Col>
          <Col xs="auto" className="m-0 border-left border-dark pt-1 pr-1 pl-2">
            <AddToolTip
              placement="top-start"
              title={Verbs.title.responseActions.responseAction.actionLabel}
            >
              <InputLabel className="m-0"><b>Label: </b></InputLabel>
            </AddToolTip>
          </Col>
          <Col xs="auto" className="p-0">
            <Input
              // invalid={isPathInvalid(fEPRequestPath[0].value)}
              placeholder="enter a label"
              bsSize="sm"
              name="actionLabel"
              value={actionItem.label}
              onChange={(e) => handleFEPResponseActionsUpdate(e, index)}
            ></Input>
          </Col>

          <Row>
            <Col ml={3} className="pl-md-4">
              <Tooltip title="Reorder this action">
                <IconButton
                  aria-label="reorder action"
                  component="span"
                  className="p-0 pt-1 ml-0"
                  onClick={() => handleReorderResponseActions(index)}
                  hidden={!isEditable}
                >
                  <Reorder
                    fontSize="small"
                    style={{ color: palette.purple }}
                  />
                </IconButton>
              </Tooltip>
            </Col>
            <Col className="pl-0">
              <Tooltip title="Clone this action">
                <IconButton
                  aria-label="clone action"
                  component="span"
                  className="p-0 pt-1 ml-0"
                  onClick={() => handleCloneResponseAction(index)}
                  hidden={!isEditable}
                >
                  <FileCopy
                    fontSize="small"
                    style={{ color: palette.blue }}
                  />
                </IconButton>
              </Tooltip>
            </Col>
            {actionsLength === 1 ? null : (
              <Col className="pl-0">
                <Tooltip title="Remove this action">
                  <IconButton
                    aria-label="remove action"
                    component="span"
                    className="p-0 pt-1 ml-0"
                    onClick={() => handleRemoveResAction(index)}
                    hidden={!isEditable}
                  >
                    <RemoveCircle
                      fontSize="small"
                      style={{ color: palette.danger }}
                    />
                  </IconButton>
                </Tooltip>
              </Col>
            )}

          </Row>
        </Row>
        <Collapse in={isExpanded} timeout="auto">
          <Row className="m-1 mt-2">
            <Col className="p-0">
              <Row className="d-flex align-items-center">
                <Col xs="auto" className="m-0">
                  <AddToolTip placement="top-start" title={Verbs.title.responseActions.responseAction.actionType}>
                    <InputLabel className="asterisk">Action type</InputLabel>
                  </AddToolTip>
                </Col>
                <Col xs="auto">
                  <Input
                    type="select"
                    bsSize="sm"
                    name="actionType"
                    value={actionItem.actionType}
                    onChange={(e) => handleFEPResponseActionsUpdate(e, index)}
                  >
                    {response_action_types.map(({ label, value }) => {
                      return <option key={value} value={value}>{label}</option>;
                    })}
                  </Input>
                </Col>
                <Col xs="auto" className="m-0 border-left border-dark">
                  <AddToolTip
                    placement="top-start"
                    title={Verbs.title.responseActions.responseAction.template}
                  >
                    <InputLabel className="m-0">Template</InputLabel>
                  </AddToolTip>
                </Col>
                <Col className="text-right p-0" xs="auto">
                  <Switch
                    className="d-flex align-items-center"
                    onChange={(checked) =>
                      handleFEPResponseActionsUpdate(
                        { target: { name: "actionTemplated", value: checked } },
                        index
                      )
                    }
                    checked={actionItem.templated}
                    height={20}
                    width={40}
                    disabled={!isEditable}
                    onColor={palette.primary}
                  />
                </Col>
              </Row>
              <Row className="mt-2 d-flex align-items-center">
                <Col xs="auto" className="m-0">
                  <AddToolTip
                    placement="top-start"
                    title={Verbs.title.responseActions.responseAction.async}
                  >
                    <InputLabel className="m-0">Async</InputLabel>
                  </AddToolTip>
                </Col>
                <Col className="text-right" xs="auto">
                  <Switch
                    className="d-flex align-items-center"
                    onChange={(checked) =>
                      handleFEPResponseActionsUpdate(
                        { target: { name: "actionAsync", value: !checked } },
                        index
                      )
                    }
                    checked={!actionItem.synchronous}
                    height={20}
                    width={40}
                    disabled={!isEditable}
                    onColor={palette.primary}
                  />
                </Col>
                <Col xs="auto" className="m-0 border-left border-dark" hidden={actionItem.synchronous}>
                  <AddToolTip
                    placement="top-start"
                    title={Verbs.title.responseActions.responseAction.delay}
                  >
                    <InputLabel className="m-0">Delay</InputLabel>
                  </AddToolTip>
                </Col>
                <Col className="text-right" xs="auto" hidden={actionItem.synchronous}>
                  <Switch
                    className="d-flex align-items-center"
                    onChange={(checked) =>
                      handleFEPResponseActionsUpdate(
                        { target: { name: "actionAsyncDelay", value: checked } },
                        index
                      )
                    }
                    checked={actionItem.delay}
                    height={20}
                    width={40}
                    disabled={!isEditable}
                    onColor={palette.primary}
                  />
                </Col>
                <Col xs="auto" className="m-0 border-left border-dark" hidden={!actionItem.delay}>
                  <AddToolTip
                    placement="top-start"
                    title={Verbs.title.responseActions.responseAction.delayValue}
                  >
                    <InputLabel className="m-0">Value</InputLabel>
                  </AddToolTip>
                </Col>
                <Col className="text-right pl-0" hidden={!actionItem.delay}>
                  <Input
                    bsSize="sm"
                    min={0}
                    max={3600}
                    name="actionAsyncDelaySeconds"
                    type={actionItem.templated ? "text" : "number"}
                    onChange={(e) => handleFEPResponseActionsUpdate(e, index)}
                    value={actionItem.delaySeconds}
                  />
                </Col>
              </Row>
              <Row className="mt-2 d-flex align-items-center">
                <Col xs="3" className="m-0">
                  <AddToolTip
                    placement="top-start"
                    title={Verbs.title.responseActions.responseAction.condition}
                  >
                    <InputLabel className="m-0">Condition</InputLabel>
                  </AddToolTip>
                </Col>
                <Col className="text-right pl-0">
                  <Input
                    // invalid={isPathInvalid(fEPRequestPath[0].value)}
                    placeholder="enter condition scriptlet"
                    bsSize="sm"
                    name="actionCondition"
                    value={actionItem.condition}
                    onChange={(e) => handleFEPResponseActionsUpdate(e, index)}
                  ></Input>
                </Col>
              </Row>

              <Row className="mt-2 d-flex align-items-center">
                <Col xs="3" className="m-0">
                  <AddToolTip
                    placement="top-start"
                    title={Verbs.title.responseActions.responseAction.forEach}
                  >
                    <InputLabel className="m-0">For each</InputLabel>
                  </AddToolTip>
                </Col>
                <Col className="text-right pl-0">
                  <Input
                    // invalid={isPathInvalid(fEPRequestPath[0].value)}
                    placeholder="enter scriptlet"
                    bsSize="sm"
                    name="actionForEach"
                    value={actionItem.forEach}
                    onChange={(e) => handleFEPResponseActionsUpdate(e, index)}
                  ></Input>
                </Col>
              </Row>
              <Divider className="mt-2" />
              {renderCorrespondingResAction(actionItem, index)}
            </Col>
          </Row>
        </Collapse>
        <Row>
          {((index === actionsLength - 1) && !(hasExceededResponseActionRows(actionsLength))) ? (
            <Col className="text-right px-2">
              <Tooltip title="Add another action">
                <IconButton
                  aria-label="add action"
                  component="span"
                  className="p-0 pt-1 mr-1"
                  onClick={() => handleAddResAction()}
                  hidden={!isEditable}
                >
                  <AddCircle fontSize="small" color="secondary" />
                </IconButton>
              </Tooltip>
            </Col>
          ) : null}
        </Row>
      </Card>
    </>
  );
}
