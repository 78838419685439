import React, { useEffect, useState } from "react";
import { Button, Col , Row } from "reactstrap"
import Select from 'react-select';
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { getProgramList } from "../../../services/user.service";
import errorResponses from "../../ErrorHandling/errorResponses"
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import workspaceAccessService from "../../../services/workspaceAccessManagement.service";



const RequestTeamsAccess = ({openDialog, setOpenDialog,requestTeamsAccess}) => {
    const [teamsList, setTeamsList] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [selectedProgram , setSelectedProgram] = useState({});
    const [selectedTeam , setSelectedTeam] = useState({});
    let history = useHistory();


    const requestAccess = () =>{
        let reqParams = {
            program_id : selectedProgram.value,
            team_id : selectedTeam.value,
        };
        requestTeamsAccess(reqParams);
    }


    const getPrograms = () => {
        getProgramList().then((response) => {
          if (response) {
            let allPrograms = response?.data?.data;
            let programsOptions = [];
            for (let i = 0; i < allPrograms.length; i++) {
              let program = allPrograms[i];
              programsOptions.push({ value: program.value, label: program.display });
            }
            setProgramList(programsOptions);
          }
          else {
            Swal.fire("Error!", errorResponses.REGISTER_PROGRAM_ERROR , "error")
          }
        })
          .catch((error) => {
            Swal.fire("Error!", errorResponses.REGISTER_PROGRAM_ERROR , "error")
            history.push({
              pathname: "errorPage",
              state: {
                errorMessage: errorResponses.GENERIC_ERROR
              }
            });
          });
      };

      const getTeamsList = async (programId) => {
        workspaceAccessService.getRequestAccessTeamsList(programId).then((response) => {
            if (response) {
              let allTeams = response.data.data;
              let teamsOptions = [];
              for (let i = 0; i < allTeams.length; i++) {
                let teamData = allTeams[i];
                teamsOptions.push({ value: teamData.team.value, label: teamData.team.display });
              }
              setTeamsList(teamsOptions);
            }
            else {
              Swal.fire("Error!", errorResponses.REGISTER_TEAMS_ERROR, "error")
            }
          }).catch((error) => {
            Swal.fire("Error!", errorResponses.REGISTER_TEAMS_ERROR, "error")
            history.push({
              pathname: "errorPage",
              state: {
                errorMessage: errorResponses.GENERIC_ERROR
              }
            });
          });
      }

      useEffect(() => {
        getPrograms();
      }, []);

    return (
        <Dialog
                    onClose={() => setOpenDialog(false)}
                    open={openDialog}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogContent>
                      <h4>Request Teams Access</h4>
                      <hr></hr>
                      <Row className="mt-2 text-left mb-xl-5" style = {{ height : '110px'}}>
                          <Col xs="6">
                              <label className="asterisk">Program Name</label>
                              <Select
                                required
                                id="programName"
                                name="programName"
                                data-testid="programName"
                                maxMenuHeight={80}
                                onChange={(e) => {
                                    setSelectedProgram(e);
                                    getTeamsList(e.value);
                                }}
                                options={programList}
                              />
                          </Col>
                          <Col xs="6">
                              <label className="asterisk">Team Name</label>
                              <Select
                                required
                                d="teamName"
                                name="teammName"
                                data-testid="teamName"
                                maxMenuHeight={80}

                                options={teamsList}
                                onChange={(e)=>{
                                    setSelectedTeam(e)
                                }}
                              />
                          </Col>
                      </Row>
                    </DialogContent>
                    <DialogActions>
                      <Button
                          variant='contained'
                          onClick={() => setOpenDialog(false)}
                          color='secondary'
                      >
                          Cancel
                      </Button>
                      <Button
                          variant='contained'
                          color='primary'
                          disabled={(!selectedTeam.value || !selectedProgram.value )}
                          name='add'
                          onClick={() => {requestAccess()}}
                      >
                          Request Access
                      </Button>
                    </DialogActions>
                </Dialog>
    )
}

export default RequestTeamsAccess;