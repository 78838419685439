import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import {
  addUserTeams,
} from '../../../services/registeration.service';
import Swal from 'sweetalert2';
import errorResponses from "../../ErrorHandling/errorResponses"

import { Clear } from '@material-ui/icons';
import {
  Form,
  Row,
  Col,
  Container,
  Input,
} from 'reactstrap';
import { useHistory } from "react-router-dom";

import accessManageService from '../../../services/accessManagement.service';
const columns = [
  { title: 'Name', field: 'user_name' },
  { title: 'Email', field: 'email' },
  { title: 'Team', field: 'team_name' },
  { title: 'Program', field: 'program_name' },
  // { title: "Status", field: "status" },
  { title: 'Type', field: 'type' },
  {
    title: 'Created On',
    field: 'created_datetime',
    type: 'datetime',
    defaultSort: 'desc',
  },
];
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: true,
});
export default function Requests(props) {
  //Purpose : This component is for listing all request and approve or Reject any request.

  const [ , setTable] = useState(false);
  const [adminFilterValues, setAdminFilterValues] = useState([
    {
      user_name: '',
      updated_datetime: '',
    },
  ]);
  const [actionbutton, setActionbutton] = useState([]);
  const [checkRoleAdmin, setRoleAdmin] = useState('GENERAL');
  const [ , setRoleFtl] = useState(false);
  let history = useHistory();

  const [dropDownApprovedOrAwaiting, setDropDownApprovedOrAwaiting] = useState('approved');
  const [data, setData] = useState([
    {
      user_name: '',
      updated_datetime: '',
    },
  ]);

  const items = [
    {
      icon: 'done',
      tooltip: 'Approved',
      onClick: (event, rowData) => {
        swalWithBootstrapButtons
          .fire({
            title: 'Approve?',
            text: 'Are you sure you want to approve the request?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, approve it!',
            confirmButtonColor: '#007bff',
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#6c757d',
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              handleSendActivation(rowData, 'approved');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              //do nothing
            }
          });
      },
    },
    {
      icon: () => <Clear />,
      tooltip: 'Reject',
      onClick: (event, rowData) => {
        swalWithBootstrapButtons
          .fire({
            title: 'Reject?',
            text: 'Are you sure you want to reject the request?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, reject it!',
            confirmButtonColor: '#007bff',
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#6c757d',
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              handleSendActivation(rowData, 'rejected');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              //do nothing
            }
          });
      },
    },
  ];

  const handleSendActivation = async (rowData, action) => {
    // since items is passed as constant, we have to make a call to check FTl again
    try {
      var response;
      let isAdmin = (await accessManageService.isFtlOrAdmin()).data.data.role;
      let isFtlorDelegate = (await accessManageService.isFtlOrDelegate()).data
        .data.isFtlOrDelegate;

      if (isAdmin === 'ADMIN') {
        if (rowData.type === "User") {
          response = await accessManageService.responseAdminUserRequest({
            email: rowData.email,
            response_action: action,
            rowData: rowData
          });
        }
        else {
          response = await accessManageService.responseAdminUserTeamsReq({
            team_id: rowData.team_id,
            user_id: rowData.id,
            response_action: action,
            rowData: rowData
          });
        }
      } else if (isFtlorDelegate) {
        response = await accessManageService.responseFtl({
          email: rowData.email,
          response_action: action,
          rowData: rowData
        });
      }
      if (response.data.code === 200) {

        if (response.data.message === 'Request approved.') {
          if (isAdmin == "ADMIN" && rowData.type === "User") {
            addUserTeams(rowData.team_id, rowData.email, "ADMIN").then(
              async () => {

                Swal.fire(response.data.message).then(
                  async () => {
                    await getNewRequestData(dropDownApprovedOrAwaiting);
                    setTableData();
                  }
                )
              }
            )
          }
          else {
            await getNewRequestData(dropDownApprovedOrAwaiting);
            setTableData();
          }
        } else {
          await getNewRequestData(dropDownApprovedOrAwaiting);
          setTableData();
          Swal.fire('Request Rejected');
        }
      } else {
        Swal.fire('Error', response.message, 'error');
      }
    }
    catch (error) {


      history.push({
        pathname: "errorPage",
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        }
      });

    }
  };

  const getNewRequestData = async (selectedDropDown) => {
    try {
      let isAdmin = (await accessManageService.isFtlOrAdmin()).data.data.role;
      let isFtlorDelegate = (await accessManageService.isFtlOrDelegate()).data
        .data.isFtlOrDelegate;
      if (isAdmin == 'ADMIN') {
        let response = await accessManageService.getAllRequests();

        if (response !== undefined) setData(response.data);
        setRoleAdmin(isAdmin);
        let filtereValues;
        if (selectedDropDown === 'approved') {
          // status 1 means it is approved by FTL and awaiting ADMIN approval
          filtereValues = response.data.data.filter(
            (dataItem) => dataItem.status == '1'
          );
        } else {
          filtereValues = response.data.data.filter(
            (dataItem) =>
              dataItem.status == '2' && dataItem.type === 'User'
          );
        }
        setAdminFilterValues(filtereValues);
      } else if (isFtlorDelegate) {
        let response = await accessManageService.getAllFtlRequests();
        setRoleFtl(isFtlorDelegate);
        if (response !== undefined) setData(response.data);
      }

      setActionbutton(items);
    }
    catch (error) {
      history.push({
        pathname: "errorPage",
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        }
      });

    }
  };

  const setTableData = () => {
    setTable(true);
  };

  useEffect(() => {
    getNewRequestData(dropDownApprovedOrAwaiting);

    // setActionbutton(items)
  }, [dropDownApprovedOrAwaiting]);

  return (
    <Container>
      <div className='white mt-3'>
        <Row>
          <Col xs='3'>
            {checkRoleAdmin == 'ADMIN' ? (
              <Input
                type='select'
                bsSize='sm'
                value={dropDownApprovedOrAwaiting}
                onChange={(e) => {
                  let value = e.target.value;
                  setDropDownApprovedOrAwaiting(e.target.value);
                  if (value === 'approved') {
                    // status 1 means it is approved by FTL and awaiting ADMIN approval
                    let filtereValues = data.data.filter(
                      (dataItem) => dataItem.status == '1'
                    );
                    setAdminFilterValues(filtereValues);
                  } else {
                    let filtereValues = data.data.filter(
                      (dataItem) =>
                        dataItem.status == '2' && dataItem.type === 'User'
                    );
                    setAdminFilterValues(filtereValues);
                  }
                }}
              >
                <option
                  // isselected={'true'}
                  value='approved'>
                  Approved by FTL
                </option>
                <option value='not_approved'>Awaiting FTL Approval</option>
              </Input>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <br></br>
        <Form>
          <Row>
            <MaterialTable
              style={{ boxShadow: 'none', width: '100%', display: 'block' }}
              columns={columns}
              data={checkRoleAdmin === 'ADMIN' ? adminFilterValues : data.data}
              actions={actionbutton}
              title={''}
              options={{
                headerStyle: {
                  fontWeight: 'bold',
                },
                actionsColumnIndex: -1,
              }}
            />
          </Row>
        </Form>
      </div>
    </Container>
  );
}
