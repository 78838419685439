import React, { useState, useEffect } from 'react';
import {
  Button, Col, Row, Card, CardBody,
  CardTitle, CardText
} from "reactstrap";
import pasmatService from '../../services/pasmat.service';
import { PasmatMainWrapper, SubHeading } from './PasmatMainWrapper';
import './pasmat.css'
import Select from 'react-select';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
const Plot = createPlotlyComponent(Plotly);


/**
 * It generates data for plotly to plot a single bubble
 * @param {*} x
 * @param {*} y
 * @param {*} marker
 * @param {*} name
 * @returns
 */
const generateBubbles = (data, xKey, yKey, markerSizeKey, labelKey) => {
  return data.map((bubbleData, index) => {
    return {
      x: [bubbleData[xKey]],
      y: [bubbleData[yKey]],
      name: bubbleData[labelKey],
      text: [`${bubbleData[markerSizeKey]}%`],
      originalData: bubbleData,
      marker: {
        size: [(50 + bubbleData[markerSizeKey] / 2)],
      },
      mode: 'markers+text',
      textfont: {
        color: 'white',
      }
    }
  })
};

const PasmatTrend = () => {
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [valueChains, setValueChains] = useState([]);
  const [selectedValueChain, setSelectedValueChain] = useState(null);
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamTrendData, setTeamTrendData] = useState([]);
  const [drillDownLevel, setDrillDownLevel] = useState("cycle");
  const [bubbleGraphTitle, setBubbleGraphTitle] = useState("PASMAT Overall Adoption Maturity - Programs");
  const bubbleGraphXTitle = "Number of Virtual Services";
  const bubbleGraphYTitle = "Number of Assessments";
  const handleDrillDownSelectionChange = (e, f) => {
    const customDrillDownLevel = f ? f : drillDownLevel
    const chosenOne = (e.points) ? e.points[0].data.originalData : e;
    switch (customDrillDownLevel) {
      case "cycle":
        setSelectedProgram(chosenOne);
        setSelectedValueChain(null);
        setSelectedApplication(null);
        setSelectedTeam(null);
        break;
      case "program":
        setSelectedValueChain(chosenOne);
        setSelectedApplication(null);
        setSelectedTeam(null);
        break;
      case "valueChain":
        setSelectedApplication(chosenOne);
        setSelectedTeam(null);
        break;
      case "application":
        setSelectedTeam(chosenOne);
        break;
      case "team":
        break;
      default:
        break;
    }
  }
  const layout = {
    title: bubbleGraphTitle,
    hovermode: 'closest',
    showlegend: true,
    autosize: true,
    // legend: { orientation: 'h', x: 0.4 },
    legend: { y: 0.3 },
    xaxis: {
      automargin: true,
      title: {
        text: bubbleGraphXTitle,
        standoff: 40
      }
    },
    yaxis: {
      automargin: true,
      title: {
        text: bubbleGraphYTitle,
        standoff: 40
      }
    },
  }
  const resetFilters = () => {
    setSelectedTeam(null);
    setSelectedApplication(null);
    setSelectedValueChain(null);
    setSelectedProgram(null);
  }
  const loadProgramsTrendData = (cycle) => {
    if (selectedCycle) {
      pasmatService.getPASMATProgramsTrendReport(selectedCycle.value)
        .then((response) => {
          setPrograms(response.data);
          setBubbleGraphData(generateBubbles(response.data, "total_stubs", "total_assessments", "maturity_percent", "label"))
        })
    }
  };
  const loadValueChainsTrendData = (program) => {
    pasmatService.getPASMATValueChainsTrendReport(selectedCycle.value, selectedProgram.value)
      .then((response) => {
        setValueChains(response.data);
        setBubbleGraphData(generateBubbles(response.data, "total_stubs", "total_assessments", "maturity_percent", "label"))
      })

  };
  const loadApplicationsTrendData = (program, valueChain) => {
    pasmatService.getPASMATApplicationsTrendReport(selectedCycle.value, selectedValueChain.value)
      .then((response) => {
        setApplications(response.data);
        setBubbleGraphData(generateBubbles(response.data, "total_stubs", "total_assessments", "maturity_percent", "label"))
      })
  };
  const loadTeamsTrendData = (program, valueChain, application) => {
    pasmatService.getPASMATTeamsTrendReport(selectedCycle.value, selectedApplication.value)
      .then((response) => {
        setTeams(response.data);
        setBubbleGraphData(generateBubbles(response.data, "total_stubs", "total_assessments", "maturity_percent", "label"))
      })
  };

  const loadTeamTrendData = () => {
    setBubbleGraphData(generateBubbles([selectedTeam], "total_stubs", "total_assessments", "maturity_percent", "label"));
    pasmatService.getPASMATTeamDetailedTrendReport(selectedTeam.team_id, selectedTeam.value)
      .then((response) => {
        setTeams(response.data);
        setTeamTrendData(response.data);
      })
  };
  const [bubbleGraphData, setBubbleGraphData] = useState(null);
  const loadBubbleGraphData = () => {
    if (!selectedProgram) {
      // If no program is selected, load programs data
      setDrillDownLevel("cycle");
      setBubbleGraphTitle("PASMAT Overall Adoption Maturity - Programs");
      loadProgramsTrendData();
    }
    else if (!selectedValueChain) {
      setDrillDownLevel("program");
      setBubbleGraphTitle(`PASMAT Overall Adoption Maturity - Value Chains for ${selectedProgram.label} program`);
      loadValueChainsTrendData();
      // valuechain is not selected - load valuechains data
    }
    else if (!selectedApplication) {
      setDrillDownLevel("valueChain");
      setBubbleGraphTitle(`PASMAT Overall Adoption Maturity - Applications for ${selectedValueChain.label} value chain`);
      loadApplicationsTrendData();
      // application is not selected - load applications data
    }
    else if (!selectedTeam) {
      setDrillDownLevel("application");
      setBubbleGraphTitle(`PASMAT Overall Adoption Maturity - Teams for ${selectedApplication.label} application`);
      loadTeamsTrendData();
      // team is not selected - load teams data
    }
    else {
      setDrillDownLevel("team");
      setBubbleGraphTitle(`PASMAT Overall Adoption Maturity - ${selectedTeam.label}`);
      loadTeamTrendData();
      // - load team data
      // - load special graphs for the team
    }
  };

  const [cycles, setCycles] = useState([]);
  const [selectedCycle, setSelectedCycle] = useState(null);

  const handleCycleChange = (e) => {
    resetFilters();
    setSelectedCycle(e);
    // setSelectedCycle();
    // Load bubblegraph data
  }


  const loadCycleData = () => {
    pasmatService.getPASMATCylceData()
      .then((response) => {
        setCycles(response.data);
        setSelectedCycle(response.data.filter(item => item.current)[0]);
      })
      .catch((error) => {
        console.log(error);
      })
    // Set current cycle as the selected cycle
  }
  const loadInitialData = () => {
    loadCycleData();
    loadBubbleGraphData();
  }
  useEffect(() => {
    loadInitialData();
  }, []);


  useEffect(() => {
    loadBubbleGraphData();
  }, [selectedProgram, selectedValueChain, selectedApplication, selectedTeam, selectedCycle]);

  const renderTeamTrendGraph = () => {
    const x = []; // PI or Cycle
    const y = []; // Adoption Percentage

    teamTrendData.forEach(cycle => {
      x.push(cycle.label);
      y.push(cycle.maturity_percent);
    })

    const trace = {
      x: x,
      y: y,
      type: 'scatter',
      name: `${selectedTeam.label} trend`,
    }

    const teamTrendlayout = {
      title: `Team Trend Analysis Per Cycle - ${selectedTeam.label}`,
      hovermode: 'closest',
      showlegend: true,
      autosize: true,
      legend: { y: 0.3 },
      xaxis: {
        automargin: true,
        title: {
          text: "Cycle",
          standoff: 40
        }
      },
      yaxis: {
        automargin: true,
        title: {
          text: "Adoption Percentage (%)",
          standoff: 40
        }
      },
    };

    return (
      <Plot
        useResizeHandler
        style={{ width: "100%" }}
        data={[trace]}
        layout={teamTrendlayout}
        config={{ displaylogo: false }}
      />
    )
  };

  const renderLatestReportMetrics = () => {
    //Get the latest reporting metrics data
    let latest_trend_analysis_metrics = teamTrendData.filter(item => item.latest)[0];
    if (!latest_trend_analysis_metrics) {
      console.log(teamTrendData);
    }

    const latest_cycle = latest_trend_analysis_metrics.value;
    const cycleQuarter = latest_trend_analysis_metrics.label.substring(0, 2);
    const maturity_level = latest_trend_analysis_metrics.maturity_percent;
    const productivity_improved = latest_trend_analysis_metrics.productivityPercentage;
    const cost_saved = latest_trend_analysis_metrics.cost_saved;

    return (
      <>
        <Row style={{ marginTop: 20 }}>
          <Col className="text-center">
            <SubHeading
              content={`Latest Trend Analysis Report Metrics - ${selectedTeam.label}`}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={3}>
            <Card>
              <CardBody>
                <CardTitle tag="h5" style={{ fontSize: 18 }} className="text-center">Latest Cycle</CardTitle>
                <CardText>
                  <div style={{ width: "60%", marginLeft: '20%' }}>
                    <CircularProgressbar
                      value={latest_cycle}
                      maxValue={latest_cycle}
                      text={cycleQuarter}
                      styles={buildStyles({
                        textColor: "#3e7ad8",
                        pathColor: "#3e7ad8"
                      })}
                    />
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs={3}>
            <Card>
              <CardBody>
                <CardTitle tag="h5" style={{ fontSize: 18 }} className="text-center">Maturity Level</CardTitle>
                <CardText>
                  <div style={{ width: "60%", marginLeft: '20%' }}>
                    <CircularProgressbar
                      value={maturity_level}
                      maxValue={100}
                      text={`${maturity_level}%`}
                      styles={buildStyles({
                        textColor: "#ff0db3",
                        pathColor: "#ff0db3"
                      })}
                    />
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs={3}>
            <Card>
              <CardBody>
                <CardTitle tag="h5" style={{ fontSize: 18 }} className="text-center">Productivity Improved</CardTitle>
                <CardText>
                  <div style={{ width: "60%", marginLeft: '20%' }}>
                    <CircularProgressbar
                      value={productivity_improved}
                      maxValue={100}
                      text={`${productivity_improved}%`}
                      styles={buildStyles({
                        textColor: "#ff890d",
                        pathColor: "#ff890d"
                      })}
                    />
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs={3}>
            <Card>
              <CardBody>
                <CardTitle tag="h5" style={{ fontSize: 18 }} className="text-center">Cost Saved</CardTitle>
                <CardText>
                  <div style={{ width: "60%", marginLeft: '20%' }}>
                    <CircularProgressbar
                      value={cost_saved}
                      maxValue={100}
                      text={`$${cost_saved.toLocaleString()}`}
                      styles={buildStyles({
                        textColor: "#ff0db3",
                        pathColor: "#ff0db3"
                      })}
                    />
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <PasmatMainWrapper>
      <Row>
        <Col xs="10"></Col>
        <Col>
          <span className="font-weight-bold">Filter Cycle: </span>
          <Select
            className="trend-select"
            options={cycles}
            value={selectedCycle}
            onChange={(e) => handleCycleChange(e)}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={3}>
          <span className="font-weight-bold">Program: </span>
          <Select
            className="trend-select"
            placeholder="Select program"
            options={programs}
            value={selectedProgram}
            onChange={(e) => handleDrillDownSelectionChange(e, "cycle")}
          />
        </Col>
        <Col xs={3}>
          <span className="font-weight-bold">Value chain: </span>
          <Select
            className="trend-select"
            placeholder="Select value chain"
            options={valueChains}
            value={selectedValueChain}
            onChange={(e) => handleDrillDownSelectionChange(e, "program")}
          />
        </Col>
        <Col xs={3}>
          <span className="font-weight-bold">Application: </span>
          <Select
            className="trend-select"
            placeholder="Select application"
            options={applications}
            value={selectedApplication}
            onChange={(e) => handleDrillDownSelectionChange(e, "valueChain")}
          />
        </Col>
        <Col xs={3}>
          <span className="font-weight-bold">Team: </span>
          <Select
            className="trend-select"
            placeholder="Select team"
            options={teams}
            value={selectedTeam}
            onChange={(e) => handleDrillDownSelectionChange(e, "application")}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Button
            className="float-right"
            style={{ backgroundColor: '#001e82' }}
            size="sm"
            onClick={() => resetFilters()}> Clear All
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12}>
          <Plot
            useResizeHandler
            style={{ width: "100%" }}
            data={bubbleGraphData}
            layout={layout}
            config={{ displaylogo: false }}
            onClick={(e) => handleDrillDownSelectionChange(e)}
          />
        </Col>
      </Row>
      {
        selectedTeam &&
        <>
          <Row className="mt-3">
            <Col xs={12} hidden={!selectedTeam}>
              {renderTeamTrendGraph()}
            </Col>
          </Row>
          {renderLatestReportMetrics()}
        </>
      }

    </PasmatMainWrapper>
  )
}

export default PasmatTrend;