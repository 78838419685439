export const maturityTable = [{
    "percentageRange": "0-20%",
    "title": "Ad-hox",
    "backgroundColor": "#ff0000"
}, {
    "percentageRange": "20-40%",
    "title": "Repeatable",
    "backgroundColor": "#d07605"
}, {
    "percentageRange": "40-60%",
    "title": "Consistent",
    "backgroundColor": "#ffaa00"
}, {
    "percentageRange": "60-80%",
    "title": "Optimised",
    "backgroundColor": "#87c114"
}, {
    "percentageRange": "80-100%",
    "title": "Leading",
    "backgroundColor": "#6fa00a"
}]

export const pasmatReportMetrics = (metricsReport) => [{
    "header": "Productivity improved",
    "value": metricsReport.productivityPercentage,
    "textColor": "#3e7ad8"
}, {
    "header": "Transaction Usage",
    "subHeader": "Number of transactions made",
    "value": metricsReport.transactionPercentage,
    "textColor": "#ff890d"
}, {
    "header": "Test phase usage (NFT & FT)",
    "subHeader": "",
    "value": metricsReport.answerPercentage,
    "textColor": "#ff0db3"
},
{
    "header": "Optimal usage of created virtual services",
    "subHeader": "",
    "value": metricsReport.percentEndpointsUsed,
    "textColor": "#ff0db3"
}

];

// TODO unused code remove after 30 days - written on 8/11/2021
// export const cyclesDummyData = [
//     {
//         "value": 2,
//         "start_date": "2022-04-01T00:00:00.000Z",
//         "end_date": "2022-06-30T00:00:00.000Z",
//         "label": "Q4 : FY21",
//         "rollout_email_sent": 1,
//         "current": false
//     },
//     {
//         "value": 3,
//         "start_date": "2021-07-01T00:00:00.000Z",
//         "end_date": "2021-09-30T00:00:00.000Z",
//         "label": "Q1 : FY22",
//         "rollout_email_sent": 1,
//         "current": false
//     },
//     {
//         "value": 4,
//         "start_date": "2021-10-01T00:00:00.000Z",
//         "end_date": "2021-12-31T00:00:00.000Z",
//         "label": "Q2 : FY22",
//         "rollout_email_sent": 1,
//         "current": true
//     },
//     {
//         "value": 5,
//         "start_date": "2022-01-01T00:00:00.000Z",
//         "end_date": "2022-03-31T00:00:00.000Z",
//         "label": "Q3 : FY22",
//         "rollout_email_sent": 0,
//         "current": false
//     }
// ];

// export const programsTrendDummyData = [
//     {
//         value: "b2b",
//         label: "B2B Digitisation",
//         maturity_percent: 34,
//         total_assessments: 4,
//         total_stubs: 100
//     },
//     {
//         value: "b2c",
//         label: "B2C Digitisation",
//         maturity_percent: 45,
//         total_assessments: 7,
//         total_stubs: 200
//     },
//     {
//         value: "omp",
//         label: "Other Major Programs",
//         maturity_percent: 25,
//         total_assessments: 2,
//         total_stubs: 60
//     }
// ]

// export const vcsTrendDummyData = [
//     {
//         value: "u2c",
//         label: "U2C",
//         maturity_percent: 60,
//         total_assessments: 3,
//         total_stubs: 60
//     },
//     {
//         value: "r2r",
//         label: "R2R",
//         maturity_percent: 50,
//         total_assessments: 3,
//         total_stubs: 30
//     },
//     {
//         value: "test",
//         label: "Test",
//         maturity_percent: 10,
//         total_assessments: 1,
//         total_stubs: 20
//     }
// ]

// export const appsTrendDummyData = [
//     {
//         value: "appA",
//         label: "AppA",
//         maturity_percent: 80,
//         total_assessments: 1,
//         total_stubs: 30
//     },
//     {
//         value: "appB",
//         label: "AppB",
//         maturity_percent: 60,
//         total_assessments: 2,
//         total_stubs: 15
//     },
//     {
//         value: "appC",
//         label: "AppC",
//         maturity_percent: 20,
//         total_assessments: 4,
//         total_stubs: 12
//     }
// ]

// export const teamsTrendDummyData = [
//     {
//         value: "TeamA",
//         label: "TeamA",
//         maturity_percent: 12,
//         total_assessments: 1,
//         total_stubs: 30
//     },
//     {
//         value: "TeamB",
//         label: "TeamB",
//         maturity_percent: 50,
//         total_assessments: 2,
//         total_stubs: 15
//     },
//     {
//         value: "TeamC",
//         label: "TeamC",
//         maturity_percent: 90,
//         total_assessments: 4,
//         total_stubs: 12
//     }
// ]

// export const teamTrendDummyData = [
//     {
//         value: 1,
//         label: 'Q1: F22',
//         maturity_percent: 30,
//         productivity_improved: 40,
//         cost_saved: 200
//     },
//     {
//         value: 2,
//         label: 'Q2: F22',
//         maturity_percent: 36,
//         latest: true,
//         productivity_improved: 50,
//         cost_saved: 400
//     }
// ]