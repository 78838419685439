import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Button } from 'reactstrap';
import _ from "lodash";
import { useHistory } from "react-router-dom";
import errorResponses from "../../ErrorHandling/errorResponses";
import Switch from "react-switch";
import { nodeColors } from '../../../assets/colors';
import { Divider } from '@material-ui/core';

export default function ReplaceToggleURL(
    {

        data,
        closeDialog,
        cjvElementsCloneData,
        setCjvElementsMethod,
        setCheckedMethod
    }
) {
    const [isChecked, setIsChecked] = useState(false);
    let history = useHistory();

    const getInputValue = () => {
        try {
            let cjVFileMapper = _.cloneDeep(cjvElementsCloneData.cjvMapper);

            if (data.stubId) {
                if (cjVFileMapper.hasOwnProperty(data.httpURL) && typeof data.httpURL !== 'undefined') {
                    return cjVFileMapper[data.httpURL].replaceHTTPURL

                }
            }
            else {
                return ''
            }
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: `${errorResponses.GENERIC_ERROR}. ${error}`
                }
            });
        }
    }
    const [realEndpoint, setRealEndpoint] = useState(getInputValue())

    const onChange = (e) => {
        setRealEndpoint(e.target.value)
    }
    const toggleChecked = () => {
        setIsChecked(!isChecked)
    }
    useEffect(() => {

        try {
            if (data.stubId) {
                let cjVFileMapper = _.cloneDeep(cjvElementsCloneData.cjvMapper);
                if (cjVFileMapper.hasOwnProperty(data.httpURL)) {
                    if (cjVFileMapper[data.httpURL].replace === true) {
                        // isChecked false means real endpoint displayed
                        setIsChecked(false)
                    }
                    else {
                        setIsChecked(true)
                    }
                }
                else {
                    setIsChecked(true)
                }
            }
        }
        catch (error) {
            history.push({
                pathname: "errorPage",
                state: {
                    errorMessage: `${errorResponses.GENERIC_ERROR}. ${error}`
                }
            });
        }
    }, [])
    const applyChanges = () => {
        //  adding appropriate CJV mappers in this function
        try {
            let cloneCJVFileData = _.cloneDeep(cjvElementsCloneData);
            if (cloneCJVFileData.cjvMapper.hasOwnProperty(data.httpURL)) {
                if (isChecked === false) {
                    let obj = {}
                    let clonedRealEndpoint = _.cloneDeep(realEndpoint);
                    obj.replaceHTTPURL = clonedRealEndpoint;
                    obj.replace = true;
                    cloneCJVFileData.cjvMapper[data.httpURL] = obj

                }
                else {
                    if (isChecked === true) {
                        //stub endpoint
                        let obj = {}
                        let clonedRealEndpoint = _.cloneDeep(realEndpoint);
                        obj.replaceHTTPURL = clonedRealEndpoint;
                        obj.replace = false;
                        cloneCJVFileData.cjvMapper[data.httpURL] = obj
                    }
                }

                setCjvElementsMethod(cloneCJVFileData)

            }
            else {
                // if replace is not present
                if (isChecked !== true) {

                    let obj = {}
                    let clonedRealEndpoint = _.cloneDeep(realEndpoint);
                    obj.replaceHTTPURL = clonedRealEndpoint;
                    obj.replace = true;
                    cloneCJVFileData.cjvMapper[data.httpURL] = obj
                    setCjvElementsMethod(cloneCJVFileData)
                }

            }
            setCheckedMethod(isChecked)
            closeDialog();
        }
        catch (error) {

        }
    }
    return (
        <div >
            <Divider />

            <Row className="mt-3 " >
                <Col xs={4} className="d-flex float-left my-auto">Selected type</Col>
                <Col xs="auto" className="d-flex flex-row justify-content-center my-auto">
                    <Switch
                        className="mr-2"
                        onChange={toggleChecked}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        offColor={nodeColors.realToggle}
                        onColor={nodeColors.stubToggle}
                        // style={{ marginBottom: '0px' }}
                        size="small"
                        height={20}
                        width={40}

                        checked={isChecked} />
                    <span > {isChecked ? 'Stub URL' : 'Toggled URL'}</span>
                </Col>
            </Row>
            <Row className="mt-3 " >
                <Col xs={4} className="d-flex float-left">
                    Stub URL
                </Col>
                <Col xs={7} className="text-left">
                    <span style={{ marginLeft: '0px' }}>{" "}{data.httpURL || ""}</span>
                </Col>
            </Row>

            <Row className="mt-3 " >
                <Col xs={4} className="d-flex float-left my-auto">Toggle URL</Col>
                <Col xs={7}>
                    <Input
                        bsSize="sm"
                        value={realEndpoint}
                        disabled={isChecked}

                        onChange={e => onChange(e)}
                    ></Input>
                </Col>
            </Row>
            <Row className="mt-3 " >
                <Col xs="8"></Col>
                <Col xs="4" className="d-flex float-right">
                    <Button className="mr-2 " color="danger" onClick={closeDialog} >
                        Cancel

                    </Button>
                    <Button color="primary" onClick={applyChanges}>
                        Apply
                    </Button>
                </Col>
            </Row>
        </div>
    )
}
