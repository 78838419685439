import axios from "axios";
import { useHistory } from "react-router-dom";
import { data } from "../utilities/logger";
import { SIGNIN, LOGOUT, CHECK_USER_EXISTS, SIGNIN_ACCOUNT01, SIGNIN_ACCOUNT01_WITH_DNUMBER, GET_TOKEN_EXPIRY, SIGN_IN_WITH_TAAS } from "./endpoints";
import { addUserDnumber } from "./user.service";

const jwtDecode = require("jwt-decode");
const customAxios = axios.create();
// Purpose : Have all method for authentication, Token storage , using profile etc


export const storeToken = (accessToken) => {
  localStorage.setItem("accessToken", accessToken);
  const decoded = jwtDecode(accessToken);
  localStorage.setItem("userRole", decoded.user.role);
  localStorage.setItem("userId", decoded.user.id);
  localStorage.setItem("userName", decoded.user.user_name);
  localStorage.setItem("userEmail", decoded.user.email);
  localStorage.setItem("dnumber", decoded.user.dnumber || null);
};

export const getToken = () => {
  //if  check URL for Telstra token in url, if present, check if user exists->convert to portal token and , store in localStoarage and return Token
  // else logout and return false as below
  if (isTokenExpired(localStorage.getItem("accessToken"))) {
    //  check if token is expired
    logout();
    return false;
  } else {
    return localStorage.getItem("accessToken");
  }
};

export const isLoggedIn = () => {
  const accessToken = getToken();
  return accessToken;
};

export const configureBotpressPostLogout = async () => {
  await window.botpressWebChat.sendEvent({ type: 'hide' });
  await window.botpressWebChat.mergeConfig(
    {
      hideWidget: true
    }
  );
};
export const logout = async () => {
  localStorage.clear();
  await configureBotpressPostLogout();
};

export const handleError = (error) => {
  if (typeof error.response !== "undefined") {
    if (error.response.status === 401) {
      console.log("Error-34::", error.response.status);
      logout();
    }
  } else {
    console.log("Error-37::", error.response);
  }
};

export const getRole = () => {
  let token = localStorage.getItem("accessToken");
  let decoded = jwtDecode(token);
  return decoded.user.role;
};

export const isTokenExpired = (accessToken) => {

  try {
    const decoded = jwtDecode(accessToken);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    // If no token is present, it will come to catch block
    return true;
  }
};

export const getExpiryCountdown = (exp, type) => {
  try {
    let expCountdown = 0;
    if (type === 'minutes')
      expCountdown = (exp - (Date.now() / 1000)) / 60;
    else
      expCountdown = exp - (Date.now() / 1000);
    return expCountdown;
  } catch (error) {
    // If no token is present, it will come to catch block
    return 0;
  }
}

export const getUserDetails = () => {
  let token = localStorage.getItem("accessToken");
  let decoded = jwtDecode(token);
  return decoded.user;
};

export const getUserRolesFromToken = (token) => {
  let decoded = jwtDecode(token);
  return decoded.user.role;
};

export const getProfileDataFromToken = (token) => {
  let decoded = jwtDecode(token);
  return decoded.user;
};
export const getEmailFromDnum = async (dnumber) => {
  let response;
  try {
    response = await axios.get('https://hangar.in.telstra.com.au/casthawk/search?criteria=' + dnumber + '&content_type=application/json');
    return response.data;
  } catch (error) {
    response = error.response;
    return response;
  }
};

export const getUserInfoFromHangar = (searchCriteria) => {
  return customAxios.get('https://hangar.in.telstra.com.au/casthawk/search?criteria=' + searchCriteria + '&content_type=application/json');
};


export const checkUserExists = async (email) => {
  var response;
  try {
    response = await axios.get(CHECK_USER_EXISTS + '?email=' + email);
    if (response)
      return response;
    else
      return false

  } catch (error) {
    response = error;
    return response;
  }
};


export const signin = async (user) => {
  let response;
  axios.defaults.headers.common = { Authorization: `Bearer ${getToken()}` };
  try {
    response = await axios.post(SIGNIN, user);
    return response.data;
  } catch (error) {
    response = error.response;
    return response;
  }

};

export const session_logout = async (user) => {
  let response;
  axios.defaults.headers.common = { Authorization: `Bearer ${getToken()}` };
  try {
    response = await axios.post(LOGOUT, user);
    await logout();
    return response.data;
  } catch (error) {
    response = error.response;
    return response;
  }
};
export const signinAccount01 = async (tokenTel, email) => {
  // tokenTel is token retrieved from Telstra URL
  let response;
  try {
    response = await axios.get(SIGNIN_ACCOUNT01 + '?email=' + email + '&token=' + tokenTel);
    return response.data;
  } catch (error) {
    response = error.response;
    return response;
  }
};

export const signinAccount01WithDnumber = async (tokenTel, dnumber) => {
  // tokenTel is token retrieved from Telstra URL
  let response;
  try {
    response = await axios.get(SIGNIN_ACCOUNT01_WITH_DNUMBER + '?dnumber=' + dnumber + '&token=' + tokenTel);
    return response.data;
  } catch (error) {
    response = error.response;
    return response;
  }
};

// telsra login

const proceedForActiveUsers = async (userData, telstraToken) => {
  let { email, dnumber } = userData;
  // if true set token and return token
  let response = await signinAccount01(telstraToken, email)
  if (response) {
    // if some error occured result will be false
    if (response.success) {
      storeToken(response.token);
      await addUserDnumber(email, dnumber);
      return { status: 'valid' };
    }
  }
  else {
    return { status: 'unauthenticated' }
  }
}

const onGetEmailSuccess = async (userData, telstraToken) => {
  let { email, name, dnumber } = userData;
  let userStatus = await checkUserExists(email);
  if (userStatus.data.success) {
    if (userStatus.data.result.status === 'active') {
      return proceedForActiveUsers(userData, telstraToken);
    }
    else {
      if (userStatus.data.result.status === 'inactive' && (userStatus.data.result.user_details.code === 2 || userStatus.data.result.user_details.code === 1)) {
        return { status: 'unapproved_yet', email: email, name: name, dnumber }
      }
      return { status: 'registeration', email: email, name: name, dnumber }
    }
  } else {
    return { status: 'unauthenticated' };
  }
}

const proceedSignInWithEmail = async (telstraToken, userDnumber) => {
  const response = await getEmailFromDnum(userDnumber);
  const email = response.mail;
  const name = response.first_name + ' ' + response.last_name;
  const dnumber = response.mAGS;
  if (email) {
    return onGetEmailSuccess({ email, name, dnumber }, telstraToken);
  }
  else {
    return { status: 'unauthenticated' };
  }
}


export const getTokenUnauthenticated = async () => {
  //  gets token if the token is there in URL
  // check if user exists, if user exists then and set the token
  // if user Doesn't exist return 'registeration' and redirect to registration page

  //  else if token is not there return a role that url is not authenticated.'unauthenticated'
  const ssoCallbackURLWithAccessToken = window.location.href;
  // Ensure accessToken is present in (string 'accessToken' is verified to be present in the URL) callback URL
  if (ssoCallbackURLWithAccessToken.includes("accessToken")) {
    try {
      // Extract JWT token sent by SSO
      const telstraToken = ssoCallbackURLWithAccessToken.split('=')[1];
      // Decode the Telstra COE SSO JWT token
      const decoded = jwtDecode(telstraToken);
      const dNumber = decoded.userId.split("/").slice(-1)[0];
      // Attempt to sign-in the user into SV system using dNumber
      let responseWithDnumber = await signinAccount01WithDnumber(telstraToken, dNumber);
      const decodedRes = jwtDecode(responseWithDnumber.token);

      if (responseWithDnumber.success && decodedRes.user.active) {
        storeToken(responseWithDnumber.token);
        return { status: 'valid' };
      }
      else if (
        responseWithDnumber.success &&
        decodedRes.user.status === 'inactive' &&
        (decodedRes.user.user_details.status === 2 || decodedRes.user.user_details.status === 1)
      ) {
        return { status: 'unapproved_yet', email: decodedRes.email, name: decodedRes.user_name, dNumber };
      }
      else {
        return await proceedSignInWithEmail(telstraToken, dNumber);
      }
    }
    catch (error) {
      return { status: 'unauthenticated' };
    }
  }
  else {
    // If URL doesn't have token
    return { status: 'unauthenticated' };
  }
};

export const getTaasTokenFromCookie = () => {
  let TaaSAccessToken = document.cookie.split('; ').find(x => x.startsWith('TaaSAccessToken='));
  return TaaSAccessToken;
}

export const signInWithTaas = async (TaaSAccessToken) => {
  try {
    if (TaaSAccessToken && TaaSAccessToken.length) {
      TaaSAccessToken = TaaSAccessToken.split('=')[1];
      const config = {
        headers: {
          Authorization: `Bearer ${TaaSAccessToken}`
        }
      }
      let response = await axios.get(`${SIGN_IN_WITH_TAAS}`, config);
      return response.data;
    } else {
      logout();
      return false
    }
  } catch (error) {
    console.log('ERROR:', error)
    let response = error.response;
    return response;
  }
}

export const getUserInfo = async () => {
  try {
    let token = getTaasTokenFromCookie();
    console.log(token)
    if (!token || !token.length) {
      logout();
      return { status: 'unauthenticated' }
    }
    let response = await signInWithTaas(token);
    // not found
    if (!response) {
      return { status: 'unauthenticated' };
    }
    if (response.success && !response.token) {
      if (response.data.status === 'notfound')
        return { status: 'registeration', user: response.data.user }
    }

    // if token
    const decodedRes = jwtDecode(response.token);
    if (response.success && decodedRes.user.active) {
      storeToken(response.token);
      return { status: 'valid', user: decodedRes.user };
    }
    else if (
      response.success &&
      decodedRes.user.status === 'inactive' &&
      (decodedRes.user.user_details.status === 2 || decodedRes.user.user_details.status === 1)
    ) {
      return { status: 'unapproved_yet', user: decodedRes.user };
    }
    else {
      return { status: 'registeration', user: decodedRes.user }
    }
  } catch (err) {
    throw err;
  }
}
